import React, { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import ExportIcon from '@material-ui/icons/Assessment';
import { excelExportFunction, ISheet } from '../../../../components/ExcelExportButton';

export interface IExcelInactiveUsersProps {
    list: any[];
}

const ExcelInactiveUsers: React.FC<IExcelInactiveUsersProps> = ({ list }) => {

    const [exportSheets, setExportSheets] = useState<ISheet[]>([]);

    useEffect(() => {

        if (!list) return;

        let sheets = [];

        sheets.push({
            sheetName: "Inactive users",
            sheetColumns: [
                { title: "Name", name: "name" },
                { title: "Surname", name: "surname" },
                { title: "Email", name: "email" },
                { title: "General", name: "general" }
            ],
            sheetData: list.map(item => {
                return {
                    name: item.userInfo.firstName,
                    surname: item.userInfo.lastName,
                    email: item.userInfo.email,
                    general: item.userInfo.general
                }
            }) || []
        });

        setExportSheets(sheets);

    }, [list]);

    return (
        <IconButton onClick={() => excelExportFunction({sheets: exportSheets})}>
            <Tooltip title="Export" aria-label="Export">
                <ExportIcon />
            </Tooltip>
        </IconButton>
    );
}

export default ExcelInactiveUsers;