import React from 'react';
import { Button, makeStyles, createStyles, Theme, CircularProgress } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import { ButtonProps } from '@material-ui/core/Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            width: '100%'
        },
        wrapper: {
            position: 'relative',
            width: '100%'
        },
        buttonProgress: {
            color: blue[800],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    }),
);

interface LoadingButtonProps {
    text: string;
    loading: boolean | undefined;
}

interface CombinedProps extends LoadingButtonProps, ButtonProps { };

const LoadingButton: React.FC<CombinedProps> = (props: CombinedProps) => {

    let classes = useStyles();
    const { disabled, loading, ...rest } = props;

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <Button
                    {...rest}
                    disabled={disabled || loading}
                    fullWidth
                >
                    {props.text}
                </Button>
                {props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
        </div>
    );
}

export default LoadingButton;