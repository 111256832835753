import React, { useState, useEffect } from 'react';
import Panel from '../../../components/Panel';
import { Formik, Form, Field } from 'formik';
import DataProvider from '../../../providers/DataProvider';
import { Grid, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import FormFooter from '../../../components/FormFooter';
import { useHistory } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { DropzoneArea } from 'material-ui-dropzone';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import AllActions from "../../../redux/actions/Index";
import { useSnackbar } from 'notistack';

const schema = Yup.object({
    department: Yup.string().required('Required'),
    company: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    subtitle: Yup.string().required('Required')
});

export interface IAddTopicProps {
}

const AddTopic: React.FC<IAddTopicProps> = () => {

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;

    let companyId = searchState.company.id !== "" ? searchState.company.id : '';
    let departmentId = searchState.department.id !== "" ? searchState.department.id : '';

    const [companySelected, setCompanySelected] = useState<any>(searchState.company);
    const [departmentSelected, setDepartmentSelected] = useState<any>(searchState.department);
    const [image, setImage] = useState<any>();

    const [departments, setDepartments] = useState<any[]>([]);
    const [companies, setCompanies] = useState<any[]>([{ id: "", name: "" }]);
    const dataProvider = new DataProvider();
    let history = useHistory();

    const onCompanySelect = (companyId: string) => {
        dataProvider.getDepartments(companyId).then(data => {
            setDepartments(data);
            setDepartmentSelected({ id: "", name: "" });
        });
    }

    useEffect(() => {
        dataProvider.getCompanies().then(data => {
            setCompanies(data);
            if (searchState.company.id !== "") {
                dataProvider.getDepartments(searchState.company.id).then(data => {
                    setDepartments(data);
                });
            }
        })
        //eslint-disable-next-line
    }, []);

    return (
        <Panel>
            <Formik
                validationSchema={schema}
                initialValues={{
                    name: "",
                    subtitle: "",
                    order: 0,
                    imageSelected: null,
                    company: companyId,
                    department: departmentId
                }}
                onSubmit={async (values, { setSubmitting }) => {

                    if (!values?.imageSelected) {
                        enqueueSnackbar("No topic picture is uploaded", { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                        setSubmitting(false);
                        return;
                    }
                    
                    let topic = {
                        name: values.name,
                        subtitle: values.subtitle,
                        order: values.order,
                        imgUrl: "",
                        imgPath: "",
                        disabled: true
                    }

                    try {

                        let imgLink = await dataProvider.addImage(image, "learningCenter_files/");

                        topic.imgUrl = imgLink.imgUrl;
                        topic.imgPath = imgLink.imgPath;

                    } catch (error) {
                        enqueueSnackbar(`Failed to save the image: ${error}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    }

                    dataProvider.AddLearningCenterTopics(values.company, values.department, topic)
                    .then(topicId => {
                        dispatch(AllActions.searchState.setSearchState({ company: companySelected, department: departmentSelected }));
                        enqueueSnackbar(`Successfully saved the topic`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                        setSubmitting(false);
                        history.replace("categories/add", { topicId: topicId });
                    })
                    .catch((error) => {
                        enqueueSnackbar(`Failed to save the topic: ${error.message || error}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    });
                }}
            >
                {({ submitForm, isSubmitting, values, setFieldValue, errors }) => (
                    <Form>
                        <Grid container spacing={3} direction="column">
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <Autocomplete
                                    disabled = {true}
                                    getOptionSelected={() => true}
                                    options={companies}
                                    getOptionLabel={(option: any) => option.name}
                                    value={companySelected}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setFieldValue("company", value.id);
                                            setCompanySelected(value);
                                            onCompanySelect(value.id);
                                        } else {
                                            setFieldValue("company", '');
                                            setFieldValue("department", '');
                                            setCompanySelected({ id: "", name: "" });
                                            setDepartments([]);
                                            setDepartmentSelected({ id: "", name: "" });
                                        }
                                    }}
                                    renderInput={params => (
                                        <Field component={TextField} {...params} name="company" label="Company" variant="filled" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <Autocomplete
                                    getOptionSelected={() => true}
                                    options={departments}
                                    getOptionLabel={(option: any) => option.name}
                                    value={departmentSelected}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setFieldValue("department", value.id);
                                            setDepartmentSelected(value);
                                        } else {
                                            setFieldValue("department", '');
                                            setDepartmentSelected({ id: "", name: "" });
                                        }
                                    }}
                                    renderInput={params => (
                                        <Field component={TextField} {...params} name="department" label="Department" variant="filled" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Name"
                                    name="name"
                                    variant="filled"
                                    fullWidth
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Subtitle"
                                    name="subtitle"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <Field
                                    component={TextField}
                                    type="number"
                                    label="Display Order"
                                    name="order"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4} container spacing={1} direction="column">
                                <Grid item>
                                    <DropzoneArea 
                                        showAlerts={false} 
                                        filesLimit={1} 
                                        dropzoneText={"Upload an image"} 
                                        onChange={
                                            (files: any) => {
                                                setFieldValue("imageSelected", files[0] ? true : false);
                                                setImage(files[0]);
                                            }
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography color="error">
                                        {values.imageSelected === false ? errors.imageSelected : ""}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <FormFooter onAction={submitForm} onBack={() => { history.goBack() }} submitting={isSubmitting} />
                            </Grid>
                        </Grid>
                    </Form>

                )
                }
            </Formik >
        </Panel>
    );
}

export default AddTopic;
