import React, { useContext } from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import Roles from '../../models/enums/Roles';
import AdminAddAchievement from './components/AdminAddAchievement';
import ManagerAddAchievement from './components/ManagerAddAchievement';
import CompManagerAddAchievement from './components/CompManagerAddAchievement';

const AddAchievement: React.FC<any> = () => {

    const { user } = useContext(AuthContext);

    const renderForm = (role: Roles | undefined) => {

        if (!role) {
            return <h1>Something went wrong</h1>
        }

        if (role === Roles.manager) {
            return <ManagerAddAchievement />
        }

        if (role === Roles.admin) {
            return <AdminAddAchievement />
        }

        if (role === Roles.comp_manager) {
            return <CompManagerAddAchievement />
        }
    };

    return (
        <>
            {
                renderForm(user?.role)
            }
        </>
    );
}

export default AddAchievement;
