import React, { useState } from 'react';
import { Grid, Button, TextField } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';

export interface IEditImageProps {
    initialImage: any;
    onSave: any;
    onCancel: any;
}
//comment
const EditImage: React.FC<IEditImageProps> = ({ onSave, initialImage, onCancel }) => {

    const [image, setImage] = useState<any>(initialImage);
    //const [image, setImage] = useState<any>();
    const [imageSelected, setImageSelected] = useState<boolean>(false);
    const [editState, setEditState] = useState(false);
    
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
            <DropzoneArea
                        showAlerts={false}
                        filesLimit={1}
                        dropzoneText="Upload a image"
                        initialFiles = {[image]}
                        onChange={
                            (files: any) => {
                                setImageSelected(files[0] ? true : false);
                                setImage(files[0]);
                                if(files[0])
                                    setEditState(false)
                            }
                        } 
                    />
            </Grid>
            <Grid item container justify="space-between" xs={12}>
                <Grid item>
                    <Button variant="outlined" color="secondary" onClick={() => { onCancel() }}>Cancel</Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined" color="primary" onClick={

                        () => {
                            onSave(image);
                            //updateMediaArr(data.id, text);

                        }
                    }>Save</Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default EditImage;