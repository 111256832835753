import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Panel from '../../../components/Panel';
import { Grid, FormControlLabel } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-material-ui';
import FormFooter from '../../../components/FormFooter';
import ImageUpload from '../../../components/ImageUpload';
import { useLocation, useHistory } from 'react-router-dom';
import DataProvider from '../../../providers/DataProvider';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

interface Topic {
    id: string;
    name: string;
    subtitle: string;
    order: number;
    imgUrl: string;
    imgPath: string;
    disabled?: boolean;
}

const schema = Yup.object({
    name: Yup.string().required('Required').min(2, "Name should be atleast 2 letters"),
    subtitle: Yup.string().required('Required').min(3, "Subtitle should be atleast 3 lettters")
});

export interface IEditTopicProps {
}

const EditTopic: React.FC<IEditTopicProps> = ({ }) => {

    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(true);
    const [imageChanged, setImageChanged] = useState(false);
    const [hasSubTopics, setHasSubTopics] = useState(false);
    const [initialState, setInitialState] = useState<Topic | any>();
    const location = useLocation();
    const history = useHistory();
    const dataprovider = new DataProvider();
    
    //@ts-ignore
    const searchState = useSelector(state => state.searchState)?.searchState;

    useEffect(() => {

        if (location.state) {

            let topic = location.state as Topic;

            let initial = {
                id: topic.id,
                name: topic.name,
                subtitle: topic.subtitle,
                order: topic.order,
                imgUrl: topic.imgUrl,
                imgPath: topic.imgPath === undefined ? null : topic.imgPath,
                disabled: topic.disabled === undefined ? false : topic.disabled
            }

            setInitialState(initial);
            setLoading(false);

        } else {
            history.goBack();
        }

    }, []);

    useEffect(() => {

        if (!searchState?.company?.id || !searchState?.department?.id || !initialState?.id) {
            return;
        }

        dataprovider.getLearningCenterSubTopics(searchState.company.id, searchState.department.id, initialState.id).then((subTopics) => {

            if (subTopics?.length > 0) {
                setHasSubTopics(true);
            }

        })

    }, [searchState, initialState]);

    return (
        <Panel isLoading={loading}>
            <Formik
                validationSchema={schema}
                enableReinitialize={true}
                initialValues={initialState}
                onSubmit={async (values, { setSubmitting }) => {

                    if (initialState.disabled && !values.disabled && !hasSubTopics) {
                        enqueueSnackbar("The topic cannot be enabled because it does not have any sub-topics", { variant: "warning", anchorOrigin: { horizontal: "center", vertical: "bottom" } });
                        setTimeout(() => {
                            history.replace("../../categories/add", { topicId: initialState.id });
                        }, 500);
                        return;
                    }

                    setSubmitting(true);

                    let editTopic = {
                        id: values.id,
                        name: values.name,
                        subtitle: values.subtitle,
                        order: values.order,
                        imgUrl: values.imgUrl,
                        imgPath: values.imgPath === undefined ? null : values.imgPath,
                        disabled: values.disabled
                    }

                    if (imageChanged) {
                        try {

                            let image = await dataprovider.addImage(values.imgUrl, "learningCenter_files/");

                            editTopic.imgUrl = image.imgUrl;
                            editTopic.imgPath = image.imgPath;

                        } catch (error) {
                            enqueueSnackbar(`Failed to update the image: ${error}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                        }
                    }
                    
                    try {
                        //@ts-ignore
                        await dataprovider.updateLearningCenterTopic(searchState.company.id, searchState.department.id, editTopic);
                        enqueueSnackbar(`Successfully updated the topic`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                        history.goBack();
                    } catch (error) {
                        enqueueSnackbar(`Failed to update the topic: ${error}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    } finally {
                        setSubmitting(false);
                    }

                }}
            >
                {({ submitForm, isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="name"
                                    name="name"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Subtitle"
                                    name="subtitle"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    component={TextField}
                                    type="number"
                                    label="Display Order"
                                    name="order"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <ImageUpload
                                    header="Topic Image"
                                    id="image_topicImage"
                                    src={values.imgUrl}
                                    onChange={(file: any) => {

                                        setImageChanged(true);
                                        setFieldValue("imgUrl", file);
                                    }}
                                />
                            </Grid>
                            <Grid item style={{ marginLeft: 10 }}>
                                <FormControlLabel
                                    label="Disabled"
                                    control={
                                        <Field
                                            id="accept"
                                            name="disabled"
                                            type="checkbox"
                                            component={CheckboxWithLabel}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormFooter
                                    actionName="save"
                                    backActionName="cancel"
                                    submitting={isSubmitting}
                                    disableAction={isSubmitting}
                                    onBack={() => {
                                        history.goBack();
                                    }}
                                    onAction={submitForm}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Panel>
    );
}

export default EditTopic;