import React, { useImperativeHandle, forwardRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Typography, Button } from '@material-ui/core';
import ScheduleType from '../../../models/enums/ScheduleType';
import DataProvider from '../../../providers/DataProvider';

interface IFormDialog {
    ref: any;
    onDelete: any;
}

const FormDialog = forwardRef<any, IFormDialog>(({ onDelete }, ref) => {

    const dataProvider = new DataProvider();

    const [open, setOpen] = useState(false);
    const [event, setEvent] = useState<any>({});
    const [start, setStart] = useState<string>();
    const [end, setEnd] = useState<string>();
    const [loading, setLoading] = useState(false);

    useImperativeHandle(ref, () => ({

        OpenModal(event: any) {
            setOpen(true);
            setEvent(event);

            var startDate = new Date(event.start);
            var endDate = new Date(event.end);

            setStart(startDate.toDateString());
            setEnd(endDate.toDateString());

        }

    }));

    const handleClose = () => {
        setOpen(false);
    };

    const deleteSchedule = () => {
        setLoading(true);
        dataProvider.deleteSchedule(event.companyId, event.departmentId, event.gameId, event.id).then(() => {
            handleClose();
            onDelete();
            setLoading(false);
        })
    }


    return (

        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#dbdbdb' }}>Event</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item container direction="row" alignItems="center" justify="space-between">
                            <Grid item style={{ marginRight: '10px' }}>
                                <Typography variant="body1" style={{ fontSize: '22px' }}>Title:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">{event.title}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" alignItems="center" justify="space-between">
                            <Grid item style={{ marginRight: '10px' }}>
                                <Typography variant="body1" style={{ fontSize: '22px' }}>Subtitle:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">{event.subTitle}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" alignItems="center" justify="space-between">
                            <Grid item style={{ marginRight: '10px' }}>
                                <Typography variant="body1" style={{ fontSize: '22px' }}>From:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">{start}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" alignItems="center" justify="space-between">
                            <Grid item style={{ marginRight: '10px' }}>
                                <Typography variant="body1" style={{ fontSize: '22px' }}>To:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">{end}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" alignItems="center" justify="space-between">
                            <Grid item style={{ marginRight: '10px' }}>
                                <Typography variant="body1" style={{ fontSize: '22px' }}>Scheduled for:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">{ScheduleType[event.scheduleType]}</Typography>
                            </Grid>
                        </Grid>
                        <br />
                        <br />
                        <Grid item container xs={12} justify="flex-end">
                            <Button disabled={loading} variant="contained" style={{ backgroundColor: '#e03434' }} onClick={deleteSchedule}><Typography style={{ color: '#e6e6e6' }}>{loading ? "loading..." : "Delete event"}</Typography></Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div >

    );
});

export default FormDialog;
