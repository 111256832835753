import React, { useState, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LinearProgress, Grid } from '@material-ui/core';
import FirebaseProvider from '../../../providers/FirestoreProvider';
import { useSelector } from 'react-redux';
import LoadingButton from '../../../components/LoaderButton';
import DataProvider from '../../../providers/DataProvider';

interface IDialogProps {
    isOpen: boolean;
    handleClose: any;
    title: string;
    contentText: string;
    gameID: string;
    categoryID: string;

}

const AlertDialog: React.FC<IDialogProps> = ({ isOpen, handleClose, title, contentText, gameID, categoryID }) => {

    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;


    const dataprovider = new DataProvider();
    const [loading, setLoading] = useState(false);

    const handleConfirm = () => {
        setLoading(true);

        dataprovider.deleteCategory(searchState.company.id, searchState.department.id, gameID, categoryID).then(() => {
            setLoading(false);
            handleClose();
        });

    }

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {contentText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Button onClick={handleClose} color="primary" disabled={loading}>
                                Cancel
                        </Button>
                        </Grid>
                        <Grid item>
                            <LoadingButton text="Delete" loading={loading} onClick={handleConfirm} color="secondary" />
                        </Grid>
                    </Grid>

                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AlertDialog;
