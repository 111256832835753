import * as React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import CompanyList from './CompanyList';
import AddCompany from './AddCompany';
import EditCompany from './EditCompany';

export default function CompanyNavigation(props: any) {

    let { path } = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={`${path}/edit/:id`}>
                    <EditCompany />
                </Route>
                <Route path={`${path}/add`}>
                    <AddCompany />
                </Route>
                <Route path={path}>
                    <CompanyList />
                </Route>
            </Switch>
        </div>
    );
}
