import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { useHistory } from 'react-router-dom';
import Panel from '../../components/Panel';
import DataProvider from '../../providers/DataProvider';
import Company from '../../models/Company';
import * as Yup from 'yup';
import FormFooter from '../../components/FormFooter';
import { DropzoneArea } from 'material-ui-dropzone';


const companySchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    url: Yup.string().url("Field must be a valid url").required("required"),
    primaryColor: Yup.string().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, "Should be a valid hex color eg. #3983f9").required("required"),
    secondaryColor: Yup.string().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, "Should be a valid hex color eg. #3983f9").required("required"),
    logoSelected: Yup.bool().oneOf([true], "Please select a logo"),
    backgroundSelected: Yup.bool().oneOf([true], "Please select a background image"),
    maxNumUsers: Yup.number().positive("Must be greater than 0").min(1, "Must be greater than 0").required("required"),
    maxNumGameSchedules: Yup.number().positive("Must be greater than 0").min(1, "Must be greater than 0").required("required")
});

export default function AddCompany() {

    const [companyLogo, setCompanyLogo] = useState<any>();
    const [backgroundImage, setBackgroundImage] = useState<any>();
    const dataProvider = new DataProvider();
    let history = useHistory();

    return (
        <Panel>
            <Formik
                initialValues={{
                    image: "",
                    name: "",
                    logoSelected: "",
                    backgroundSelected: "",
                    url: "",
                    primaryColor: "",
                    secondaryColor: "",
                    maxNumUsers: 0,
                    maxNumGameSchedules: 0
                }}
                validationSchema={companySchema}
                onSubmit={async (values, { setSubmitting }) => {

                    //Init company object
                    let addCompany: Company = {
                        name: values.name,
                        logoUrl: "logoRef",
                        logoPath: "company_images/",
                        backgroundImgUrl: "backgroundRef",
                        backgroundImgPath: "company_images/",
                        url: values.url,
                        colors: {
                            0: values.primaryColor,
                            1: values.secondaryColor
                        },
                        maxNumUsers: values.maxNumUsers,
                        maxNumGameSchedules: values.maxNumGameSchedules
                    };

                    try {
                        let logoRef = await dataProvider.addImage(companyLogo, 'company_images/');
                        let backgroundRef = await dataProvider.addImage(backgroundImage, 'company_images/');

                        //set image references
                        addCompany.logoUrl = logoRef.imgUrl;
                        addCompany.backgroundImgUrl = backgroundRef.imgUrl;
                        addCompany.logoPath = logoRef.imgPath;
                        addCompany.backgroundImgPath = backgroundRef.imgPath;

                        await dataProvider.addCompany(addCompany);
                        history.goBack();

                    } catch (error) {
                        //TODO
                        //DO ERROR HANDLING FOR FRONT END
                        console.log(error);

                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ submitForm, isSubmitting, setFieldValue, errors }) => (
                    <Form>

                        <Grid container direction="row" spacing={2}>
                            <Grid container item xs={4} direction="column" spacing={3}>
                                <Grid item>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Name"
                                        name="name"
                                        variant="filled"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Unique URL"
                                        name="url"
                                        variant="filled"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Primary Color"
                                        name="primaryColor"
                                        variant="filled"
                                        fullWidth
                                    />
                                    {/* <ColorBox defaultValue="transparent" onChange={() => { console.log("changed") }} /> */}
                                </Grid>
                                <Grid item>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Secondary Color"
                                        name="secondaryColor"
                                        variant="filled"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <Field
                                        component={TextField}
                                        type="number"
                                        label="Maximum Users"
                                        name="maxNumUsers"
                                        variant="filled"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <Field
                                        component={TextField}
                                        type="number"
                                        label="Maximum Monthly Game Schedules"
                                        name="maxNumGameSchedules"
                                        variant="filled"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={8} direction="row" spacing={3}>
                                <Grid item xs={6} style={{ width: "100%" }}>
                                    <DropzoneArea showAlerts={false} filesLimit={1} dropzoneText={errors.logoSelected ? errors.logoSelected : ""} onChange={
                                        (files: any) => {
                                            console.log("Files: ", files[0]);
                                            setFieldValue("logoSelected", files[0] ? true : false);
                                            setCompanyLogo(files[0]);
                                        }
                                    } />

                                </Grid>
                                <Grid item xs={6} style={{ width: "100%" }}>
                                    <DropzoneArea showAlerts={false} filesLimit={1} dropzoneText={errors.backgroundSelected ? errors.backgroundSelected : ""} onChange={
                                        (files: any) => {
                                            console.log("Files: ", files[0]);
                                            setFieldValue("backgroundSelected", files[0] ? true : false);
                                            setBackgroundImage(files[0]);
                                        }
                                    } />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <FormFooter onAction={submitForm} onBack={history.goBack} submitting={isSubmitting} />
                            </Grid>
                        </Grid>
                    </Form>
                )
                }
            </Formik >
        </Panel >
    );
}
