import * as React from 'react';
import List from './List';
import AddTopic from './AddTopic';
import SubTopics from './SubTopicList';
import AddSubTopic from './AddSubTopic';
import EditSubTopic from './EditSubTopic';
import EditTopic from './components/EditTopic';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ShareTopic from './ShareTopic';




export default function UserNavigation() {

    let { path } = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={`${path}/topic/edit/:id`} >
                    <EditTopic />
                </Route>
                <Route path={`${path}/subTopic/edit/:id`} >
                    <EditSubTopic />
                </Route>
                <Route path={`${path}/categories/add`} >
                    <AddSubTopic />
                </Route>
                <Route path={`${path}/categories/:id`} >
                    <SubTopics />
                </Route>
                <Route path={`${path}/add`} >
                    <AddTopic />
                </Route>
                <Route path={`${path}/share`}>
                    <ShareTopic />
                </Route>
                <Route path={path}>
                    <List />
                </Route>
            </Switch>
        </div>
    );
}
