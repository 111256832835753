import * as React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import PeopleIcon from '@material-ui/icons/People';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import DomainDisabledIcon from '@material-ui/icons/DomainDisabled';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import ChatIcon from '@material-ui/icons/Chat';
import BarChartIcon from '@material-ui/icons/BarChart';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import FunctionsIcon from '@material-ui/icons/Functions';

export interface IManangerMenuProps {
}

export default function AdminMenu(props: IManangerMenuProps) {

    // Use this variable to export custom data from the database to Excel since the Firebase console does not have that feature yet
    const EnableExport = false;
    const EnableRecalculate = false;

    const history = useHistory();
    let path = useLocation();

    const matchRoute = (route: string) => {
        let match = path.pathname.search(route);
        if (match === 0)
            return true;
        return false;
    }

    return (
        <div >
            <ListItem button onClick={() => history.push("/companies")} selected={matchRoute("/companies")}>
                <ListItemIcon>
                    <LocationCityIcon />
                </ListItemIcon>
                <ListItemText primary="Companies" />
            </ListItem>
            <ListItem button onClick={() => history.push("/departments")} selected={matchRoute("/departments")}>
                <ListItemIcon>
                    <DomainDisabledIcon />
                </ListItemIcon>
                <ListItemText primary="Departments" />
            </ListItem>
            <ListItem button onClick={() => history.push("/users")} selected={matchRoute("/users")}>
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
            </ListItem>
            <ListItem button onClick={() => history.push("/games")} selected={matchRoute("/games")}>
                <ListItemIcon>
                    <SportsEsportsIcon />
                </ListItemIcon>
                <ListItemText primary="Games" />
            </ListItem>
            <ListItem button onClick={() => history.push("/gameScheduler")} selected={matchRoute("/gameScheduler")}>
                <ListItemIcon>
                    <DateRangeIcon />
                </ListItemIcon>
                <ListItemText primary="Game scheduler" />
            </ListItem>
            <ListItem button onClick={() => history.push("/achievements")} selected={matchRoute("/achievements")}>
                <ListItemIcon>
                    <EmojiEventsIcon />
                </ListItemIcon>
                <ListItemText primary="Achievements" />
            </ListItem><ListItem button onClick={() => history.push("/learningCenter")} selected={matchRoute("/learningCenter")}>
                <ListItemIcon>
                    <ImportContactsIcon />
                </ListItemIcon>
                <ListItemText primary="Learning Center" />
            </ListItem>
            <ListItem button onClick={() => history.push("/communications")} selected={matchRoute("/communications")}>
                <ListItemIcon>
                    <ChatIcon />
                </ListItemIcon>
                <ListItemText primary="Communications" />
            </ListItem>
            <ListItem button onClick={() => history.push("/reports")} selected={matchRoute("/reports")}>
                <ListItemIcon>
                    <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
            </ListItem>
            {
                EnableExport && 
                <ListItem button onClick={() => history.push("/exports")} selected={matchRoute("/exports")}>
                    <ListItemIcon>
                        <ImportExportIcon />
                    </ListItemIcon>
                    <ListItemText primary="Exports" />
                </ListItem>
            }
            {
                EnableRecalculate &&
                <ListItem button onClick={() => history.push("/recalculations")} selected={matchRoute("/recalculations")}>
                    <ListItemIcon>
                        <FunctionsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Recalculations" />
                </ListItem>
            }
        </div>
    );
}
