import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import DataProvider from '../../providers/DataProvider';
import { AuthContext } from '../../providers/AuthProvider';
import ExcelExportButton, { IColumn } from '../../components/ExcelExportButton';

export interface IExcelDataSetupProps {
}

const ExcelDataSetup: React.FC<IExcelDataSetupProps> = ({ }) => {

    const dataprovider = new DataProvider();

    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState<boolean>(true);
    const [exportColumns, setExportColumns] = useState<IColumn[]>([]);
    const [exportData, setExportData] = useState<any>([]);

    const getUserGamesPlayedFull = async () => {

        const companyId = 'xNi9xySgYeoL2pnIuytJ';
        const startDate = new Date("2022/04/01");
        const endDate = new Date("2022/06/30");
        // const startDate = new Date("2022/10/01");
        // const endDate = new Date("2022/12/31");

        const columns = [
            { title: "ID", name: "id"},
            { title: "CompanyID", name: "companyId"},
            { title: "Company Name", name: "companyName"},
            { title: "DepartmentID", name: "departmentId"},
            { title: "Department Name", name: "departmentName"},
            { title: "UserID", name: "userId"},
            { title: "Email", name: "email"},
            { title: "First Name", name: "firstName"},
            { title: "Last Name", name: "lastName"},
            { title: "GameID", name: "gameId"},
            { title: "Game Name", name: "gameName"},
            { title: "Date Played", name: "date"},
            { title: "Accuracy", name: "accuracy"},
            { title: "Points", name: "points"},
            { title: "Max Points", name: "maxPoints"},
            { title: "Passed", name: "passed"},
            { title: "Duration", name: "duration"}
        ];

        dataprovider.getAllUsers(user)
        .then(usersResponse => {

            const users = usersResponse?.filter((u: any) => u.companyId === companyId);

            dataprovider.getUserGamesPlayed(companyId, "", "", startDate, endDate)
            .then(gamesResponse => {
                
                let data = [];

                for (let i = 0; i < gamesResponse.length; i++) {
                    const gamePlayed = gamesResponse[i];
                    const user = users.find((u: any) => u.userId == gamePlayed.context.userId);
                    
                    if (gamePlayed.context.companyId != companyId) {
                        continue;
                    }

                    let d = new Date(1970, 0, 1, 0, 0, 0, 0);
                    d.setSeconds(d.getSeconds() + gamePlayed.date.fullDate.seconds)

                    let year = d.getFullYear();
                    let month = d.getMonth() + 1;
                    let day = d.getDate();

                    data.push({
                        id: gamePlayed.id,
                        companyId: gamePlayed.context.companyId,
                        companyName: user?.companyName || "",
                        departmentId: gamePlayed.context.departmentId,
                        departmentName: user?.departmentName || "",
                        userId: gamePlayed.context.userId,
                        email: user?.email || "User not found",
                        firstName: user?.firstName || "",
                        lastName: user?.lastName || "",
                        gameId: gamePlayed.context.gameId,
                        gameName: gamePlayed.context.gameName,
                        date: `${year}-${(month > 9 ? '' : '0') + month}-${(day > 9 ? '' : '0') + day}`,
                        accuracy: gamePlayed.gameStats.answerAccuracy,
                        points: gamePlayed.gameStats.answersCorrectCount,
                        maxPoints: gamePlayed.gameStats.possibleAnswersCorrectCount,
                        passed: gamePlayed.gameStats.isGameWon,
                        duration: gamePlayed.gameStats.playTime
                    });
                }

                setExportColumns(columns);
                setExportData(data);
                setLoading(false)

            })
            .catch(error => {
                console.log("games played error = ", error)
                window.alert(error);
                setLoading(false)
            });

        }).catch(error => {
            console.log("users erred = ", error);
            window.alert(error);
            setLoading(false);
        });

    };

    const getUserGamesPlayed = async () => {
        /*
        Get all the documents in the GamesPlayed collection of the database for the specified:
            1) Company
            2) Department
            3) User
            4) Date range (excluding limits)
        */

        let companyId = 'H7qX9Zo52K5YQBGBNJy4';
        let departmentId = '';
        let userId = 'RKTR8AYQbxeoNgJsYvxBQAnE1u02';
        let endDate = new Date(2021, 8, 29, 23, 59);
        let startDate = new Date("2023/01/01");

        dataprovider.getUserGamesPlayed(companyId, departmentId, userId, startDate, endDate)
        .then(resultsArr => {
console.log("games played")
            let data = [];
            let columns = [
                { title: "ID", name: "id"},
                { title: "CompanyID", name: "companyId"},
                { title: "DepartmentID", name: "departmentId"},
                { title: "UserID", name: "userId"},
                { title: "GameID", name: "gameId"},
                { title: "Game Name", name: "gameName"},
                { title: "Date Played", name: "date"},
                { title: "Accuracy", name: "accuracy"},
                { title: "Points", name: "points"},
                { title: "Max Points", name: "maxPoints"},
                { title: "Passed", name: "passed"},
                { title: "Duration", name: "duration"}
            ];

            for (let i = 0; i < resultsArr.length; i++) {
                const gamePlayed = resultsArr[i];
                
                if (gamePlayed.context.companyId != companyId) {
                    continue;
                }

                let d = new Date(1970, 0, 1, 0, 0, 0, 0);
                d.setSeconds(d.getSeconds() + gamePlayed.date.fullDate.seconds)

                let year = d.getFullYear();
                let month = d.getMonth() + 1;
                let day = d.getDate();

                data.push({
                    id: gamePlayed.id,
                    companyId: gamePlayed.context.companyId,
                    departmentId: gamePlayed.context.departmentId,
                    userId: gamePlayed.context.userId,
                    gameId: gamePlayed.context.gameId,
                    gameName: gamePlayed.context.gameName,
                    date: `${year}-${(month > 9 ? '' : '0') + month}-${(day > 9 ? '' : '0') + day}`,
                    accuracy: gamePlayed.gameStats.answerAccuracy,
                    points: gamePlayed.gameStats.answersCorrectCount,
                    maxPoints: gamePlayed.gameStats.possibleAnswersCorrectCount,
                    passed: gamePlayed.gameStats.isGameWon,
                    duration: gamePlayed.gameStats.playTime
                });
            }

            setExportColumns(columns);
            setExportData(data);
            setLoading(false)

        })
        .catch(error => {
            setLoading(false)
            window.alert(error);
            console.log("games played error:")
            console.dir(error)
        });
    }

    const getAllUsers = async () => {
        /*
        Get all the documents in the GamesPlayed collection of the database for the specified:
            1) Company
            2) Department
            3) User
            4) Date range (excluding limits)
        */

        dataprovider.getAllUsers(user)
        .then(resultsArr => {
console.log("users done")
            let columns = [
                { title: "CompanyID", name: "companyId"},
                { title: "Company Name", name: "companyName"},
                { title: "DepartmentID", name: "departmentId"},
                { title: "Department Name", name: "departmentName"},
                { title: "UserID", name: "userId"},
                { title: "Email", name: "email"},
                { title: "First Name", name: "firstName"},
                { title: "Last Name", name: "lastName"},
                { title: "ID Number", name: "idNumber"},
                { title: "Role", name: "role"},
                { title: "Is Disabled", name: "disabled"},
                { title: "Games Played", name: "gamesPlayed"},
                { title: "Accuracy", name: "accuracy"},
                { title: "Total Points", name: "totalPoints"}
            ];

            // for (let i = 0; i < resultsArr.length; i++) {
            //     const gamePlayed = resultsArr[i];
                
            //     let d = new Date(1970, 0, 1, 0, 0, 0, 0);
            //     d.setSeconds(d.getSeconds() + gamePlayed.date.fullDate.seconds)

            //     data.push({
            //         companyId: gamePlayed.context.companyId,
            //         departmentId: gamePlayed.context.departmentId,
            //         userId: gamePlayed.context.userId,
            //         gameName: gamePlayed.context.gameName,
            //         date: d.toLocaleString(),
            //         accuracy: gamePlayed.gameStats.answerAccuracy,
            //         points: gamePlayed.gameStats.answersCorrectCount,
            //         maxPoints: gamePlayed.gameStats.possibleAnswersCorrectCount,
            //         passed: gamePlayed.gameStats.isGameWon,
            //         duration: gamePlayed.gameStats.playTime
            //     });
            // }

            setExportColumns(columns);
            setExportData(resultsArr);
            setLoading(false);

        })
        .catch(error => {
            window.alert(error);
            setLoading(false);
            console.log("users erred")
        });
    }

    const getUserAnswers = async () => {

        /*
            Get all the documents in the Answers collection for a specific user
        */

        let companyId = 'awm9obZ06MApsRSkWx3i';
        let userId = 'suQEkXQQWhM2Te4A0d0ptHsfXv12';

        dataprovider.getUserAnswers(companyId, userId)
        .then(resultsArr => {

            let data = [];
            let columns = [
                { title: "ID", name: "id"},
                { title: "DepartmentID", name: "departmentId"},
                { title: "GameID", name: "gameId"},
                { title: "CategoryID", name: "categoryId"},
                { title: "Date", name: "date"},
                { title: "Score", name: "score"},
                { title: "Answers", name: "answers"},
                { title: "Correct Answers", name: "answersCorrect"}
            ];

            let scores = 0;
            let maxScores = 0;

            for (let i = 0; i < resultsArr.length; i++) {
                const userAnswer = resultsArr[i];
                
                let d = new Date(1970, 0, 1, 0, 0, 0, 0);
                d.setSeconds(d.getSeconds() + userAnswer.date.seconds)

                let year = d.getFullYear();
                let month = d.getMonth() + 1;
                let day = d.getDate();
                let hour = d.getHours();
                let minute = d.getMinutes();
                let second = d.getSeconds();

                data.push({
                    id: userAnswer.id,
                    departmentId: userAnswer.departmentId,
                    gameId: userAnswer.gameId,
                    categoryId: userAnswer.categoryId,
                    //date: d.toISOString(),        // Not working with the excel download
                    date: `${year}-${(month > 9 ? '' : '0') + month}-${(day > 9 ? '' : '0') + day}-${hour}-${minute}-${second}`,
                    score: userAnswer.score,
                    answers: JSON.stringify(userAnswer.answers).replace(/,/g, '|'),
                    answersCorrect: JSON.stringify(userAnswer.answersCorrect).replace(/"/g, '').replace(/,/g, '|')
                });

                let answersArray = Object.values(userAnswer.answersCorrect);
                //Object.values(ans).filter(a => a === 1).length
                let totalAnswerScore = answersArray.filter(a => a === 1).length;
                
                scores += Math.min(totalAnswerScore, 5);
                maxScores += answersArray.length;
            }

            let avg = scores / maxScores;

            setExportColumns(columns);
            setExportData(data);
            setLoading(false);

        })
        .catch(error => {
            window.alert(error);
            setLoading(false);
        });
    };

    const getAllTopics = async () => {
        
        dataprovider.getAllLearningCenterTopics()
        .then(resultsArr => {
            
            let columns = [
                { title: "CompanyID", name: "companyId"},
                { title: "Company Name", name: "companyName"},
                { title: "DepartmentID", name: "departmentId"},
                { title: "Department Name", name: "departmentName"},
                { title: "TopicID", name: "topicId"},
                { title: "Topic Name", name: "topicName"},
                { title: "Is Shared", name: "isShared"},
                { title: "Is Child", name: "isChild"},
                { title: "HostID", name: "hostId"},
                { title: "Is Loading", name: "isLoading"},
                { title: "Child DepartmentIDs", name: "childDepartments"}
            ];

            setExportColumns(columns);
            setExportData(resultsArr);
            setLoading(false);

        })
        .catch(error => {
            setLoading(false);
            window.alert(error);
        });

    };

    const resetTopicsLoadingState = async () => {

        dataprovider.resetLearningCenterTopicLoadingState("U9oTvagHhWplt9ZqT1Fs")
        .then(response => {

            if (response && response > 0) {
                window.alert(`Updated topics count is ${response}`)
            }

            getAllTopics();

        })
        .catch(error => {
            setLoading(false);
            window.alert(error);
        });
    };

    const getAllLeaderboards = async () => {
        
        dataprovider.getAllLeaderboards()
        .then(resultsArr => {
            
            let columns = [
                { title: "Leaderboard ID", name: "leaderboardId" },
                { title: "Company ID", name: "companyId" },
                { title: "Company Name", name: "companyName" },
                { title: "Department ID", name: "departmentId" },
                { title: "Department Name", name: "departmentName" },
                { title: "User ID", name: "userId" },
                { title: "User Name", name: "userName" },
                { title: "Points", name: "points" },
                { title: "Max Points", name: "maxPoints" },
                { title: "Accuracy", name: "accuracy" }
            ];

            setExportColumns(columns);
            setExportData(resultsArr);
            setLoading(false);

        })
        .catch(error => {
            setLoading(false);
            window.alert(error);
        });

    };

    const getAllGames = async () => {

        let companyId = 'InuwefELLvxyUNln7eBU';
        let departmentId = '';

        dataprovider.getAllGames(companyId, departmentId)
            .then(resultsArr => {
                console.log("getAllGames done, resultsArr = ", resultsArr)
                let columns = [
                    { title: "CompanyID", name: "companyId"},
                    { title: "Company Name", name: "companyName"},
                    { title: "DepartmentID", name: "departmentId"},
                    { title: "Department Name", name: "departmentName"},
                    { title: "GameID", name: "gameId"},
                    { title: "Game Name", name: "name"},
                    { title: "Max Correct", name: "maximumCorrect"},
                ];
    
                setExportColumns(columns);
                setExportData(resultsArr);
                setLoading(false);
    
            })
            .catch(error => {
                window.alert(error);
                setLoading(false);
                console.log("getAllGames erred")
            });
    }

    useEffect(() => {

        //getUserGamesPlayedFull();
        //getUserGamesPlayed();
        //getAllUsers();
        //getUserAnswers();
        //getAllTopics();
        //resetTopicsLoadingState();
        //getAllLeaderboards();
        getAllGames();

    }, []);

    if (loading) {
        return (
            <div>Loading data</div>
        );
    } else {
        return (
            <ExcelExportButton fileName='DataExport' buttonTitle='Download' sheets={[{ sheetColumns: exportColumns, sheetData: exportData}]} />
        );
    }
}

export default ExcelDataSetup;