import * as React from 'react';
import { Grid, Divider, Typography } from '@material-ui/core';
import Panel from './Panel';

export interface IAppProps {
    actions: React.ReactNode[];
    filters?: React.ReactNode[];
    content: React.ReactNode;
    preConditionSet?: boolean;


}

const MainLayout: React.FC<IAppProps> = ({ actions, filters, content, preConditionSet = true }) => {
    return (
        <Panel>
            <Grid container spacing={3} direction="column">
                <Grid container item spacing={2} justify="flex-end">
                    {
                        actions.map((node, i) => (
                            <Grid item key={i}>
                                {node}
                            </Grid>
                        ))
                    }
                </Grid>
                <Divider />
                <Grid container item xs={12}>
                    {
                        filters?.map((node, i) => (
                            <Grid item key={i} xs={12}>
                                {node}
                            </Grid>
                        ))
                    }
                </Grid>
                {
                    preConditionSet ?
                        <Grid container item xs={12}>
                            {content}
                        </Grid>
                        :
                        <Grid container item xs={12} justify="center" alignItems="center" style={{ minHeight: "400px" }}>
                            <Typography variant = "h6">Please select company and department</Typography>
                        </Grid>
                }

            </Grid>
        </Panel>
    );
}

export default MainLayout;
