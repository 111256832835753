import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, TextField as MUITextField } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { useHistory } from 'react-router-dom';
import Panel from '../../components/Panel';
import DataProvider from '../../providers/DataProvider';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import * as Yup from 'yup';
import FormFooter from '../../components/FormFooter';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

const validation = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(25, 'Too Long!')
        .required('Required'),
    description: Yup.string()
        .min(2, 'Too Short!')
        .max(1000, 'Too Long!'),
    company: Yup.string().required('Required')
});

export default function AddDepartment() {

    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;

    const [selectedCompany, setSelectedCompany] = useState(searchState.company);
    const [companies, setCompanies] = useState<any[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const dataProvider = new DataProvider();
    let history = useHistory();

    useEffect(() => {
        dataProvider.getCompanies().then(data => {
            setCompanies(data);
        })
        //eslint-disable-next-line
    }, []);

    return (
        <Panel>
            <Formik
                initialValues={{
                    name: "",
                    description: "",
                    company: searchState.company
                }}
                validationSchema={validation}
                onSubmit={(values, { setSubmitting }) => {

                    setSubmitting(true);
                    console.log("add values", values);

                    let department = {
                        name: values.name,
                        description: values.description
                    }


                    dataProvider.addDepartment(values.company.id, department).then(data => {
                        setSubmitting(false);
                        enqueueSnackbar(`Department added!`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                        history.push("/Departments");
                    }).catch(err => {
                        enqueueSnackbar(err, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    });
                }}
            >
                {({ submitForm, isSubmitting, values, setFieldValue, errors, touched }) => (
                    <Form>
                        <Grid container spacing={3} direction="column">
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <Field
                                    name="company"
                                    component={Autocomplete}
                                    options={companies}
                                    getOptionLabel={(option: any) => option.name}
                                    value={values.company}
                                    onChange={(event: any, data: any) => {
                                        setFieldValue("company", data);

                                    }}
                                    renderInput={(params: AutocompleteRenderInputParams) => (
                                        <MUITextField
                                            {...params}
                                            error={touched['company'] && !!errors['company']}
                                            helperText={
                                                //@ts-ignore
                                                touched['company'] && errors['company']?.title
                                            }
                                            label="Company"
                                            variant="filled"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Name"
                                    name="name"
                                    variant="filled"
                                    fullWidth
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Description"
                                    name="description"
                                    variant="filled"
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <FormFooter submitting={isSubmitting} onAction={submitForm} onBack={() => { history.goBack() }} />
                            </Grid>
                        </Grid>
                    </Form>

                )
                }
            </Formik >
        </Panel >
    );
}
