import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { useHistory, useLocation } from 'react-router-dom';
import Panel from '../../components/Panel';
import DataProvider from '../../providers/DataProvider';
import FormFooter from '../../components/FormFooter';
import * as Yup from 'yup';

const validation = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(25, 'Too Long!')
        .required('Required'),
    subtitle: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    description: Yup.string().required('Required')
});


export default function AddCategory() {

    let location = useLocation();
    //@ts-ignore
    const state: any = location.state;
    //@ts-ignore
    const gameId = state.gameId;
    let history = useHistory();
    const dataProvider = new DataProvider();

    return (
        <Panel>
            <Formik
                validationSchema={validation}
                initialValues={{
                    name: '',
                    subtitle: '',
                    description: '',
                    order: 0,
                    passRate: '0',
                    mustWatchVideo: false,
                    media: []

                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    //@ts-ignore
                    dataProvider.addCategory(state.company.id, state.department.id, gameId, values).then((data: any) => {
                        
                        setSubmitting(false);
                        history.replace(`/games/categories/questions/bulk`, {
                            company: state.company,
                            department: state.department,
                            gameId: gameId,
                            categoryId: data.id
                        });

                    })
                }}
            >
                {({ submitForm, isSubmitting, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={3} direction="column">
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Category name"
                                    name="name"
                                    variant="filled"
                                    fullWidth
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Subtitle"
                                    name="subtitle"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Description"
                                    name="description"
                                    variant="filled"
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Display Order"
                                    name="order"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item container xs={12} sm={12} md={4} xl={4} justify="space-between">
                                <FormFooter onAction={submitForm} onBack={() => { history.goBack() }} submitting={isSubmitting} />
                            </Grid>
                        </Grid>
                    </Form>

                )
                }
            </Formik >
        </Panel >
    );
}
