import * as React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AchievementsList from './AchievementsList';
import AddAchievement from './AddAchievement';
import EditAchievement from './EditAchievement';
import ShareAchievement from "./ShareAchievement";

export interface IAchievementNavigator {

}

const AchievementNavigator: React.FC<IAchievementNavigator> = () => {

    let { path } = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={`${path}/edit/:id`}>
                    <EditAchievement />
                </Route>
                <Route path={`${path}/add`}>
                    <AddAchievement />
                </Route>
                <Route path={`${path}/share`}>
                    <ShareAchievement />
                </Route>
                <Route path={path}>
                    <AchievementsList />
                </Route>
            </Switch>
        </div>
    );
}

export default AchievementNavigator;
