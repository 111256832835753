import React, { useContext } from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import Panel from '../../components/Panel';
import { Grid } from '@material-ui/core';
import AdminAddGame from './components/AdminAddGame';
import ManagerAddGame from './components/MangerAddGame';
import CompManagerAddGame from './components/CompManagerAddGame';
import Roles from '../../models/enums/Roles';

export default function AddGame() {

    const { user } = useContext(AuthContext);

    const renderForm = (role: Roles | undefined) => {

        if (!role) {
            return <h1>Something went wrong</h1>
        }

        if (role === Roles.manager) {
            return <ManagerAddGame />
        }

        if (role === Roles.admin) {
            return <AdminAddGame />
        }

        if (role === Roles.comp_manager) {
            return <CompManagerAddGame />
        }
    };

    return (
        <Panel>
            <Grid container>
                <Grid item xs={12}>
                    {
                        renderForm(user?.role)
                    }
                </Grid>
            </Grid>
        </Panel>
    );
}
