import { Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import FormFooter from '../../components/FormFooter';
import Panel from '../../components/Panel';
import DataProvider from '../../providers/DataProvider';
import FirebaseProvider from '../../providers/FirestoreProvider';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: '#e6e6e6',
        minHeight: 400,
        borderRadius: 15
    },
    failedText: {
        color: "#757575"
    },
    bar: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    }
}));

export interface State {
    company: {
        id: string;
        name: string;
    },
    department: {
        id: string;
        name: string;
    }
}

export enum DisplayState {
    LOADING = 1,
    SHARING,
    NOT_SELECTED,
    DISPLAY,
    IS_CHILD,
    IN_PROGRESS
}

export enum AchievementState {
    IS_HOST = 1,    //Achievement is shared to other departments
    IS_CHILD,  //Achievement was shared from another department
    IS_NONE    //Achievement is not a host or a child
}

export interface IShareAchievementProps {
}

const ShareAchievement: React.FC<IShareAchievementProps> = ({ }) => {

    const history = useHistory();
    const classes = useStyles();
    const location = useLocation();
    const dataprovider = new DataProvider();
    const state = location.state as State;
    const { enqueueSnackbar } = useSnackbar();

    const [achievements, setAchievements] = useState<any[]>([]);
    const [selectedAchievement, setSelectedAchievement] = useState<any | null>(null);
    const [departments, setDepartments] = useState<any[]>([]);
    const [displayDepartments, setDisplayDepartments] = useState<any[]>([]);
    const [checkedDepartments, setCheckedDepartments] = useState<any[]>([]);
    const [displayState, setDisplayState] = useState<DisplayState>(DisplayState.NOT_SELECTED);
    const [submitting, setSubmitting] = useState(false);

    function deepCopy(obj: any) {
        return JSON.parse(JSON.stringify(obj));
    }

    const renderTable = (loading: DisplayState) => {

        switch (loading) {
            case DisplayState.NOT_SELECTED:
                return (
                    <Grid
                        container
                        className={classes.container}
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={12}>
                            <Typography variant="h5">Select an achievement</Typography>
                        </Grid>
                    </Grid>
                )
            case DisplayState.LOADING:
                return (
                    <Skeleton variant="rect" animation="wave" height={400} style={{ width: '100%' }} />
                )
            case DisplayState.SHARING:
                return (
                    <Grid
                        container
                        className={classes.container}
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={12}>
                            <Typography variant="h5">Updating the shared achievement, please wait</Typography>
                        </Grid>
                    </Grid>
                )
            case DisplayState.DISPLAY:
                return (
                    <MaterialTable
                        options={
                            {
                                selection: true
                            }
                        }
                        columns={[
                            { title: 'Name', field: 'name' },
                        ]}
                        data={displayDepartments}
                        title="Pick department(s)"
                        onSelectionChange={(rows) => {
                            setCheckedDepartments(rows);
                        }}
                    />
                );
            case DisplayState.IS_CHILD:
                return (
                    <Grid
                        container
                        className={classes.container}
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={8}>
                            <Typography variant="h5" align="center">This is a child achievement of a shared achievement. You can not share this achievement to other departments</Typography>
                        </Grid>
                    </Grid>
                )
            case DisplayState.IN_PROGRESS:
                return (
                    <Grid
                        container
                        className={classes.container}
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={8}>
                            <Typography variant="h5" align="center">An operation is currently being perform on this achievement, please come back in a few minutes</Typography>
                        </Grid>
                    </Grid>
                )
            default:
                break;
        }

    }

    const handleAchievementSelect = (achievement: any) => {

        setDisplayState(DisplayState.LOADING);
        setSelectedAchievement(achievement);
        setDisplayDepartments(deepCopy(departments));

        if (achievement === null) {
            setDisplayState(DisplayState.NOT_SELECTED);
            return;
        }

        try {

            if (achievement.shared.loading) {
                setDisplayState(DisplayState.IN_PROGRESS);
                return;
            }

            if (achievement.shared.isChild) {
                setDisplayState(DisplayState.IS_CHILD);
                return;
            }

            if (achievement.shared.isHost) {

                // Tik each department that's already shared
                let tempDepartments = deepCopy(departments);

                for (let i = 0; i < tempDepartments.length; i++) {
                    const dept = tempDepartments[i];
                    
                    for (let j = 0; j < achievement.shared.departments.length; j++) {
                        const checked = achievement.shared.departments[j];
                        
                        if (checked === dept.id) {
                            tempDepartments[i].tableData = {
                                ...tempDepartments[i].tableData,
                                checked: true
                            }
                        }
                    }
                }

                setDisplayDepartments([...tempDepartments]);

            }

            setDisplayState(DisplayState.DISPLAY);

        } catch (error) {
            setDisplayState(DisplayState.DISPLAY);
        }

    }

    const handleSubmit = () => {

        setSubmitting(true);
        setDisplayState(DisplayState.SHARING);

        const shareAchievement = FirebaseProvider.functions().httpsCallable('shareAchievement');
		const params = { 
			hostAchievementId: selectedAchievement.id,
			hostCompanyId: state.company.id, 
			hostDepartmentId: state.department.id, 
			childDepartmentIDs: checkedDepartments.map(d => d.id)
		};
		
        shareAchievement(params)
            .then((response) => {

				if (response?.data?.isSuccessful === true) {

					enqueueSnackbar("Completed the update successfully. Reloading the achievement details.", { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });

				} else {
					let errors: any[] = [ ...response?.data?.errors ] || [ "No further details available" ];
					let errorMessage = errors.join("; ");

					enqueueSnackbar("Completed the update, but with some errors: " + errorMessage, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
				}
				
				dataprovider
					.getAchievementsFD(state.company.id, state.department.id)
					.then(achievements => {

                        setAchievements(achievements);
                        setSelectedAchievement(null);
						setSubmitting(false);
			
					})
					.catch((err) => {
						enqueueSnackbar("Failed to load the achievements: " + err, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
					})
					.finally(() => {
						setDisplayState(DisplayState.NOT_SELECTED);
					});

            })
            .catch(err => {

				let errorMsg = `shareAchievement failed for ID ${selectedAchievement.id} at ${new Date().toISOString()}. Exception: ${err || "No further details"}`;

				setSubmitting(false);
				enqueueSnackbar("Failed to share the achievement. Please keep a copy of this error message for the HyperBoliq Support Team: " + errorMsg, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });

			});
    }

    useEffect(() => {

        dataprovider
            .getDepartmentsFD(state.company.id)
            .then(res => {

                let filteredArr = res.filter(x => x.id !== state.department.id);
                setDepartments(filteredArr);

            }).catch(err => {
                enqueueSnackbar("Failed to load the departments: " + err, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
            });

        dataprovider
            .getAchievementsFD(state.company.id, state.department.id)
            .then(data => {
                setAchievements(data);
            }).catch(err => {
                enqueueSnackbar("Failed to load the achievements: " + err, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
            });

    }, []);

    return (
        <Panel>
            <Grid container direction="row">
                <Grid item xs={4}>
                    <Autocomplete
                        id="autocomplete-achievement"
                        value={selectedAchievement}
                        options={achievements}
                        getOptionLabel={(option) => option.name}
                        style={{ width: 300 }}
                        onChange={(event, data) => {
                            handleAchievementSelect(data);
                        }}
                        renderInput={(params) => <TextField {...params} label="Achievement" variant="outlined" />}
                    />
                </Grid>
                <Grid item xs={8}>
                    {renderTable(displayState)}
                </Grid>
                <Grid item xs={12}>
                    <FormFooter
                        actionName="save"
                        backActionName="cancel"
                        submitting={submitting}
                        onAction={() => {
                            handleSubmit();
                        }}
                        onBack={() => {
                            history.goBack();
                        }}
                    />
                </Grid>
            </Grid>
        </Panel>
    );
}

export default ShareAchievement;