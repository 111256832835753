import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { Grid, AppBar, Typography, Toolbar, makeStyles } from '@material-ui/core';
import ExcelByGame from './exports/ExcelByGame';

const useStyles = makeStyles((theme) => ({

    bar: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    }
}));

export interface IGameStatsProps {
    gameStats: any[] | undefined;
}

const GameStats: React.FC<IGameStatsProps> = ({ gameStats }) => {

    const classes = useStyles();

    return (
        <Grid container direction="column">
            <Grid item>
                <AppBar position="static" className={classes.bar}>
                    <Toolbar>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="h6">Game Stats</Typography>
                            </Grid>
                            <Grid item>
                                <ExcelByGame list={gameStats ? gameStats : []} />
                            </Grid>
                        </Grid>

                    </Toolbar>
                </AppBar>
            </Grid>
            <Grid item>
                <MaterialTable style={{ width: '100%', overflow: 'auto', height: '400px' }}
                    columns={[
                        { title: "Name", field: "gameName" },
                        { title: "Played", field: "gamesPlayed" },
                        {
                            field: 'averageAccuracy',
                            title: 'Avg Accuracy',
                            render: rowData => <Typography>{Math.round((rowData.averageAccuracy + Number.EPSILON) * 100)} %</Typography>
                        },
                        {
                            field: 'timePlayed',
                            title: 'Time Played',
                            render: rowData => <Typography>{rowData.timePlayed} s</Typography>
                        }
                    ]}
                    data={gameStats ? gameStats : []}
                    options={{
                        paging: false,
                        search: false,
                        showTitle: false,
                        toolbar: false,
                        actionsColumnIndex: -1
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default GameStats;