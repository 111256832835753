import React, { useState, useEffect } from 'react';
import { Link, Breadcrumbs, Typography, makeStyles, List } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import BusinessIcon from '@material-ui/icons/Business';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import CategoryIcon from '@material-ui/icons/Category';
import QuestionIcon from '@material-ui/icons/Assignment';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LearningCenterIcon from '@material-ui/icons/ImportContacts';
import ChatIcon from '@material-ui/icons/Chat';
import { useHistory, useParams } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    current: {
        color: 'white',
    },
    stack: {
        color: "#d1d1d1"
    },
    seperator: {
        color: '#d1d1d1',
        fontFamily: "monospace"
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
        marginBottom: -2
    },
    link: {
        display: 'flex',
    },
}));

export default function BreadCrumb() {

    const [arr, setArr] = useState<any[]>(["Dashboard"]);
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const [id,setId] = useState<string>('');
    const pathnames = location.pathname.split('/').filter(x => x);

    const trim = (route: string) => {
        let charArr: string[] = route.split('/');
        //Remove the first item in array since it will always be ""
        charArr = charArr.slice(1);
        let trimmedRoute: string = '';

        for (let index = 0; index < charArr.length - 1; index++) {

            trimmedRoute = trimmedRoute + "/" + charArr[index];
        }
        return trimmedRoute;
    }

    const MapRoute = (route: string) => {

        let newRoute: string = route;



        if (newRoute.includes("edit") || (newRoute.includes("categories") && !newRoute.includes("categories/add") && !newRoute.includes("questions/add"))) {

            newRoute = trim(newRoute);
        }

        switch (newRoute) {
            case "/gameScheduler":
                setArr([
                    { icon: <DateRangeIcon className={classes.icon} />, route: "Scheduler" }
                ]);
                break;
            case "/games":
                setArr([
                    { icon: <SportsEsportsIcon className={classes.icon} />, route: "Games" }
                ]);
                break;
            case "/games/add":
                setArr([
                    { icon: <SportsEsportsIcon className={classes.icon} />, route: "Games" },
                    { icon: undefined, route: "Add" }
                ]);
                break;
            case "/games/categories":
                setArr([
                    { icon: <SportsEsportsIcon className={classes.icon} />, route: "Games" },
                    { icon: <CategoryIcon className={classes.icon} />, route: "Categories" }
                ]);
                break;
            case "/games/categories/add":
                setArr([
                    { icon: <SportsEsportsIcon className={classes.icon} />, route: "Games" },
                    { icon: <CategoryIcon className={classes.icon} />, route: "Categories" },
                    { icon: undefined, route: "Add" }
                ]);
                break;
            case "/games/categories/edit":
                setArr([
                    { icon: <SportsEsportsIcon className={classes.icon} />, route: "Games" },
                    { icon: <CategoryIcon className={classes.icon} />, route: "Categories" },
                    { icon: undefined, route: "Edit" }
                ]);
                break;
            case "/games/categories/questions":
                setArr([
                    { icon: <SportsEsportsIcon className={classes.icon} />, route: "Games" },
                    { icon: <CategoryIcon className={classes.icon} />, route: "Categories" },
                    { icon: <QuestionIcon className={classes.icon} />, route: "Questions" }
                ]);
                break;
            case "/games/categories/questions/add":
                setArr([
                    { icon: <SportsEsportsIcon className={classes.icon} />, route: "Games" },
                    { icon: <CategoryIcon className={classes.icon} />, route: "Categories" },
                    { icon: <QuestionIcon className={classes.icon} />, route: "Questions" },
                    { icon: undefined, route: "Add" }
                ]);
                break;
            case "/games/edit":
                setArr([
                    { icon: <SportsEsportsIcon className={classes.icon} />, route: "Games" },
                    { icon: undefined, route: "Edit" }
                ]);
                break;

            case "/users":
                setArr([
                    { icon: <PeopleAltIcon className={classes.icon} />, route: "Users" }
                ]);
                break;
            case "/users/add":
                setArr([
                    { icon: <PeopleAltIcon className={classes.icon} />, route: "Users" },
                    { icon: undefined, route: "Add" }
                ]);
                break;
            case "/companies":
                setArr([
                    { icon: <BusinessIcon className={classes.icon} />, route: "Companies" }
                ]);
                break;
            case "/companies/add":
                setArr([
                    { icon: <BusinessIcon className={classes.icon} />, route: "Companies" },
                    { icon: undefined, route: "Add" }
                ]);
                break;
            case "/departments":
                setArr([
                    { icon: <LocationCityIcon className={classes.icon} />, route: "Departments" }
                ]);
                break;
            case "/departments/add":
                setArr([
                    { icon: <LocationCityIcon className={classes.icon} />, route: "Departments" },
                    { icon: undefined, route: "Add" }
                ]);
                break;
            case "/achievements":
                setArr([
                    { icon: <EmojiEventsIcon className={classes.icon} />, route: "Achievements" }
                ]);
                break;
            case "/achievements/add":
                setArr([
                    { icon: <EmojiEventsIcon className={classes.icon} />, route: "Achievements" },
                    { icon: undefined, route: "Add" }
                ]);
                break;
            case "/achievements/share":
                setArr([
                    { icon: <EmojiEventsIcon className={classes.icon} />, route: "Achievements" },
                    { icon: undefined, route: "Share" }
                ]);
                break;
            case "/achievements/edit":
                setArr([
                    { icon: <EmojiEventsIcon className={classes.icon} />, route: "Achievements" },
                    { icon: undefined, route: "Edit" }
                ]);
                break;
            case "/learningCenter":
                setArr([
                    { icon: <LearningCenterIcon className={classes.icon} />, route: "Learning Center" }
                ]);
                break;
            case "/learningCenter/add":
                setArr([
                    { icon: <LearningCenterIcon className={classes.icon} />, route: "Learning Center" },
                    { icon: undefined, route: "Add Topic" }
                ]);
                break;
            case "/learningCenter/categories":
                setArr([
                    { icon: <LearningCenterIcon className={classes.icon} />, route: "Learning Center" },
                    { icon: undefined, route: "Sub Topics" }
                ]);
                break;
            case "/learningCenter/categories/add":
                setArr([
                    { icon: <LearningCenterIcon className={classes.icon} />, route: "Learning Center" },
                    { icon: <CategoryIcon className={classes.icon} />, route: "Sub Topics" },
                    { icon: undefined, route: "Add" }
                ]);
                break;
            case "/learningCenter/topic/edit":
                setArr([
                    { icon: <LearningCenterIcon className={classes.icon} />, route: "Learning Center" },
                    { icon: <CategoryIcon className={classes.icon} />, route: "Topic" },
                    { icon: undefined, route: "Edit" }
                ]);
                break;
            case "/learningCenter/subTopic/edit":
                setArr([
                    { icon: <LearningCenterIcon className={classes.icon} />, route: "Learning Center" },
                    { icon: <CategoryIcon className={classes.icon} />, route: "Sub Topics" },
                    { icon: undefined, route: "Edit" }
                ]);
                break;
            case "/communications":
                setArr([
                    { icon: <ChatIcon className={classes.icon} />, route: "Communications" }
                ]);
                break;
            case "/communications/add":
                setArr([
                    { icon: <ChatIcon className={classes.icon} />, route: "Communications" },
                    { icon: undefined, route: "Send" }
                ]);
                break;
            default:

                setArr([
                    { icon: null, route: "Dashboard" }
                ]);
                break;
        }
    };

    useEffect(() => {
        let splitPath = location.pathname.split('/');
        if(splitPath[splitPath.length - 2] === "categories"){
            setId(splitPath[splitPath.length - 1]);
        }
        MapRoute(location.pathname);
        
        //eslint-disable-next-line
    }, [location]);


    return (

        
                            

        <Breadcrumbs separator='>' classes={{ separator: classes.seperator }}>
            {   
                
                
                arr.map((breadcrumb: any, i: any) => {

                    let isLast = breadcrumb === arr[arr.length - 1];
                    // use usestate to capture current locaiton and set prevPath
                    return (
                        <Link onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                            event.preventDefault();
                            
                            let routeTo = `/${pathnames.slice(0, i + 1).join("/")}`;
                            console.log(routeTo);
                            let last = routeTo.split('/')[routeTo.split('/').length - 1]
                            if( last === "categories" ){
                                routeTo = routeTo + '/' + id;
                            }else if(last === "questions"){
                                routeTo = routeTo + '/list';
                            }
                            console.log(routeTo);
                            let gameId = localStorage.getItem('categoryId');
                            history.push(routeTo,{gameId: id, categoryId: gameId}); 

                        }} 
                        color="inherit" className={classes.link} key={i}>
                            <Typography variant="h6" className={isLast ? classes.current : classes.stack} noWrap>
                                {breadcrumb.icon ? breadcrumb.icon : <div></div>}
                                {breadcrumb.route}
                            </Typography>
                        </Link>
                    )
                })
            }
        </Breadcrumbs>
    );
}
