import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import ExcelExportButton, { ISheet } from '../../../../components/ExcelExportButton';

export interface IExcelByDepartmentProps {
    list: any[];
}

const ExcelByDepartment: React.FC<IExcelByDepartmentProps> = ({ list }) => {

    const [exportSheets, setExportSheets] = useState<ISheet[]>([]);

    useEffect(() => {

        if (!list) return;

        let sheets = [];

        sheets.push({
            sheetName: "Employees",
            sheetColumns: [
                { title: "Department Name", name: "name" },
                { title: "Number of Players", name: "userCount" },
                { title: "Active players", name: "activePlayerCount" },
                { title: "Average Points", name: "averagePoints" }
            ],
            sheetData: list || []
        });

        setExportSheets(sheets);

    }, [list]);

    return (
        <ExcelExportButton 
            fileName='DataExport' 
            buttonTitle='Download' 
            sheets={exportSheets}
            buttonProps={{size: "small", variant: "outlined", style: { backgroundColor: 'white' }}}
        >
            <Typography style={{ fontWeight: 600, fontSize: 14 }}>Export</Typography>
        </ExcelExportButton>
    );
}

export default ExcelByDepartment;