import React, { useState, Fragment } from 'react';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom'
import DataProvider from '../../providers/DataProvider';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add'
import MainLayout from '../../components/MainLayout';
import Filter from '../../components/Filter';
import PopupConfirm from '../../components/PopupConfirm';
import { useDispatch } from 'react-redux';
import AllActions from '../../redux/actions/Index';
import { useSnackbar } from 'notistack';

const DepartmentList = () => {

    const [departments, setDepartments] = useState<any[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<any>({});
    const [selectedDepartmentId, setSelectedDepartmentId] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [checkingUsers, setCheckingUsers] = useState(false);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const dataProvider = new DataProvider();
    const dispatch = useDispatch();
    let history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    function deleteDepartment(departmentId: string) {
        
        setCheckingUsers(true);

        // Check if department has any users
        dataProvider.getUsers(selectedCompany.id, departmentId)
            .then((users) => {
                
                if (users?.length > 0) {
                    // The department has users linked to it, cannot delete
                    enqueueSnackbar("Cannot delete department before its users are removed", { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    return;
                }

                // No users linked to the department, continue to delete
                handleDeleteOpen();
                setSelectedDepartmentId(departmentId);

            })
            .catch((error) => {
                enqueueSnackbar(`Failed to check department users: ${error}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
            })
            .finally(() => {
                setCheckingUsers(false);
            });
    }

    const handleDeleteOpen = () => {
        setOpenDeletePopup(true);
    };

    const handleDeleteClose = () => {
		setOpenDeletePopup(false);
	};

    const handleDeleteConfirm = () => {
        
        setLoading(true);
        handleDeleteClose();

        dataProvider.deleteDepartment(selectedCompany.id, selectedDepartmentId)
            .then(() => {
                
                // Remove the department from the list
                let newDepartments = departments.filter(d => d.id !== selectedDepartmentId);
                
                setDepartments(newDepartments);
                enqueueSnackbar(`Succesfully deleted the department`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                
            })
            .catch(error => {
                enqueueSnackbar(`Failed to delete department: ${error}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
            })
            .finally(() => {
                setLoading(false);
            })
    };

    return (
        <MainLayout
            actions={[
                <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={() => { history.push("departments/add") }}>Add Department</Button>
            ]}
            filters={[
                <Filter
                    loading={loading}
                    onlyCompany={true}
                    onSubmit={(company: any) => {
                        setSelectedCompany(company);
                        setLoading(true);
                        dataProvider.getDepartmentsFD(company.id).then(data => {
                            setDepartments(data);
                            dispatch(AllActions.searchState.setSearchState({ company: company, department: '' }));
                            setLoading(false);
                        }).catch(err => {
                            console.log(err);
                            setLoading(false);
                        })
                    }} />
            ]}
            content={
                <Fragment>
                    <MaterialTable 
                        style={{ width: '100%' }}
                        title="Departments"
                        data={departments}
                        isLoading={loading || checkingUsers}
                        options={{
                            actionsColumnIndex: -1
                        }}
                        columns={[
                            { title: "Name", field: "name" },
                            { title: "Description", field: "description" }
                        ]}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Edit department',
                                onClick: (event, rowData) => {
                                    history.push("departments/edit/" + rowData.id, { companyID: selectedCompany.id });
                                }
                            },
                            {
                                icon: 'delete',
                                tooltip: 'Delete department',
                                onClick: (event, rowData) => {
                                    deleteDepartment(rowData.id);
                                }
                            }
                        ]}
                    />
                    <PopupConfirm
                        contentText="This action can not be reverted!"
                        isOpen={openDeletePopup}
                        title="Are you sure you want to delete this department?"
                        handleClose={handleDeleteClose}
                        handleConfirm={handleDeleteConfirm}
                    />
                </Fragment>
            }
        />
    );
};

export default DepartmentList;
