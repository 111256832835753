import React, { useState, useEffect } from 'react';
import { Grid, Typography, createStyles, makeStyles, Theme } from '@material-ui/core';
import placeholder from './placeholder.png';
//import Image from 'react-image-appear';
import image from './placeholder.png';
//import Image from 'material-ui-image';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        image: {
            borderStyle: "solid",
            borderWidth: "2px",
            borderRadius: "22px"
        },
        input: {
            display: 'none',
        },
    }),
);


export interface IImageUploadProps {
    header?: string;
    label?: string;
    id: string;
    onChange: Function;
    src?: string;
}

const ImageUpload: React.FC<IImageUploadProps> = ({ header, label, id, onChange, src }) => {

    const classes = useStyles();
    const [source, setSource] = useState(src ? src : placeholder);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!src) {
            setSource(placeholder);
        }
    }, [src]);

    return (
        <Grid container direction="column" spacing={2}>
            <input
                accept="image/*"
                className={classes.input}
                id={id}
                multiple
                type="file"
                onChange={(event) => {
                    //@ts-ignore
                    setSource(URL.createObjectURL(event.target.files[0]));
                    //@ts-ignore
                    onChange(event.target.files[0]);
                }}
            />
            <Grid item>
                {
                    header && header.length > 0 
                    ? <Typography variant="h6">{header}</Typography>
                    : <Typography variant="body1">{label}</Typography>
                }
            </Grid>
            <Grid item style={{ paddingBottom: "60px" }} xs={12}>
                <label htmlFor={id}>
                    <img src={image} alt="" width="100%" height="100%" style={loading ? {} : { display: 'none' }} />
                    <img src={source} onLoad={() => setLoading(false)} alt="" width="100%" height="100%" style={loading ? { display: 'none' } : {}} className={classes.image} />
                </label>
            </Grid>
        </Grid>
    );
}

export default ImageUpload;
