import * as React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ExcelDataSetup from './ExcelDataSetup';

export interface IAppProps {
}

export default function UserNavigation(props: any) {

    let { path } = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={path}>
                    <ExcelDataSetup />
                </Route>
            </Switch>
        </div>
    );
}
