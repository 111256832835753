import React, { useRef, useContext, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FormHelperText, Grid, TextField, FormControlLabel, Button, IconButton, Typography, makeStyles, Divider } from '@material-ui/core';
import { green, orange, red } from '@material-ui/core/colors';
import { Autocomplete } from '@material-ui/lab';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Formik, Form, Field } from 'formik';
import { CheckboxWithLabel, TextField as FormikTextField } from 'formik-material-ui';
import AllActions from '../../redux/actions/Index';
import UserRoles from '../../models/enums/Roles';
import IEditUser from '../../models/EditUser';
import DataProvider from '../../providers/DataProvider';
import { AuthContext } from '../../providers/AuthProvider';
import firebase from '../../providers/FirestoreProvider';
import Panel from '../../components/Panel';
import FormFooter from '../../components/FormFooter';
import Dialog from './components/ConfirmDuplicate';
import { Clear, Add } from '@material-ui/icons';

interface RegionIdentifier {
    id: number;
    company: string;
    name: string;
}

interface IRole {
    id: number;
    role: string;
}

const AllRoles: IRole[] = [
    { id: UserRoles.employee, role: "Employee" },
    { id: UserRoles.manager, role: "Manager" },
    { id: UserRoles.comp_manager, role: "Company Manager" },
    { id: UserRoles.admin, role: "Admin" }
]

export default function AddEditUser() {

    const useStyles = makeStyles((theme) => ({
        item: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
            backgroundColor: "white",
            color: "black",
            "&:hover": {
                backgroundColor: "lightgrey",
                color: "black"
            },
        },
        itemAction: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
            color: 'black',
            transition: 'background-color 0.1s ease',
            '&:hover': {
                animation: '$colorChange 1s infinite', // add animation for color change
            },
        },
        '@keyframes colorChange': { // define the keyframes for the color change animation
            '0%': {
                color: 'red',
            },
            '25%': {
                color: '#ff5733',
            },
            '50%': {
                color: '#ff8f66',
            },
            '75%': {
                color: '#ffb399',
            },
            '100%': {
                color: 'red',
            },
        }
    }));
    const classes = useStyles();

    const dataProvider = new DataProvider();
    const mountedRef = useRef(true);
    const history = useHistory();
    const dispatch = useDispatch();
    const searchState = useSelector((state: any) => state.searchState)?.searchState;
    const { id: userId } = useParams<{ id: string }>();
    const { user: loggedInUser } = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();

    const isEditForm = Number(userId?.length) > 0;
    const [loading, setLoading] = useState<boolean>(true);
    const [usersInfo, setUsersInfo] = useState<any>({ current: 0, limit: 0 });
    const [usersInfoColor, setUsersInfoColor] = useState<any>({ color: green[700] });
    const [roles, setRoles] = useState<IRole[]>([]);
    const [companies, setCompanies] = useState<any[]>([]);
    const [departments, setDepartments] = useState<any[]>([]);
    const [initialFormValues, setInitialFormValues] = useState<any>({
        userId: userId || "",
        userRoleId: "",
        userRole: {},
        companyId: searchState?.company?.id || "",
        company: {},
        departmentId: searchState?.department?.id || "",
        department: {},
        idNumber: "",
        name: "",
        surname: "",
        email: "",
        general: "",
        gameRetryOverride: 0
    });

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogUser, setDialogUser] = useState({});

    const validationSchema = Yup.object().shape({
        userRoleId: Yup.string().required("Required").oneOf(roles.map(r => r.id.toString()), "Invalid selection").nullable(),
        companyId: Yup.string().when("userRoleId", {
            is: (roleId) => !isCompanyDisabled(roleId),
            then: Yup.string().required('Required').oneOf(companies.map(c => c.id), "Invalid selection").nullable()
        }),
        departmentId: Yup.string().when("userRoleId", {
            is: (roleId) => !isDepartmentDisabled(roleId),
            then: Yup.string().required('Required').oneOf(departments.map(d => d.id), "Invalid selection").nullable()
        }),
        idNumber: Yup.string().matches(/^[0-9]+$/, "ID number may only contain numbers").length(13).notRequired(),
        name: Yup.string().matches(/^[a-zA-Z -]+$/, "Name may only contain letters").required('Required'),
        surname: Yup.string().matches(/^[a-zA-Z -]+$/, "Surname may only contain letters").required('Required'),
        email: Yup.string().email().notRequired(),
        general: Yup.string().matches(/^[^,]+$/, "Cannot contain a comma ','."),
        gameRetryOverride: Yup.number().min(0),
    });
    const [regionIdentifiers, setRegionIdentifiers] = useState<any[]>([]);
    const [newRegionIdentifier, setNewRegionIdentifier] = useState(null);
    const [selectedRegionIdentifier, setSelectedRegionIdentifier] = useState<any>(null);

    const [shouldRefresh, setShouldRefresh] = useState<boolean>(true);

    const isCompanyDisabled = (selectedRoleId: number) => {

        // Disable the company selection based on the selected role, the role of the logged-in user
        return Number(selectedRoleId) === UserRoles.admin || Number(loggedInUser?.role) !== UserRoles.admin;

    }

    const isDepartmentDisabled = (selectedRoleId: number) => {

        let roleId = Number(selectedRoleId);
        let loggedRoleId = Number(loggedInUser?.role);

        // Disable the department selection based on the selected role and the role of the logged-in user
        return roleId === UserRoles.admin || roleId === UserRoles.comp_manager || (loggedRoleId !== UserRoles.admin && loggedRoleId !== UserRoles.comp_manager);

    }

    useEffect(() => {

        if (shouldRefresh) {

            getUser(initialFormValues.userId).then(async resUser => {

                if (resUser?.companyId !== undefined || initialFormValues.companyId !== '') {
                    const regions = await dataProvider.getRegionIdentifiers(resUser?.company || initialFormValues.companyId);
                    setRegionIdentifiers(regions);
                    setRegionIdentifiers(regions);

                    const foundRegion = regions.filter((r: any) => r.name === resUser?.region)[0];
                    setSelectedRegionIdentifier(foundRegion);
                }

                Promise.allSettled([
                    getCompanies(),
                    getDepartments(resUser?.company || initialFormValues.companyId),
                    getUserInfo(resUser?.company || initialFormValues.companyId),
                ]).then((response: any) => {

                    const resCompanies = response[0].value;
                    const resDepartments = response[1].value;
                    const defaultRole = AllRoles.find(r => r.id === resUser?.role);
                    const defaultCompany = resCompanies.find((c: any) => c.id === resUser?.company || c.id === initialFormValues.companyId);
                    const defaultDepartment = resDepartments.find((d: any) => d.id === resUser?.department || d.id === initialFormValues.departmentId);

                    setInitialFormValues({
                        ...initialFormValues,
                        userRoleId: defaultRole?.id || "",
                        userRole: defaultRole || {},
                        companyId: defaultCompany?.id || "",
                        company: defaultCompany || {},
                        departmentId: defaultDepartment?.id || "",
                        department: defaultDepartment || {},
                        idNumber: resUser?.idNumber || "",
                        name: resUser?.firstName || "",
                        surname: resUser?.lastName || "",
                        email: resUser?.email || "",
                        general: resUser?.general || "",
                        disabled: resUser?.disabled === true,
                        gameRetryOverride: resUser?.gameRetryOverride || 0,
                    });

                    setLoading(false);
                    setShouldRefresh(false);
                });

            })

            switch (loggedInUser?.role) {
                case UserRoles.admin:

                    setRoles(AllRoles);

                    break;
                case UserRoles.comp_manager:

                    setRoles(AllRoles.filter(r => r.id === UserRoles.employee || r.id === UserRoles.manager));

                    break;
                case UserRoles.manager:

                    setRoles(AllRoles.filter(r => r.id === UserRoles.employee));

                    break;
                default:
                    enqueueSnackbar("User does not have the necessary permissions!", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    setTimeout(() => {
                        history.goBack();
                    }, 2000);
                    break;
            }

            return () => { mountedRef.current = false; }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    }, [shouldRefresh]);

    function getUser(userId: string) {

        return new Promise<any>(async (res) => {

            if (!isEditForm) {
                res(null);
                return;
            }

            dataProvider.getUserById(userId, loggedInUser, initialFormValues.companyId)
                .then(user => {
                    res(user);
                })
                .catch(error => {
                    res(null);
                    enqueueSnackbar('Failed to load the existing user details: ' + error.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                })
                .finally(() => {
                    if (!mountedRef.current) return null;
                });

        });

    }

    function getCompanies() {

        return new Promise<any[]>(async (res) => {

            dataProvider.getCompanies()
                .then(data => {

                    let resCompanies = data.sort((a, b) => a.name > b.name ? 1 : -1);

                    setCompanies(resCompanies);
                    res(resCompanies);

                })
                .catch(error => {
                    res([]);
                    enqueueSnackbar('Failed to load the company options: ' + error.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                })
                .finally(() => {
                    if (!mountedRef.current) return null;
                });

        });

    }

    function getDepartments(companyId: string) {

        return new Promise<any[]>(async (res) => {

            if (!companyId) {
                setDepartments([]);
                res([]);
                return;
            }

            dataProvider.getDepartments(companyId)
                .then(data => {

                    let resDepartments = data.sort((a, b) => a.name > b.name ? 1 : -1);

                    setDepartments(resDepartments);
                    res(resDepartments);

                })
                .catch(error => {
                    res([]);
                    enqueueSnackbar('Failed to load the department options: ' + error.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                })
                .finally(() => {
                    if (!mountedRef.current) return null;
                });

        });

    }

    function getUserInfo(companyId: string) {

        let current = 0;
        let limit = 0;

        if (!companyId) {
            setUsersInfo({ current, limit });
            return;
        }

        dataProvider.getCompany(companyId)
            .then(data => {

                current = data.userCount || 0;
                limit = data.maxNumUsers || 0;

                setUsersInfo({ current, limit });
                setUsersInfoColor(current >= limit ? { color: red[700] } : current >= limit - 5 ? { color: orange[700] } : { color: green[700] });

            }).catch((err) => {
                enqueueSnackbar("Failed to get users count and limit: " + err.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
            })
            .finally(() => {
                if (!mountedRef.current) return null;
            });

    }

    async function addNewUser(values: any) {

        enqueueSnackbar("Creating New User Please wait", { variant: "info", anchorOrigin: { horizontal: "right", vertical: "bottom" } });

        let addUser = firebase.functions().httpsCallable('addUser');

        let params = {
            accountRoleId: 3,
            userRole: values.userRoleId,
            company: values.companyId,
            department: values.departmentId,
            idNumber: values.idNumber,
            name: values.name,
            surname: values.surname,
            email: values.email,
            general: values.general,
            gameRetryOverride: values.gameRetryOverride,
            regionIdentifier: selectedRegionIdentifier?.name,
        };

        if (!params.email) {

            addUser = firebase.functions().httpsCallable('createUserWithNoEmail');

            params.company = companies.find(c => c.id === values.companyId);
            params.department = departments.find(d => d.id === values.departmentId);

        }

        try {
            addUser(params)
                .then((response) => {
                    const user = response.data;
                    getUserInfo(values.companyId);
                    dispatch(AllActions.searchState.setSearchState({ company: values.company, department: values.department }));
                    enqueueSnackbar(`User ${user.email} created`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                })
                .catch((error) => {
                    if (error.message?.includes("userAccount")) {
                        // Account already exists, prompt for confirmation
                        let errorObj = JSON.parse(error.message);
                        let newParams = {
                            ...params,
                            id: errorObj?.userAccount?.uid
                        };
                        setDialogUser(newParams);
                        setDialogOpen(true);
                    } else {
                        enqueueSnackbar('Failed to add user: ' + error.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (error) {
            enqueueSnackbar('Failed to add user: ' + error, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        }

    }

    async function addOrEditUser(values: any, actions: any, isEdit: boolean, disabledChanged: boolean, emailChanged: boolean) {
        
        try {

            if (isEdit) {

                setLoading(true);

                await dataProvider.addOrEditUser(values, actions, isEdit, disabledChanged, emailChanged, initialFormValues.email, !selectedRegionIdentifier ? '' : selectedRegionIdentifier.name)
                
                setLoading(false);
                enqueueSnackbar("User Updated!", { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });

            } else {

                setLoading(true);
                await addNewUser(values);
                setLoading(false);

            }

            setShouldRefresh(true);

        } catch (error) {
            enqueueSnackbar("Error with Saving user", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        }

    }

    async function onSubmit(values: any, actions: any) {

        setLoading(true);
        
        let disabledStateChanged = initialFormValues.disabled !== values.disabled;
        let emailChanged = initialFormValues.email !== values.email;

        if (isEditForm && emailChanged) {
            // Need to check if the email is linked to any other user
            try {
                const allUsers = await dataProvider.getAllUsers(undefined)
                const usersWithNewEmail = allUsers.filter((u: any) => u.email.trim().toLowerCase() == values.email.trim().toLowerCase());
                const usersWithPreviousEmail = allUsers.filter((u: any) => u.companyId !== values.companyId && u.email.trim().toLowerCase() == initialFormValues.email.trim().toLowerCase());

                if (usersWithNewEmail.length > 0) {
                    enqueueSnackbar('Cannot update email: already in use by another account', { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    return;
                }
    
                if (usersWithPreviousEmail.length > 0) {
                    // Need to update the email address for all the users using the old one
                    for (let i = 0; i < usersWithPreviousEmail.length; i++) {
                        const otherUser = usersWithPreviousEmail[i];
                        
                        await firebase.firestore().collection(`Companies/${otherUser.companyId}/Users`).doc(otherUser.userId).update({
                            'userInfo.email': values.email
                        });
                    }
                }
            } catch (error: any) {
                enqueueSnackbar('Failed to edit user: ' + error.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                return;   
            }
        }

        await addOrEditUser(values, actions, isEditForm, disabledStateChanged, emailChanged);

        setLoading(false);

    }

    function handleRoleChanged(value: any, setFieldValue: Function) {

        setFieldValue("userRoleId", value?.id || "");
        setFieldValue("userRole", value);

        if (isCompanyDisabled(value?.id)) {
            setFieldValue("companyId", "");
            setFieldValue("company", {});
            getUserInfo("");
        }

        if (isDepartmentDisabled(value?.id)) {
            setFieldValue("departmentId", "");
            setFieldValue("department", {});
        }

    }

    async function handleCompanyChanged(value: any, setFieldValue: Function) {

        setFieldValue("companyId", value?.id || "");
        setFieldValue("company", value);

        getDepartments(value?.id);
        getUserInfo(value?.id);

        const regions = await dataProvider.getRegionIdentifiers(value?.id);

        setRegionIdentifiers(regions);

        if (!value?.id) {
            setFieldValue("departmentId", "");
            setFieldValue("department", {});
        }

    }

    function handleDepartmentChanged(value: any, setFieldValue: Function) {

        setFieldValue("departmentId", value?.id || "");
        setFieldValue("department", value);

    }

    function handleDialogClose(success: boolean, error: any = null) {

        setDialogOpen(false);

        if (success === true) {
            enqueueSnackbar("Additional company-link is created to existing account", { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        } else {
            enqueueSnackbar(`Failed to create additional company-link for existing account${(error != null ? `. Error: ${error}` : "")}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        }

    }
    
    const renderOption = (option: RegionIdentifier) => (
        <>
            <Grid container>
                <Grid className={classes.item} item xs={6}>
                    {option.name}
                </Grid>
            </Grid>
        </>
    );

    function handleAddRegionIdentifier(values: any) {
        if (newRegionIdentifier === "" || newRegionIdentifier === null) {
            enqueueSnackbar(`Please enter a region identifier`, { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
            return;
        }
        const addableRegionIdentifier: RegionIdentifier = {
            id: regionIdentifiers.length + 1,
            company: values.companyId != "" ? searchState?.company?.id : "",
            name: newRegionIdentifier
        }
        //make sure it is not already added
        if (regionIdentifiers.find((item: RegionIdentifier) => item.name === addableRegionIdentifier.name)) {
            enqueueSnackbar(`${addableRegionIdentifier.name} already added`, { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
            return;
        }
        setRegionIdentifiers([...regionIdentifiers, addableRegionIdentifier]);
    }
    function handleRemoveRegionIdentifier(value: any) {
        setSelectedRegionIdentifier(null);
        setNewRegionIdentifier(null);
        setRegionIdentifiers(regionIdentifiers.filter((item: RegionIdentifier) => item.id !== value.id));
    }

    return (
        <Panel isLoading={loading}>
            <Grid container spacing={2} direction="column">

                <Grid item>
                    <div style={{ marginBottom: '20px' }}>
                        Users added: <span style={usersInfoColor}>{usersInfo.current}/{usersInfo.limit}</span>
                    </div>
                </Grid>

                <Grid item xs={4} container>
                    <Formik
                        validationSchema={validationSchema}
                        initialValues={initialFormValues}
                        enableReinitialize={true}
                        onSubmit={(values, { setSubmitting, resetForm }) => onSubmit(values, { setSubmitting, resetForm })}
                    >
                        {({
                            values,
                            isSubmitting,
                            errors,
                            touched,
                            submitForm,
                            setFieldValue
                        }) => (
                            <Form noValidate style={{ width: '100%' }}>
                                <Grid container spacing={3} direction="column">

                                    <Grid item>
                                        <Autocomplete
                                            id="userRoleId"
                                            disabled={isEditForm || isSubmitting}
                                            options={roles}
                                            getOptionSelected={() => true}
                                            getOptionLabel={(option: IRole) => option.role || ""}
                                            value={values.userRole}
                                            onChange={(e, value) => handleRoleChanged(value, setFieldValue)}
                                            renderInput={params => (
                                                <Field
                                                    {...params}
                                                    component={TextField}
                                                    required={!isEditForm}
                                                    name="userRoleId"
                                                    label="Role"
                                                    variant="filled"
                                                    error={errors.userRoleId && touched.userRoleId}
                                                    helperText={errors.userRoleId && touched.userRoleId ? errors.userRoleId : ""}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Autocomplete
                                            id="companyId"
                                            disabled={isCompanyDisabled(values?.userRoleId) || isEditForm || isSubmitting}
                                            options={companies}
                                            getOptionSelected={() => true}
                                            getOptionLabel={(option: any) => option.name || ""}
                                            value={values.company}
                                            onChange={(e, value) => handleCompanyChanged(value, setFieldValue)}
                                            renderInput={params => (
                                                <Field
                                                    {...params}
                                                    component={TextField}
                                                    required={!isCompanyDisabled(values?.userRoleId) && !isEditForm}
                                                    name="companyId"
                                                    label="Company"
                                                    variant="filled"
                                                    error={errors.companyId && touched.companyId && !isCompanyDisabled(values?.userRoleId)}
                                                    helperText={errors.companyId && touched.companyId && !isCompanyDisabled(values?.userRoleId) ? errors.companyId : ""}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Autocomplete
                                            id="departmentId"
                                            disabled={isDepartmentDisabled(values?.userRoleId) || isSubmitting}
                                            options={departments}
                                            getOptionSelected={() => true}
                                            getOptionLabel={(option: any) => option.name || ""}
                                            value={values.department}
                                            onChange={(e, value) => handleDepartmentChanged(value, setFieldValue)}
                                            renderInput={params => (
                                                <Field
                                                    {...params}
                                                    component={TextField}
                                                    required={!isDepartmentDisabled(values?.userRoleId)}
                                                    name="departmentId"
                                                    label="Department"
                                                    variant="filled"
                                                    error={errors.departmentId && touched.departmentId && !isDepartmentDisabled(values?.userRoleId)}
                                                    helperText={errors.departmentId && touched.departmentId && !isDepartmentDisabled(values?.userRoleId) ? errors.departmentId : ""}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item>
                                        {regionIdentifiers.length > 0 && <Autocomplete
                                            options={regionIdentifiers}
                                            disableCloseOnSelect
                                            getOptionSelected={(option, value) => option?.id === value?.id}
                                            getOptionLabel={(option: RegionIdentifier) => option?.name}
                                            renderOption={renderOption}
                                            value={selectedRegionIdentifier}
                                            onChange={(event, value) => { console.log(event, value); setSelectedRegionIdentifier(value); }}
                                            renderInput={(params) => (
                                                <Field
                                                    {...params}
                                                    component={TextField}
                                                    label="Region Identifier"
                                                    variant="filled"
                                                    value={selectedRegionIdentifier}
                                                    onChange={(e: any) => { console.log(e); setNewRegionIdentifier(e.target.value); }}
                                                />
                                            )}
                                        />}
                                    </Grid>

                                    <Grid item>
                                        <Field
                                            component={FormikTextField}
                                            type="text"
                                            label="ID Number"
                                            name="idNumber"
                                            variant="filled"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Field
                                            component={FormikTextField}
                                            type="text"
                                            label="Name"
                                            name="name"
                                            variant="filled"
                                            fullWidth
                                            required
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Field
                                            component={FormikTextField}
                                            type="text"
                                            label="Surname"
                                            name="surname"
                                            variant="filled"
                                            fullWidth
                                            required
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Field
                                            component={FormikTextField}
                                            type="text"
                                            label="Email"
                                            name="email"
                                            variant="filled"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Field
                                            component={FormikTextField}
                                            type="text"
                                            label="General Field"
                                            name="general"
                                            variant="filled"
                                            fullWidth
                                        />
                                        <FormHelperText id="helper-text" style={{ fontSize: 16 }}>Seperate values with a ";"</FormHelperText>
                                    </Grid>

                                    <Grid item>
                                        <Field
                                            component={FormikTextField}
                                            type="text"
                                            label="Game Retry Override"
                                            name="gameRetryOverride"
                                            variant="filled"
                                            fullWidth
                                        />
                                    </Grid>

                                    {
                                        isEditForm &&
                                        <Grid item style={{ marginLeft: 10 }}>
                                            <FormControlLabel
                                                label="Disabled"
                                                control={
                                                    <Field
                                                        name="disabled"
                                                        type="checkbox"
                                                        component={CheckboxWithLabel}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    }

                                    <Grid item>
                                        <FormFooter onAction={() => { submitForm() }} onBack={() => { history.goBack() }} actionName={isEditForm ? "Save" : "Add"} submitting={isSubmitting} />
                                    </Grid>

                                </Grid>
                            </Form>
                        )}
                    </Formik >
                </Grid>

                <Dialog
                    title="Please Confirm"
                    contentText="The user account already exists. Proceed to link account to this company?"
                    isOpen={dialogOpen}
                    handleClose={handleDialogClose}
                    user={dialogUser} />
            </Grid>
        </Panel>
    );
}


//Force