import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import ExcelExportButton, { ISheet } from '../../../../components/ExcelExportButton';

export interface IExcelByPercentageProps {
    list: any;
}

const ExcelByPercentage: React.FC<IExcelByPercentageProps> = ({ list }) => {

    const [exportSheets, setExportSheets] = useState<ISheet[]>([]);

    useEffect(() => {

        if (!list) return;

        let sheets = [];

        sheets.push({
            sheetName: "By Percentages",
            sheetColumns: [
                { title: "Percentage", name: "percentage" },
                { title: "Points", name: "points" },
                { title: "Games Played", name: "gamesPlayed" },
                { title: "Time Played", name: "timeSpent" },
            ],
            sheetData: list || []
        });

        setExportSheets(sheets);

    }, [list]);

    return (
        <ExcelExportButton 
            fileName='DataExport' 
            buttonTitle='Download' 
            sheets={exportSheets}
            buttonProps={{size: "small", variant: "outlined", style: { backgroundColor: 'white' }}}
        >
            <Typography style={{ fontWeight: 600, fontSize: 14 }}>Export</Typography>
        </ExcelExportButton>
    );
}

export default ExcelByPercentage;