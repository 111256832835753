import React, { useState, useEffect } from 'react';
import { Grid, Divider, Tabs, Tab, AppBar, TextField } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import readXlsxFile from 'read-excel-file';
import FormFooter from '../../../components/FormFooter';
import MaterialTable from 'material-table';
import { TabPanel, a11yProps } from '../functions/TabFuncitons';
import firebase from '../../../providers/FirestoreProvider';
import DataProvider from '../../../providers/DataProvider';
import Selector from './CompanyDepartmentSelector';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

const schema = {
    'Name': {
        prop: "name",
        type: String,
        required: true
    },
    'Surname': {
        prop: "surname",
        type: String,
        required: true
    },
    'Email': {
        prop: "email",
        type: String,
        required: false
    },
    'ID Number': {
        prop: "idNumber",
        type: String,
        required: false
    },
    'general': {
        prop: "general",
        type: String,
        required: false
    }
}

export interface IBulkAddProps {
    UsersInfo: any,
    onUsersInfoUpdated: any
}

export default function BulkAdd(props: IBulkAddProps) {

    const dataProvider = new DataProvider();

    //Used to track tabs
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);

    const [failed, setFailed] = useState<any[]>([]);

    //the raw data from excel show all rows (rows) and showing each error (errors)
    const [rows, setRows] = useState<any[]>([]);
    const [errors, setErrors] = useState<any[]>([]);

    //The user list seperated between users:succeeded and errorList:failed
    const [users, setUsers] = useState<any[]>([]);
    const [errorList, setErrorList] = useState<any[]>([]);

    const [submitting, setSubmitting] = useState(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [password, setPassword] = useState<any>("");

    const { enqueueSnackbar } = useSnackbar();

    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;

    const [department, setDepartment] = useState<any>({ id: 0, name: "" });
    const [company, setCompany] = useState<any>({ id: 0, name: "" });
    const [usersInfo, setUsersInfo] = useState<any>(props.UsersInfo);

    const history = useHistory();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {

        try {
            
            setSubmitting(true);
            var addBulk = firebase.functions().httpsCallable('bulkAdd');

            addBulk({ users: users, company: company.id, department: department.id, companyName: company.name, password: password, userRoleId: 4 }).then((err) => {
                let failed: any[] = err.data;
                setValue(2);
                handleClickOpen();
                setFailed(failed);
                setSubmitting(false);
                getUserInfo();
            }).catch(err => {
                enqueueSnackbar("Failed to add users: " + err, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                setSubmitting(false);
            });
        } catch (error) {
            enqueueSnackbar("Failed to add users: " + error, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
            setSubmitting(false);
        }

    }

    const handleSelectState = () => {
        if (department.name !== "" && rows.length > 0) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }

    const seperateErrors = () => {
        const empty: any[] = [];
        var errorArr: any[] = empty.concat(errors);
        var rowsArr: any[] = empty.concat(rows);
        var succeeded: any[] = [];
        var errorList: any[] = [];

        var error = false;
        for (let i = 0; i < rowsArr.length; i++) {
            error = false;
            for (let j = 0; j < errorArr.length; j++) {
                if (i === errorArr[j].row - 1) {
                    error = true;
                    console.log("Found error: ", i);
                }
            }

            if (!error) { succeeded.push(rowsArr[i]) }
            if (error) { errorList.push(rowsArr[i]) }
        }
        setUsers(succeeded);
        setErrorList(errorList);
    }

    const onUsersInfoUpdated = (current: any, limit: any) => {
        setUsersInfo({ current, limit });
        props.onUsersInfoUpdated(current, limit);
    };

    function getUserInfo(){
        var current = 0;
        var limit = 0;
        
        if (company.id != 0 && company.id.length > 0){
            dataProvider.getCompany(company.id).then(data => {
                current = data.userCount || 0;
                limit = data.maxNumUsers || 0;

                onUsersInfoUpdated(current, limit);
            }).catch((err) => {
                enqueueSnackbar("Failed to get users count and limit: " + err.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
            });
        }
    }

    useEffect(() => {
        //Update button state based on users, department
        handleSelectState();
        seperateErrors();
        getUserInfo();
        //eslint-disable-next-line
    }, [rows, department, company])

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Grid container spacing={3}>
            <Grid container item xs={5} direction="row" spacing={3}>
                <Grid item xs={12}>
                    <Selector disableCompany={true} companyInitial={searchState.company} departmentInitial={searchState.department} onSelect={(company: any, department: any) => {
                        setCompany(company);
                        setDepartment(department);
                    }} />
                </Grid>
                <Grid item xs={12}>
                    <TextField id="tfPassword" label="Custom Password" onChange={(event) => { setPassword(event.target.value) }} />
                    <DropzoneArea
                        acceptedFiles={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                        showAlerts={false}
                        filesLimit={1}
                        dropzoneText="Upload Excel (.xlsx extension only)"
                        onChange={
                            (files: any) => {
                                readXlsxFile(files[0], { schema }).then(({ rows, errors }: any) => {
                                    setErrors(errors);
                                    console.log("rows", rows);
                                    console.log("errors", errors);
                                    setRows(rows);
                                    setFailed([]);
                                });
                            }
                        }
                        onDelete={() => {
                            setRows([]);
                        }} />
                </Grid>
            </Grid>
            <Grid container item xs={1} justify="center">
                <Grid item>
                    <Divider orientation="vertical" variant="fullWidth" />
                </Grid>
            </Grid>
            <Grid container item xs={6}>
                <Grid item xs={12}>
                    <AppBar position="static">
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                            <Tab label="Users to Add" {...a11yProps(0)} />
                            <Tab label="Errors" {...a11yProps(1)} />
                            <Tab label="Failed" {...a11yProps(2)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <MaterialTable style={{ width: '100%', overflow: 'auto', height: "100%" }}
                            columns={[
                                { title: "Name", field: "name" },
                                { title: "Surname", field: "surname" },
                                { title: "Email", field: "email" }
                            ]}
                            data={users}
                            options={{
                                search: false,
                                showTitle: false,
                                toolbar: false,
                                actionsColumnIndex: -1
                            }}

                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <MaterialTable style={{ width: '100%', overflow: 'auto', height: "100%" }}
                            columns={[
                                { title: "Name", field: "name" },
                                { title: "Surname", field: "surname" },
                                { title: "Email", field: "email" }
                            ]}
                            data={errorList}
                            options={{
                                search: false,
                                showTitle: false,
                                toolbar: false,
                                actionsColumnIndex: -1
                            }}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <MaterialTable style={{ width: '100%', overflow: 'auto', height: "100%" }}
                            columns={[
                                { title: "Email", field: "email" },
                                { title: "Error Desc", field: "error.errorInfo.message" }
                            ]}
                            data={failed}
                            options={{
                                search: false,
                                showTitle: false,
                                toolbar: false
                            }}

                        />
                    </TabPanel>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={12}>
                    <FormFooter disableAction={disabled} onAction={() => { handleSubmit() }} onBack={() => { history.goBack() }} submitting={submitting} />
                </Grid>
            </Grid>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Report"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">

                            {failed.length > 0 ? `${failed.length} row(s) failed, look at the failed tab in the table to view detailed summary` : "All users added succesfully"}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Grid>
    );
}