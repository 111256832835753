import React, { useState } from 'react';
import { Grid, AppBar, Typography, Toolbar, makeStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import Export from './exports/ExcelInactiveUsers';

const useStyles = makeStyles((theme) => ({
    bar: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    }
}));

export interface IInactiveTableProps {
    users: any[] | undefined;
}

const InactiveTable: React.FC<IInactiveTableProps> = ({ users }) => {


    const classes = useStyles();

    const getPlayTime = (data: any) => {
        let gameStats = data.gameStats;

        if (gameStats === undefined) {
            return 0;
        }

        if (gameStats.playTime === undefined)
            return 0;

        return gameStats.playTime;
    }


    const getAccuracy = (data: any) => {
        let gameStats = data.gameStats;

        if (gameStats === undefined) {
            return 0;
        }

        if (gameStats.answerAccuracy === undefined) {
            return 0;
        }

        let accuracy = Math.round((gameStats.answerAccuracy + Number.EPSILON) * 100)
        if (isNaN(accuracy))
            return 0;

        return accuracy;
    }


    return (
        <Grid container direction="column">
            <Grid item>
                <AppBar position="static" className={classes.bar}>
                    <Toolbar>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="h6">Inactive players</Typography>
                            </Grid>
                            <Grid item>
                                <Export list={users ? users : []} />
                            </Grid>
                        </Grid>

                    </Toolbar>
                </AppBar>
            </Grid>
            <Grid item xs={12}>
                <MaterialTable style={{ width: '100%', height: '500px', overflowX: 'scroll' }}
                    columns={[
                        { title: "Name", field: "userInfo.firstName" },
                        { title: "Surname", field: "userInfo.lastName" },
                        { title: "Email", field: "userInfo.email" },
                        { title: "General", field: "userInfo.general" }
                    ]}
                    data={users ? users : []}
                    options={{
                        paging: false,
                        search: false,
                        showTitle: false,
                        toolbar: false,
                        actionsColumnIndex: -1
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default InactiveTable;