import React, { useState, useContext, useEffect, Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab';
import { Grid, TextField as MUITextField } from '@material-ui/core';
import * as Yup from 'yup';
import FormFooter from '../../../components/FormFooter';
import EditbleMediaSelector from '../../../components/MediaSelectorEditable';
import { useHistory } from 'react-router-dom';
import DataProvider from '../../../providers/DataProvider';
import { AuthContext } from '../../../providers/AuthProvider';
import { useSelector } from 'react-redux';


const validation = Yup.object().shape({
    title: Yup.string().required('required'),
    media: Yup.array().min(1),
    company: Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required()
    }).required()
});

export interface IAddCommunicationProps {
}

export default function AddCommunication(props: IAddCommunicationProps) {

    const { user, currentUser } = useContext(AuthContext);

    const dataprovider = new DataProvider();
    const [mediaError, setMediaError] = useState(false);
    const [media, setMedia] = useState<any[]>([]);
    const [companies, setCompaines] = useState<any[]>([]);
    const [departments, setDepartments] = useState<any[]>([]);
    let history = useHistory();

    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;


    var onCompanySelect = (data: any, setFieldValue: any) => {

        if (data) {

            dataprovider.getDepartments(data.id).then(res => {
                setDepartments(res);
            });

        } else {
            setFieldValue("department", null);
            setDepartments([]);
        }
    }

    const getDepartments = (company: any) => {
        if (company.id !== "") {
            dataprovider.getDepartments(company.id).then(res => {
                setDepartments(res);
            });
        }
    }

    useEffect(() => {

        getDepartments(searchState.company);

        dataprovider.getCompanies().then(data => {
            setCompaines(data);
        })

    }, []);

    return (
        <Fragment>
            <Formik
                validationSchema={validation}
                initialValues={{
                    title: '',
                    company: searchState.company,
                    department: searchState.department,
                    media: ''
                }}
                onSubmit={(values, { setSubmitting }) => {

                    let communication = {
                        title: values.title,
                        media: values.media,
                        sendDate: new Date(),
                        initialSendDate: new Date(),
                        company: values.company.id,
                        department: values.department? values.department.id: "",
                        sender: {
                            uid: currentUser?.uid,
                            email: user?.email
                        }
                    }

                    dataprovider.addCommunication(communication).then(() => {
                        history.goBack();
                    }).catch(err => {

                    })
                    setSubmitting(false);
                }}
            >
                {({ submitForm, isSubmitting, errors, touched, setFieldValue, values }) => (
                    <Form>
                        <Grid container direction="column" spacing={3}>
                            <Grid item xs={4}>
                                <Field
                                    variant="filled"
                                    component={TextField}
                                    name="title"
                                    type="text"
                                    label="Title"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    disabled={true}
                                    name="company"
                                    component={Autocomplete}
                                    options={companies}
                                    getOptionLabel={(option: any) => option.name}
                                    value={values.company}
                                    onChange={(event: any, data: any) => {
                                        setFieldValue("company", data);

                                        onCompanySelect(data, setFieldValue);

                                    }}
                                    renderInput={(params: AutocompleteRenderInputParams) => (
                                        <MUITextField
                                            {...params}
                                            error={touched['company'] && !!errors['company']}
                                            helperText={
                                                //@ts-ignore
                                                touched['company'] && errors['company']?.title
                                            }
                                            label="Company"
                                            variant="filled"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    name="department"
                                    component={Autocomplete}
                                    options={departments}
                                    getOptionLabel={(option: any) => option.name}
                                    value={values.department}
                                    onChange={(event: any, data: any) => {
                                        setFieldValue("department", data);

                                    }}
                                    renderInput={(params: AutocompleteRenderInputParams) => (
                                        <MUITextField
                                            {...params}
                                            error={touched['department'] && !!errors['department']}
                                            helperText={
                                                "If department is not selected message will be sent out to all departments in company"
                                            }
                                            label="Department"
                                            variant="filled"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <EditbleMediaSelector
                                    dataPath={`/Communication`}
                                    error={mediaError}
                                    heading="Message content"
                                    onMediaChange={(arr: any[]) => {
                                        setMediaError(arr.length > 0 ? false : true);
                                        setFieldValue("media", arr);
                                        setMedia(arr);
                                    }} />
                            </Grid>
                            <Grid item>
                                <FormFooter onBack={() => { history.goBack() }} actionName="Send" onAction={() => { submitForm() }} submitting={isSubmitting} />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Fragment>
    );
}
