import { v4 as uuidv4 } from 'uuid';
import { BlobServiceClient, AnonymousCredential } from "@azure/storage-blob";

const azureStorageContainer = process.env.REACT_APP_AZURE_STORAGE_CONTAINER_NAME || "";
const account = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT || "";
const accountSas = atob(process.env.REACT_APP_AZURE_SAS_TOKEN || "");

interface videoReturn {
    videoUrl: string,
    videoPath: string,
    videoProcessed: boolean,
    videoIsUpload: boolean
}

interface azureAuthResult {
    tokenType: string,
    accessToken: string
}

class AzureProvider {
    addVideo = (video: any, name: string) => new Promise<videoReturn>(async (res, rej) => {        
        var fileName = uuidv4();
        var file = video;
        var fileExtension = name.substring(name.lastIndexOf('.')+1, name.length) || name;
        const anonymousCredential = new AnonymousCredential();
        const blobServiceClient = new BlobServiceClient(
            `https://${account}.blob.core.windows.net${accountSas}`,
            anonymousCredential
          );

        const containerClient = blobServiceClient.getContainerClient(azureStorageContainer);
        const content = file;
        const blobName = fileName + "." + fileExtension;
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        await blockBlobClient.upload(content, Buffer.byteLength(content));
        const link = `https://${account}.blob.core.windows.net/${azureStorageContainer}/${fileName}.${fileExtension}`;
        res({videoUrl: link, videoPath: link, videoIsUpload: true, videoProcessed: false});
    });
}

export default AzureProvider;
