import React, { useState, useEffect, useImperativeHandle, forwardRef, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { TextField } from 'formik-material-ui';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Formik, Field } from 'formik';
import DataProvider, { GameScheduleState } from '../providers/DataProvider';
import { Grid, TextField as MuiTextField, Typography, LinearProgress, FormControlLabel, Checkbox, DialogActions } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { DialogProps } from '@material-ui/core';
import { GridSize } from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import UserSelector from '../pages/scheduler/components/UserSelector';
import ScheduleType from '../models/enums/ScheduleType';



interface ISettings {
    dialogSize: DialogProps['maxWidth'];
    itemSize: GridSize;
    height: string;
}

interface IFormDialog {
    ref: any;
    onSchedualAdd: any;
}

const FormDialog = forwardRef<any, IFormDialog>(({ onSchedualAdd }, ref) => {

    const dataProvider = new DataProvider();
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [open, setOpen] = useState(false);
    const [usersSelected, setUsersSelected] = useState<any[]>([]);
    // eslint-disable-next-line
    const [games, setGames] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();
    const [isIndividual, setIsIndividual] = useState(false);
    const [settings, setSettings] = useState<ISettings>({
        dialogSize: "lg",
        itemSize: 12,
        height: '400px'
    });
    const [scheduleState, setScheduleState] = useState<GameScheduleState | undefined>();
    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;

    useImperativeHandle(ref, () => ({

        OpenModal(start: any, end: any) {

            setIsIndividual(false);


            if (start && end) {
                setEndDate(formatDate(end));
                setStartDate(formatDate(start));
            } else {
                setEndDate({});
                setStartDate({});
            }

            setOpen(true);
            dataProvider.getGamesFD(searchState.company.id, searchState.department.id).then(data => {
                let gamesList: any[] = [];
                data.forEach(game => {
                    gamesList.push({
                        id: game.id,
                        name: game.name
                    })
                });

                setGames(gamesList);
            });
        }

    }));

    const formatDate = (date: string) => {
        let dateObject: Date = new Date(date);
        var day = dateObject.getDate();
        var month: number = dateObject.getMonth() + 1;
        var year = dateObject.getFullYear();

        var dateFormated = year + "-" + pad(month.toString(), 2, "0") + "-" + pad(day.toString(), 2, "0");
        return dateFormated;
    };

    const pad = (n: string, width: any, z: any) => {

        let difference = width - n.length;
        let padded = n;

        if (difference > 0) {

            for (let index = 0; index < difference; index++) {
                padded = "0" + padded;
            }
        }
        return padded;
    }

    const handleClose = () => {
        setOpen(false);
    };

    const displayText = (state: GameScheduleState | undefined, value: any | null) => {

        if (value === null) {
            return (<></>);
        } else if (value.id !== '') {
            switch (state) {
                case GameScheduleState.EMPTY:
                    return (<Typography style={{ color: 'red' }}>Atleast one category in this game should have a question.</Typography>);
                case GameScheduleState.IS_CHILD:
                    return (<></>);
                case GameScheduleState.CAN_SCHEDULE:
                    return (<></>);
                default:
                    return (<></>);
            }
        }
    }

    let getScheduleState = (id: string | null) => new Promise<GameScheduleState | undefined>(async (res, rej) => {

        if (id === null) {
            console.log("ID was null");
            setDisableSubmit(true);
            res(undefined);
            return;
        } else {
            let gameState = dataProvider.getGameScheduleState(searchState.company.id, searchState.department.id, id);

            gameState.then(state => {
                if (state === GameScheduleState.CAN_SCHEDULE || state === GameScheduleState.IS_CHILD) {
                    setDisableSubmit(false);
                } else {
                    setDisableSubmit(true);
                }
                res(state);
            });

            gameState.catch(err => {
                console.log("ERROR", err);
                res(undefined);
            });
        }
    });

    useEffect(() => {

        if (isIndividual) {
            setSettings({
                dialogSize: "lg",
                itemSize: 6,
                height: '600px'
            });
        } else {
            setSettings({
                dialogSize: "sm",
                itemSize: 12,
                height: '400px'
            });
        }

    }, [isIndividual]);

    return (

        <div>
            <Dialog

                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                //scroll="body"
                maxWidth={settings.dialogSize}
            >
                <DialogTitle id="form-dialog-title">Schedule Game</DialogTitle>
                <Formik
                    initialValues={{
                        startDate: startDate,
                        endDate: endDate,
                        game: { id: '', name: '' },
                        company: '',
                        department: '',
                        isIndividual: false
                    }}
                    onSubmit={(values, { setSubmitting }) => {

                        let scheduleType = values.isIndividual ? ScheduleType.individual : ScheduleType.department;


                        dataProvider.addSchedule(searchState.company.id, searchState.department.id, values.game.id, new Date(values.startDate), new Date(values.endDate), scheduleType, usersSelected).then(data => {
                            setSubmitting(false);
                            onSchedualAdd();
                            handleClose();
                        })
                    }}
                >
                    {({ submitForm, isSubmitting, values, setFieldValue, touched, errors }) => (
                        <Fragment>
                            <DialogContent style={{ height: settings.height }}>
                                <Grid container direction="row" spacing={3}>
                                    <Grid item container spacing={3} xs={settings?.itemSize}>
                                        <Grid item>
                                            {
                                                displayText(scheduleState, values.game)
                                            }

                                        </Grid>
                                        <Grid container item direction="column">
                                            <Grid item container justify="space-between" alignItems="center">
                                                <Grid item xs={5}>
                                                    <MuiTextField
                                                        type="date"
                                                        variant="filled"
                                                        fullWidth={true}
                                                        value={startDate}
                                                        defaultValue={startDate}
                                                        onChange={(event) => {
                                                            let startDate = event.target.value;
                                                            setFieldValue("startDate", startDate);
                                                            setStartDate(startDate);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h5">to</Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <MuiTextField
                                                        type="date"
                                                        variant="filled"
                                                        fullWidth={true}
                                                        value={endDate}
                                                        defaultValue={endDate}
                                                        onChange={(event) => {
                                                            let endDate = event.target.value;
                                                            setFieldValue("endDate", endDate);
                                                            setEndDate(endDate);
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/* <Autocomplete
                                                options={games}
                                                fullWidth={true}
                                                getOptionLabel={(option: any) => option.name}
                                                onChange={(e: any, value: any) => {
                                                    setFieldValue("game", value.id);
                                                    console.log("Game: ", value);
                                                    dataProvider.getGameScheduleState(searchState.company.id, searchState.department.id, value.id).then(state => { 
                                                        
                                                    })
                                                    // dataProvider.gameIsEmpty(searchState.company.id, searchState.department.id, value.id).then(isEmpty => {
                                                    //     if (isEmpty)
                                                    //         setIsEmptyText(true);
                                                    //     else
                                                    //         setIsEmptyText(false);
                                                    //     setIsEmpty(isEmpty);
                                                    // });
                                                }}
                                                renderInput={(params: any) => (
                                                    <Field component={TextField} {...params} name="game" label="Game" variant="filled" />
                                                )}
                                            /> */}

                                            <Field
                                                name="game"
                                                component={Autocomplete}
                                                options={games}
                                                getOptionLabel={(option: any) => option.name}
                                                value={values.game}
                                                onChange={async (e: any, data: any) => {
                                                    setFieldValue("game", data);
                                                    //console.log("GAME SELECTED: ", data);
                                                    if (data === null) {
                                                        console.log("Data was null", getScheduleState(null));
                                                        setScheduleState(await getScheduleState(null));
                                                    } else {
                                                        console.log("Data was not null", getScheduleState(data.id));
                                                        setScheduleState(await getScheduleState(data.id));
                                                    }
                                                }}
                                                renderInput={(params: AutocompleteRenderInputParams) => (
                                                    <MuiTextField
                                                        {...params}
                                                        error={touched['game'] && !!errors['game']}
                                                        helperText={
                                                            //@ts-ignore
                                                            touched['game'] && errors['game']?.title
                                                        }
                                                        label="Game"
                                                        variant="filled"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={<Checkbox onChange={(event) => {
                                                    setFieldValue("isIndividual", event.target.checked);
                                                    setIsIndividual(event.target.checked);
                                                }} />}
                                                label="Schedule individual"
                                            />
                                        </Grid>
                                    </Grid>
                                    {
                                        isIndividual ?
                                            <Grid container item xs={6}>
                                                <UserSelector
                                                    company={searchState.company.id}
                                                    department={searchState.department.id}
                                                    onUserSelect={(data: any[]) => {
                                                        setUsersSelected(data);
                                                    }} />
                                            </Grid>
                                            :
                                            <></>
                                    }
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Grid container justify="space-between" spacing={2}>
                                    <Grid item xs={12}>
                                        {
                                            isSubmitting ?
                                                <LinearProgress />
                                                :
                                                <></>
                                        }

                                    </Grid>
                                    <Grid item>
                                        <Button onClick={handleClose} color="primary" variant="outlined">
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={submitForm} color="secondary" variant="outlined" disabled={disableSubmit}>
                                            Schedule
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Fragment>

                    )}
                </Formik>
            </Dialog>
        </div >

    );
});

export default FormDialog;
