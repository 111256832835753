import React from 'react';
import MaterialTable from 'material-table';
import { 
    Grid, 
    AppBar, 
    Toolbar, 
    makeStyles, 
    Typography
} from '@material-ui/core';
import ExportDefault from './exports/ExportDefault';

const useStyles = makeStyles((theme) => ({
    bar: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    }
}));

export interface IUserAnswers {
    data: any[];
}

const UserAnswers: React.FC<IUserAnswers> = ({ data }) => {

    const classes = useStyles();

    const columns = [
        { title: "Company Name", field: "companyName", hidden: true },
        { title: "Department Name", field: "departmentName", hidden: true },
        { title: "Username", field: "userName" },
        { title: "Email", field: "email" },
        { title: "Game Name", field: "gameName" },
        { title: "Category Name", field: "categoryName" },
        { title: "Date Played", field: "datePlayed" },
        { title: "Question Text", field: "questionText" },
        { title: "Question Type", field: "questionType" },
        { title: "Correct Answer", field: "correctAnswer" },
        { title: "User Answer", field: "userAnswer" },
        { title: "Score", field: "score" }
    ];

    const tableOptions = {
        paging: true,
        search: false,
        showTitle: false,
        actionsColumnIndex: -1,
        pageSizeOptions: [10, 25, 50, 100, 500]
    }

    return (
        <Grid container direction="column">
            <Grid item>
                <AppBar position="static" className={classes.bar}>
                    <Toolbar>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="h6">User Answers</Typography>
                            </Grid>
                            <Grid item>
                                <ExportDefault data={data || []} columns={columns} />
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Grid>
            <Grid item>
                <MaterialTable style={{ width: '100%', overflow: 'auto', height: '400px' }}
                    columns={columns}
                    data={data || []}
                    options={tableOptions}
                />
            </Grid>
        </Grid>
    );
}

export default UserAnswers;