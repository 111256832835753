import * as React from 'react';
import { LinearProgress, Grid, Button } from '@material-ui/core';

export interface IFormFooter {
    onAction?: any;
    onBack?: any;
    submitting?: boolean;
    actionName?: string;
    backActionName?: string;
    disableAction?: boolean;
    disableBack?: boolean;
}

const FormFooter: React.FC<IFormFooter> = ({ onAction, onBack, submitting, actionName, disableAction, backActionName = "back", disableBack = false }) => {

    var isDisabled = disableAction ? disableAction : false;
    var disabled = !submitting && !isDisabled;
    var backDisabled = disableBack || submitting;

    return (
        <Grid item container direction="column" spacing={3}>
            <Grid item>
                {
                    submitting ?
                        <LinearProgress />
                        :
                        <div></div>
                }

            </Grid>
            <Grid item container justify="space-between">
                <Grid item>
                    <Button variant="contained" color="primary" disabled={backDisabled} onClick={() => { onBack() }}>{backActionName}</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" disabled={!disabled} onClick={() => { onAction() }}>{actionName ? actionName : "Add"}</Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default FormFooter;
