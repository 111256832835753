import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Panel from '../../../components/Panel';
import EditMatchTheTerms from './EditMatchTheTerms';
import { State } from './AddQuestions';
import EditMultiChoice from './EditMultipleChoice';
import EditWordBucket from './EditWordBucket';
import DataProvider from '../../../providers/DataProvider';
import EditImageMap from './EditImageMap';
import MultipleChoice from './AddMultipleChoice';

export interface BucketQuestion {
    id?: number;
    questionType: number;
    question: string;
    bucket1: string;
    bucket2: string;
    mediaLink: string;
    whereToFind: string;
    bucket1List: string[];
    bucket2List: string[];
    topic?: any;
    subTopic?: any;
}


export interface TermsObj {
    term1: string;
    term2: string;
}

export interface MatchTheTerms {
    tableData: { id: number };
    questionText: string,
    mediaLink: string,
    questionType: number,
    topic?: any,
    subTopic?: any,
    whereToFind: string,
    terms: TermsObj[];
}

let options = [
    { id: 0, letter: "A" },
    { id: 1, letter: "B" },
    { id: 2, letter: "C" },
    { id: 3, letter: "D" }
]

export interface IEditQuestionProps {
}

const EditQuestion: React.FC<IEditQuestionProps> = ({ }) => {

    const location = useLocation();
    let data: any = location.state;
    let question: any = data.question;
    let state: State = data.state;
    const [loading, setLoading] = useState(true);
    const [topic, setTopic] = useState({ id: '', name: '' });
    const [subTopic, setSubtopic] = useState({ id: '', title: '' });
    let dataprovider = new DataProvider();

    useEffect(() => {

        if (question.learningTopicId) {
            //@ts-ignore
            dataprovider.getLearningCenterTopics(state.company.id, state.department.id).then(data => {
                let learningTopic = data.find(item => item.id === question.learningTopicId);
                setTopic(learningTopic);
                if (learningTopic && question.learningSubTopicId) {
                    //@ts-ignore
                    dataprovider.getLearningCenterSubTopic(state.company.id, state.department.id, learningTopic.id, question.learningSubTopicId).then(item => {
                        setSubtopic(item);
                        setLoading(false);
                    })
                } else {
                    setLoading(false);
                }

            })
        } else {
            setLoading(false);
        }



    }, []);

    const renderEditForm = (id: number) => {

        //@ts-ignore
        // let topics = await dataprovider.getLearningCenterTopics(state.company.id, state.department.id);
        // let topic = topics.find(item => item.id === question.learningTopicId);

        switch (id) {
            case 0: {
                let multiChoice = question;

                let values = {
                    id: multiChoice.tableData.id,
                    imgUrl: multiChoice.imgUrl,
                    imgPath: multiChoice.imgPath,
                    question: multiChoice.questionText,
                    answer_a: multiChoice.answers[0],
                    answer_b: multiChoice.answers[1],
                    answer_c: multiChoice.answers[2],
                    answer_d: multiChoice.answers[3],
                    correctAnswer: options[multiChoice.correctAnswerIndex],
                    mediaUri: multiChoice.mediaLink,
                    questionType: multiChoice.questionType,
                    topic: topic,
                    subTopic: subTopic,
                    whereToFind: multiChoice.whereToFind
                }

                return <EditMultiChoice data={values} state={state} />
            }
            case 1: {
                let fillBlank = question;

                let values = {
                    id: fillBlank.tableData.id,
                    question: fillBlank.questionText,
                    answer_a: fillBlank.answers[0],
                    answer_b: fillBlank.answers[1],
                    answer_c: fillBlank.answers[2],
                    answer_d: fillBlank.answers[3],
                    correctAnswer: options[fillBlank.correctAnswerIndex],
                    mediaUri: fillBlank.mediaLink,
                    questionType: fillBlank.questionType,
                    topic: topic,
                    subTopic: subTopic,
                    whereToFind: fillBlank.whereToFind

                }
                return <EditMultiChoice data={values} state={state} />
            }
            case 2: {

                let matchTerms: any = { ...question };

                const terms1 = matchTerms.terms[0].items;
                const terms2 = matchTerms.terms[1].items;
                let termsMapped: TermsObj[] = [];

                if (terms1.length !== terms2.length) {
                    return null;
                }

                for (let i = 0; i < terms1.length; i++) {
                    const item1 = terms1[i];
                    const item2 = terms2[i];

                    let obj: TermsObj = {
                        term1: item1.name,
                        term2: item2.name
                    }

                    termsMapped.push(obj);
                }

                matchTerms.terms = termsMapped;
                matchTerms.topic = topic;
                matchTerms.subTopic = subTopic;

                return <EditMatchTheTerms data={matchTerms} state={state} />
            }
            case 3: {
                let items: any[] = question.items;
                let firstBucket: string[] = [];
                let secondBucket: string[] = [];

                for (let i = 0; i < items.length; i++) {
                    const element = items[i];

                    if (element.matchIndex === 0) {
                        firstBucket.push(element.name);
                    }

                    if (element.matchIndex === 1) {
                        secondBucket.push(element.name);
                    }
                }

                let wordBucket: BucketQuestion = {
                    id: question.tableData.id,
                    questionType: 3,
                    question: question.questionText,
                    bucket1: question.buckets[0].name,
                    bucket2: question.buckets[1].name,
                    mediaLink: question.mediaLink,
                    whereToFind: question.whereToFind,
                    bucket1List: firstBucket,
                    bucket2List: secondBucket,
                    topic: topic,
                    subTopic: subTopic

                };
                return <EditWordBucket data={wordBucket} state={state} />
            }
            case 4: {
                //Prepare values for question
                let values = {};
                return (<EditImageMap state={state} data={data}/>);
            }
            default:
                return <h1>Can't edit this question type</h1>
        }
    }

    return (
        <Panel>
            {
                loading ?
                    <h1>Loading</h1>
                    :
                    renderEditForm(question.questionType)
            }
        </Panel>
    );
}

export default EditQuestion;