import React, { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import ExportIcon from '@material-ui/icons/Assessment';
import { excelExportFunction, ISheet } from '../../../../components/ExcelExportButton';

export interface IExportAveragesProps {
    object: any;
}

const ExportAverages: React.FC<IExportAveragesProps> = ({ object }) => {

    const [exportSheets, setExportSheets] = useState<ISheet[]>([]);

    useEffect(() => {

        if (!object) return;

        let sheets = [];

        sheets.push({
            sheetName: "Averages",
            sheetColumns: [
                { title: "Avg Games", name: "avgGames" },
                { title: "Avg Points", name: "avgPoints" },
                { title: "Avg Wins", name: "avgWins" }
            ],
            sheetData: [{
                avgGames: object.avgGames,
                avgPoints: object.avgPoints,
                avgWins: object.avgWins
            }]
        });

        setExportSheets(sheets);

    }, [object]);

    return (
        <IconButton onClick={() => excelExportFunction({sheets: exportSheets})}>
            <Tooltip title="Export" aria-label="Export">
                <ExportIcon />
            </Tooltip>
        </IconButton>
    );
}

export default ExportAverages;