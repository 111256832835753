import React, { useState } from 'react';
import { Paper, Grid, makeStyles, Theme, Container, Typography } from '@material-ui/core';
import { Form, Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import GameOn from './redCard.png';
import FirebaseProvider from '../../providers/FirestoreProvider';
import LoadingButton from '../../components/LoaderButton';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        height: "100%",
        marginTop: "100px"
    },
    paper: {
        padding: theme.spacing(2),
        backgroundColor: "#f0f0f0"
    },
    errorText: {
        color: "#e84141"
    },
    errorContainer: {
        minHeight: "50px"
    }

}));

export default function LogIn() {

    const [errorText, setErrorText] = useState<string>();
    const classes = useStyles();

    const setErrorMessage = (errorCode: string) => {

        switch (errorCode) {
            case "auth/user-not-found":
                setErrorText("Email or password is wrong!");
                break;
            case "auth/wrong-password":
                setErrorText("Email or password is wrong!");
                break;
            default:
                console.log(errorCode);
                setErrorText("Something went wrong!");
                break;
        }
    }

    const initialValues = {
        email: '',
        password: ''
    }

    const onSubmit = async (values: any, setSubmitting: any) => {
        setErrorText("");
        setSubmitting(true);
        var addUser = FirebaseProvider.functions().httpsCallable('isManager');

        addUser({ email: values.email }).then(isManager => {
            let bool = isManager.data;
            if (bool) {
                FirebaseProvider.auth().signInWithEmailAndPassword(values.email, values.password).then(() => {

                }).catch(err => {
                    setErrorMessage(err.code);
                    setSubmitting(false);
                });
            } else {
                setErrorText("You do not have permision to access this site");
                setSubmitting(false);
            }
        }).catch(err => {
            setErrorText(err);
            setSubmitting(false);
        });
    }

    const validation = Yup.object().shape({
        email: Yup.string().email().required('Required'),
        password: Yup.string().required('Required')
    });

    return (
        <Container maxWidth="lg" component="main" className={classes.container}>
            <Grid container justify="center" className={classes.container}>
                <Grid item xs={4}>
                    <Paper className={classes.paper} elevation={3}>
                        <Formik initialValues={initialValues} onSubmit={(values, { setSubmitting }) => { onSubmit(values, setSubmitting) }} validationSchema={validation}>
                            {({ submitForm, isSubmitting, setFieldValue }) => (
                                <Form>
                                    <Grid container spacing={3}>
                                        <Grid item container xs={12} justify="center">
                                            <img src={GameOn} alt="" width="380" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={TextField}
                                                type="email"
                                                label="Email"
                                                name="email"
                                                variant="filled"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={TextField}
                                                type="password"
                                                label="Password"
                                                name="password"
                                                variant="filled"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid container item xs={12} justify="center">
                                            <div className={classes.errorContainer}>
                                                <Typography variant="subtitle1" className={classes.errorText}>{errorText}</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <LoadingButton text="LOGIN" loading={isSubmitting} onClick={submitForm} color="primary" variant="contained" />
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                </Grid>
            </Grid>
        </Container >

    );
}


//Force