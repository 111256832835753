import React, { useState, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import { TextField, CheckboxWithLabel } from 'formik-material-ui';
import { useHistory } from 'react-router-dom';
import DataProvider from '../../../providers/DataProvider';
import * as Yup from 'yup';
import FormFooter from '../../../components/FormFooter';
import { DropzoneArea } from 'material-ui-dropzone';
import EditbleMediaSelector from '../../../components/MediaSelectorEditable';
import { AuthContext } from '../../../providers/AuthProvider';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

const validation = Yup.object().shape({
    name: Yup.string().required('Required'),
    subtitle: Yup.string().required('Required')
});

export default function ManagerAddGame() {

    const { enqueueSnackbar } = useSnackbar();

    const { user } = useContext(AuthContext);
    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;

    const [selectedTopic, setSelectedTopic] = useState<any>();
    const [gameImage, setGameImage] = useState<any>();
    const [mediaError, setMediaError] = useState(false);

    const dataProvider = new DataProvider();
    let history = useHistory();
    const [media, setMedia] = useState<any[]>([]);

    return (
        <Formik
            initialValues={{
                name: '',
                subtitle: '',
                maximumCorrect: '',
                completetionThresholdPrecent: '',
                gameImageSelected: false,
                media: [],
                order: 0,
                passRate: '',
                mustWatchVideo: false,
                gameRetries: 0,
                categoryRetries: 0

            }}
            validationSchema={validation}
            onSubmit={async (values, { setSubmitting }) => {
                
                if (!values?.gameImageSelected) {
                    enqueueSnackbar("No game picture is uploaded", { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    setSubmitting(false);
                    return;
                }
                
                if (!(values?.media?.length > 0)) {
                    enqueueSnackbar("No media is provided", { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    setSubmitting(false);
                    return;
                }

                if (values?.mustWatchVideo && !values?.media?.some((m: any) => m.mediaType.trim().toLowerCase() == "video")) {
                    enqueueSnackbar("No video is uploaded", { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    setSubmitting(false);
                    return;
                }
                
                setSubmitting(true);

                let game: any = {
                    name: values.name,
                    subtitle: values.subtitle,
                    gameImgUrl: "",
                    gameImgPath: "",
                    maximumCorrect: 0,
                    passRate: values.passRate,
                    completetionThresholdPrecent: "70",
                    media: media,
                    isExam: selectedTopic ? true : false,
                    learningTopic: selectedTopic ? selectedTopic : null,
                    order: values.order,
                    mustWatchVideo: values.mustWatchVideo,
                    gameRetries: values.gameRetries,
                    categoryRetries: values.categoryRetries
                };

                try {

                    let img = await dataProvider.addImage(gameImage, "games_images/");

                    game.gameImgUrl = img.imgUrl;
                    game.gameImgPath = img.imgPath;

                    let gameRef: any = await dataProvider.addGame(user?.company, user?.department, game);

                    setSubmitting(false);
                    history.replace("/games/categories/add", {
                        gameId: gameRef?.id,
                        company: { id: user?.company },
                        department: { id: user?.department }
                    });

                } catch (error) {
                    console.log(error);
                }
            }}
        >
            {({ submitForm, isSubmitting, setFieldValue, errors }) => (
                <Form noValidate>

                    <Grid container spacing={3}>
                        <Grid container item spacing={3} direction="column" xs={4}>
                            <Grid item>
                                <Field
                                    required
                                    component={TextField}
                                    type="text"
                                    label="Name"
                                    name="name"
                                    variant="filled"
                                    fullWidth
                                />

                            </Grid>
                            <Grid item >
                                <Field
                                    required
                                    component={TextField}
                                    type="text"
                                    label="Subtitle"
                                    name="subtitle"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item >
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Display Order"
                                    name="order"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item >
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Game Retries per day"
                                    name="gameRetries"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item >
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Category Retries per day"
                                    name="categoryRetries"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item >
                                <Field
                                    component={CheckboxWithLabel}
                                    indeterminate={false}
                                    type="checkbox"
                                    name="mustWatchVideo"
                                    Label={{ label: 'Must watch video before playing' }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={8} container spacing={1} direction="column">
                            <Grid item>
                                <DropzoneArea 
                                    showAlerts={false} 
                                    filesLimit={1} 
                                    dropzoneText={"Upload an image"} 
                                    onChange={
                                        (files: any) => {
                                            setFieldValue("gameImageSelected", files[0] ? true : false);
                                            setGameImage(files[0]);
                                        }
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography color="error">
                                    {errors.gameImageSelected}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <EditbleMediaSelector
                                dataPath={`/Companies/${user?.company}/Departments/${user?.department}/Games`}
                                error={mediaError}
                                onMediaChange={(arr: any[]) => {
                                    setMediaError(arr.length > 0 ? false : true);
                                    setFieldValue("media", arr);
                                    setMedia(arr);
                                }} />
                        </Grid>

                        <Grid item xs={12}>
                            <FormFooter onAction={submitForm} onBack={() => { history.goBack() }} submitting={isSubmitting} />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik >

    );
}
