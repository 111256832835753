import React, { useEffect, useState } from 'react';
import Panel from '../../components/Panel';
import DataProvider from '../../providers/DataProvider';
import Department from '../../models/Department';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { Grid } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import FormFooter from '../../components/FormFooter';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

const validation = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(25, 'Too Long!')
        .required('Required'),
    description: Yup.string()
        .min(2, 'Too Short!')
        .max(1000, 'Too Long!')
});

export interface IEditDepartmentProps {
}

export default function EditDepartment(props: IEditDepartmentProps) {

    const dataProvider = new DataProvider();
    const { enqueueSnackbar } = useSnackbar();
    const [department, setDepartment] = useState<Department>();
    const [loading, setLoading] = useState(true);
    let location = useLocation();
    let params = useParams();
    let history = useHistory();

    //@ts-ignore
    let companyID = location.state.companyID;
    //@ts-ignore
    let departmentID = params.id;

    useEffect(() => {
        dataProvider.getDepartment(companyID, departmentID).then(data => {
            setDepartment(data);
            console.log("departments", data);
            setLoading(false);
        });
        //eslint-disable-next-line
    }, []);
    return (
        <Panel isLoading={loading}>
            <Formik
                validationSchema={validation}
                initialValues={{
                    name: department?.name,
                    description: department?.description
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);

                    var department: Department = {
                        id: departmentID,
                        //@ts-ignore
                        name: values.name,
                        //@ts-ignore
                        description: values.description
                    };

                    dataProvider.editDepartment(companyID, department).then(data => {
                        enqueueSnackbar(`${department.name} updated!`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                        setSubmitting(false);
                    }).catch(err => {
                        enqueueSnackbar(err, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                        setSubmitting(false);
                    });

                }}
            >
                {({ submitForm, isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={3} direction="column">
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Name"
                                    name="name"
                                    variant="filled"
                                    fullWidth
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Description"
                                    name="description"
                                    variant="filled"
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <FormFooter actionName="save" submitting={isSubmitting} onAction={submitForm} onBack={() => { history.goBack() }} />
                            </Grid>
                        </Grid>
                    </Form>

                )}
            </Formik >
        </Panel>
    );
}
