import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Page from './dashboard/Dashboard';
import { ThemeProvider } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { store } from './redux/store/Store';
import { AuthProvider } from './providers/AuthProvider';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
export const globalStore = store;


const theme = createTheme({
  // overrides: {
  //   MuiCssBaseline: {
  //     '@global': {
  //       body: {
  //         background: "linear-gradient(to right top, #cdcdcd, #bbbac4, #aaa7bb, #9895b3, #8683aa)"
  //       }
  //     }
  //   }
  // },
  palette: {
    type: 'light',
    primary: {
      main: "#2b57b5"
    }
  }
});

ReactDOM.render(

  <ThemeProvider theme={theme}>
    <SnackbarProvider maxSnack={3}>
      <Provider store={globalStore}>
        <AuthProvider>
          <Router>
            <Switch>
              <Route path="/"  >
                <Page />
              </Route>
            </Switch>
          </Router>
        </AuthProvider>
      </Provider>
    </SnackbarProvider>
  </ThemeProvider >

  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
