import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, LinearProgress, Divider, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import { Autocomplete } from '@material-ui/lab';
import { DropzoneArea } from 'material-ui-dropzone';
import DataProvider from '../providers/DataProvider';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AzureProvider from '../providers/AzureProvider';
import CachedIcon from '@material-ui/icons/Cached';
import EditTextMediaSelector from '../components/EditTextMediaSelector';
import EditImageMediaSelector from '../components/EditImageMediaSelector';
import ImageUpload from '../components/ImageUpload';
import { useSnackbar } from 'notistack';

export interface IMediaSelectorProps {
    dataPath: string;
    onMediaChange: any;
    initialState?: any[];
    error?: boolean;
    heading?: string;
}

const MediaSelector: React.FC<IMediaSelectorProps> = ({ dataPath, onMediaChange, initialState, error = false, heading = "" }) => {

    const { enqueueSnackbar } = useSnackbar();
    
    //edit states
    const [editData, setEditData] = useState<any>();
    const [editState, setEditState] = useState(false);
    const [editMediaType, setEditMediaType] = useState<string>('');
    //edit states


    const [type, setType] = useState<any>({ id: 0, type: "" });
    const [media, setMedia] = useState<any[]>([]);
    const [videoLink, setVideoLink] = useState<string>('');
    const [video, setVideo] = useState<any>();
    const [videoName, setVideoName] = useState<string>('');
    const [image, setImage] = useState<any>();
    const [text, setText] = useState<string>('');
    const [pdf, setPdf] = useState<any>();
    const [pdfSelected, setPdfSelected] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [imageSelected, setImageSelected] = useState<boolean>(false);
    const [videoSelected, setVideoSelected] = useState<boolean>(false);
    const dataProvider = new DataProvider();
    const azureProvider = new AzureProvider();

    const options: any[] = [
        { id: 1, type: "Video" },
        { id: 2, type: "Image" },
        { id: 3, type: "Text" },
        { id: 4, type: "PDF" }
    ]

    const addMedia = async () => {

        if (!name || name.trim().length < 2) {
            setNameError(true);
            enqueueSnackbar("Media name is invalid or empty", { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
            return;
        }
        
        var failed: boolean = false;
        setLoading(true);

        switch (type.id) {
            case 1:
                try {
                    if (videoLink.length > 5 && name.length > 2) {
                        addStateArr({ mediaType: type.type, media: videoLink, name: name });
                    } else if (videoSelected && name.length > 2) {
                        let videoLink = await azureProvider.addVideo(video, videoName);
                        addStateArr({
                            mediaType: type.type,
                            media: videoLink.videoUrl,
                            name: name,
                            videoPath: videoLink.videoPath,
                            processingCompleted: false,
                            processingStarted: false
                        });
                        await dataProvider.processVideo({
                            mediaType: type.type,
                            media: videoLink.videoUrl,
                            name: name,
                            videoPath: videoLink.videoPath,
                            processingCompleted: false,
                            processingStarted: false,
                            dataPath: dataPath
                        });
                        setVideoSelected(false);
                    } else {
                        enqueueSnackbar("Video is not provided", { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                        failed = true;
                    }
                } catch (error) {
                    failed = true;
                    enqueueSnackbar(`Failed to upload video: ${error}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                }
                break;
            case 2:
                try {
                    if (imageSelected && name.length > 2) {
                        let imageLink = await dataProvider.addImage(image, "learningCenter_files/");
                        addStateArr({ mediaType: type.type, media: imageLink.imgUrl, name: name, imagePath: imageLink.imgPath });
                        setImageSelected(false);
                    } else {
                        enqueueSnackbar("Image is not provided", { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                        failed = true;
                    }
                } catch (error) {
                    enqueueSnackbar(`Failed to upload image: ${error}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    failed = true;
                }
                break;
            case 3:
                try {
                    if (text.length > 2 && name.length > 2) {
                        addStateArr({ mediaType: type.type, media: text, name: name });
                    } else {
                        failed = true;
                        enqueueSnackbar("Text is invalid or empty", { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    }
                } catch (error) {
                    failed = true;
                    enqueueSnackbar(`Failed to add text: ${error}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                }
                break;
            case 4:
                try {
                    if (pdfSelected && name.length > 2) {
                        let pdfLink = await dataProvider.addPdf(pdf, "learningCenter_files/");
                        addStateArr({ mediaType: type.type, media: pdfLink.fileUrl, name: name, pdfPath: pdfLink.filePath });
                        setPdfSelected(false);
                    } else {
                        failed = true;
                        enqueueSnackbar("File is not provided", { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    }
                } catch (error) {
                    failed = true;
                    enqueueSnackbar(`Failed to upload file: ${error}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                }
                break;
            default:
                failed = true;
                enqueueSnackbar("Media type is invalid or not selected", { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                break;
        }

        if (!failed) {
            setText("");
            setName("");
            setType({ id: 0, type: "" });
            setImage('');
        }

        setLoading(false);
    }

    const addStateArr = (item: any) => {

        let id = media.length;

        item = { ...item, id };

        setMedia([...media, item]);
    }

    const removeStateArr = (id: any) => {
        let placehodler: any[] = media;

        placehodler = placehodler.filter((value, index, array) => { return value.id !== id });

        setMedia(placehodler);
    }

    const moveDown = (id: any) => {
        //@ts-ignore
        let arr: any[] = [].concat(media);

        if (id + 1 < arr.length) {
            for (let i = 0; i < arr.length; i++) {
                const itemId = arr[i].id;

                if (itemId === id) {
                    arr[i].id = id + 1;
                }

                if (itemId === (id + 1)) {
                    arr[i].id = itemId - 1;
                }

            }
        }

        var sorted: any[] = arr.sort((a, b) => a.id - b.id);
        setMedia(sorted);

    }

    const moveUp = (id: any) => {
        //@ts-ignore
        let arr: any[] = [].concat(media);

        if (id > 0) {
            for (let i = 0; i < arr.length; i++) {
                const itemId = arr[i].id;

                if (itemId === id) {
                    arr[i].id = id - 1;
                }

                if (itemId === (id - 1)) {
                    arr[i].id = itemId + 1;
                }

            }
        }

        var sorted: any[] = arr.sort((a, b) => a.id - b.id);
        setMedia(sorted);

    }

    const editableItems = (item: string) => {
        switch (item) {
            case "Text":
                return false;
            case "Image":
                return false;
            default:
                return true;
        }
    }

    const updateMediaArr = (id: any, text: any) => {

        let newArr = [...media];
        let updateItem = newArr[id];
        updateItem.media = text;
        newArr[id] = updateItem;
        setMedia(newArr);
    }

    const renderEditInput = (mediaType: string, data: any) => {

        switch (mediaType) {
            case "Text":
                return (
                    <EditTextMediaSelector
                        initialText={data.media}
                        onSave={(editedText: string) => {
                            updateMediaArr(data.id, editedText);
                            setEditState(false);
                        }}
                        onCancel={() => {
                            setEditState(false);
                        }} />
                )
            case "Image":
                return (
                    <EditImageMediaSelector
                        initialImage={data.media}
                        onSave={(image: any) => {
                            updateMediaArr(data.id, image);
                            setEditState(false);
                        }}
                        onCancel={() => {
                            setEditState(false);
                        }} 
                    />

                )
            default:
                return (
                    <Typography>This item can not be edited</Typography>
                )
        }
    }

    useEffect(() => {
        onMediaChange(media);
        //eslint-disable-next-line
    }, [media]);

    useEffect(() => {
        setMedia(initialState ? initialState : []);
    }, [initialState])

    

    const renderInput = (id: any) => {

        switch (id) {
            case 1:
                return (
                    <>
                        <TextField label="Video url" name="videoUrl" variant="filled" fullWidth onChange={(event) => { setVideoLink(event.target.value) }} />
                        <strong>OR</strong>
                        <DropzoneArea
                            showAlerts={false}
                            filesLimit={1}
                            acceptedFiles={["video/*"]}
                            maxFileSize={1024 * 1024 * 1024 * 3} // 3GB
                            dropzoneText="Upload a video"
                            onChange={
                                (files: any) => {
                                    setVideoSelected(files[0] ? true : false);
                                    setVideoName(files[0] ? files[0].name : '');
                                    setVideo(files[0]);
                                }
                            } />
                    </>
                )
            case 2:
                return (
                    <DropzoneArea
                        showAlerts={false}
                        filesLimit={1}
                        dropzoneText="Upload a image"
                        onChange={
                            (files: any) => {
                                setImageSelected(files[0] ? true : false);
                                setImage(files[0]);
                            }
                        } />
                )
            case 3:
                return (
                    <TextField multiline rows={18} variant="filled" fullWidth style={{ height: "100%" }} onChange={(event) => { setText(event.target.value) }} />
                )
            case 4:
                return (
                    <DropzoneArea
                        maxFileSize = {50000000}
                        acceptedFiles={[".pdf","application/pdf"]}
                        showAlerts={false}
                        filesLimit={1}
                        dropzoneText="Upload a pdf"
                        onChange={
                            (files: any) => {
                                setPdfSelected(files[0] ? true : false);
                                setPdf(files[0]);
                            }
                        } />
                )
            default:
                return (<Typography variant="h6" style={{ color: error ? 'red' : 'black' }}>{error ? "Pick at least one media type" : "Pick a media type"}</Typography>)
        }

    }

    const renderMediaType = (rowData: any) => {
        if (rowData.processingCompleted === false) {
            return (
                <>
                    {rowData.mediaType}
                    <CachedIcon />
                </>
            )
        } else {
            return (
                <>
                    {rowData.mediaType}
                </>
            )
        }
    }

    return (
        <Grid container spacing={3} style={{ backgroundColor: '#dedede', borderRadius: '10px' }}>
            <Grid item xs={12}>
                <Typography variant="h5">{heading}</Typography>
            </Grid>
            <Grid item container xs={6}>
                <Grid item container direction="column" spacing={3}>
                    <Grid item container spacing={2}>
                        <Grid item xs={6}>
                            <Autocomplete
                                disabled={isLoading}
                                options={options}
                                getOptionSelected={() => true}
                                getOptionLabel={option => option.type}
                                value={type}
                                onChange={(e, value) => {
                                    if (value) {
                                        setType(value);
                                    } else {
                                        setType({ id: "", name: "" })
                                    }

                                }}
                                renderInput={params => (
                                    <TextField {...params} name="type" label="Media Type" variant="filled" />
                                )}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                error={nameError}
                                disabled={isLoading}
                                label="Name"
                                variant="filled"
                                fullWidth
                                value={name}
                                onChange={(event) => {
                                    if (nameError && event.target.value.length > 2) {
                                        setNameError(false);
                                    }

                                    if (!nameError && event.target.value.length < 2) {
                                        setNameError(true);
                                    }

                                    setName(event.target.value);
                                }}
                            />
                        </Grid>


                    </Grid>
                    <Grid item>
                        <MaterialTable style={{ width: '100%', overflow: 'auto', height: "100%", minHeight: "300px" }}
                            columns={[
                                { title: "Priority", field: "id" },
                                { title: "Name", field: "name" },
                                { title: "Media type", field: "mediaType", render: rowData => renderMediaType(rowData) }
                            ]}
                            data={media}
                            options={{
                                search: false,
                                showTitle: false,
                                paging: false,
                                toolbar: false,
                                actionsColumnIndex: -1
                            }}
                            actions={[
                                {
                                    icon: () => <KeyboardArrowDownIcon />,
                                    tooltip: 'Move Down',
                                    onClick: (event, rowData) => {
                                        moveDown(rowData.id);
                                    },
                                    disabled: editState

                                },
                                {
                                    icon: () => <KeyboardArrowUpIcon />,
                                    tooltip: 'Move Up',
                                    onClick: (event, rowData) => {
                                        moveUp(rowData.id);
                                    },
                                    disabled: editState
                                },
                                {
                                    icon: 'delete',
                                    tooltip: 'Delete',
                                    onClick: (event, rowData) => {
                                        removeStateArr(rowData.id);
                                    },
                                    disabled: editState
                                },
                                rowData => ({
                                    icon: 'edit',
                                    onClick: (event, rowData) => {
                                        //@ts-ignore
                                        setEditData(rowData);
                                        //@ts-ignore
                                        setEditMediaType(rowData.mediaType);
                                        setEditState(true);
                                    },
                                    //@ts-ignore
                                    disabled: editableItems(rowData.mediaType)
                                })

                            ]}

                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container justify="center" xs={1}>
                <Grid item>
                    <Divider orientation="vertical" variant="fullWidth" />
                </Grid>
            </Grid>
            <Grid item container xs={5} justify="center" alignItems="center">
                {
                    editState ?
                        renderEditInput(editMediaType, editData)
                        :
                        renderInput(type.id)
                }
            </Grid>


            <Grid item xs={12}>
                {
                    isLoading ?
                        <LinearProgress />
                        :
                        <></>
                }

            </Grid>
            <Grid item container xs={12} justify="flex-end">
                <Grid item>
                    <Button disabled={isLoading} variant="contained" color="primary" onClick={() => { addMedia() }}>
                        Add media
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default MediaSelector;
