import React, { useState, useContext, Fragment } from 'react';
import Panel from '../../../components/Panel';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import FormFooter from '../../../components/FormFooter';
import EditbleMediaSelector from '../../../components/MediaSelectorEditable';
import { useHistory } from 'react-router-dom';
import DataProvider from '../../../providers/DataProvider';
import { AuthContext } from '../../../providers/AuthProvider';


const validation = Yup.object().shape({
    title: Yup.string().required('required'),
    media: Yup.array().min(1)
});

export interface IAddCommunicationProps {
}

export default function AddCommunication(props: IAddCommunicationProps) {

    const { user, currentUser } = useContext(AuthContext);

    const dataprovider = new DataProvider();
    const [mediaError, setMediaError] = useState(false);
    const [media, setMedia] = useState<any[]>([]);
    let history = useHistory();

    return (
        <Fragment>
            <Formik
                validationSchema={validation}
                initialValues={{
                    title: '',
                    media: ''
                }}
                onSubmit={(values, { setSubmitting }) => {

                    let communication = {
                        title: values.title,
                        media: values.media,
                        sendDate: new Date(),
                        initialSendDate: new Date(),
                        company: user?.company,
                        department: user?.department,
                        sender: {
                            uid: currentUser?.uid,
                            email: user?.email
                        }
                    }

                    dataprovider.addCommunication(communication).then(() => {
                        history.goBack();
                    }).catch(err => {

                    })
                    setSubmitting(false);
                }}
            >
                {({ submitForm, isSubmitting, errors, touched, setFieldValue }) => (
                    <Form>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <Field
                                    variant="filled"
                                    component={TextField}
                                    name="title"
                                    type="text"
                                    label="Title"
                                />
                            </Grid>
                            <Grid item>
                                <EditbleMediaSelector
                                    dataPath = {`/Communication`}
                                    error={mediaError}
                                    heading="Message content"
                                    onMediaChange={(arr: any[]) => {
                                        setMediaError(arr.length > 0 ? false : true);
                                        setFieldValue("media", arr);
                                        setMedia(arr);
                                    }} />
                            </Grid>
                            <Grid item>
                                <FormFooter onBack={() => { history.goBack() }} actionName="Send" onAction={() => { submitForm() }} submitting={isSubmitting} />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Fragment>
    );
}
