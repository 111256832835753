import { Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import FormFooter from "../../components/FormFooter";
import Panel from "../../components/Panel";
import DataProvider from "../../providers/DataProvider";
import FirebaseProvider from '../../providers/FirestoreProvider';
import Skeleton from "@material-ui/lab/Skeleton";
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: "#e6e6e6",
		minHeight: 400,
		borderRadius: 15,
	},
	failedText: {
		color: "#757575",
	},
	bar: {
		borderTopLeftRadius: 20,
		borderTopRightRadius: 20,
	},
}));

export interface State {
	company: {
		id: string;
		name: string;
	};
	department: {
		id: string;
		name: string;
	};
}

export enum DisplayState {
	LOADING = 1,
    SHARING,
	NOT_SELECTED,
	DISPLAY,
	IS_CHILD,
	IN_PROGRESS,
}

export enum TopicState {
	IS_HOST = 1, //Topic is shared to other departments
	IS_CHILD, //Topic was shared from antoher department
	IS_NONE, //Topic is not a host or a child
}

export interface IShareTopicProps {}

const ShareTopic: React.FC<IShareTopicProps> = ({}) => {

	const dataprovider = new DataProvider();
	const location = useLocation();
	const state = location.state as State;
	const [topics, setTopics] = useState<any[]>([]);
	const [selectedTopic, setSelectedTopic] = useState<any | null>(null);
	const [departments, setDepartments] = useState<any[]>([]);
	const [checkedDepartments, setCheckedDepartments] = useState<any[]>([]);
	const [displayState, setDisplayState] = useState<DisplayState>(DisplayState.NOT_SELECTED);
	const [submitting, setSubmitting] = useState(false);
	const history = useHistory();
	const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

	const renderTable = (loading: DisplayState) => {
		switch (loading) {
			case DisplayState.NOT_SELECTED:
				return (
					<Grid container className={classes.container} spacing={0} direction="column" alignItems="center" justify="center">
						<Grid item xs={12}>
							<Typography variant="h5">Select a Topic</Typography>
						</Grid>
					</Grid>
				);
			case DisplayState.LOADING:
				return <Skeleton variant="rect" animation="wave" height={400} style={{ width: "100%" }} />;
            case DisplayState.SHARING:
                return (
                    <Grid
                        container
                        className={classes.container}
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={12}>
                            <Typography variant="h5">Updating the shared topic, please wait</Typography>
                        </Grid>
                    </Grid>
                )
			case DisplayState.DISPLAY:
				return (
					<MaterialTable
						options={{
							selection: true,
						}}
						columns={[{ title: "Name", field: "name" }]}
						data={departments}
						title="Pick department(s)"
						onSelectionChange={(rows) => {
							setCheckedDepartments(rows);
						}}
					/>
				);
			case DisplayState.IS_CHILD:
				return (
					<Grid container className={classes.container} spacing={0} direction="column" alignItems="center" justify="center">
						<Grid item xs={8}>
							<Typography variant="h5" align="center">
								This is a child Topic of a shared Topic. You can not share this Topic to other departments
							</Typography>
						</Grid>
					</Grid>
				);
			case DisplayState.IN_PROGRESS:
				return (
					<Grid container className={classes.container} spacing={0} direction="column" alignItems="center" justify="center">
						<Grid item xs={8}>
							<Typography variant="h5" align="center">
								An operation is currently being perform on this Topic, please come back in a few minutes
							</Typography>
						</Grid>
					</Grid>
				);
			default:
				break;
		}
	};

	const handleTopicSelect = (topic: any) => {
		setSelectedTopic(topic);
		if (topic === null) {
			setDisplayState(DisplayState.NOT_SELECTED);
			return;
		}

		setDisplayState(DisplayState.LOADING);
		let topicState: TopicState = TopicState.IS_NONE;

		try {
			if (topic.shared.loading) {
				setDisplayState(DisplayState.IN_PROGRESS);
				return;
			}

			if (topic.shared.isHost) {
				topicState = TopicState.IS_HOST;
			}

			if (topic.shared.isChild) {
				setDisplayState(DisplayState.IS_CHILD);
				topicState = TopicState.IS_CHILD;
			}
		} catch (error) {
			//If Topic.shared.host is not found.
			topicState = TopicState.IS_NONE;
		}

		//Topic is not a shared Topic
		if (topicState === TopicState.IS_NONE) {
			dataprovider
				.getDepartmentsFD(state.company.id)
				.then((res) => {
					setDepartments(res.filter((x) => x.id !== state.department.id));
					setDisplayState(DisplayState.DISPLAY);
				})
				.catch((err) => {
					enqueueSnackbar("Failed to load the departments: " + err, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
				});
		} else if (topicState === TopicState.IS_HOST) {
			//Topic is shared and should populate table
			dataprovider
				.getDepartmentsFD(state.company.id)
				.then((res) => {
					let response = [...res.filter((x) => x.id !== state.department.id)];
					var checkedDepartments = [];

					for (let i = 0; i < response.length; i++) {
						const base = response[i];
						for (let j = 0; j < topic.shared.departments.length; j++) {
							const checked = topic.shared.departments[j];
							if (checked === base.id) {
								response[i].tableData = {
									...response[i].tableData,
									checked: true,
								};
								checkedDepartments.push(base)
							}
						}
					}

					setCheckedDepartments(checkedDepartments);
					setDepartments([...response]);
					setDisplayState(DisplayState.DISPLAY);
				})
				.catch((err) => {
					enqueueSnackbar("Failed to load the departments: " + err, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
				});
		}
	};

	const handleSubmit = () => {

        setSubmitting(true);
        setDisplayState(DisplayState.SHARING);
        
        const shareTrainingMaterialTopic = FirebaseProvider.functions().httpsCallable('shareTrainingMaterialTopic');
		const params = { 
			hostTopicId: selectedTopic.id,
			hostCompanyId: state.company.id, 
			hostDepartmentId: state.department.id, 
			childDepartmentIDs: checkedDepartments.map(d => d.id)
		};
		
        shareTrainingMaterialTopic(params)
			.then((response) => {

				setDepartments([]);

				if (response?.data?.isSuccessful === true) {

					enqueueSnackbar("Completed the update successfully. Reloading the topic details.", { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });

				} else {
					let errors: any[] = [ ...response?.data?.errors ] || [ "No further details available" ];
					let errorMessage = errors.join("; ");

					enqueueSnackbar("Completed the update, but with some errors: " + errorMessage, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
				}
				
				dataprovider
					.getLearningCenterTopics(state.company.id, state.department.id)
					.then(topics => {

						setTopics(topics);
						setSelectedTopic(null);
						setSubmitting(false);
			
					})
					.catch((err) => {
						enqueueSnackbar("Failed to load the topics: " + err, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
					})
					.finally(() => {
						setDisplayState(DisplayState.NOT_SELECTED);
					});
		

			}).catch(err => {

				let errorMsg = `shareTrainingMaterialTopic failed for ID ${selectedTopic.id} at ${new Date().toISOString()}. Exception: ${err || "No further details"}`;

				setSubmitting(false);
				enqueueSnackbar("Failed to share the topic. Please keep a copy of this error message for the HyperBoliq Support Team: " + errorMsg, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });

			});

	};

	useEffect(() => {
		dataprovider
			.getDepartmentsFD(state.company.id)
			.then((res) => {
				setDepartments(res.filter((x) => x.id !== state.department.id));
			})
			.catch((err) => {
				enqueueSnackbar("Failed to load the departments: " + err, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
			});

		dataprovider
			.getLearningCenterTopics(state.company.id, state.department.id)
			.then((data) => {
				setTopics(data);
			})
			.catch((err) => {
				enqueueSnackbar("Failed to load the topics: " + err, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
			});

	}, []);

	return (
		<Panel>
			<Grid container direction="row">
				<Grid item xs={4}>
					<Autocomplete
						id="autocomplete-Topics"
						value={selectedTopic}
						options={topics}
						getOptionLabel={(option) => option.name}
						style={{ width: 300 }}
						onChange={(event, data) => {
							handleTopicSelect(data);
						}}
						renderInput={(params) => <TextField {...params} label="Topic" variant="outlined" />}
					/>
				</Grid>
				<Grid item xs={8}>
					{renderTable(displayState)}
				</Grid>
				<Grid item xs={12}>
					<FormFooter
						actionName="save"
						backActionName="cancel"
						submitting={submitting}
						onAction={() => {
							handleSubmit();
						}}
						onBack={() => {
							history.goBack();
						}}
					/>
				</Grid>
			</Grid>
		</Panel>
	);
};

export default ShareTopic;
