import React, { useState, Fragment } from 'react';
import { Button, Chip, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import DataProvider from '../../providers/DataProvider';
import { useDispatch, useSelector } from 'react-redux';
import AllActions from '../../redux/actions/Index';
import MainLayout from '../../components/MainLayout';
import Filter from '../../components/Filter';
import Dialog from './components/ConfirmDelete';
import { useSnackbar } from 'notistack';

export interface IAchievementList {
}

const AchievementList: React.FC<IAchievementList> = () => {

    //@ts-ignore
    const searchState = useSelector(state => state.searchState)?.searchState;
    const dispatch = useDispatch();
    const dataProvider = new DataProvider();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [achievements, setAchievements] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [achievementId, setAchievementId] = useState("0");
    const [dialogText, setDialogText] = useState("This action can not be reverted! Are you sure you want to procceed?");
    const [open, setOpen] = useState(false);

    function handleDialogClose(success: boolean, error: any = null) {

        setOpen(false);

        if (success) {
            enqueueSnackbar("Achievement deleted successfully", { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        } else {
            enqueueSnackbar(`Failed to delete achievement${error != null ? `. Error: ${error}` : "" }`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        }

        dataProvider.getAchievementsFD(searchState.company.id, searchState.department.id)
        .then(array => {
            setAchievements(array);
        })
        .catch(error => {
            enqueueSnackbar("Failed to load remaining achievements: " + error, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        });

    }

    function isEditDisabled(rowData: any) {
        return rowData?.shared?.isChild === true;
    }

    return (
        <MainLayout
            actions={[
                <Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="primary"
                    disabled={searchState.company.id === ""}
                    onClick={() => {
                        history.push('/achievements/share', { company: searchState.company, department: searchState.department })
                    }}>
                    Share achievement
                </Button>,
                <Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        history.push('/achievements/add')
                    }}>
                    add achievement
                </Button>
            ]}
            filters={[
                <Filter
                    loading={loading}
                    onSubmit={(company: any, department: any) => {
                        setLoading(true);
                        dataProvider.getAchievementsFD(company.id, department.id).then(array => {
                            dispatch(AllActions.searchState.setSearchState({ company: company, department: department }));
                            setAchievements(array);
                            setLoading(false);
                        });
                    }} />
            ]}
            content={
                <Fragment>
                    <MaterialTable 
                        title="Achievements"
                        data={achievements}
                        style={{ width: '100%' }}
                        options={{ actionsColumnIndex: -1 }}
                        columns={[
                            {
                                title: "Name",
                                field: "name",
                                render: rowData => (
                                    <Grid container spacing={1} direction="row" alignItems="center">
                                        <Grid item>
                                            <Typography>{rowData.name}</Typography>
                                        </Grid>
                                        <Grid item>
                                            {
                                                rowData.shared && (
                                                    rowData.shared.isHost && <Chip label="Shared Achievement" color="secondary" /> ||
                                                    rowData.shared.isChild && <Chip label="Child Achievement" style={{ backgroundColor: '#b3e6ff' }} />
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            },
                            { title: "Sub Title", field: "description" },
                            { title: "Monetary", field: "isMonetary" }
                        ]}
                        actions={[
                            {
                                icon: 'delete',
                                onClick: (event, rowData) => {

                                    setAchievementId(rowData.id);

                                    if (rowData.shared && rowData.shared.isHost === true) {
                                        setDialogText("Deleting this achievement will delete all child achievements. This can not be reverted! Are you sure you want to procceed?");
                                    }

                                    setOpen(true);

                                }
                            },
                            rowData => ({
                                icon: 'edit',
                                disabled: isEditDisabled(rowData),
                                onClick: (event, rowData) => {
                                    history.push("achievements/edit/" + rowData.id, { company: rowData.company, department: rowData.department });
                                }
                            })
                        ]}
                    />
                    <Dialog title="Are you sure?" contentText={dialogText} isOpen={open} handleClose={handleDialogClose} id={achievementId} />
                </Fragment>
            }
        />

    );
}

export default AchievementList;
