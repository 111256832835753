import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import MaterialTable from 'material-table';
import DataProvider from '../../../providers/DataProvider';
import { Autocomplete } from '@material-ui/lab';

export interface IUserSelectorProps {
    company: string;
    department: string;
    onUserSelect?: any;
}

const UserSelector: React.FC<IUserSelectorProps> = ({ company, department, onUserSelect }) => {

    const [users, setUsers] = useState<any[]>([]);
    const [usersSelected, setUsersSelected] = useState<any[]>([]);
    const [selectedUser, setSelectedUser] = useState<any>();
    const dataProvider = new DataProvider();

    const deleteUser = (userID: string) => {

        let placehodler: any[] = usersSelected;

        placehodler = placehodler.filter((value, index, array) => { return value.id !== userID });

        setUsersSelected(placehodler);

    }

    const addUser = (user: any) => {
        var alreadyAdded = false;
        //Check if user already added
        usersSelected.forEach((item: any) => {
            if (item.id === user.id) {
                alreadyAdded = true;
            }
        });

        if (!alreadyAdded) {
            //@ts-ignore
            let userArr: any[] = [].concat(usersSelected);
            userArr.push(user);
            setUsersSelected(userArr);
            setSelectedUser({ firstName: "" });
        }
    }

    useEffect(() => {
        dataProvider.getUsers(company, department).then(data => {
            setUsers(data);
            console.log(data);
        });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        console.log("Selected users: ", usersSelected);
        onUserSelect(usersSelected);
        //eslint-disable-next-line
    }, [usersSelected])

    return (
        <Grid container spacing={3}>
            <Grid item container direction="row" justify="center" alignItems="center" xs={12} spacing={2}>
                <Grid item xs={10}>
                    <Autocomplete
                        value={selectedUser}
                        options={users}
                        getOptionLabel={(item) => item.firstName + " " + item.lastName + ` (${item.idNumber})`}
                        onChange={(event, item) => {
                            setSelectedUser(item);
                        }}
                        getOptionSelected={(option, value) => true}
                        renderInput={(params) => <TextField {...params} label="Select Employee" variant="filled" />}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            addUser(selectedUser);
                        }}
                    >ADD</Button>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ height: '100%' }}>
                <MaterialTable
                    columns={[
                        { title: "Email", field: "email" },
                        { title: "Name", field: "firstName" },
                        { title: "Surname", field: "lastName" }
                    ]}
                    data={usersSelected}
                    options={{
                        search: false,
                        showTitle: false,
                        toolbar: false,
                        actionsColumnIndex: -1
                    }}
                    actions={[
                        {
                            icon: 'delete',
                            tooltip: 'Delete',
                            onClick: (event, rowData) => { deleteUser(rowData.id) }
                        }
                    ]}

                />
            </Grid>
        </Grid >
    );
}

export default UserSelector;
