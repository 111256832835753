import React, { useState, Fragment } from 'react';
import { ListItem, List, ListItemText, ListItemSecondaryAction, IconButton, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { Selectable } from '../AddImageMap';

export interface IGroupListProps {
    groups: Selectable[];
    onSelect: any;
    addGroup: any;
    removeGroup: any;
}

const GroupList: React.FC<IGroupListProps> = ({ groups, onSelect, addGroup, removeGroup }) => {

    const [selected, setSelected] = useState<string>(groups[0].id);

    const handleSelect = (id: any) => {
        console.log("Was selected", selected);
        console.log("New selected", id);
        setSelected(id);
        onSelect(id);
    }
    return (
        <Fragment>
            <Button variant="contained" color="primary" fullWidth endIcon={<AddIcon />} onClick={() => addGroup()}>Add grouping</Button>
            <List component="nav" aria-label="main mailbox folders">
                {
                    groups.map((group, index) => {
                        let arrLength = groups.length;
                        let isSelected = group.id === selected;
                        return (
                            <ListItem button key={group.id} onClick={() => handleSelect(group.id)} selected={isSelected}>
                                <ListItemText primary={"Group " + (index + 1)} />
                                <ListItemSecondaryAction>
                                    <IconButton disabled = {arrLength < 2? true: false} edge="end" aria-label="delete" onClick={() => removeGroup(index, isSelected)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )
                    })
                }
            </List>
        </Fragment>

    );
}

export default GroupList;
