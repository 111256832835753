import React, { useState, useEffect } from 'react';
import { Grid, TextField as MUITextField, Button, List, ListItem, ListItemText, Typography, ListItemSecondaryAction, IconButton, makeStyles, ValueLabelProps } from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";
import FormFooter from '../../../components/FormFooter';
import { Form, Formik, Field } from 'formik';
import { AutocompleteRenderInputParams, Autocomplete } from '@material-ui/lab';
import { TextField } from 'formik-material-ui';
import DataProvider from '../../../providers/DataProvider';
import * as Yup from 'yup';
import { State } from './AddQuestions';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';

const validation = Yup.object().shape({
    question: Yup.string().required('Required'),
    bucket1: Yup.string().required('Required'),
    bucket2: Yup.string().required('Required'),
    bucket1List: Yup.array().min(1, "Should have atleast one term"),
    bucket2List: Yup.array().min(1, "Should have atleast one term")
});

const useStyles = makeStyles((theme) => ({
    listText: {
        fontSize: 20
    },
    list: {
        borderRadius: 5,
        borderWidth: 1,
        borderColor: "#878787",
        borderStyle: "solid",
        width: '100%',
        overflow: 'auto',
        height: '180px'
    }
}));

interface TermsObj {
    term1: string;
    term2: string;
}

export interface IWordBucketProps {
    state: State
}

const WordBucket: React.FC<IWordBucketProps> = ({ state }) => {

    const classes = useStyles();

    const dataProvider = new DataProvider();
    const [topics, setTopics] = useState<any[]>([]);
    const [subTopics, setSubtopics] = useState<any[]>([]);
    const history = useHistory();

    const [word1, setWord1] = useState("");
    const [word2, setWord2] = useState("");

    const [list1, setList1] = useState<string[]>([]);
    const [list2, setList2] = useState<string[]>([]);

    const { enqueueSnackbar } = useSnackbar();

    const addWord = (word: string, listNumber: number, callback: any) => {
        if (listNumber === 1) {
            let arr: string[] = [...list1];
            arr.push(word);
            callback("bucket1List", arr);
            setList1(arr);
            setWord1("");
        } else if (listNumber === 2) {
            let arr: string[] = [...list2];
            arr.push(word);
            callback("bucket2List", arr);
            setWord2("");
            setList2(arr);
        }
    }

    const removeWord = (index: number, listNumber: number, callback: any) => {
        if (listNumber === 1) {
            let arr: string[] = [...list1];
            arr.splice(index, 1);
            callback("bucket1List", arr);
            setList1(arr);
        } else if (listNumber === 2) {
            let arr: string[] = [...list2];
            arr.splice(index, 1);
            callback("bucket2List", arr);
            setList2(arr);
        }
    }

    const onTopicSelect = (id: string) => {
        if (id) {
            //@ts-ignore
            dataProvider.getLearningCenterSubTopics(state.company.id, state.department.id, id).then(data => {
                setSubtopics(data);
            });
        }

    }

    useEffect(() => {
        //@ts-ignore
        dataProvider.getLearningCenterTopics(state.company.id, state.department.id).then(data => {
            setTopics(data);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    return (
        <Grid container direction="column">
            <Grid item>
                <Formik
                    validationSchema={validation}
                    initialValues={{
                        questionType: 3,
                        question: "",
                        bucket1: "",
                        bucket2: "",
                        mediaLink: "",
                        topic: { id: "", name: "" },
                        subTopic: { id: "", title: "" },
                        whereToFind: "",
                        bucket1List: [],
                        bucket2List: []
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {

                        let buckets = {
                            0: {
                                bucketIndex: 0,
                                name: values.bucket1
                            },
                            1: {
                                bucketIndex: 1,
                                name: values.bucket2
                            }
                        };

                        let items1: any[] = [];

                        items1 = values.bucket1List.map((item, index) => {
                            return {
                                matchIndex: 0,
                                name: item
                            }
                        });

                        let items2: any[] = [];

                        items2 = values.bucket2List.map((item, index) => {
                            return {
                                matchIndex: 1,
                                name: item
                            }
                        });

                        let items = items1.concat(items2);

                        let addQuestion = {
                            buckets: buckets,
                            items: items,
                            questionText: values.question,
                            mediaLink: values.mediaLink,
                            questionType: values.questionType,
                            learningSubTopicId: values.subTopic.id,
                            learningTopicId: values.topic.id,
                            whereToFind: values.whereToFind
                        };

                        //@ts-ignore
                        dataProvider.addQuestions(state.company.id, state.department.id, state.gameId, state.categoryId, addQuestion).then(data => {
                            setSubmitting(false);
                            setList1([]);
                            setList2([]);
                            enqueueSnackbar(`Added question`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                            resetForm();

                        }).catch(err => {
                            console.log(err);
                            enqueueSnackbar("An error occurred", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                            setSubmitting(false);
                        });

                    }}
                >
                    {({ values, submitForm, isSubmitting, setFieldValue, touched, errors }) => (
                        <Grid item container lg={12}>
                            <Form style={{ width: '100%' }}>
                                <Grid container justify="space-between" direction="column" style={{ minHeight: '60vh' }}>
                                    <Grid item container xs={12} spacing={1}>
                                        <Grid item container xs={6} spacing={2}>
                                            <Grid item container xs={6} spacing={1}>
                                                <Grid item xs={12}>
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        label="Bucket name"
                                                        name="bucket1"
                                                        variant="filled"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item container xs={12} alignItems="center" spacing={2}>
                                                    <Grid item xs={10}>
                                                        <MUITextField
                                                            variant="filled"
                                                            type="text"
                                                            label="Word"
                                                            value={word1}
                                                            onChange={(event) => {
                                                                setWord1(event.target.value);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <IconButton
                                                            size="small"
                                                            style={{ backgroundColor: '#d4d4d4' }}
                                                            onClick={() => {
                                                                addWord(word1, 1, setFieldValue);
                                                            }}
                                                        >
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <List className={classes.list}>
                                                        {
                                                            list1.map((word, index) => {
                                                                return (
                                                                    <ListItem>
                                                                        <ListItemText primary={word} />
                                                                        <ListItemSecondaryAction>
                                                                            <IconButton edge="end" aria-label="delete" onClick={() => {
                                                                                removeWord(index, 1, setFieldValue);
                                                                            }}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                )
                                                            })
                                                        }
                                                    </List>
                                                </Grid>
                                                <Grid item>
                                                    <Typography color="error">{
                                                        //@ts-ignore
                                                        errors['bucket1List']?.toString()
                                                    }</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={6} spacing={1}>
                                                <Grid item xs={12}>
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        label="Bucket name"
                                                        name="bucket2"
                                                        variant="filled"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item container xs={12} alignItems="center" spacing={2}>
                                                    <Grid item xs={10}>
                                                        <MUITextField
                                                            variant="filled"
                                                            type="text"
                                                            label="Word"
                                                            value={word2}
                                                            onChange={(event) => {
                                                                setWord2(event.target.value);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <IconButton
                                                            size="small"
                                                            style={{ backgroundColor: '#d4d4d4' }}
                                                            onClick={() => {
                                                                addWord(word2, 2, setFieldValue);
                                                            }}>
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <List className={classes.list}>
                                                        {
                                                            list2.map((word, index) => {
                                                                return (
                                                                    <ListItem>
                                                                        <ListItemText primary={word} />
                                                                        <ListItemSecondaryAction>
                                                                            <IconButton edge="end" aria-label="delete" onClick={() => {
                                                                                removeWord(index, 2, setFieldValue);
                                                                            }}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                )
                                                            })
                                                        }
                                                    </List>
                                                </Grid>
                                                <Grid item>
                                                    <Typography color="error">{
                                                        //@ts-ignore
                                                        errors['bucket2List']?.toString()
                                                    }</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={6} spacing={2}>
                                            <Grid item xs={12}>
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    label="Question"
                                                    name="question"
                                                    variant="filled"
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormFooter onAction={submitForm} onBack={history.goBack} submitting={isSubmitting} actionName="Save" backActionName="back" />
                                    </Grid>
                                </Grid>
                            </Form>
                        </Grid>
                    )}
                </Formik >
            </Grid>
        </Grid >

    );
}

export default WordBucket;