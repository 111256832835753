import { Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import FormFooter from '../../components/FormFooter';
import Panel from '../../components/Panel';
import DataProvider from '../../providers/DataProvider';
import FirebaseProvider from '../../providers/FirestoreProvider';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: '#e6e6e6',
        minHeight: 400,
        borderRadius: 15
    },
    failedText: {
        color: "#757575"
    },
    bar: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    }
}));

export interface State {
    company: {
        id: string;
        name: string;
    },
    department: {
        id: string;
        name: string;
    }
}

export enum DisplayState {
    LOADING = 1,
    SHARING,
    NOT_SELECTED,
    DISPLAY,
    IS_CHILD,
    IN_PROGRESS
}

export enum GameState {
    IS_HOST = 1,    //Game is shared to other departments
    IS_CHILD,       //Game was shared from antoher department
    IS_NONE         //Game is not a host or a child
}

export interface IShareGameProps {
}

const ShareGame: React.FC<IShareGameProps> = ({ }) => {

    const dataprovider = new DataProvider();
    const location = useLocation();
    const state = location.state as State;
    const [games, setGames] = useState<any[]>([]);
    const [selectedGame, setSelectedGame] = useState<any | null>(null);
    const [departments, setDepartments] = useState<any[]>([]);
    const [checkedDepartments, setCheckedDepartments] = useState<any[]>([]);
    const [displayState, setDisplayState] = useState<DisplayState>(DisplayState.NOT_SELECTED);
    const [submitting, setSubmitting] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const renderTable = (loading: DisplayState) => {

        switch (loading) {
            case DisplayState.NOT_SELECTED:
                return (
                    <Grid
                        container
                        className={classes.container}
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={12}>
                            <Typography variant="h5">Select a game</Typography>
                        </Grid>
                    </Grid>
                )
            case DisplayState.LOADING:
                return (
                    <Skeleton variant="rect" animation="wave" height={400} style={{ width: '100%' }} />
                )
            case DisplayState.SHARING:
                return (
                    <Grid
                        container
                        className={classes.container}
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={12}>
                            <Typography variant="h5">Updating the shared game, please wait</Typography>
                        </Grid>
                    </Grid>
                )
            case DisplayState.DISPLAY:
                return (
                    <MaterialTable
                        options={
                            {
                                selection: true
                            }
                        }
                        columns={[
                            { title: 'Name', field: 'name' },
                        ]}
                        data={departments}
                        title="Pick department(s)"
                        onSelectionChange={(rows) => {
                            setCheckedDepartments(rows);
                        }}
                    />
                );
            case DisplayState.IS_CHILD:
                return (
                    <Grid
                        container
                        className={classes.container}
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={8}>
                            <Typography variant="h5" align="center">This is a child game of a shared game. You can not share this game to other departments</Typography>
                        </Grid>
                    </Grid>
                )
            case DisplayState.IN_PROGRESS:
                return (
                    <Grid
                        container
                        className={classes.container}
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={8}>
                            <Typography variant="h5" align="center">An operation is currently being perform on this game, please come back in a few minutes</Typography>
                        </Grid>
                    </Grid>
                )
            default:
                break;
        }

    }

    const handleGameSelect = (game: any) => {

        setSelectedGame(game);
        if (game === null) {
            setDisplayState(DisplayState.NOT_SELECTED);
            return;
        }

        setDisplayState(DisplayState.LOADING);
        
        let gameState: GameState = GameState.IS_NONE;

        try {

            if (game.shared.loading) {
                setDisplayState(DisplayState.IN_PROGRESS);
                return;
            }

            if (game.shared.isHost) {
                gameState = GameState.IS_HOST;
            }

            if (game.shared.isChild) {
                setDisplayState(DisplayState.IS_CHILD);
                gameState = GameState.IS_CHILD;
            }


        } catch (error) {
            //If game.shared.host is not found.
            gameState = GameState.IS_NONE;
        }

        //Game is not a shared game
        if (gameState === GameState.IS_NONE) {

            dataprovider.getDepartmentsFD(state.company.id).then(res => {
                setDepartments(res.filter(x => x.id !== state.department.id));
                setDisplayState(DisplayState.DISPLAY);
            }).catch(err => {
                enqueueSnackbar("Failed to load the departments: " + err, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
            });

        } else if (gameState === GameState.IS_HOST) //game is shared and should populate table
        {
            dataprovider.getDepartmentsFD(state.company.id).then(res => {

                let response = [...res.filter(x => x.id !== state.department.id)];

                for (let i = 0; i < response.length; i++) {
                    const base = response[i];
                    //console.log(`Iteration ${i}, Base obj ${JSON.stringify(base)}`);
                    //console.log(game.shared.departments.length);
                    for (let j = 0; j < game.shared.departments.length; j++) {

                        const checked = game.shared.departments[j];
                        //console.log(`Iteration i:${i} j:${j}, Checked obj ${checked}`);

                        //console.log("checked: ", checked);
                        //console.log("base: ", base.id);

                        if (checked === base.id) {
                            //console.log("Checked: ", response[i].name);
                            response[i].tableData = {
                                ...response[i].tableData,
                                checked: true
                            }
                        }
                    }
                }

                setDepartments([...response]);
                setDisplayState(DisplayState.DISPLAY);
            }).catch(err => {
                enqueueSnackbar("Failed to load the departments: " + err, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
            });
        }

    }

    const handleSubmit = () => {

        setSubmitting(true);
        setDisplayState(DisplayState.SHARING);
        
        const shareGame = FirebaseProvider.functions().httpsCallable('shareGame');
		const params = { 
			hostGameId: selectedGame.id,
			hostCompanyId: state.company.id, 
			hostDepartmentId: state.department.id, 
			childDepartmentIDs: checkedDepartments.map(d => d.id)
		};
		
        shareGame(params)
            .then((response) => {

				setDepartments([]);

				if (response?.data?.isSuccessful === true) {

					enqueueSnackbar("Completed the update successfully. Reloading the game details.", { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });

				} else {
					let errors: any[] = [ ...response?.data?.errors ] || [ "No further details available" ];
					let errorMessage = errors.join("; ");

					enqueueSnackbar("Completed the update, but with some errors: " + errorMessage, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
				}
				
				dataprovider
					.getGamesFD(state.company.id, state.department.id)
					.then(games => {

                        setGames(games);
                        setSelectedGame(null);
						setSubmitting(false);
			
					})
					.catch((err) => {
						enqueueSnackbar("Failed to load the games: " + err, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
					})
					.finally(() => {
						setDisplayState(DisplayState.NOT_SELECTED);
					});

            })
            .catch(err => {

				let errorMsg = `shareGame failed for ID ${selectedGame.id} at ${new Date().toISOString()}. Exception: ${err || "No further details"}`;

				setSubmitting(false);
				enqueueSnackbar("Failed to share the game. Please keep a copy of this error message for the HyperBoliq Support Team: " + errorMsg, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });

			});

    }

    useEffect(() => {

        dataprovider
            .getDepartmentsFD(state.company.id)
            .then(res => {
                setDepartments(res.filter(x => x.id !== state.department.id));
            })
            .catch(err => {
                enqueueSnackbar("Failed to load the departments: " + err, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
            });

        dataprovider
            .getGamesFD(state.company.id, state.department.id)
            .then(data => {
                setGames(data);
            })
			.catch((err) => {
                enqueueSnackbar("Failed to load the games: " + err, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
			});

    }, []);

    return (
        <Panel>
            <Grid container direction="row">
                <Grid item xs={4}>
                    <Autocomplete
                        id="autocomplete-games"
                        value={selectedGame}
                        options={games}
                        getOptionLabel={(option) => option.name}
                        style={{ width: 300 }}
                        onChange={(event, data) => {
                            handleGameSelect(data);
                        }}
                        renderInput={(params) => <TextField {...params} label="Game" variant="outlined" />}
                    />
                </Grid>
                <Grid item xs={8}>
                    {renderTable(displayState)}
                </Grid>
                <Grid item xs={12}>
                    <FormFooter
                        actionName="save"
                        backActionName="cancel"
                        submitting={submitting}
                        onAction={() => {
                            handleSubmit();
                        }}
                        onBack={() => {
                            history.goBack();
                        }}
                    />
                </Grid>
            </Grid>
        </Panel>
    );
}

export default ShareGame;