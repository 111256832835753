import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import TableGrid from '../components/Grid';
import Stepper from '../components/Stepper';
import { State } from './AddQuestions';
import EditStepper from '../components/EditStepper';

const url = 'https://firebasestorage.googleapis.com/v0/b/test-fddd5.appspot.com/o/testing%2FArtboard65.png?alt=media&token=6157be6c-7588-42ab-ba19-a0d4e159af3e';

export interface Point {
    x: number;
    y: number;
}

export interface Selectable {
    id: string;
    clickables: Point[];
}

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundImage: `url(${url})`,
        backgroundSize: 'contain',
        height: 800,
        width: 400
    },
    table: {
        borderWidth: 1,
        borderColor: '#a3a3a3',
        borderStyle: 'solid'
    }
}));


export interface IImageMapProps {
    state: State;
    data: any;
}

const EditImageMap: React.FC<IImageMapProps> = ({ state, data }) => {

    const classes = useStyles();

    return (
        <Grid container>
            <Grid item xs={12}>
                <EditStepper state={state} data={data} />
            </Grid>
        </Grid>
    );
}

export default EditImageMap;