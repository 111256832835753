import React, { useEffect, useState, useContext } from 'react';
import Panel from '../../components/Panel';
import MainLayout from '../../components/MainLayout';
import { Button } from '@material-ui/core';
import Filter from '../../components/Filter';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import DataProvider from '../../providers/DataProvider';
import { AuthContext } from '../../providers/AuthProvider';
import { useSelector, useDispatch } from 'react-redux';
import AllActions from '../../redux/actions/Index';

export interface ICommunicationsListProps {
}

export default function CommunicationsList(props: ICommunicationsListProps) {

    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;

    const { user } = useContext(AuthContext);
    const dispatch = useDispatch();
    let history = useHistory();
    const [communications, setCommunications] = React.useState<any[]>([]);
    const dataprovider = new DataProvider();

    return (
        <MainLayout
            filters={[
                <Filter
                    loading={false}
                    allowCompanyOnlySearch
                    onSubmit={(company: any, department: any) => {
                        if (company) {
                            dataprovider.getCommunications(company.id, department?.id).then(data => {
                                setCommunications(data);
                                dispatch(AllActions.searchState.setSearchState({ company: company, department: department }));
                            })
                        }
                    }} />
            ]}
            actions={[<Button variant="contained" color="primary" onClick={() => { history.push('/communications/add') }}>Send communication</Button>]}
            content={
                <MaterialTable style={{ width: '100%' }}
                    columns={[
                        { title: "Title", field: "title" },
                        { title: "Sender", field: "sender.email" },
                        { title: "Send Date", field: "sendDate" },
                        { title: "Initial Send Date", field: "initialSendDate" }

                    ]}
                    data={communications}
                    title="Communications"
                    actions={[
                        {
                            icon: 'edit',
                            onClick: (event, rowData) => {
                                history.push('/communications/edit/' + rowData.id, { company: searchState.company.id, department: searchState.department.id });
                            }
                        },
                        {
                            icon: 'delete',
                            onClick: (event, rowData) => {
                                dataprovider.removeCommunication(rowData.id).then(() => {
                                    dataprovider.getCommunications(rowData.company, rowData.department).then(data => {
                                        setCommunications(data);

                                    })
                                });
                            }
                        }
                    ]}
                />
            }
        />
    );
}
