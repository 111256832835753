import React, { useState, useEffect } from 'react';
import { Grid, TextField as MUITextField } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import FormFooter from '../../../components/FormFooter';
import ImageUpload from '../../../components/ImageUpload';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import DataProvider from '../../../providers/DataProvider';
import Question from '../../../models/Question';
import { State } from './AddQuestions';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

let options = [
    { id: 0, letter: "A" },
    { id: 1, letter: "B" },
    { id: 2, letter: "C" },
    { id: 3, letter: "D" }
]

const validation = Yup.object().shape({
    question: Yup.string().required('Required'),
    answer_a: Yup.string().required('Required'),
    answer_b: Yup.string().required('Required'),
    answer_c: Yup.string().required('Required'),
    answer_d: Yup.string().required('Required'),
    correctAnswer: Yup.object().shape({
        id: Yup.string().required(),
        letter: Yup.string().required()
    }).required('Required')
});

export interface IMultipleChoiceProps {
    state: State;
}

const MultipleChoice: React.FC<IMultipleChoiceProps> = ({ state }) => {

    const dataProvider = new DataProvider();
    const [topics, setTopics] = useState<any[]>([]);
    const [subTopics, setSubtopics] = useState<any[]>([]);
    const { enqueueSnackbar } = useSnackbar();

    let history = useHistory();

    const onTopicSelect = (id: string) => {
        if (id) {
            //@ts-ignore
            dataProvider.getLearningCenterSubTopics(state.company.id, state.department.id, id).then(data => {
                setSubtopics(data);
            });
        }
    }

    useEffect(() => {
        //@ts-ignore
        dataProvider.getLearningCenterTopics(state.company.id, state.department.id).then(data => {
            setTopics(data);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    return (
        <Grid container>
            <Grid item>
                <Formik
                    validationSchema={validation}
                    initialValues={{
                        imgUrl: "",
                        imgPath: "",
                        questionType: 0,
                        question: "",
                        answer_a: "",
                        answer_b: "",
                        answer_c: "",
                        answer_d: "",
                        correctAnswer: { id: 0, letter: "" },
                        mediaLink: "",
                        topic: { id: "", name: "" },
                        subTopic: { id: "", title: "" },
                        whereToFind: ""
                    }}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {

                        let addQuestion = {
                            answers: {
                                0: values.answer_a,
                                1: values.answer_b,
                                2: values.answer_c,
                                3: values.answer_d,
                            },
                            correctAnswerIndex: values.correctAnswer.id,
                            imgUrl: values.imgUrl,
                            imgPath: values.imgPath || null,
                            questionText: values.question,
                            mediaLink: values.mediaLink,
                            questionType: values.questionType,
                            learningSubTopicId: values.subTopic.id,
                            learningTopicId: values.topic.id,
                            whereToFind: values.whereToFind
                        };
                        
                        if (values.imgUrl) {
                            try {

                                let imgLink = await dataProvider.addImage(values.imgUrl, "games_images/");
        
                                addQuestion.imgUrl = imgLink.imgUrl;
                                addQuestion.imgPath = imgLink.imgPath;
        
                            } catch (error) {
                                enqueueSnackbar(`Failed to save the image: ${error}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                            }
                        }

                        //@ts-ignore
                        dataProvider.addQuestions(state.company.id, state.department.id, state.gameId, state.categoryId, addQuestion).then(() => {
                            setSubmitting(false);
                            enqueueSnackbar(`Added question`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                            resetForm();
                        }).catch(err => {
                            console.log("Something went wrong:", err);
                            enqueueSnackbar("An error occurred", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                            setSubmitting(false);
                        })
                    }}
                >
                    {({ values, submitForm, isSubmitting, setFieldValue, touched, errors }) => (
                        <Grid item container lg={12}>
                            <Form style={{ width: '100%' }}>
                                <Grid container spacing={3} direction="row">

                                    <Grid item xs={12} md={8} container spacing={3} direction="column">
                                        <Grid item>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Question"
                                                name="question"
                                                variant="filled"
                                                fullWidth
                                                multiline
                                                rows={4}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Answer A"
                                                name="answer_a"
                                                variant="filled"
                                                fullWidth
                                                multiline
                                                rows={2}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Answer B"
                                                name="answer_b"
                                                variant="filled"
                                                fullWidth
                                                multiline
                                                rows={2}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Answer C"
                                                name="answer_c"
                                                variant="filled"
                                                fullWidth
                                                multiline
                                                rows={2}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Answer D"
                                                name="answer_d"
                                                variant="filled"
                                                fullWidth
                                                multiline
                                                rows={2}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                name="correctAnswer"
                                                getOptionSelected={() => { return true }}
                                                component={Autocomplete}
                                                options={options}
                                                getOptionLabel={(option: any) => option.letter}
                                                value={values.correctAnswer}
                                                onChange={(event: any, data: any) => {
                                                    setFieldValue("correctAnswer", data);
                                                }}
                                                renderInput={(params: AutocompleteRenderInputParams) => (
                                                    <MUITextField
                                                        {...params}
                                                        error={touched['correctAnswer'] && !!errors['correctAnswer']}
                                                        helperText={
                                                            //@ts-ignore
                                                            touched['correctAnswer'] && errors['correctAnswer']?.title
                                                        }
                                                        label="Correct answer"
                                                        variant="filled"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <ImageUpload
                                            label="Question Image (optional)"
                                            id="image_questionImage"
                                            src={values.imgUrl}
                                            onChange={(file: any) => {
                                                setFieldValue("imgUrl", file);
                                            }}
                                        />
                                    </Grid>
                                    
                                    <Grid item container>
                                        <FormFooter onAction={submitForm} onBack={() => { history.goBack() }} submitting={isSubmitting} actionName="Save" backActionName="back" />
                                    </Grid>

                                </Grid>
                            </Form>
                        </Grid>
                    )}
                </Formik >
            </Grid>
        </Grid >
    );
}

export default MultipleChoice;