import React, { useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    description: {
        color: "#c7c7c7",
    },
}));

export interface IItemTileProps {
    text: string;
    value: any;
    unit?: string;
}

const ItemTile: React.FC<IItemTileProps> = ({ text, value, unit = null }) => {

    let valueText = "";

    if (unit !== null) {
        valueText = `${isNaN(value)} ${unit}`;
    } else {
        valueText = `${isNaN(value) ? 0 : value}`;
    }

    const classes = useStyles();

    return (
        <Grid container >
            <Grid item container justify="center" alignItems="center" direction="column">
                <Grid item>
                    <Typography variant="h6" className={classes.description}>{text}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4">{valueText}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ItemTile;