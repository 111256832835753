import React, { useState, useEffect } from 'react';
import MainLayout from '../../components/MainLayout';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable from 'material-table';
import { useHistory, useParams } from 'react-router-dom';
import DataProvider from '../../providers/DataProvider';
import { useSelector } from 'react-redux';


export default function LearningCenterList() {

    const [topics, setTopics] = useState<any[]>([]);
    const history = useHistory();
    const dataprovider = new DataProvider();
    const params = useParams();

    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;

    //@ts-ignore
    let company = searchState.company;
    //@ts-ignore
    let department = searchState.department;
    //@ts-ignore
    let topicId = params.id;


    useEffect(() => {
        dataprovider.getLearningCenterSubTopics(company.id, department.id, topicId).then(data => {
            setTopics(data);
        }).catch(err => {
            history.goBack();
        });
        // eslint-disable-next-line
    }, []);

    return (
        <MainLayout
            actions={[
                <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={() => { history.push("add", { topicId: topicId }) }}>add subtopic</Button>
            ]}
            content={
                <MaterialTable style={{ width: '100%' }}
                    columns={[
                        { title: "Title", field: "title" },
                        { title: "Sub Title", field: "subTitle" }
                    ]}
                    actions={[
                        {
                            icon: "edit",
                            tooltip: 'Edit',
                            onClick: (event, rowData) => {
                                console.log(rowData);

                                history.push("/learningCenter/subTopic/edit/" + rowData.id, { topicId });
                            }
                        }
                    ]}
                    data={topics}
                    title="Topics"
                />
            }
        />
    );
}
