import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Paper, Grid } from '@material-ui/core';

interface ITabsProps {
    items: TabPanelObject[];
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export interface TabPanelObject {
    index: number;
    heading: string;
    content: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Paper
            style={{ backgroundColor: "#ebebeb", minHeight: '60vh', padding: 10 }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            {...other}
        >
            <Grid container>
                {children}
            </Grid>
        </Paper>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const SimpleTabs: React.FC<ITabsProps> = ({ items }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        {
                            items.map(item => {
                                return (
                                    <Tab label={item.heading} {...a11yProps(item.index)} />
                                )
                            })
                        }
                    </Tabs>
                </AppBar>

                {
                    items.map(item => {
                        return (
                            <TabPanel value={value} index={item.index}>
                                {item.content}
                            </TabPanel>
                        )
                    })
                }
            </Grid>
        </Grid>
    );
}

export default SimpleTabs;
