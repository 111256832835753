import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import { TextField, CheckboxWithLabel } from 'formik-material-ui';
import { useHistory } from 'react-router-dom';
import DataProvider from '../../../providers/DataProvider';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Yup from 'yup';
import FormFooter from '../../../components/FormFooter';
import { DropzoneArea } from 'material-ui-dropzone';
import EditbleMediaSelector from '../../../components/MediaSelectorEditable';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

const validation = Yup.object().shape({
    company: Yup.string().required('Company required'),
    department: Yup.string().required('Department is required'),
    name: Yup.string().required('Required'),
    subtitle: Yup.string().required('Required')
});

export default function AdminAddGame() {

    const { enqueueSnackbar } = useSnackbar();

    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;

    let companyId = searchState.company.id !== "" ? searchState.company.id : '';
    let departmentId = searchState.department.id !== "" ? searchState.department.id : '';

    const [companies, setCompanies] = useState<any[]>([]);
    const [departments, setDepartments] = useState<any[]>([]);
    const [companySelected, setCompanySelected] = useState<any>(searchState.company);
    const [departmentSelected, setDepartmentSelected] = useState<any>(searchState.department);
    const [selectedTopic, setSelectedTopic] = useState<any>();
    const [gameImage, setGameImage] = useState<any>();
    const [mediaError, setMediaError] = useState(false);

    const dataProvider = new DataProvider();
    let history = useHistory();
    const [media, setMedia] = useState<any[]>([]);

    const onCompanySelect = (companyId: string) => {
        setDepartmentSelected({ id: "", name: "" });
        dataProvider.getDepartments(companyId).then(data => {
            setDepartments(data);
        });
    }

    useEffect(() => {
        dataProvider.getCompanies().then(data => {
            setCompanies(data);
            if (searchState.company.id !== "") {
                dataProvider.getDepartments(searchState.company.id).then(data => {
                    setDepartments(data);
                });
            }
        });
        // eslint-disable-next-line
    }, []);

    return (
        <Formik
            initialValues={{
                name: '',
                subtitle: '',
                maximumCorrect: '',
                completetionThresholdPrecent: '',
                company: companyId,
                department: departmentId,
                gameImageSelected: false,
                media: [],
                order: 0,
                passRate: '0',
                mustWatchVideo: false,
                gameRetries: 0,
                categoryRetries: 0

            }}
            validationSchema={validation}
            onSubmit={async (values, { setSubmitting }) => {

                if (!values?.gameImageSelected) {
                    enqueueSnackbar("No game picture is uploaded", { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    setSubmitting(false);
                    return;
                }
                
                if (!(values?.media?.length > 0)) {
                    enqueueSnackbar("No media is provided", { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    setSubmitting(false);
                    return;
                }

                if (values?.mustWatchVideo && !values?.media?.some((m: any) => m.mediaType.trim().toLowerCase() == "video")) {
                    enqueueSnackbar("No video is uploaded", { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    setSubmitting(false);
                    return;
                }

                
                setSubmitting(true);

                let game: any = {
                    name: values.name,
                    subtitle: values.subtitle,
                    gameImgUrl: "",
                    gameImgPath: "",
                    maximumCorrect: 0,
                    completetionThresholdPrecent: "70",
                    media: media,
                    isExam: selectedTopic ? true : false,
                    learningTopic: selectedTopic ? selectedTopic : null,
                    order: values.order,
                    passRate: values.passRate,
                    mustWatchVideo: values.mustWatchVideo,
                    gameRetries: values.gameRetries,
                    categoryRetries: values.categoryRetries
                };

                try {

                    let img = await dataProvider.addImage(gameImage, "games_images/");

                    game.gameImgUrl = img.imgUrl;
                    game.gameImgPath = img.imgPath;

                    let gameRef: any = await dataProvider.addGame(values.company, values.department, game);
                    
                    setSubmitting(false);
                    history.replace("/games/categories/add", {
                        gameId: gameRef?.id,
                        company: { id: values.company },
                        department: { id: values.department }
                    });

                } catch (error) {
                    console.log(error);
                }
            }}
        >
            {({ submitForm, isSubmitting, values, setFieldValue, errors }) => (
                <Form noValidate>
                    <Grid container spacing={3}>
                        <Grid container item spacing={3} direction="column" xs={4}>
                            <Grid item>
                                <Autocomplete
                                    getOptionSelected={() => true}
                                    options={companies}
                                    getOptionLabel={(option: any) => option.name}
                                    value={companySelected}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setFieldValue("company", value.id);
                                            setCompanySelected(value);
                                            onCompanySelect(value.id);
                                        } else {
                                            setFieldValue("company", '');
                                            setCompanySelected({ id: "", name: "" });
                                            setDepartments([]);
                                            setDepartmentSelected({ id: "", name: "" });
                                        }
                                    }}
                                    renderInput={params => (
                                        <Field 
                                            required
                                            {...params} 
                                            component={TextField}
                                            name="company" 
                                            label="Company" 
                                            variant="filled" />
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <Autocomplete
                                    getOptionSelected={() => true}
                                    options={departments}
                                    getOptionLabel={(option: any) => option.name}
                                    value={departmentSelected}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setFieldValue("department", value.id);
                                            setDepartmentSelected(value);
                                        } else {
                                            setFieldValue("department", '');
                                            setDepartmentSelected({ id: "", name: "" });
                                        }
                                    }}
                                    renderInput={params => (
                                        <Field 
                                            required
                                            {...params}
                                            component={TextField}
                                            name="department" 
                                            label="Department" 
                                            variant="filled" />
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <Field
                                    required
                                    component={TextField}
                                    type="text"
                                    label="Name"
                                    name="name"
                                    variant="filled"
                                    fullWidth
                                />

                            </Grid>
                            <Grid item >
                                <Field
                                    required
                                    component={TextField}
                                    type="text"
                                    label="Subtitle"
                                    name="subtitle"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item >
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Display Order"
                                    name="order"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item >
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Game Retries per day"
                                    name="gameRetries"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item >
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Category Retries per day"
                                    name="categoryRetries"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item >
                                <Field
                                    component={CheckboxWithLabel}
                                    indeterminate={false}
                                    type="checkbox"
                                    name="mustWatchVideo"
                                    Label={{ label: 'Must watch video before playing' }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={8} container spacing={1} direction="column">
                            <Grid item>
                                <DropzoneArea 
                                    showAlerts={false} 
                                    filesLimit={1} 
                                    dropzoneText={"Upload an image"} 
                                    onChange={
                                        (files: any) => {
                                            setFieldValue("gameImageSelected", files[0] ? true : false);
                                            setGameImage(files[0]);
                                        }
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography color="error">
                                    {errors.gameImageSelected}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <EditbleMediaSelector
                                dataPath={`/Companies/${companyId}/Departments/${departmentId}/Games`}
                                heading="Introduction media"
                                error={mediaError}
                                onMediaChange={(arr: any[]) => {
                                    setMediaError(arr.length > 0 ? false : true);
                                    setFieldValue("media", arr);
                                    setMedia(arr);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormFooter onAction={submitForm} onBack={() => { history.goBack() }} submitting={isSubmitting} />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik >

    );
}
