import React from 'react';
import PercentageTile from './PercantageTile';
import { Grid, Paper, AppBar, Toolbar, Typography, makeStyles } from '@material-ui/core';
import { Percentages } from '../../../models/Percentanges';
import ExcelByPercentage from './exports/ExcelByPercantages';

const useStyles = makeStyles((theme) => ({

    bar: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    }
}));

export interface IPercentageRowProps {
    data: Percentages;
}

const PercentageRow: React.FC<IPercentageRowProps> = ({ data }) => {

    const classes = useStyles();

    const toArr = (object: Percentages) => {
        let arr: any[] = [];

        arr[0] = {
            ...object?.bottom25,
            percentage: "Bottom 25%"
        };

        arr[1] = {
            ...object?.mid50,
            percentage: "Mid 50%"
        };

        arr[2] = {
            ...object?.top25,
            percentage: "Top 25%"
        };

        return arr;

    }


    return (
        <Grid container style={{ backgroundColor: 'red' }}>
            <Paper style={{ width: '100%' }}>
                <Grid container>
                    <Grid container item>
                        <AppBar position="static" className={classes.bar}>
                            <Toolbar>
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography variant="h6">By points</Typography>
                                    </Grid>
                                    <Grid item>
                                        <ExcelByPercentage list={toArr(data)} />

                                    </Grid>
                                </Grid>

                            </Toolbar>
                        </AppBar>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={4}>
                            <PercentageTile position="Bottom 25%" data={data.bottom25} />
                        </Grid>
                        <Grid item xs={4}>
                            <PercentageTile position="Mid 50%" data={data.mid50} />
                        </Grid>
                        <Grid item xs={4}>
                            <PercentageTile position="Top 25%" data={data.top25} />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}

export default PercentageRow;
