import React, { useState } from 'react';
import { Grid, Paper, Divider, makeStyles, AppBar, Toolbar } from '@material-ui/core';
import ItemTile from './ItemTile';
import ExportAverages from './exports/ExcelAverages';


const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: 525,
    },
    averagesBar: {
        backgroundColor: 'grey',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    }
}));

export interface IAverageBarProps {
    avgGames: number | undefined;
    avgPoints: number | undefined;
    avgWins: number | undefined;
}

const AverageBar: React.FC<IAverageBarProps> = ({ avgGames, avgPoints, avgWins }) => {

    const classes = useStyles();

    return (
        <Grid container>
            <Grid container item xs={12}>
                <Grid item container justify="flex-end" className={classes.averagesBar}>
                    <ExportAverages
                        object={
                            {
                                avgGames: avgGames,
                                avgPoints: avgPoints,
                                avgWins: avgWins
                            }
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container direction="column" justify="space-around" className={classes.container}>
                            <Grid item>
                                <ItemTile value={avgGames} text="Avg Num of Games" />
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                            <Grid item>
                                <ItemTile value={avgPoints} text="Avg Num of Points" />
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                            <Grid item>
                                <ItemTile value={avgWins} text="Avg Num of Wins" />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AverageBar;