import React, { useState, useEffect, Fragment } from 'react';
import { Button, Grid, Divider, Typography } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import Panel from '../../components/Panel';
import DataProvider from '../../providers/DataProvider';
import MaterialTable from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import { useSelector } from 'react-redux';
import DisplayQuestions from './components/DisplayQuestions';
import MainLayout from '../../components/MainLayout';
import ExcelExportQuestions from './questions/ExcelExportQuestions';


interface State {
    categoryId: string;
    company: string;
    department: string;
    gameId: string;
    companyName?: string;
    departmentName?: string;
    categoryName?: string;
}

interface InitialValues {
    id?: number;
    question: string;
    answer_a: string;
    answer_b: string;
    answer_c: string;
    answer_d: string;
    correctAnswer: {
        id: number;
        letter: string;
    },
    mediaUri: string;
    questionType?: number;
    learningTopicId: string;
    learningSubTopicId: string;
    whereToFind: string;
}



let options = [
    { id: 0, letter: "A" },
    { id: 1, letter: "B" },
    { id: 2, letter: "C" },
    { id: 3, letter: "D" }

]

export default function AddQuestion() {

    let location = useLocation();
    //@ts-ignore
    let state: State = location.state;
    const dataProvider = new DataProvider();
    const [questions, setQuestions] = useState<any[]>([]);
    const [editValues, setEditValues] = useState<any>({});
    const [isEditState, setEditState] = useState(false);
    const [showForm, setShowForm] = useState(false);

    let history = useHistory();

    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;

    var PopulateTable = () => {
        dataProvider.getQuestions(searchState.company.id, searchState.department.id, state.gameId, state.categoryId).then(data => {
            setQuestions(data);
            console.log("QUESTIONS", data);
        }).catch(err => {
            console.log(err);
            history.push("/games");
        });
    };

    const QuestionType = function (x: number): string {
        switch (x) {
            case 0:
                return "Multiple choice"
            case 1:
                return "Fill in the blank"
            case 2:
                return "Match the terms"
            case 3:
                return "Word bucket"
            case 4:
                return "Image selections"
            case 5:
                return "Statement Blanking"
            case 6:
                return "Crossword"
            default:
                return "unknown"
                break;
        }
    }


    useEffect(() => {
        PopulateTable();
        localStorage.setItem('categoryId',state.categoryId);
        console.log("saved game id to local storage");
        // eslint-disable-next-line
    }, []);

    return (
        <MainLayout
            actions={
                [
                    <ExcelExportQuestions list={questions} />,
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            history.push("/games/categories/questions/bulk", state);
                        }}
                    >
                        Import questions
                </Button>,
                    < Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            history.push("/games/categories/questions/add", state);
                        }}
                    >
                        Add question
                </Button>
                ]
            }
            content={
                < Fragment >
                    <MaterialTable style={{ width: '100%' }}
                        columns={[
                            { title: "Question", field: "questionText" },
                            {
                                title: "Question type",
                                field: "questionType",
                                render: rowData => {
                                    return <Typography>{QuestionType(rowData.questionType)}</Typography>
                                }
                            }
                        ]}
                        data={questions}
                        title="Questions"
                        actions={[
                            (rowData: any) => ({
                                icon: () => <EditIcon />,
                                disabled: isEditState,
                                onClick: (event, rowData) => {

                                    console.log("Row data", rowData);
                                    history.push("/games/categories/questions/edit", { question: rowData, state: state });
                                }
                            }),
                            {
                                icon: 'delete',
                                onClick: (event, rowData) => {
                                    console.log("State", state);
                                    //@ts-ignore
                                    dataProvider.removeQuestion(state.company.id, state.department.id, state.gameId, state.categoryId, rowData.tableData.id).then(() => {
                                        PopulateTable();
                                    });
                                }
                            }

                        ]}
                        options={{
                            actionsColumnIndex: -1
                        }}
                    />
                </Fragment >
            }
        />
    );
}


//Force