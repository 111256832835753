import React, { useState, useEffect } from 'react';
import { Grid, TextField as MUITextField, Button, List, ListItem, ListItemText, Typography, ListItemSecondaryAction, IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";
import FormFooter from '../../../components/FormFooter';
import { Form, Formik, Field } from 'formik';
import { AutocompleteRenderInputParams, Autocomplete } from '@material-ui/lab';
import { TextField } from 'formik-material-ui';
import DataProvider from '../../../providers/DataProvider';
import Question from '../../../models/Question';
import * as Yup from 'yup';
import { State } from './AddQuestions';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const validation = Yup.object().shape({
    question: Yup.string().required('Required'),
    terms: Yup.array().min(2, "Should add atleast two terms").max(5, "Cant add more then 5 terms").required()
});

const useStyles = makeStyles((theme) => ({
    listText: {
        fontSize: 20
    },
    list: {
        borderRadius: 5,
        borderWidth: 1,
        borderColor: "#878787",
        borderStyle: "solid",
        width: '100%',
        overflow: 'auto',
        height: '180px'
    }
}));

interface TermsObj {
    term1: string;
    term2: string;
}

export interface IMatchTheTermsProps {
    state: State
}

const MatchTheTerms: React.FC<IMatchTheTermsProps> = ({ state }) => {

    const classes = useStyles();

    const [term1, setTerm1] = useState("");
    const [term2, setTerm2] = useState("");
    const [terms, setTerms] = useState<TermsObj[]>([]);

    const dataProvider = new DataProvider();
    const [topics, setTopics] = useState<any[]>([]);
    const [subTopics, setSubtopics] = useState<any[]>([]);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const handleAddTerm = (callback: any) => {
        if (term1.length > 0 && term2.length > 0) {
            let term: TermsObj = {
                term1: term1,
                term2: term2
            }

            setTerm1("");
            setTerm2("");
            addTerm(term, callback);
        }
    }

    const addTerm = (row: TermsObj, callback: any) => {
        const arr = [...terms];
        arr.push(row);

        callback("terms", arr);
        setTerms(arr);
    }

    const deleteTerm = (index: number, callback: any) => {
        const arr = [...terms];
        arr.splice(index, 1);
        callback("terms", arr);
        setTerms(arr);
    }

    const onSubmit = () => {
        let items1: any[] = [];
        let items2: any[] = [];

        terms.forEach((item, index) => {
            let obj1 = {
                name: item.term1,
                index: index
            }

            items1.push(obj1);

            let obj2 = {
                name: item.term2,
                index: index
            }

            items2.push(obj2);
        });

        let question = {
            0: {
                columnIndex: 0,
                items: items1
            },
            1: {
                columnIndex: 1,
                items: items2
            }
        }

    }



    const onTopicSelect = (id: string) => {
        if (id) {
            //@ts-ignore
            dataProvider.getLearningCenterSubTopics(state.company.id, state.department.id, id).then(data => {
                setSubtopics(data);
            });
        }
    }

    useEffect(() => {
        //@ts-ignore
        dataProvider.getLearningCenterTopics(state.company.id, state.department.id).then(data => {
            setTopics(data);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    return (
        <Grid container direction="column">
            <Grid item>
                <Formik
                    validationSchema={validation}
                    initialValues={{
                        questionType: 2,
                        question: "",
                        mediaLink: "",
                        topic: { id: "", name: "" },
                        subTopic: { id: "", title: "" },
                        whereToFind: "",
                        terms: []
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {

                        let termsArr: TermsObj[] = values.terms;

                        let items1: any[] = [];
                        let items2: any[] = [];

                        termsArr.forEach((item, index) => {
                            let obj1 = {
                                name: item.term1,
                                index: index
                            }

                            items1.push(obj1);

                            let obj2 = {
                                name: item.term2,
                                index: index
                            }

                            items2.push(obj2);
                        });

                        let terms = {
                            0: {
                                columnIndex: 0,
                                items: items1
                            },
                            1: {
                                columnIndex: 1,
                                items: items2
                            }
                        }

                        let addQuestion = {
                            questionText: values.question,
                            mediaLink: values.mediaLink,
                            questionType: values.questionType,
                            learningSubTopicId: values.subTopic.id,
                            learningTopicId: values.topic.id,
                            whereToFind: values.whereToFind,
                            terms: terms
                        };

                        //@ts-ignore
                        dataProvider.addQuestions(state.company.id, state.department.id, state.gameId, state.categoryId, addQuestion).then(() => {
                            setSubmitting(false);
                            resetForm();
                            enqueueSnackbar(`Added question`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                            setTerms([]);
                        }).catch(err => {
                            console.log("Something went wrong:", err);
                            enqueueSnackbar("An error occurred", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                            setSubmitting(false);
                        });
                    }}
                >
                    {({ values, submitForm, isSubmitting, setFieldValue, touched, errors }) => (
                        <Grid item container xs={12}>
                            <Form style={{ width: '100%' }}>
                                <Grid container justify="space-between" direction="column" style={{ minHeight: '60vh' }}>
                                    <Grid item container xs={12} spacing={1}>
                                        <Grid item container xs={6} spacing={2}>
                                            <Grid container item spacing={1} xs={12}>
                                                <Grid item xs={5}>
                                                    <MUITextField
                                                        variant="filled"
                                                        label="Term 1"
                                                        value={term1}
                                                        onChange={(event) => {
                                                            setTerm1(event.target.value);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <MUITextField
                                                        variant="filled"
                                                        label="Term 2"
                                                        value={term2}
                                                        onChange={(event) => {
                                                            setTerm2(event.target.value);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => {
                                                            handleAddTerm(setFieldValue);

                                                        }}
                                                    >Add
                                                </Button>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12} >
                                                <Grid item xs={12}>
                                                    <div>
                                                        <List component="nav" aria-label="main mailbox folders" className={classes.list}>
                                                            {
                                                                terms.map((object, index) => {
                                                                    return (
                                                                        <ListItem button>
                                                                            <Grid item container justify="space-between">
                                                                                <Grid item>
                                                                                    <Typography className={classes.listText}>{object.term1}</Typography>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Typography className={classes.listText}>{object.term2}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <ListItemSecondaryAction>
                                                                                <IconButton edge="end" aria-label="delete" onClick={() => {
                                                                                    deleteTerm(index, setFieldValue);
                                                                                }}>
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </ListItemSecondaryAction>
                                                                        </ListItem>
                                                                    );
                                                                })
                                                            }
                                                        </List>
                                                    </div>
                                                </Grid>
                                                <Grid item style={{ minHeight: 30 }}>
                                                    <Typography color="error">{
                                                        //@ts-ignore
                                                        errors['terms']?.toString()
                                                    }</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={6} spacing={2}>
                                            <Grid item xs={12}>
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    label="Question"
                                                    name="question"
                                                    variant="filled"
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormFooter onAction={submitForm} onBack={history.goBack} submitting={isSubmitting} actionName="Save" backActionName="back" />
                                    </Grid>
                                </Grid>
                            </Form>
                        </Grid>
                    )
                    }
                </Formik >
            </Grid>

        </Grid >

    );
}

export default MatchTheTerms;