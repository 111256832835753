import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

interface Props {
    children: React.ReactNode;
    isLoading?: boolean;
    helperText?: string;
}

const Panel: React.FC<Props> = ({ children, isLoading = false, helperText = "" }) => {

    const useStyles = makeStyles((theme) => ({
        paper: {
            padding: theme.spacing(2),
            backgroundColor: "#f0f0f0"
        }
    }));

    const classes = useStyles();

    return (
        <Paper className={classes.paper} >
            {
                helperText.length > 1 ?
                    <Grid container item style = {{marginBottom: '50px'}}>
                        <Typography>{helperText}</Typography>
                    </Grid>
                    :
                    <></>
            }
            {
                isLoading ?
                    <h1>Loading</h1>
                    :
                    children

            }
        </Paper>
    );
}

export default Panel;
