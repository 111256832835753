import React, { useState } from 'react';
import { Grid, Button, TextField } from '@material-ui/core';

export interface IEditTextProps {
    initialText: string;
    onSave: any;
    onCancel: any;
}

const EditText: React.FC<IEditTextProps> = ({ onSave, initialText, onCancel }) => {

    const [text, setText] = useState<string>(initialText);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField multiline rows={18} variant="filled" fullWidth value={text} onChange={(text) => {

                    setText(text.target.value)
                }} />
            </Grid>
            <Grid item container justify="space-between" xs={12}>
                <Grid item>
                    <Button variant="outlined" color="secondary" onClick={() => { onCancel() }}>Cancel</Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined" color="primary" onClick={

                        () => {
                            onSave(text);
                            //updateMediaArr(data.id, text);

                        }
                    }>Save</Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default EditText;