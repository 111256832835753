import React, { useState } from 'react';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import Panel from '../../components/Panel';
import { Formik, Form, Field } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import DataProvider from '../../providers/DataProvider';
import Company from '../../models/Company';
import ImageUpload from '../../components/ImageUpload';
import FormFooter from '../../components/FormFooter';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Autocomplete } from '@material-ui/lab';
import { TextField as SecondaryText } from '@material-ui/core';
import { Add, Clear, ClearAll, ClearRounded } from '@material-ui/icons';


const companySchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    url: Yup.string().url("Field must be a valid url").required("required"),
    primary: Yup.string().min(3, 'Too Short!').required("required"),
    secondary: Yup.string().min(3, 'Too Short!').required("required"),
    maxNumUsers: Yup.number().positive("Must be greater than 0").min(1, "Must be greater than 0").required("required"),
    maxNumGameSchedules: Yup.number().positive("Must be greater than 0").min(1, "Must be greater than 0").required("required")
});

export interface IEditCompanyProps {
}

export default function EditCompany(props: IEditCompanyProps) {

    const dataProvider = new DataProvider();

    const { enqueueSnackbar } = useSnackbar();
    const [backgroundChanged, setBackgroundChanged] = useState(false);
    const [logoChanged, setLogoChanged] = useState(false);
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const history = useHistory();
    //@ts-ignore
    const [company, setCompany] = useState<Company>(undefined);
    //@ts-ignore
    const companyID = params.id;
    React.useEffect(() => {
        console.log("Get Company")
        dataProvider.getCompany(companyID).then(company => {
            setCompany(company);
            console.log("Get Region Identifiers");
            dataProvider.getRegionIdentifiers(companyID).then(regions => {
                setRegionIdentifiers(regions);
                console.log(regions);
                console.log("GOT Region Identifiers");
            });
            setLoading(false);
        });
        
        
    }, []);
    const useStyles = makeStyles((theme) => ({
        item: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
            backgroundColor: "white",
            color: "black",
            "&:hover": {
                backgroundColor: "lightgrey",
                color: "black"
            },
        },
        itemAction: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
            color: 'black',
            transition: 'background-color 0.1s ease',
            '&:hover': {
                animation: '$colorChange 1s infinite', // add animation for color change
            },
        },
        '@keyframes colorChange': { // define the keyframes for the color change animation
            '0%': {
                color: 'red',
            },
            '25%': {
                color: '#ff5733',
            },
            '50%': {
                color: '#ff8f66',
            },
            '75%': {
                color: '#ffb399',
            },
            '100%': {
                color: 'red',
            },
        }
    }));
    const [regionIdentifiers, setRegionIdentifiers] = useState<any>([{ id: 1, name: "test" }, { id: 2, name: "test2" }, { id: 3, name: "test3" }]);
    const [selectedRegionIdentifier, setSelectedRegionIdentifier] = useState<any>(null);
    const [newRegionIdentifier, setNewRegionIdentifier] = useState<any>(null);
    const classes = useStyles();
    const renderOption = (option: any) => (
        <>
            <Grid container>
                <Grid className={classes.item} item xs={12}>
                    {option.name}
                    <IconButton className={classes.itemAction} onClick={() => handleRemoveRegionIdentifier(option)}>
                        <Clear />
                    </IconButton>
                </Grid>
            </Grid>
        </>
    );
    const handleRemoveRegionIdentifier = (option: any) => {
        //TODO: remove region identifier
        setRegionIdentifiers(regionIdentifiers.filter((item: any) => item.id !== option.id));
    };
    const handleAddRegionIdentifier = () => {
        //TODO: add region identifier
        if (!newRegionIdentifier) {
            enqueueSnackbar("Please enter a region name", { variant: "error" });
            return;
        }
        const exists = regionIdentifiers.find((item: any) => item.name === newRegionIdentifier);
        if (exists) {
            enqueueSnackbar("Region already added", { variant: "error" });
            return;
        }
        //create new region identifier with id set to length of array +1
        const regionToAdd = { id: regionIdentifiers.length + 1, name: newRegionIdentifier };
        setRegionIdentifiers([...regionIdentifiers, regionToAdd]);
        setRegionIdentifiers([...regionIdentifiers, regionToAdd]);
    };
    return (
        <Panel isLoading={loading}>
            <Formik
                validationSchema={companySchema}
                initialValues={{
                    name: company?.name,
                    primary: company?.colors[0],
                    secondary: company?.colors[1],
                    url: company?.url,
                    logo: company?.logoUrl,
                    background: company?.backgroundImgUrl,
                    maxNumUsers: company?.maxNumUsers,
                    maxNumGameSchedules: company?.maxNumGameSchedules
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);

                    var editedCompany: Company = {
                        name: values.name,
                        colors: {
                            0: values.primary,
                            1: values.secondary
                        },
                        backgroundImgUrl: values.background,
                        backgroundImgPath: company.backgroundImgPath,
                        logoUrl: values.logo,
                        logoPath: company.backgroundImgPath,
                        url: values.url,
                        maxNumUsers: values.maxNumUsers,
                        maxNumGameSchedules: values.maxNumGameSchedules
                    }

                    try {

                        if (backgroundChanged && !logoChanged) {
                            let image = await dataProvider.addImage(values.background, "company_images/");
                            editedCompany.backgroundImgUrl = image.imgUrl;
                            editedCompany.backgroundImgPath = image.imgPath;
                        }

                        if (logoChanged && !backgroundChanged) {
                            let image = await dataProvider.addImage(values.logo, "company_images/");
                            editedCompany.logoUrl = image.imgUrl;
                            editedCompany.logoPath = image.imgPath;
                        }

                        if (logoChanged && backgroundChanged) {

                            let background = await dataProvider.addImage(values.background, "company_images/");
                            editedCompany.backgroundImgUrl = background.imgUrl;
                            editedCompany.backgroundImgPath = background.imgPath;

                            let logo = await dataProvider.addImage(values.logo, "company_images/");
                            editedCompany.logoUrl = logo.imgUrl;
                            editedCompany.logoPath = logo.imgPath;
                        }

                        dataProvider.editCompany(companyID, editedCompany).then((data) => {
                            console.log("Saving Region Identifiers", companyID, regionIdentifiers);
                            dataProvider.saveRegionIdentifiers(companyID, regionIdentifiers).then((data:any) => {
                                enqueueSnackbar(`Company edited`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                                setSubmitting(false);
                            });
                        })

                    } catch (error) {
                        enqueueSnackbar(error, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ submitForm, isSubmitting, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={3}>
                            <Grid container item spacing={3} direction="column" xs={4}>
                                <Grid item>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Name"
                                        name="name"
                                        variant="filled"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Primary Color"
                                        name="primary"
                                        variant="filled"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Secondary Color"
                                        name="secondary"
                                        variant="filled"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Unique Url"
                                        name="url"
                                        variant="filled"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <Field
                                        component={TextField}
                                        type="number"
                                        label="Maximum Users"
                                        name="maxNumUsers"
                                        variant="filled"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <Field
                                        component={TextField}
                                        type="number"
                                        label="Maximum Monthly Game Schedules"
                                        name="maxNumGameSchedules"
                                        variant="filled"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        options={regionIdentifiers}
                                        disableCloseOnSelect
                                        getOptionSelected={(option, value) => option.id === value?.id}
                                        getOptionLabel={(option: any) => option.name || ""}
                                        renderOption={renderOption}
                                        value={selectedRegionIdentifier}
                                        onChange={(event, value) => { console.log(event, value); setSelectedRegionIdentifier(value); }}
                                        renderInput={(params) => (
                                            <Field
                                                {...params}
                                                component={SecondaryText}
                                                label="Region Identifier"
                                                variant="filled"
                                                value={selectedRegionIdentifier}
                                                onChange={(e: any) => { console.log(e); setNewRegionIdentifier(e.target.value); }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            <IconButton style={{
                                                                position: 'absolute',
                                                                right: '30px',
                                                                top: '5px',
                                                                display: newRegionIdentifier == null ? 'none' : 'block'
                                                            }} onClick={() => { setNewRegionIdentifier(null); setSelectedRegionIdentifier(null) }}>
                                                                <ClearRounded />
                                                            </IconButton>

                                                            <IconButton style={{
                                                                position: 'absolute',
                                                                right: '0px',
                                                                top: '5px',
                                                                display: newRegionIdentifier !== null ? 'block' : 'none'
                                                            }} onClick={() => { handleAddRegionIdentifier(); setNewRegionIdentifier(null) }}>
                                                                <Add />
                                                            </IconButton>
                                                        </>
                                                    )
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={8} spacing={3}>
                                <Grid item xs={6}>
                                    <ImageUpload
                                        header="Company Logo"
                                        id="company_logo"
                                        src={values.logo} onChange={(img: any) => {
                                            setFieldValue("logo", img);
                                            setLogoChanged(true);
                                        }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <ImageUpload
                                        header="Company Background"
                                        id="company_background"
                                        src={values.background} onChange={(img: any) => {
                                            setFieldValue("background", img);
                                            setBackgroundChanged(true);
                                        }} />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <FormFooter actionName="Save" onAction={submitForm} submitting={isSubmitting} onBack={() => { history.goBack() }} />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik >
        </Panel >
    );
}
