import React, { useState } from 'react';
import { Grid, Stepper, Step, StepLabel } from '@material-ui/core';
import Step1 from '../questions/imageMatch/Step1Add';
import Step2 from '../questions/imageMatch/Step2Add';
import { State } from '../questions/AddQuestions';

const steps = ["Add question details", "Map image"];
const url = 'https://firebasestorage.googleapis.com/v0/b/test-fddd5.appspot.com/o/testing%2FArtboard65.png?alt=media&token=6157be6c-7588-42ab-ba19-a0d4e159af3e';

export interface IStepperContainerProps {
    state: State;
}

const StepperContainer: React.FC<IStepperContainerProps> = ({ state }) => {

    const [activeStep, setActiveStep] = useState<number>(0);
    const [form, setForm] = useState<any>();

    const stepperContent = (step: number) => {
        switch (step) {
            case 0:
                return <Step1 state={state} next={handleNext} />
            case 1:
                return <Step2 data={form} state={state} reset={handleReset} back={handleBack} />
            default:
                break;
        }
    }

    const handleBack = () => {
        if (activeStep > 0)
            setActiveStep((prevStep) => prevStep - 1);
    }

    const handleNext = (data: any) => {
        console.log("DATA", data);
        setForm(data);
        setActiveStep((prevStep) => prevStep + 1);
    }

    const handleReset = () => {
        setActiveStep(0);
    }



    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stepper activeStep={activeStep} >
                    {
                        steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))
                    }
                </Stepper>
            </Grid>
            <Grid item container xs={12}>
                {
                    stepperContent(activeStep)
                }
            </Grid>
        </Grid>
    );
}

export default StepperContainer;