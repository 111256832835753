import React, { useState } from 'react';
import { Grid, AppBar, Tabs, Tab, Typography, Toolbar, makeStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import { TabPanel, a11yProps } from './TabFunctions';
import ExportIcon from '@material-ui/icons/Assessment';
import ExportPoints from './exports/ExportByTopPoints';
import ExportGamesPlayed from './exports/ExportByTopGamesPlayed';
import ExportAccuracy from './exports/ExportByTopAccuracy';

export interface ITop10Props {
    top10: any;
}

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: '#e6e6e6',
        minHeight: 550,
        borderRadius: 15
    },
    failedText: {
        color: "#757575"
    },
    bar: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    }
}));

const Top10: React.FC<ITop10Props> = ({ top10 }) => {

    const [value, setValue] = React.useState(0);
    const classes = useStyles();

    function round(value: number) {
        return Math.round(value);
    }

    if (top10 === null) {
        return (
            <Grid
                container
                className={classes.container}
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
            >
                <Grid item xs={12}>
                    <Typography variant="h5" className={classes.failedText}>Failed to load Top 10 table</Typography>
                </Grid>
            </Grid>
        )
    }

    let gamesPlayed = top10?.topGamesPlayed;
    let accuracy = top10?.topAccuracy?.sort((a: any, b: any) => (b.accuracy - a.accuracy) || (b.gamesPlayed - a.gamesPlayed)); // https://stackoverflow.com/questions/6913512/how-to-sort-an-array-of-objects-by-multiple-fields
    let points = top10?.topPoints?.map((p: any) => { return {
        ...p,
        points: round(p.points)
    }});

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <AppBar position="static" className={classes.bar}>
                    <Toolbar>
                        <Grid container justify="flex-start" alignItems="center">
                            <Grid item>
                                <Typography variant="h5">Top Players</Typography>
                            </Grid>

                        </Grid>
                    </Toolbar>
                </AppBar>
                <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="Points" {...a11yProps(0)} />
                        <Tab label="Games played" {...a11yProps(1)} />
                        <Tab label="Accuracy" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <MaterialTable style={{ width: '100%', overflow: 'auto', height: "460px" }}
                        columns={[
                            { title: "Name", field: "name" },
                            { title: "Surname", field: "surname" },
                            { title: "Email", field: "email" },
                            { title: "General", field: "general" },
                            { title: "Points", field: "points" },
                        ]}
                        data={points ? points : []}
                        options={{
                            paging: false,
                            search: false,
                            showTitle: false,
                            toolbar: true,
                            actionsColumnIndex: -1
                        }}
                        actions={[
                            {
                                icon: () => <ExportIcon />,
                                tooltip: 'Add User',
                                isFreeAction: true,
                                onClick: (event) => alert("You want to add a new row")
                            }
                        ]}
                        components={{
                            Action: props => {
                                return (
                                    <ExportPoints list={points ? points : []} />
                                )
                            }
                        }}

                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <MaterialTable style={{ width: '100%', overflow: 'auto', height: "460px" }}
                        columns={[
                            { title: "Name", field: "name" },
                            { title: "Surname", field: "surname" },
                            { title: "Email", field: "email" },
                            { title: "General", field: "general" },
                            { title: "Games played", field: "gamesPlayed" },
                        ]}
                        data={gamesPlayed ? gamesPlayed : []}
                        options={{
                            paging: false,
                            search: false,
                            showTitle: false,
                            toolbar: true,
                            actionsColumnIndex: -1
                        }}
                        actions={[
                            {
                                icon: () => <ExportIcon />,
                                tooltip: 'Export ',
                                isFreeAction: true,
                                onClick: (event) => alert("You want to add a new row")
                            }
                        ]}
                        components={{
                            Action: props => {
                                return (
                                    <ExportGamesPlayed list={gamesPlayed ? gamesPlayed : []} />
                                )
                            }
                        }}
                    />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <MaterialTable style={{ width: '100%', overflow: 'auto', height: "460px" }}
                        columns={[
                            { title: "Name", field: "name" },
                            { title: "Surname", field: "surname" },
                            { title: "Email", field: "email" },
                            { title: "General", field: "general" },
                            {
                                field: 'accuracy',
                                title: 'Accuracy',
                                render: rowData => <Typography>{Math.round((rowData.accuracy + Number.EPSILON) * 100)} %</Typography>
                            }
                        ]}
                        data={accuracy ? accuracy : []}
                        options={{
                            paging: false,
                            search: false,
                            showTitle: false,
                            toolbar: true
                        }}
                        actions={[
                            {
                                icon: () => <ExportIcon />,
                                tooltip: 'Export',
                                isFreeAction: true,
                                onClick: (event) => alert("You want to add a new row")
                            }
                        ]}
                        components={{
                            Action: props => {
                                return (
                                    <ExportAccuracy list={accuracy ? accuracy : []} />
                                )
                            }
                        }}

                    />
                </TabPanel>
            </Grid>
        </Grid>
    );
}

export default Top10;
