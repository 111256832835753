import React, { useState, useEffect } from 'react';
import ExcelJS from 'exceljs';
import { Button, Tooltip } from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';

export interface IColumn {
    title: string;
    name: string;
}

export interface ISheet {
    sheetName?: string;
    sheetColumns: IColumn[];
    sheetData: any;
}

export interface IExcelExportFunction {
    fileName?: string;
    sheets: ISheet[];
}

export interface IExcelExportButton {
    fileName?: string;
    buttonTitle?: string;
    sheets: ISheet[];
    buttonProps?: any;
    children?: React.ReactNode;
}

export const excelExportFunction = async (props: IExcelExportFunction) => {

    const workbook = new ExcelJS.Workbook();

    for (let s = 0; s < props.sheets.length; s++) {
        const sheet = props.sheets[s];
        const worksheet = workbook.addWorksheet(sheet.sheetName || `Sheet ${s + 1}`);
        
        // Set up worksheet columns
        worksheet.columns = sheet.sheetColumns.map((col: IColumn) => ({
            header: col.title,
            key: col.name,
            width: 20
        }));

        // Add rows to the worksheet
        worksheet.addRows(sheet.sheetData);
    }

    // Generate Excel file as a Blob
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    
    // Create a URL for the Blob and download
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = (props.fileName || "ExportedDownload") + ".xlsx";
    document.body.appendChild(link);
    link.click();

    // Cleanup the link and URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

}

export const ExcelExportButton: React.FC<IExcelExportButton> = ({ fileName, buttonTitle, sheets, buttonProps = {}, children }) => {

    const [ hasAvailableData, setHasAvailableData ] = useState(false);

    useEffect(() => {

        setHasAvailableData((sheets?.length > 0 && sheets[0].sheetColumns?.length > 0));  

    }, [sheets]);

    const downloadExcel = async () => {

        await excelExportFunction({fileName: fileName, sheets: sheets});

    };

    return (
        <Tooltip title={!hasAvailableData ? "No valid data" : ""} arrow>
            <span>
                <Button 
                    variant="contained" 
                    color="primary" 
                    startIcon={Object.keys(buttonProps).length > 0 ? null : <ImportExportIcon />}
                    disabled={!hasAvailableData}
                    onClick={hasAvailableData ? downloadExcel : null}
                    {...buttonProps}
                >
                    {children || buttonTitle || "Download"}
                </Button>
            </span>
        </Tooltip> 
    );
}

export default ExcelExportButton;