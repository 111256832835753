import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';

interface IDialogProps {
    isOpen: boolean;
    handleClose: any;
    handleConfirm: any;
    title: string;
    contentText: string;
}

const PopupConfirm: React.FC<IDialogProps> = ({ isOpen, handleClose, handleConfirm, title, contentText }) => {

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {contentText}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleConfirm} color="secondary">
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>

            </Dialog>
        </div>
    );
}

export default PopupConfirm;