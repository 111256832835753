import React, { useState, useRef, Fragment } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Popup from '../../components/PopupForm';
import "react-big-calendar/lib/css/react-big-calendar.css";
import DataProvider from "../../providers/DataProvider";
import MainLayout from "../../components/MainLayout";
import { Button } from "@material-ui/core";
import { Grid } from '@material-ui/core';
import Filter from "../../components/Filter";
import AddIcon from '@material-ui/icons/Add';
import { useDispatch, useSelector } from "react-redux";
import AllActions from '../../redux/actions/Index';
import ViewEvent from './components/ViewEvent';
import { green, orange, red } from '@material-ui/core/colors';
import { useEffect } from "react";
import { useSnackbar } from 'notistack';

const localizer = momentLocalizer(moment);

const CalendarView: React.FC = () => {

    const dataProvider = new DataProvider();
    const { enqueueSnackbar } = useSnackbar();
    const [events, setEvents] = useState<any[]>([]);
    const [filterSelected, setFilterSelected] = useState<boolean>(false);
    const [filterLoading, setFilterLoading] = useState(false);
    const [calendarDate, setCalendarDate] = useState<Date>(new Date());
    const [schedulesInfo, setSchedulesInfo] = useState<any>({ monthName: "N/A", current: 0, limit: 0 });
    const [schedulesInfoColor, setSchedulesInfoColor] = useState<any>({ color: green[700] });

    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;

    const dispatch = useDispatch();

    const childRef = useRef();
    const viewEventRef = useRef();

    useEffect(() => {
        var monthName = schedulesInfo.monthName;
        var current = schedulesInfo.current;
        var limit = schedulesInfo.limit;

        // Check if the month is different
        let newMonthNum = calendarDate.getMonth();
        let newMonthName = moment(calendarDate).format("MMM");

        if (newMonthName != monthName){
            // Assign the new month name
            monthName = newMonthName;

            // Get the number of events scheduled within this month
            current = 0;

            events.forEach(event => {
                let monthStart = new Date(event.start).getMonth();
                let monthEnd = new Date(event.end).getMonth();

                if (monthStart == newMonthNum || monthEnd == newMonthNum){
                    current++;
                }
            });

            setSchedulesInfo({ monthName: newMonthName, current: current, limit: limit });
        }

        // Get the schedules limit for the company
        if (searchState.company.id != 0 && searchState.company.id.length > 0){
            dataProvider.getCompany(searchState.company.id).then(data => {
                limit = data.maxNumGameSchedules || 0;

                setSchedulesInfo({ monthName: newMonthName, current: current, limit: limit });
            });
        }

    }, [calendarDate, searchState.company]);

    useEffect(() => {        
        // An event has been added or removed, update the schedules info
        let current = 0;
        let monthNum = calendarDate.getMonth();

        events.forEach(event => {
            let monthStart = new Date(event.start).getMonth();
            let monthEnd = new Date(event.end).getMonth();

            if (monthStart == monthNum || monthEnd == monthNum){
                current++;
            }
        });

        setSchedulesInfo({ monthName: schedulesInfo.monthName, current: current, limit: schedulesInfo.limit });

    }, [events]);

    useEffect(() => {
        if (schedulesInfo.current > schedulesInfo.limit){
            setSchedulesInfoColor({ color: red[700] });
        } else if (schedulesInfo.current > (schedulesInfo.limit - 5)){
            setSchedulesInfoColor({ color: orange[700] });
        } else {
            setSchedulesInfoColor({ color: green[700] });
        }
    }, [schedulesInfo]);

    return (
        <MainLayout
            preConditionSet={filterSelected}
            actions={[
                <Button
                    disabled={!filterSelected}
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="primary"
                    onClick={() => {                        
                        if (schedulesInfo.current >= schedulesInfo.limit){
                            enqueueSnackbar("Cannot add more schedules to this month, the limit has been reached", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                            return;
                        }

                        //@ts-ignore 
                        childRef.current.OpenModal(undefined, undefined)
                    }}>Add Schedule</Button>
            ]}
            filters={
                [
                    <Grid container item justify="space-between" alignItems="center">
                        <Grid item xs={8}>
                            <Filter
                            loading={filterLoading}
                            onSubmit={(company: any, department: any) => {
                                setFilterLoading(true);
                                dataProvider.getSchedules(company.id, department.id).then(data => {

                                    console.log("Events: ", data);
                                    setEvents(data);
                                    dispatch(AllActions.searchState.setSearchState({ company: company, department: department }));
                                    setFilterSelected(true);
                                    setFilterLoading(false);
                                })
                            }} /> 
                        </Grid>
                        <Grid item>
                            { filterSelected == true && (
                                    <div>Schedules Added for {schedulesInfo.monthName}: <span style={schedulesInfoColor}>{schedulesInfo.current}/{schedulesInfo.limit}</span></div>
                            )}
                        </Grid>
                    </Grid>
                ]
            }
            content={[
                <Fragment>
                    <div className="App" style={{ width: '100%' }}>
                        <Calendar
                            onSelectEvent={(event: any, e: any) => {
                                console.log("Event", event);
                                console.log("e", e);
                                //@ts-ignore
                                viewEventRef.current.OpenModal(event);
                            }}
                            localizer={localizer}
                            defaultDate={new Date()}
                            defaultView="month"
                            events={events}
                            style={{ height: "100vh" }}
                            onSelectSlot={(response: any) => {

                                console.log("Time select: ", response);

                                console.log(new Date(response.start).getMonth());
                                console.log(response);
                                
                                if (schedulesInfo.current >= schedulesInfo.limit){
                                    enqueueSnackbar("Cannot add more schedules to this month, the limit has been reached", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                                    return;
                                }

                                //@ts-ignore
                                childRef.current.OpenModal(response.start, response.end);
                            }}
                            selectable={true}
                            onNavigate={(date: any, view: any) => {
                                setCalendarDate(date);
                            }}
                        />
                    </div>
                    <Popup ref={childRef} onSchedualAdd={() => {
                        dataProvider.getSchedules(searchState.company.id, searchState.department.id).then(data => {
                            setEvents(data);
                            enqueueSnackbar("Schedule successfully added", { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                        });
                    }} />
                    <ViewEvent ref={viewEventRef} onDelete={() => {
                        dataProvider.getSchedules(searchState.company.id, searchState.department.id).then(data => {
                            setEvents(data);
                        });
                    }} />
                </Fragment>
            ]} />
    );
}

export default CalendarView;
