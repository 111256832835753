import 'firebase/compat/firestore';
import firebase from './FirestoreProvider';
import User from '../models/User';
import LoggedUser from '../models/LoggedUser';
import Company from '../models/Company';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import Department from '../models/Department';
import Game from '../models/Game';
import ScheduleTypes from '../models/enums/ScheduleType';
import UserRoles from '../models/enums/Roles'


interface imageReturn {
    imgUrl: string,
    imgPath: string
}

interface fileReturn {
    fileUrl: string,
    filePath: string
}

export enum GameScheduleState {
    EMPTY = 1,
    IS_CHILD,
    CAN_SCHEDULE
}

function CompareSharedObjects(sharedObj1: any, sharedObj2: any) {
    return (
        sharedObj1.hostId == sharedObj2.hostId &&
        sharedObj1.isHost == sharedObj2.isHost &&
        sharedObj1.isChild == sharedObj2.isChild &&
        JSON.stringify(sharedObj1.departments?.sort((a: string, b: string) => a > b ? 1 : -1)) == JSON.stringify(sharedObj2.departments?.sort((a: string, b: string) => a > b ? 1 : -1))
    );
}

class DataProvider {

    getAllLeaderboards = () => new Promise<any>(async (res, rej) => {

        let today = new Date();
        let gamesPlayedCollection = await firebase.firestore()
            .collection("GamesPlayed")
            .where('context.companyId', '==', "xNi9xySgYeoL2pnIuytJ")
            .where('context.departmentId', '==', "C4BEYxXz7WOINryvWo61")
            .where('context.gameId', '==', "B7X99qqaeLakU5zVpH5x")
            .where('context.userId', '==', "yGgiTClFABRfXJrsGLL8joQLda52")
            .where('date.fullDate', '>=', new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1))
            .get();

        gamesPlayedCollection?.docs?.forEach((doc, d) => {
            let data = doc?.data();
            let baseDate = new Date(1970, 0, 1, 0, 0, 0, 0);

            console.log(`doc ${d + 1} = `, data)
            console.log(`doc ${d} date raw = `, data?.date?.fullDate)
            console.log(`doc ${d} date = `, new Date(baseDate.setSeconds(baseDate.getSeconds() + data?.date?.fullDate?.seconds)))
            //console.log(`doc ${d} date comparison = `, data?.date?.fullDate >= new Date(today.getFullYear(), today.getMonth(), today.getDate()))
        });

        return;

        let allLeaderboards: any[] = [];
        let numCompanies = 0;
        let companyCount = 0;
        let numDepartments = 0;
        let departmentCount = 0;
        let numLeaderboards = 0;
        let leaderboardCount = 0;

        const resolve = () => {

            if (companyCount < numCompanies || departmentCount < numDepartments || leaderboardCount < numLeaderboards)
                return;

            res(allLeaderboards);

        };

        try {

            let companyCollection = await firebase.firestore().collection("Companies/").get();
            let companyDocs = [...companyCollection.docs];
            numCompanies += companyDocs.length;

            companyDocs.forEach(async (companyDoc, c) => {

                let company = companyDoc.data();
                let departmentCollection = await firebase.firestore().collection(`Companies/${companyDoc.id}/Departments/`).get();
                let departmentDocs = [...departmentCollection.docs];
                numDepartments += departmentDocs.length;

                departmentDocs.forEach(async (departmentDoc, d) => {

                    let department = departmentDoc.data();
                    let leaderboardCollection = await firebase.firestore().collection(`Companies/${companyDoc.id}/Departments/${departmentDoc.id}/Leaderboard`).get();
                    let leaderboardDocs = [...leaderboardCollection.docs];
                    numLeaderboards += leaderboardDocs.length;

                    leaderboardDocs.forEach(async (leaderboardDoc, l) => {

                        let leaderboard = leaderboardDoc.data();
                        let isPrimary = leaderboardDoc.id === "Leaderboard";
                        let users = Object.values(leaderboard?.users);

                        if (users?.length > 0) {
                            users.forEach((item: any) => {
                                allLeaderboards.push({
                                    leaderboardId: isPrimary ? "Primary" : leaderboardDoc.id,
                                    companyId: companyDoc.id,
                                    companyName: company.name,
                                    departmentId: departmentDoc.id,
                                    departmentName: department.name,
                                    userId: item.userId,
                                    userName: item.name,
                                    points: item.points,
                                    maxPoints: item.maximumScore,
                                    accuracy: item.score
                                });
                            });
                        }

                        leaderboardCount++;
                        resolve();

                    });

                    departmentCount++;
                    resolve();

                });

                companyCount++;
                resolve();

            });

        } catch (error) {
            console.log(`${new Date().toLocaleTimeString()} ERROR FOR getAllLeaderboards: `, error);
            rej(error)
        }

    });

    getAllUsers = (user: LoggedUser | undefined) => new Promise<any>(async (res, rej) => {

        let assignDepartmentNames = function (allDepartmentDocs: any[], allUsers: any[]) {

            let uniqueDepartmentDocs = allDepartmentDocs.filter((doc, i) => allDepartmentDocs.indexOf(doc) === i);

            uniqueDepartmentDocs.forEach(departmentDoc => {
                let department = departmentDoc.data();

                allUsers.forEach(user => {
                    if (user.departmentId == departmentDoc.id) {
                        user.departmentName = department.name;
                    }
                });
            });

            return allUsers;
        };

        try {

            let allUsers: any[] = [];
            let allDepartmentDocs: any[] = [];
            let isCompaniesProcessed = false;
            let isManagersProcessed = false;
            let companyDocs: any[] = [];

            if (!user || Number(user?.role) === UserRoles.admin) {
                let companyCollection = await firebase.firestore().collection("Companies/").get();
                companyDocs.push(...companyCollection.docs);
            }
            // else if (user?.companies && user.companies?.length > 0) {
            //     let companyCollection = await firebase.firestore().collection("Companies/").get();
            //     companyDocs.push(...companyCollection.docs.filter(doc => user.companies?.some(id => doc.id == id)));
            // }
            else {
                let companyDoc = await firebase.firestore().collection("Companies/").doc(user?.company).get();
                companyDocs.push(companyDoc);
            }

            companyDocs.forEach(async (companyDoc, i) => {

                let company = companyDoc.data();

                if (Number(user?.role) === UserRoles.admin || Number(user?.role) === UserRoles.comp_manager) {
                    let departmentCollection = await firebase.firestore().collection(`Companies/${companyDoc.id}/Departments/`).get();
                    allDepartmentDocs.push(...departmentCollection.docs);
                } else {
                    let departmentdoc = await firebase.firestore().collection(`Companies/${companyDoc.id}/Departments/`).doc(user?.department).get();
                    allDepartmentDocs.push(departmentdoc);
                }

                let userCollection = await firebase.firestore().collection(`Companies/${companyDoc.id}/Users/`).get();
                let userDocs = userCollection.docs;

                userDocs.forEach(userDoc => {

                    let userData = userDoc.data();
                    let user = userData.userInfo;
                    let gameStats = userData.gameStats;

                    allUsers.push({
                        userId: userDoc.id,
                        departmentId: user?.department || "unknown",
                        departmentName: !user?.department ? "None" : "unknown",
                        companyId: user?.company || "unknown",
                        companyName: company?.name || "unknown",
                        email: user?.email || "unknown",
                        firstName: user?.firstName || "unknown",
                        lastName: user?.lastName || "unknown",
                        idNumber: user?.idNumber || "unknown",
                        role: user?.role || "unknown",
                        disabled: user?.disabled || false,
                        gamesPlayed: gameStats?.gamesPlayed || 0,
                        accuracy: gameStats?.answerAccuracy || 0,
                        totalPoints: gameStats?.answersCorrectCount || 0,
                        general: user?.general || ""
                    });

                });

                // Only resolve this promise when both users and managers are completed
                if ((i + 1) >= companyDocs.length) {
                    isCompaniesProcessed = true;

                    if (isManagersProcessed) {
                        res(assignDepartmentNames(allDepartmentDocs, allUsers));
                        //res(allUsers);
                    }
                }

            });

            if (user?.role != UserRoles.admin) {

                isManagersProcessed = true;

            } else {

                let managerCollection = await firebase.firestore().collection("Managers/").get();
                let managerDocs = managerCollection.docs;

                managerDocs.forEach((userDoc, i) => {
                    let userData = userDoc.data();
                    let user = userData.userInfo || userData;
                    let gameStats = userData.gameStats;

                    allUsers.push({
                        userId: userDoc.id,
                        departmentId: user?.department || "unknown",
                        departmentName: !user?.department ? "None" : "unknown",
                        companyId: user?.company || "unknown",
                        companyName: !user?.company ? "None" : "unknown",
                        companies: user?.companies || [],
                        email: user?.email || "unknown",
                        firstName: user?.firstName || "unknown",
                        lastName: user?.lastName || "unknown",
                        idNumber: user?.idNumber || "unknown",
                        role: user?.role || "unknown",
                        disabled: user?.disabled || false,
                        gamesPlayed: gameStats?.gamesPlayed || 0,
                        accuracy: gameStats?.answerAccuracy || 0,
                        totalPoints: gameStats?.answersCorrectCount || 0,
                        general: user?.general || ""
                    });

                    // Only resolve this promise when both users and managers are completed
                    if ((i + 1) >= managerDocs.length) {
                        isManagersProcessed = true;

                        if (isCompaniesProcessed) {
                            res(assignDepartmentNames(allDepartmentDocs, allUsers));
                            //res(allUsers);
                        }
                    }
                })

            }

        } catch (error) {
            console.log(`${new Date().toLocaleTimeString()} ERROR FOR getAllUsers: `, error);
            rej(error)
        }
    });

    getUserAnswers = (companyId: any, userId: any) => new Promise<any>(async (res, rej) => {
        try {

            let resource = `Companies/${companyId}/Users/${userId}/Answers`;
            let snapshot = await firebase.firestore().collection(resource).get();

            let resultArr = (await snapshot).docs.map(item => {
                return {
                    ...item.data(),
                    id: item.id
                }
            });

            res(resultArr);

        } catch (error) {
            rej(error);
        }
    });

    deleteUserGamePlayed = (id: any) => new Promise<any>(async (res, rej) => {

        try {

            let resource = "GamesPlayed";
            let snapshot = firebase.firestore().collection(resource).doc(id).delete();

            snapshot.then(data => {
                res(data);
                console.log("Deleted succesfully, data = ", data)
            });

            snapshot.catch(err => {
                console.log("couldn't delete record")
                console.dir(err);
            })

        } catch (error) {
            console.log("failed to delete record")
            console.dir(error);
        }

    });

    getUserGamesPlayed = (companyId: any, departmentId: any, userId: any, startDate: Date, endDate: Date) => new Promise<any>(async (res, rej) => {
        try {

            let resource = "GamesPlayed";
            let snapshot = await firebase.firestore().collection(resource)
                .where('context.companyId', '==', companyId)
                //.where('context.departmentId', '==', departmentId)
                //.where('context.userId', '==', userId)
                .where('date.fullDate', '>=', startDate)
                .where('date.fullDate', '<=', endDate)
                .get();

            let resultArr = (await snapshot).docs.map(item => {
                return {
                    ...item.data(),
                    id: item.id
                }
            });

            res(resultArr);

        } catch (error) {
            rej(error);
        }
    });

    getReport = (company: any, department: any, date: Date, offset: number) => new Promise<any>(async (res, rej) => {

        try {
            console.log("getReport", company, department, date.getDate(), date.getMonth() + 1, date.getFullYear(), offset);
            let resource = "Reporting";
            let snapshot = await firebase.firestore().collection(resource)
                .where('companyId', '==', company)
                .where('departmentId', '==', department || "")
                .where('date.day', '==', date.getDate())
                .where('date.month', '==', (date.getMonth() + 1))
                .where('date.year', '==', date.getFullYear())
                .where('offset', '==', offset)
                .get();
            console.log("getReport", snapshot);
            let arr: any[] = (await snapshot).docs.map(item => {
                return {
                    ...item.data(),
                    id: item.id,
                }
            });
            console.log("getReport", arr);
            for (let i = 0; i < arr.length; i++) {
                let rep = arr[i];
                let d = new Date(1970, 0, 1, 0, 0, 0, 0);

                d.setSeconds(d.getSeconds() + rep.createdDate.fullDate.seconds);

                rep.createdDate.actualDate = `${d.toLocaleDateString()}`;
            }

            if (arr.length !== 0) {
                //let report = arr[0];
                let report = arr.reduce((a, b) => {
                    return ((a?.createdDate?.fullDate?.seconds ?? 0) > ((b?.createdDate?.fullDate?.seconds ?? 0))) ? a : b;
                });
                console.log("getReport", report);
                res(report);
            } else {
                console.log("getReport", "no report");
                res(null)
            }
        } catch (error) {
            rej(error);
        }
    });

    getCommunications = (company: any, department: any) => new Promise<any>(async (res, rej) => {

        let resource = "Communication";
        let snapshotQuery = firebase.firestore().collection(resource).where('company', '==', company)

        if (department) {
            snapshotQuery = snapshotQuery.where('department', '==', department);
        }

        let snapshot = snapshotQuery.get();

        snapshot.then(docs => {

            let comms: any[] = [];

            docs.forEach(doc => {

                const sendDate: Date = doc.data().sendDate.toDate();

                try {
                    let initialSendDate: Date = doc.data().initialSendDate.toDate();

                    comms.push({
                        ...doc.data(),
                        sendDate: sendDate.toDateString(),
                        initialSendDate: initialSendDate.toDateString(),
                        id: doc.id
                    });
                } catch (error) {
                    comms.push({
                        ...doc.data(),
                        sendDate: sendDate.toDateString(),
                        initialSendDate: sendDate.toDateString(),
                        id: doc.id
                    });
                }
            })

            res(comms);
        });

        snapshot.catch(err => {
            rej(err);
        })

    });

    getCommunication = (company: any, department: any, communicationID: string) => new Promise<any>(async (res, rej) => {
        let resource = "Communication";
        let snapshot = firebase.firestore().collection(resource).doc(communicationID).get();

        snapshot.then(doc => {

            let communication: any = doc.data();

            res({
                ...communication,
                id: doc.id,
                company: company,
                department: department
            });
        });

        snapshot.catch(err => {
            rej(err);
        })

    })

    updateCommunication = (communicationID: string, communication: any) => new Promise<any>(async (res, rej) => {

        let resource = "Communication";
        let snapshot = firebase.firestore().collection(resource).doc(communicationID).update(
            {
                title: communication.title,
                media: communication.media,
                sendDate: new Date()
            }
        )

        snapshot.then(data => {
            res(data);
        });

        snapshot.catch(err => {
            rej(err);
        })

    })

    addCommunication = (communication: any) => new Promise<any>(async (res, rej) => {

        let resource = "Communication";
        let snapshot = firebase.firestore().collection(resource).add(communication);

        snapshot.then(data => {
            res(data);
        });

        snapshot.catch(err => {
            rej(err);
        })

    });

    removeCommunication = (communicationID: any) => new Promise<any>(async (res, rej) => {

        let resource = "Communication";
        let snapshot = firebase.firestore().collection(resource).doc(communicationID).delete();

        snapshot.then(data => {
            res(data);
        });

        snapshot.catch(err => {
            rej(err);
        })

    });

    getUserById = (userId: any, userAccount: LoggedUser | undefined, companyId?: string | undefined) => new Promise<any>(async (res, rej) => {

        // Check the managers collection first if allowed
        if (Number(userAccount?.role) === UserRoles.admin || Number(userAccount?.role) === UserRoles.comp_manager) {

            let managerDoc = await firebase.firestore().collection("Managers/").doc(userId).get();
            let result = managerDoc?.data();

            if (result) {
                res(result);
                return;
            }

        }

        // Now check the user collections of each of the companies based on the access level of the logged-in user
        let companyDocs: any[] = [];

        if (Number(userAccount?.role) === UserRoles.admin) {
            let companyCollection = await firebase.firestore().collection("Companies/").get();
            companyDocs.push(...companyCollection.docs);
        }
        // else if (userAccount?.companies && userAccount.companies?.length > 0) {
        //     let companyCollection = await firebase.firestore().collection("Companies/").get();
        //     companyDocs.push(...companyCollection.docs.filter(doc => userAccount.companies?.some(id => doc.id == id)));
        // }
        else {
            let companyDoc = await firebase.firestore().collection("Companies/").doc(userAccount?.company).get();
            companyDocs.push(companyDoc);
        }

        if (companyId && companyId.length > 0) {
            companyDocs = companyDocs.filter(c => c.id === companyId);
        }

        companyDocs.forEach(async (companyDoc) => {

            let userDoc = await firebase.firestore().collection(`Companies/${companyDoc.id}/Users/`).doc(userId).get();
            let result = userDoc?.data();

            if (result?.userInfo) {
                res(result.userInfo);
                return;
            }

        });

    });

    getUser = (company: any, userId: any) => new Promise<any>(async (res, rej) => {

        let resource = "Companies/" + company + "/Users";

        let snapshot = firebase.firestore().collection(resource).doc(userId).get();

        snapshot.then(doc => {

            let data = doc.data();
            let user = {
                ...data?.userInfo,
                id: doc.id
            }

            res(user);
        });

    });

    editUser = (company: any, userId: any, data: any) => new Promise<any>(async (res, rej) => {

        let resource = "Companies/" + company + "/Users";

        let snapshot = firebase.firestore().collection(resource).doc(userId).update({
            "userInfo.firstName": data.name,
            "userInfo.lastName": data.surname,
            "userInfo.idNumber": data.idNumber,
        });

        snapshot.then(data => {

            res(data);
        });

    });

    getUsers = (company: any, department?: any) => new Promise<any>(async (res, rej) => {

        let usersArr: any[] = [];

        try {
            let userDocs = department != null
                ? await firebase.firestore().collection("Companies/" + company + "/Users").where('userInfo.department', '==', department).get()
                : await firebase.firestore().collection("Companies/" + company + "/Users").get();

            let managerSnapshot = department != null
                ? await firebase.firestore().collection(`Managers`).where('company', '==', company).where('department', '==', department).get()
                : await firebase.firestore().collection(`Managers`).where('company', '==', company).get();

            userDocs.docs.forEach(user => {
                let userObj = {
                    ...user.data().userInfo,
                    id: user.id
                }

                usersArr.push(userObj);
            });

            if (!managerSnapshot.empty) {


                managerSnapshot.docs.forEach(manager => {

                    let managerObj = {
                        ...manager.data(),
                        id: manager.id
                    }

                    usersArr.push(managerObj);
                })
            }

            res(usersArr);

        } catch (error) {
            console.log(error);
            rej(error);
        }
    });

    addUser = (company: any, user: User) => new Promise(async (res, rej) => {

        //When the document was added to the firestore a cloud function will be triggered and a user will be created and a custom claim will be assigned

        //NB !!!
        //updating the role field on the user will not update the custom claims.

        let resource = "Managers";

        firebase.firestore().collection(resource).doc().set(user).then(data => {
            res(data);
        }).catch(err => rej(err));

    });

    //Function to completely remove user from firebase
    removeUser = (deleteUser: any, currentUser: any) => new Promise(async (res, rej) => {

        // First remove the user document along with its sub-collections
        // Note: the user account is removed from the Firebase Auth context upon a trigger cloud function
        let deleteUserAndChildDocs = firebase.functions().httpsCallable('deleteUserAndChildDocs');
        deleteUserAndChildDocs({ deleteUser: deleteUser, currentUser: currentUser })
            .then(response => {

                res(response);

            })
            .catch(error => {

                rej("Failed to completely delete the user data: " + error);

            });

    });


    getDepartments = (company: any) => new Promise<any[]>(async (res, rej) => {
        let resource = "Companies/" + company + "/Departments";

        let snapshot = firebase.firestore().collection(resource).get();
        let values: any[] = [];

        snapshot.then(data => {

            if (data.docs.length === 0) {
                res([]);
            } else {
                let departments = data.docs;

                departments.forEach(department => {

                    if (!department.data().disabled) {
                        values.push({
                            id: department.id,
                            name: department.data().name
                        });
                    }
                    res(values);
                });
            }
        });

        snapshot.catch(err => {
            rej(err);
        });

    });

    editCompany = (companyID: string, editedCompany: Company) => new Promise<any>(async (res, rej) => {

        let snapshot = firebase.firestore().collection("Companies").doc(companyID).update(editedCompany);

        snapshot.then(data => {
            res(data);
        });

        snapshot.catch(err => rej(err));


    })

    getCompany = (companyID: string) => new Promise<Company>(async (res, rej) => {

        let snapshot = firebase.firestore().collection("Companies").doc(companyID).get();

        snapshot.then(doc => {
            var companyDoc: Company = doc.data() as Company;

            companyDoc = {
                ...companyDoc,
                id: doc.id
            };

            res(companyDoc);
        });

        snapshot.catch(err => rej(err));

    })

    getCompanies = () => new Promise<any[]>(async (res, rej) => {

        const getCompFunc = firebase.functions().httpsCallable('getCompanies');

        getCompFunc()
            .then((response: any) => {

                const result = response?.data;

                if (result?.isSuccess === true) {
                    res(result?.companies);
                } else {
                    rej(result?.error || "An unexpected error occurred");
                }
            })
            .catch((error) => {
                rej(error);
            });

    });

    getCompaniesFD = () => new Promise<any[]>(async (res, rej) => {

        let snapshot = firebase.firestore().collection("Companies").get();
        let values: any[] = [];

        snapshot.then(data => {
            let companies = data.docs;

            companies.forEach(company => {

                if (!company.data().disabled) {
                    values.push({
                        ...company.data(),
                        id: company.id
                    });
                }
                res(values);
            });
        });

        snapshot.catch(err => {
            rej(err);
        });

    });

    addCompany = (company: Company) => new Promise(async (res, rej) => {

        let snapshot = firebase.firestore().collection("Companies");

        snapshot.add(company).then(data => {
            res(data);
        }).catch(err => {
            rej(err);
        });
    });

    editDepartment = (companyID: string, department: Department) => new Promise<any>(async (res, rej) => {

        let resource = "Companies/" + companyID + "/Departments";
        let snapshot = firebase.firestore().collection(resource).doc(department.id).update(
            {
                name: department.name,
                description: department.description
            }
        );

        snapshot.then(data => {
            res(data);
        });

    })

    getDepartment = (companyID: string | undefined, departmentID: string) => new Promise<Department>(async (res, rej) => {

        if (!companyID)
            rej("department ID is undefined");

        let resource = "Companies/" + companyID + "/Departments";
        let snapshot = firebase.firestore().collection(resource).doc(departmentID).get();

        snapshot.then(doc => {

            let data = doc.data();

            var department: Department = {
                id: doc.id,
                name: data?.name,
                description: data?.description
            };

            res(department);
        });

        snapshot.catch(err => { rej(err) });
    });

    getDepartmentsFD = (company: any) => new Promise<any[]>(async (res, rej) => {

        let resource = "Companies/" + company + "/Departments";
        let snapshot = firebase.firestore().collection(resource).get();
        let values: any[] = [];

        snapshot.then(data => {

            if (data.docs.length === 0) {
                res([]);
            }

            let departments = data.docs;

            departments.sort((a, b) => a.data().name.localeCompare(b.data().name));

            departments.forEach(department => {
                if (!department.data().disabled) {
                    values.push({
                        id: department.id,
                        name: department.data().name,
                        description: department.data().description
                    });
                }
                res(values);
            });
        });

        snapshot.catch(err => {
            rej(err);
        });

    });

    addDepartment = (company: any, department: any) => new Promise(async (res, rej) => {

        let resource = "Companies/" + company + "/Departments";
        let snapshot = firebase.firestore().collection(resource);

        snapshot.add(department).then(data => {
            res(data);
        }).catch(err => {
            rej(err);
        });
    });

    deleteDepartment = (companyId: string, departmentId: string) => new Promise(async (res, rej) => {

        let resource = `Companies/${companyId}/Departments`;
        let snapshot = firebase.firestore().collection(resource).doc(departmentId).delete();

        snapshot.then(() => {
            res(true);
        });

        snapshot.catch(err => {
            rej(err);
        })

    });

    createSharedGame = (companyId: string, departmentId: string, gameId: string, departments: any[]) => new Promise<any | void>(async (res, rej) => {

        let resource = "Companies/" + companyId + "/Departments/" + departmentId + "/Games";
        let shared = {};

        if (departments.length === 0) {
            shared = {
                isHost: false,
                isChild: false,
                hostId: null,
                departments: [],
                loading: true
            }
        } else {
            let departmentArr: string[] = [];
            departments.forEach(item => {
                departmentArr.push(item.id);
            });
            shared = {
                isHost: true,
                isChild: false,
                hostId: null,
                departments: departmentArr,
                loading: true
            }
        }

        //This update will trigger a cloud function that checks if child games needs to be updated and updates them if needed
        let snapshot = firebase.firestore().collection(resource).doc(gameId).update({
            shared: shared
        });

        await snapshot;
        snapshot.catch((err) => rej(err));
    });

    createShareAchievement = (companyId: string, departmentId: string, achievementId: string, departments: any[]) => new Promise<any | void | void>(async (res, rej) => {

        let resource = "Companies/" + companyId + "/Departments/" + departmentId + "/Achievements";
        let shared = {};

        if (departments.length === 0) {
            shared = {
                isHost: false,
                isChild: false,
                hostId: null,
                departments: [],
                loading: true
            }
        } else {
            let departmentArr: string[] = [];
            departments.forEach(item => {
                departmentArr.push(item.id);
            });
            shared = {
                isHost: true,
                isChild: false,
                hostId: null,
                departments: departmentArr,
                loading: true
            }
        }

        //This update will trigger a cloud function that checks if child games needs to be updated and updates them if needed
        let snapshot = firebase.firestore().collection(resource).doc(achievementId).update({
            shared: shared
        });

        await snapshot;
        snapshot.catch((err) => rej(err));
    });

    createSharedTopic = (companyId: string, departmentId: string, topicId: string, departments: any[]) => new Promise<any>(async (res, rej) => {

        let resource = "Companies/" + companyId + "/Departments/" + departmentId + "/TrainingMaterial";
        let shared = {};

        if (departments.length === 0) {
            shared = {
                isHost: false,
                isChild: false,
                hostId: null,
                departments: [],
                loading: true
            }
        } else {
            let departmentArr: string[] = [];
            departments.forEach(item => {
                departmentArr.push(item.id);
            });
            shared = {
                isHost: true,
                isChild: false,
                hostId: null,
                departments: departmentArr,
                loading: true
            }
        }

        //This update will trigger a cloud function that checks if child games needs to be updated and updates them if needed
        let snapshot = firebase.firestore().collection(resource).doc(topicId).update({
            shared: shared
        });

        await snapshot;
        snapshot.catch((err) => rej(err));
    });


    getGame = (companyId: string, departmentId: string, gameId: string) => new Promise<Game>(async (res, rej) => {

        let resource = "Companies/" + companyId + "/Departments/" + departmentId + "/Games";
        let snapshot = firebase.firestore().collection(resource).doc(gameId).get();

        snapshot.then(doc => {

            let gameDoc = doc.data() as Game;
            gameDoc = {
                ...gameDoc,
                companyId: companyId,
                departmentId: departmentId
            }
            res(gameDoc);
        });
        snapshot.catch(err => {
            rej(err);
        });


    });

    getGameScheduleState = (companyId: string, departmentId: string, gameId: string) => new Promise<GameScheduleState>(async (res, rej) => {

        let gameResource = `Companies/${companyId}/Departments/${departmentId}/Games`;
        let categoryResource = `Companies/${companyId}/Departments/${departmentId}/Games/${gameId}/Categories`;
        let gameState = GameScheduleState.EMPTY;

        try {
            let game = await (await firebase.firestore().collection(gameResource).doc(gameId).get()).data();

            if (game === undefined) {
                throw new Error('Game not found!');
            } else {
                if (game.shared !== undefined) {
                    if (game.shared.isHost) {
                        gameState = GameScheduleState.CAN_SCHEDULE;
                    } else if (game.shared.isChild) {
                        gameState = GameScheduleState.IS_CHILD;
                        res(gameState);
                        return;
                    } else if (game.shared.isChild === false && game.shared.isHost === false) {
                        gameState = GameScheduleState.CAN_SCHEDULE;
                    } else {
                        throw new Error("Something went wrong");
                    }
                } else {
                    //game is not a child or a host game.
                    gameState = GameScheduleState.CAN_SCHEDULE;
                }
            }

            let categoriesSnapshot = await firebase.firestore().collection(categoryResource).get();

            if (categoriesSnapshot.docs.length === 0) {
                gameState = GameScheduleState.EMPTY;
                res(gameState);
                return;
            }

            let categories: any[] = categoriesSnapshot.docs.map(item => {
                return {
                    ...item.data(),
                    id: item.id
                }
            });

            //Check if any category has question in them
            for (let i = 0; i < categories.length; i++) {
                const category = categories[i];
                if (category.questions !== undefined) {
                    res(GameScheduleState.CAN_SCHEDULE);
                    return;
                }
            }


        } catch (error) {
            rej(error);
        }

    });

    gameIsEmpty = (company: string, department: string, gameId: string) => new Promise<boolean>(async (res, rej) => {

        let resource = "Companies/" + company + "/Departments/" + department + "/Games/" + gameId + "/Categories";
        let isEmpty = true;

        let snapshot = firebase.firestore().collection(resource).get();

        snapshot.then(docs => {

            docs.forEach(doc => {
                let category = doc.data();

                if (category.questions) {
                    isEmpty = false;
                }
            });

            res(isEmpty);
        });

        snapshot.catch(err => rej(err));

    });

    getGamesFD = (company: any, department: any) => new Promise<any[]>(async (res, rej) => {

        let resource = "Companies/" + company + "/Departments/" + department + "/Games";
        let snapshot = firebase.firestore().collection(resource).get();
        let values: any[] = [];

        snapshot.then(data => {

            let games = data.docs;

            let defaultSharedOb = {
                isHost: false,
                isChild: false,
                departments: [],
                hostId: null,
                loading: false
            }

            games.forEach(game => {
                let singleGame = game.data() as Game;
                let shared = singleGame.shared || defaultSharedOb;

                values.push({
                    ...singleGame,
                    id: game.id,
                    shared: shared
                });
            });

            res(values);

        }).catch(err => {

            rej(err);

        });

    });

    checkGameSharedIntegrity = (user: LoggedUser | undefined, companyID: string, departmentID: string, games: any[]) => new Promise<any[]>(async (res, rej) => {

        /*
            Purpose:
                Ensure that the game is still valid in itself as well as in its sharing capacity

            Methodology:
                For each of the games, check whether each of their shared department IDs are still
                valid and remove the invalid IDs. If there are no more department IDs, update the shared
                object to the default state unless it's a child shared object (which shouldn't be the case,
                but in this application you never know). If it's a child game, then delete it.

            Note:
                It was found that some of the games was shared with departments that no longer
                exist. As such, they are still indicated as shared objects even though it couldn't be
                established with which department.
         */

        let result: any[] = [];
        let updateList: any[] = [];
        let deleteList: any[] = [];
        let departmentDocs: any[] = [];

        // Get all the existing department IDs for the given company
        if (Number(user?.role) === UserRoles.admin || Number(user?.role) === UserRoles.comp_manager) {
            let departmentCollection = await firebase.firestore().collection(`Companies/${companyID}/Departments/`).get();
            departmentDocs.push(...departmentCollection.docs);
        } else {
            let departmentdoc = await firebase.firestore().collection(`Companies/${companyID}/Departments/`).doc(user?.department).get();
            departmentDocs.push(departmentdoc);
        }

        let validDepartmentIDs = departmentDocs.map((doc: any) => doc.id);

        // Get all the games for the given company
        let allCompanyGames: any[] = [];

        for (let d = 0; d < validDepartmentIDs.length; d++) {
            const deptId = validDepartmentIDs[d];

            let gameCollection = await firebase.firestore().collection(`Companies/${companyID}/Departments/${deptId}/Games`).get();
            let gameDocs = gameCollection.docs;

            for (let g = 0; g < gameDocs.length; g++) {
                const gameDoc = gameDocs[g];

                allCompanyGames.push({
                    id: gameDoc.id,
                    departmentID: deptId,
                    ...(gameDoc.data() as Game)
                });
            }
        }

        if (games && games.length > 0) {
            for (let g = 0; g < games.length; g++) {

                let game = games[g];
                let sharedObj = game.shared;

                // Check for and remove the duplicates
                for (let ng = g + 1; ng < games.length; ng++) {
                    const nextGame = games[ng];

                    if (game.name == nextGame.name && CompareSharedObjects(game.shared, nextGame.shared)) {
                        deleteList.push(nextGame);
                        games.splice(ng--, 1);
                    }
                }

                if (!sharedObj) {
                    // This game is not shared with or from any departments
                    result.push(game);
                    continue;
                }

                if (sharedObj.isChild) {

                    // This is a child game, check if it's still shared from its host
                    let hostGame = allCompanyGames.find(g => g.id == sharedObj.hostId && !g.shared?.isChild);

                    if (!hostGame) {

                        // Host game is not found, delete this child game
                        deleteList.push(game);
                        continue;

                    } else if (!hostGame.shared?.departments?.find((id: string) => id == departmentID)) {

                        // This child game is not shared from its host anymore, delete it
                        deleteList.push(game);
                        continue;

                    } else {

                        result.push(game);

                    }

                } else {

                    // This is a host game, validate its shared department IDs
                    let newDeptList = sharedObj.departments.filter((d: string) => validDepartmentIDs.some(id => d === id));

                    // Check if the game is still shared to each of the valid department IDs
                    for (let nd = 0; nd < newDeptList.length; nd++) {
                        const sharedDeptId = newDeptList[nd];

                        let childGame = allCompanyGames.find(ag => ag.departmentID == sharedDeptId && ag.shared?.hostId == game.id)

                        if (!childGame) {
                            // This game is not shared to the specific department anymore
                            newDeptList.splice(nd--, 1);
                        }
                    }

                    // Compare the validated shared department IDs to the original
                    if (JSON.stringify(newDeptList.sort((a: string, b: string) => a > b ? 1 : -1)) == JSON.stringify(sharedObj.departments.sort((a: string, b: string) => a > b ? 1 : -1))) {

                        result.push(game);

                    } else {

                        if (newDeptList.length > 0) {

                            // Update the shared department IDs with the validated IDs
                            sharedObj.departments = newDeptList;
                            updateList.push(game);
                            result.push(game);

                        } else {

                            // This game is no longer shared, update to default state
                            game.shared = {
                                isHost: false,
                                isChild: false,
                                departments: [],
                                hostId: null,
                                loading: false
                            };

                            updateList.push(game);
                            result.push(game);

                        }
                    }
                }

            }
        }

        if (updateList.length > 0) {
            // Update the games
            updateList.forEach(game => {

                let path = `Companies/${companyID}/Departments/${departmentID}/Games`;

                firebase.firestore().collection(path).doc(game.id)
                    .update({
                        "shared": game.shared
                    })
                    .catch(err => {
                        console.log(err);
                    })

            })
        }

        if (deleteList.length > 0) {
            // Delete the games
            deleteList.forEach(game => {

                const deleteGame = firebase.functions().httpsCallable('deleteGame');

                deleteGame({
                    companyId: companyID,
                    departmentId: departmentID,
                    gameId: game.id
                });

            })
        }

        res(result);

    });
    
    getAllGames = (companyId: any, departmentId: any) => new Promise<any>(async (res, rej) => {

        try {

            let allGames: any[] = [];
            let companyDocs: any[] = [];
            let departmentDocs: any[] = [];

            
            if (companyId?.length > 0) {
                let companyDoc = await firebase.firestore().collection("Companies/").doc(companyId).get();
                companyDocs.push(companyDoc);
            }
            else {
                let companyCollection = await firebase.firestore().collection("Companies/").get();
                companyDocs.push(...companyCollection.docs);
            }

            companyDocs.forEach(async (companyDoc, i) => {

                let company = companyDoc.data();
                
                if (departmentId?.length > 0) {
                    let departmentdoc = await firebase.firestore().collection(`Companies/${companyDoc.id}/Departments/`).doc(departmentId).get();
                    departmentDocs.push(departmentdoc);
                } else {
                    let departmentCollection = await firebase.firestore().collection(`Companies/${companyDoc.id}/Departments/`).get();
                    departmentDocs.push(...departmentCollection.docs);
                }

                departmentDocs.forEach(async (departmentDoc, d) => {

                    let department = departmentDoc.data();
                    let gameCollection = await firebase.firestore().collection(`Companies/${companyDoc.id}/Departments/${departmentDoc.id}/Games/`).get();
                    let gameDocs = gameCollection.docs;
    
                    gameDocs.forEach(gameDoc => {
    
                        let game = gameDoc.data();
    
                        allGames.push({
                            gameId: gameDoc.id,
                            companyId: companyDoc.id || "unknown",
                            companyName: company?.name || "unknown",
                            departmentId: departmentDoc.id || "unknown",
                            departmentName: department.name || "unknown",
                            name: game?.name || "unknown",
                            maximumCorrect: isNaN(game?.maximumCorrect) ? "unknown" : game.maximumCorrect,
                        });
    
                    });
    
                    // Only resolve this promise when both users and managers are completed
                    if ((i + 1) >= companyDocs.length && (d + 1) >= departmentDocs.length) {
                        res(allGames);
                    }
                });
            });

        } catch (error) {
            console.log(`${new Date().toLocaleTimeString()} ERROR FOR getAllGames: `, error);
            rej(error)
        }
    });
    
    addGame = (company: any, department: any, game: any) => new Promise(async (res, rej) => {

        let resource = "Companies/" + company + "/Departments/" + department + "/Games";
        let snapshot = firebase.firestore().collection(resource);

        if (game.isExam) {
            snapshot.add(game).then(data => {

                let resource = "Companies/" + company + "/Departments/" + department + "/TrainingMaterial";

                firebase.firestore().collection(resource).doc(game.learningTopic.id).update({
                    gameExamId: data.id
                }).then(() => {
                    res(data);
                }).catch(err => {
                    rej(err);
                });

            }).catch(err => {
                rej(err);
            });
        } else {
            snapshot.add(game).then(data => {
                res(data);
            }).catch(err => {
                rej(err);
            });
        }
    });

    editGame = (company: any, department: any, game: any, gameId: any, previousLearningCenter?: any) => new Promise(async (res, rej) => {

        let gameResource = "Companies/" + company + "/Departments/" + department + "/Games";
        let snapshot = firebase.firestore().collection(gameResource).doc(gameId);

        try {
            let resource = "Companies/" + company + "/Departments/" + department + "/TrainingMaterial";
            await snapshot.update(game);

            if (game.isExam) {
                await firebase.firestore().collection(resource).doc(game.learningTopic.id).update({
                    gameExamId: gameId
                });
            };

            if (previousLearningCenter) {
                await firebase.firestore().collection(resource).doc(previousLearningCenter.id).update({
                    gameExamId: null
                });
            }
        } catch (error) {
            console.log("error: ", error);
            rej(error);
        }

        res("successfully updated");
    });

    resetGameMaxScore = (companyId: any, departmentId: any, gameId: any) => new Promise<any>(async (res, rej) => {

        /*  Business logic:
            The max score (maximumCorrect) represents the number of questions the user can possible get right, but the
            questions are limited to 5 questions per category. So the max score is calculated as:

            MaxScore = No. of game categories * 5
        */

        let calculatedMaxScore = 0;
        let gamePath = `Companies/${companyId}/Departments/${departmentId}/Games`;

        try {
            const gameDoc = await firebase.firestore().collection(gamePath).doc(gameId).get();
            const gameData = gameDoc.data();
            const categoriesCollection = await firebase.firestore().collection(`${gamePath}/${gameId}/Categories/`).get();

            for (let c = 0; c < categoriesCollection.docs.length; c++) {
                const categoryDoc = categoriesCollection.docs[c];
                const categoryData = categoryDoc.data();
                const numQuestions = Object.keys(categoryData?.questions || {}).length;

                if (numQuestions > 0) {
                    // Only count a max of 5 questions per category
                    calculatedMaxScore += Math.min(5, numQuestions);
                }
            }

            if (gameData?.maximumCorrect !== calculatedMaxScore) {
                let result = await firebase.firestore().collection(gamePath).doc(gameId)
                .update({
                    "maximumCorrect": calculatedMaxScore
                })
                .catch(err => {
                    console.log(err);
                    rej({
                        hasFailed: true,
                        error: err
                    });
                })

                res({
                    hasFailed: false,
                    hasUpdated: true,
                    updatedScore: calculatedMaxScore
                })
            } else {
                res({
                    hasFailed: false,
                    hasUpdated: false
                });
            }

        } catch (error) {
            console.log("error: ", error);
            rej({
                hasFailed: true,
                error: error
            });
        }
    });

    getCategory = (company: any, department: any, game: any, categoryID: string) => new Promise<any>(async (res, rej) => {
        let resource = "Companies/" + company + "/Departments/" + department + "/Games/" + game + "/Categories";
        let snapshot = firebase.firestore().collection(resource).doc(categoryID).get();

        snapshot.then(data => {
            res(data.data());
        });

        snapshot.catch(err => {
            rej(err)
        });
    })

    updateCategory = (company: any, department: any, game: any, categoryID: string, data: any) => new Promise<any>(async (res, rej) => {
        let resource = "Companies/" + company + "/Departments/" + department + "/Games/" + game + "/Categories";
        let snapshot = firebase.firestore().collection(resource).doc(categoryID).update({
            name: data.name,
            description: data.description,
            subtitle: data.subtitle,
            order: data.order ? data.order : 0,
            passRate: data.passRate ? data.passRate : '0',
            mustWatchVideo: data.mustWatchVideo === undefined ? false : data.mustWatchVideo,
            media: data.media ? data.media : []
        });

        snapshot.then(data => {
            res(true);
        })

        snapshot.catch(err => {
            rej(err)
        })
    });

    deleteCategory = (company: any, department: any, game: any, categoryID: string) => new Promise<any>(async (res, rej) => {
        let resource = "Companies/" + company + "/Departments/" + department + "/Games/" + game + "/Categories";
        let snapshot = firebase.firestore().collection(resource).doc(categoryID).delete();

        snapshot.then(() => {
            res(true);
        });

        snapshot.catch(err => {
            rej(err);
        })
    });

    getCategoriesFD = (company: any, department: any, game: any) => new Promise<any[]>(async (res, rej) => {
        let resource = "Companies/" + company + "/Departments/" + department + "/Games/" + game + "/Categories";

        let snapshot = firebase.firestore().collection(resource).get();
        let values: any[] = [];

        snapshot.then(data => {
            try {

                let categories = data.docs;

                categories.forEach(category => {
                    var singleCategory = category.data();
                    values.push({
                        id: category.id,
                        name: singleCategory.name,
                        subtitle: singleCategory.subtitle,
                        description: singleCategory.description,
                        imageRef: singleCategory.imageRef,
                        order: singleCategory.order,
                        mustWatchVideo: singleCategory.mustWatchVideo
                    });

                    res(values);
                });
            } catch (error) {
                rej(error);
            }

        });

        snapshot.catch(err => {
            rej(err);
        });
    });

    addCategory = (company: any, department: any, game: any, category: any) => new Promise(async (res, rej) => {

        let resource = "Companies/" + company + "/Departments/" + department + "/Games/" + game + "/Categories";
        let snapshot = firebase.firestore().collection(resource);

        snapshot.add(category).then(data => {
            res(data);
        }).catch(err => {
            rej(err);
        });
    });

    addBulkQuestions = (company: any, department: any, game: any, categoryId: any, questions: any[]) => new Promise(async (res, rej) => {

        try {
            let db = firebase.firestore();

            let resource = "Companies/" + company + "/Departments/" + department + "/Games/" + game + "/Categories";
            let snapshot = db.collection(resource).doc(categoryId).get();

            snapshot.then(category => {

                let document = category.data();

                if (document) {
                    let questionArr = document.questions ? Object.values(document.questions) : [];
                    let mergedArr = questionArr.concat(questions);
                    let AddQuestions = db.collection(resource).doc(categoryId).update({
                        questions: { ...mergedArr }
                    });

                    AddQuestions.then(data => {
                        res(mergedArr);
                    });
                } else {
                    res(null);
                }

            });
        } catch (error) {
            rej(error);
        }
    });

    addQuestions = (company: any, department: any, game: any, categoryId: any, question: any) => new Promise<any>(async (res, rej) => {


        let db = firebase.firestore();

        let resource = "Companies/" + company + "/Departments/" + department + "/Games/" + game + "/Categories";
        let snapshot = db.collection(resource).doc(categoryId).get();

        snapshot.then((category) => {

            let document = category.data();

            if (document) {
                //get question object
                let questions = document.questions;

                let keyArray: any[] = [];
                let lastKey: any = "0";
                var newKey: number = 0;

                try {
                    //get key values to retrieve last index of object
                    keyArray = Object.keys(questions);
                    if (keyArray.length === 0) {
                        newKey = 0;
                    } else {
                        lastKey = keyArray.pop() || "0";
                        newKey = parseInt(lastKey) + 1;
                    }
                } catch (error) {
                    newKey = 0;
                }

                //Add new object to old object
                let insertQuestions = {
                    ...questions,
                    [newKey]: question
                }

                //Insert concatenated object
                let AddQuestion = db.collection(resource).doc(categoryId).update({
                    questions: insertQuestions
                });

                AddQuestion.then(response => {
                    res(response);
                }).catch(err => rej(err));

            }
        })

    });

    removeQuestion = (company: any, department: any, game: any, categoryId: any, questionID: any) => new Promise<any>(async (res, rej) => {

        let db = firebase.firestore();

        let resource = "Companies/" + company + "/Departments/" + department + "/Games/" + game + "/Categories";
        let snapshot = db.collection(resource).doc(categoryId).get();

        snapshot.then(data => {

            let category = data.data();

            //@ts-ignore
            let questions = category.questions;
            console.log("Deletion data", questions);

            var result = Object.keys(questions).map(key => questions[key]);
            result.splice(questionID, 1);

            console.log("Spliced array", result);
            const objResult = { ...result };
            console.log("Object array", { ...result });

            db.collection(resource).doc(categoryId).update({
                questions: objResult
            }).then(() => {
                res(true)
            }).catch(err => {
                console.log(err);
                rej(err)
            })
        });
    })


    addImage = (image: any, path: any) => new Promise<imageReturn>(async (res, rej) => {

        var storageRef = firebase.storage().ref();
        var fileName = uuidv4();
        var fullPath = path + fileName;
        var file = image;

        var uploadTask = storageRef.child(fullPath).put(file);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function (snapshot) {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                //var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                //console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function (error: any) {
                rej(error);
            }, function () {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    console.log('File available at', downloadURL);
                    res({ imgUrl: downloadURL, imgPath: fullPath });
                });
            });
    });

    addPdf = (pdf: any, path: any) => new Promise<fileReturn>(async (res, rej) => {

        var storageRef = firebase.storage().ref();
        var fileName = uuidv4();
        var fullPath = path + fileName + ".pdf";
        var file = pdf;

        var uploadTask = storageRef.child(fullPath).put(file);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function (snapshot) {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                //var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                //console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function (error: any) {
                rej(error);
            }, function () {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    console.log('File available at', downloadURL);
                    res({ fileUrl: downloadURL, filePath: fullPath });
                });
            });
    });

    processVideo = (media: any) => new Promise<any[]>(async (res, rej) => {
        let addedvideo = firebase.functions().httpsCallable('processAzureVideo');
        addedvideo(media).then((data) => {
            res([data]);
        }).catch(err => {
            console.log(err);
            rej(err);
        });
    });

    getQuestions = (company: any, department: any, game: any, category: any) => new Promise<any[]>(async (res, rej) => {

        let db = firebase.firestore();

        let resource = "Companies/" + company + "/Departments/" + department + "/Games/" + game + "/Categories";

        try {
            let snapshot = db.collection(resource).doc(category).get();

            snapshot.then(data => {
                try {
                    //@ts-ignore
                    let questions = data.data().questions;

                    if (questions) {
                        let questionList: any[] = Object.values(questions);
                        res(questionList);
                    } else {
                        res([]);
                    }
                } catch (error) {
                    rej(error);
                }
            });

            snapshot.catch(err => {
                console.log(err);
                rej(err)
            })
        } catch (error) {
            rej(error);
        }

    });

    updateQuestion = (company: any, department: any, game: any, category: any, question: any) => new Promise<any[]>(async (res, rej) => {

        let db = firebase.firestore();

        let resource = "Companies/" + company + "/Departments/" + department + "/Games/" + game + "/Categories";
        let dotNotation = "questions." + question.id;

        let snapshot = db.collection(resource).doc(category).update({
            [dotNotation]: question
        });

        await snapshot;

    });

    updateAchievement = (company: any, department: any, achievementID: any, data: any) => new Promise<any>(async (res, rej) => {

        let resource = "Companies/" + company + "/Departments/" + department + '/Achievements';
        let snapshot = firebase.firestore().collection(resource).doc(achievementID).update({
            name: data.name,
            description: data.description,
            imgUrl: data.imgUrl,
            imgPath: data.imgPath
        });


        snapshot.then(() => {
            res(true);
        });

        snapshot.catch(err => {
            rej(err);
        })

    })

    getAchievement = (company: any, department: any, achievementID: any) => new Promise<any>(async (res, rej) => {

        let resource = "Companies/" + company + "/Departments/" + department + '/Achievements';
        let snapshot = firebase.firestore().collection(resource).doc(achievementID).get();

        snapshot.then(document => {
            let achievement = document.data();

            res(achievement);
        })
    })


    addAchievement = (company: any, department: any, achievement: any) => new Promise(async (res, rej) => {

        let resource = "Companies/" + company + "/Departments/" + department + '/Achievements';
        let snapshot = firebase.firestore().collection(resource);

        snapshot.add(achievement).then(data => {
            res(data);
        }).catch(err => {
            rej(err);
        });
    });

    getAchievements = (company: any, department: any) => new Promise<any[]>(async (res, rej) => {

        let db = firebase.firestore();


        let resource = "Companies/" + company + "/Departments/" + department + "/Achievements";
        let snapshot = db.collection(resource).get();

        snapshot.then(collection => {
            let docs = collection.docs;
            let result: any[] = [];

            docs.forEach(achievement => {
                let item = achievement.data();
                result.push({ ...item, id: achievement.id, companyID: company, departmentID: department });
            });

            res(result);
        })

    });

    checkAchievementSharedIntegrity = (user: LoggedUser | undefined, companyID: string, departmentID: string, achievements: any[]) => new Promise<any[]>(async (res, rej) => {

        /*
            Purpose:
                Ensure that the achievement is still valid in itself as well as in its sharing capacity

            Methodology:
                For each of the achievements, check whether each of their shared department IDs are still
                valid and remove the invalid IDs. If there are no more department IDs, update the shared
                object to the default state unless it's a child shared object (which shouldn't be the case,
                but in this application you never know). If it's a child achievement, then delete it.

            Note:
                It was found that some of the achievements was shared with departments that no longer
                exist. As such, they are still indicated as shared objects even though it couldn't be
                established with which department.
         */

        let result: any[] = [];
        let updateList: any[] = [];
        let deleteList: any[] = [];
        let departmentDocs: any[] = [];

        // Get all the existing department IDs for the given company
        if (Number(user?.role) === UserRoles.admin || Number(user?.role) === UserRoles.comp_manager) {
            let departmentCollection = await firebase.firestore().collection(`Companies/${companyID}/Departments/`).get();
            departmentDocs.push(...departmentCollection.docs);
        } else {
            let departmentdoc = await firebase.firestore().collection(`Companies/${companyID}/Departments/`).doc(user?.department).get();
            departmentDocs.push(departmentdoc);
        }

        let validDepartmentIDs = departmentDocs.map((doc: any) => doc.id);

        // Get all the achievements for the given company
        let allCompanyAchievements: any[] = [];

        for (let d = 0; d < validDepartmentIDs.length; d++) {
            const deptId = validDepartmentIDs[d];

            let achievementCollection = await firebase.firestore().collection(`Companies/${companyID}/Departments/${deptId}/Achievements`).get();
            let achievementDocs = achievementCollection.docs;

            for (let a = 0; a < achievementDocs.length; a++) {
                const achievementDoc = achievementDocs[a];

                allCompanyAchievements.push({
                    id: achievementDoc.id,
                    departmentID: deptId,
                    ...achievementDoc.data()
                });
            }
        }

        if (achievements && achievements.length > 0) {
            for (let a = 0; a < achievements.length; a++) {

                let achievement = achievements[a];
                let sharedObj = achievement.shared;

                // Check for and remove the duplicates
                for (let na = a + 1; na < achievements.length; na++) {
                    const nextAchievement = achievements[na];

                    if (achievement.name == nextAchievement.name && CompareSharedObjects(achievement.shared, nextAchievement.shared)) {
                        deleteList.push(nextAchievement);
                        achievement.splice(na--, 1);
                    }
                }

                if (!sharedObj) {
                    // This game is not shared with or from any departments
                    result.push(achievement);
                    continue;
                }

                if (sharedObj.isChild) {

                    // This is a child achievement, check if it's still shared from its host
                    let hostAchievement = allCompanyAchievements.find(a => a.id == sharedObj.hostId && !a.shared?.isChild);

                    if (!hostAchievement) {

                        // Host achievement is not found, delete this child achievement
                        deleteList.push(achievement);
                        continue;

                    } else if (!hostAchievement.shared?.departments?.find((id: string) => id == departmentID)) {

                        // This child achievement is not shared from its host anymore, delete it
                        deleteList.push(achievement);
                        continue;

                    } else {

                        result.push(achievement);

                    }

                } else {

                    // This is a host achievement, validate its shared department IDs
                    let newDeptList = sharedObj.departments.filter((d: string) => validDepartmentIDs.some(id => d === id));

                    // Check if the achievement is still shared to each of the valid department IDs
                    for (let nd = 0; nd < newDeptList.length; nd++) {
                        const sharedDeptId = newDeptList[nd];

                        let childAchievement = allCompanyAchievements.find(aa => aa.departmentID == sharedDeptId && aa.shared?.hostId == achievement.id)

                        if (!childAchievement) {
                            // This achievement is not shared to the specific department anymore
                            newDeptList.splice(nd--, 1);
                        }
                    }

                    // Compare the validated shared department IDs to the original
                    if (JSON.stringify(newDeptList.sort((a: string, b: string) => a > b ? 1 : -1)) == JSON.stringify(sharedObj.departments.sort((a: string, b: string) => a > b ? 1 : -1))) {

                        result.push(achievement);

                    } else {

                        if (newDeptList.length > 0) {

                            // Update the shared department IDs with the validated IDs
                            sharedObj.departments = newDeptList;
                            updateList.push(achievement);
                            result.push(achievement);

                        } else {

                            // This achievement is no longer shared, update to default state
                            achievement.shared = {
                                isHost: false,
                                isChild: false,
                                departments: [],
                                hostId: null,
                                loading: false
                            };

                            updateList.push(achievement);
                            result.push(achievement);

                        }
                    }

                }

            }
        }

        if (updateList.length > 0) {
            // Update the achievements
            updateList.forEach(achievement => {

                let path = `Companies/${companyID}/Departments/${departmentID}/Achievements`;

                firebase.firestore().collection(path).doc(achievement.id)
                    .update({
                        "shared": achievement.shared
                    })
                    .catch(err => {
                        console.log(err);
                    })

            })
        }

        if (deleteList.length > 0) {
            // Delete the achievements
            deleteList.forEach(achievement => {

                const deleteAchievement = firebase.functions().httpsCallable('deleteAchievement');

                deleteAchievement({
                    companyId: companyID,
                    departmentId: departmentID,
                    achievementId: achievement.id
                });

            })
        }

        res(result);

    });

    getAchievementsFD = (company: any, department: any) => new Promise<any[]>(async (res, rej) => {

        let resource = "Companies/" + company + "/Departments/" + department + "/Achievements";
        let snapshot = firebase.firestore().collection(resource).get();
        let values: any[] = [];

        snapshot.then(data => {

            let achievements = data.docs;

            let defaultSharedOb = {
                isHost: false,
                isChild: false,
                departments: [],
                hostId: null,
                loading: false
            }

            achievements.sort((a, b) => a.data().name.localeCompare(b.data().name));

            achievements.forEach(achievement => {
                let singleAchievement = achievement.data();
                let shared = singleAchievement.shared ? singleAchievement.shared : defaultSharedOb;

                values.push({
                    id: achievement.id,
                    company: singleAchievement.company,
                    name: singleAchievement.name,
                    department: singleAchievement.department,
                    description: singleAchievement.description,
                    endDate: singleAchievement.endDate,
                    imgUrl: singleAchievement.imgUrl,
                    isMonetary: singleAchievement.isMonetary,
                    isTiered: singleAchievement.isTiered,
                    shared: shared
                });
            });

            res(values);

        }).catch(err => {

            rej(err);

        });

    });

    getManager = (uid: string) => new Promise<any>(async (res, rej) => {
        let db = firebase.firestore();
        let resource = "Managers";

        let snapshot = db.collection(resource).doc(uid).get();

        snapshot.then((document) => {
            let manager = document.data();

            res(manager);
        });

        snapshot.catch(err => {
            rej(err);
        });
    });



    addSchedule = (company: any, department: any, game: any, startDate: any, endDate: any, scheduleType: ScheduleTypes, usersSelected: any[] = []) => new Promise<any>(async (res, rej) => {
        let db = firebase.firestore();
        let resource = "Companies/" + company + "/Departments/" + department + "/Games/";

        let end: Date = new Date(endDate);
        end.setHours(end.getHours() + 21);

        if (scheduleType === ScheduleTypes.department) {

            let snapshot = db.collection(resource).doc(game).update({
                schedules: firebase.firestore.FieldValue.arrayUnion({
                    id: uuidv4(),
                    startDate: startDate,
                    endDate: end,
                    scheduleType: scheduleType,
                })
            });

            snapshot.then(() => {
                res(true);
            });

            snapshot.catch(err => {
                rej(err);
            });
        } else if (scheduleType === ScheduleTypes.individual) {

            let idArray: any[] = [];

            if (usersSelected.length === 0) {
                rej("Cannot create a schedule without adding users to it");
            }

            usersSelected.forEach(item => {
                idArray.push(item.id);
            });

            let snapshot = db.collection(resource).doc(game).update({
                schedules: firebase.firestore.FieldValue.arrayUnion({
                    id: uuidv4(),
                    startDate: startDate,
                    endDate: endDate,
                    scheduleType: scheduleType,
                    users: idArray
                })
            });

            snapshot.then(() => {
                res(true);
            });

            snapshot.catch(err => {
                rej(err);
            });
        }


    });

    deleteSchedule = (company: any, department: any, gameId: string, scheduleId: string) => new Promise<any>(async (res, rej) => {

        let db = firebase.firestore();
        let resource = "Companies/" + company + "/Departments/" + department + "/Games";

        let snapshot = db.collection(resource).doc(gameId).get();

        snapshot.then(game => {
            let gameObject = game.data();

            //@ts-ignore
            let schedulesArr: any[] = gameObject.schedules;

            db.collection(resource).doc(gameId).update({
                schedules: schedulesArr.filter(item => item.id !== scheduleId)
            }).then(() => {
                res(true);
            }).catch(err => rej(err))
        })

    })

    getSchedules = (company: any, department: any) => new Promise<any[]>(async (res, rej) => {
        let db = firebase.firestore();
        let resource = "Companies/" + company + "/Departments/" + department + "/Games";

        let snapshot = db.collection(resource).get();
        let events: any[] = [];


        snapshot.then(collection => {

            let documents = collection.docs;

            documents.forEach(doc => {
                let game = doc.data();
                let schedules: any[] = doc.data().schedules;
                console.log("Schedules: ", schedules);


                if (schedules) {
                    schedules.forEach(schedule => {

                        events.push({
                            title: game?.name,
                            subTitle: game.subtitle,
                            start: moment(schedule.startDate.toDate()).toDate(),
                            end: moment(schedule.endDate.toDate()).toDate(),
                            allDay: true,
                            id: schedule.id,
                            gameId: doc.id,
                            companyId: company,
                            departmentId: department,
                            scheduleType: schedule.scheduleType
                        });
                    });
                }
            });

            res(events);
        });
        snapshot.catch(err => console.log(err));
    });

    checkTopicSharedIntegrity = (user: LoggedUser | undefined, companyID: string, departmentID: string, topics: any[]) => new Promise<any[]>(async (res, rej) => {

        /*
            Purpose:
                Ensure that the topic is still valid in itself as well as in its sharing capacity

            Methodology:
                For each of the topics, check whether each of their shared department IDs are still
                valid and remove the invalid IDs. If there are no more department IDs, update the shared
                object to the default state unless it's a child shared object (which shouldn't be the case,
                but in this application you never know). If it's a child topic, then delete it.

            Note:
                It was found that some of the topics was shared with departments that no longer
                exist. As such, they are still indicated as shared objects even though it couldn't be
                established with which department.
         */

        let result: any[] = [];
        let updateList: any[] = [];
        let deleteList: any[] = [];
        let departmentDocs: any[] = [];

        // Get all the existing department IDs for the given company
        if (Number(user?.role) === UserRoles.admin || Number(user?.role) === UserRoles.comp_manager) {
            let departmentCollection = await firebase.firestore().collection(`Companies/${companyID}/Departments/`).get();
            departmentDocs.push(...departmentCollection.docs);
        } else {
            let departmentdoc = await firebase.firestore().collection(`Companies/${companyID}/Departments/`).doc(user?.department).get();
            departmentDocs.push(departmentdoc);
        }

        let validDepartmentIDs = departmentDocs.map((doc: any) => doc.id);

        // Get all the topics for the given company
        let allCompanyTopics: any[] = [];

        for (let d = 0; d < validDepartmentIDs.length; d++) {
            const deptId = validDepartmentIDs[d];

            let topicCollection = await firebase.firestore().collection(`Companies/${companyID}/Departments/${deptId}/TrainingMaterial`).get();
            let topicDocs = topicCollection.docs;

            for (let t = 0; t < topicDocs.length; t++) {
                const topicDoc = topicDocs[t];

                allCompanyTopics.push({
                    id: topicDoc.id,
                    departmentID: deptId,
                    ...topicDoc.data()
                });
            }
        }

        if (topics && topics.length > 0) {
            for (let t = 0; t < topics.length; t++) {

                let topic = topics[t];
                let sharedObj = topic.shared;

                // Check for and remove the duplicates
                for (let nt = t + 1; nt < topics.length; nt++) {
                    const nextTopic = topics[nt];

                    if (topic.name == nextTopic.name && CompareSharedObjects(topic.shared, nextTopic.shared)) {
                        deleteList.push(nextTopic);
                        topics.splice(nt--, 1);
                    }
                }

                if (!sharedObj) {
                    // This topic is not shared with or from any departments
                    result.push(topic);
                    continue;
                }

                if (sharedObj.isChild) {

                    // This is a child topic, check if it's still shared from its host
                    let hostTopic = allCompanyTopics.find(at => at.id == sharedObj.hostId && !at.shared?.isChild);

                    if (!hostTopic) {

                        // Host topic is not found, delete this child topic
                        deleteList.push(topic);
                        continue;

                    } else if (!hostTopic.shared?.departments?.find((id: string) => id == departmentID)) {

                        // This child topic is not shared from its host anymore, delete it
                        deleteList.push(topic);
                        continue;

                    } else {

                        result.push(topic);

                    }

                } else {

                    // This is a host topic, validate its shared department IDs
                    let newDeptList = sharedObj.departments.filter((d: string) => validDepartmentIDs.some(id => d === id));

                    // Check if the topic is still shared to each of the valid department IDs
                    for (let nd = 0; nd < newDeptList.length; nd++) {
                        const sharedDeptId = newDeptList[nd];

                        let childTopic = allCompanyTopics.find(at => at.departmentID == sharedDeptId && at.shared?.hostId == topic.id)

                        if (!childTopic) {
                            // This topic is not shared to the specific department anymore
                            newDeptList.splice(nd--, 1);
                        }
                    }

                    // Compare the validated shared department IDs to the original
                    if (JSON.stringify(newDeptList.sort((a: string, b: string) => a > b ? 1 : -1)) == JSON.stringify(sharedObj.departments.sort((a: string, b: string) => a > b ? 1 : -1))) {

                        result.push(topic);

                    } else {

                        if (newDeptList.length > 0) {

                            // Update the shared department IDs with the validated IDs
                            sharedObj.departments = newDeptList;
                            updateList.push(topic);
                            result.push(topic);

                        } else {

                            // This topic is no longer shared, update to default state
                            topic.shared = {
                                isHost: false,
                                isChild: false,
                                departments: [],
                                hostId: null,
                                loading: false
                            };

                            updateList.push(topic);
                            result.push(topic);

                        }
                    }
                }

            }
        }

        if (updateList.length > 0) {
            // Update the topics
            updateList.forEach(topic => {

                let path = `Companies/${companyID}/Departments/${departmentID}/TrainingMaterial`;

                firebase.firestore().collection(path).doc(topic.id)
                    .update({
                        "shared": topic.shared
                    })
                    .catch(err => {
                        console.log(err);
                    })

            })
        }

        if (deleteList.length > 0) {
            // Delete the topics
            deleteList.forEach(topic => {

                const deleteLearningTopic = firebase.functions().httpsCallable('deleteLearningCenterTopic');

                deleteLearningTopic({
                    companyId: companyID,
                    departmentId: departmentID,
                    learningTopicId: topic.id
                });

            })
        }

        res(result);

    });

    getLearningCenterTopics = (company: string, department: string) => new Promise<any[]>(async (res, rej) => {

        let resource = "Companies/" + company + "/Departments/" + department + "/TrainingMaterial";
        let snapshot = firebase.firestore().collection(resource).get();
        let values: any[] = [];

        snapshot.then((documents) => {

            let topicDocs = documents.docs;

            let defaultSharedOb = {
                isHost: false,
                isChild: false,
                departments: [],
                hostId: null,
                loading: false
            }

            topicDocs.forEach(topicDoc => {
                let topic = topicDoc.data();
                let shared = topic.shared || defaultSharedOb;

                values.push({
                    ...topic,
                    id: topicDoc.id,
                    shared: shared
                });
            });

            res(values);

        }).catch(err => {

            rej(err);

        });

    });

    getLearningCenterSubTopic = (company: string, department: string, topicId: string, subTopicId: string) => new Promise<any>(async (res, rej) => {

        try {
            let db = firebase.firestore();
            let resource = "Companies/" + company + "/Departments/" + department + "/TrainingMaterial/" + topicId + "/SubTopics";

            let snapshot = db.collection(resource).doc(subTopicId).get();

            snapshot.then((document) => {
                let data = {
                    ...document.data(),
                    id: document.id
                }

                res(data);
            });

            snapshot.catch(err => {
                rej(err);
            });
        } catch (error) {
            rej(error);
        }

    });

    updateLearningCenterSubTopic = (company: string, department: string, topicId: string, subTopicId: string, subTopic: any) => new Promise<any>(async (res, rej) => {
        let db = firebase.firestore();
        let resource = "Companies/" + company + "/Departments/" + department + "/TrainingMaterial/" + topicId + "/SubTopics";

        console.log("Updating subtopic: ", subTopic);

        let snapshot = db.collection(resource).doc(subTopicId).update({
            title: subTopic.title,
            subTitle: subTopic.subTitle,
            order: subTopic.order,
            media: subTopic.media
        })

        snapshot.then((document) => {
            res(document)
        });

        snapshot.catch(err => {
            rej(err);
        });
    });

    updateLearningCenterTopic = (company: string, department: string, topic: any) => new Promise<any>(async (res, rej) => {
        let db = firebase.firestore();
        let resource = `Companies/${company}/Departments/${department}/TrainingMaterial`;

        let snapshot = db.collection(resource).doc(topic.id).update({
            name: topic.name,
            subtitle: topic.subtitle,
            order: topic.order,
            imgUrl: topic.imgUrl,
            imgPath: topic.imgPath,
            disabled: topic.disabled
        })

        snapshot.then((document) => {
            res(document)
        });

        snapshot.catch(err => {
            rej(err);
        });
    });

    toggleLearningCenterTopic = (companyId: string, departmentId: string, topicId: any, disabled: boolean) => new Promise<any>(async (res, rej) => {

        try {

            let resource = `Companies/${companyId}/Departments/${departmentId}/TrainingMaterial`;

            await firebase.firestore().collection(resource).doc(topicId).update({
                disabled: disabled
            });

            res(true);

        } catch (error) {

            rej(error);

        }

    });

    getLearningCenterSubTopics = (company: string, department: string, topicId: string) => new Promise<any[]>(async (res, rej) => {

        try {
            let db = firebase.firestore();
            let resource = "Companies/" + company + "/Departments/" + department + "/TrainingMaterial/" + topicId + "/SubTopics";
            var subTopics: any[] = [];

            let snapshot = db.collection(resource).get();

            snapshot.then((documents) => {

                documents.forEach(doc => {
                    subTopics.push({ ...doc.data(), id: doc.id });
                });

                res(subTopics);
            });

            snapshot.catch(err => {
                rej(err);
            });
        } catch (error) {
            rej(error);
        }

    });

    AddLearningCenterTopics = (company: string | undefined, department: string | undefined, topic: any) => new Promise<any>(async (res, rej) => {

        if (!(company && department)) {
            rej("Parameters cannot be undefined");
            return;
        }

        try {
            let db = firebase.firestore();
            let resource = `Companies/${company}/Departments/${department}/TrainingMaterial`;

            let savedTopicRef = await db.collection(resource).add(topic);

            res(savedTopicRef.id);

        } catch (error) {
            rej(error)
        }

    });

    addLearningCenterSubTopic = (company: string, department: string, topicId: any, subTopics: any) => new Promise<any>(async (res, rej) => {
        let db = firebase.firestore();
        let resource = "Companies/" + company + "/Departments/" + department + "/TrainingMaterial/" + topicId + "/SubTopics";

        let snapshot = db.collection(resource).add(subTopics);

        snapshot.then(data => {
            res(data);
        });

        snapshot.catch(err => rej(err));
    });

    getAllLearningCenterTopics = () => new Promise<any>(async (res, rej) => {

        try {

            let allTopics: any[] = [];

            let companyCollection = await firebase.firestore().collection("Companies/").get();
            let companyDocs = companyCollection.docs;

            companyDocs.forEach(async (companyDoc, c) => {

                let companyData = companyDoc.data();
                let departmentCollection = await firebase.firestore().collection(`Companies/${companyDoc.id}/Departments/`).get();
                let departmentDocs = departmentCollection.docs;

                departmentDocs.forEach(async (departmentDoc, d) => {

                    let departmentData = departmentDoc.data();
                    let topicCollection = await firebase.firestore().collection(`Companies/${companyDoc.id}/Departments/${departmentDoc.id}/TrainingMaterial`).get();
                    let topicDocs = topicCollection.docs;

                    topicDocs.forEach(topicDoc => {

                        let topicData = topicDoc.data();
                        let shared = topicData.shared;

                        allTopics.push({
                            companyId: companyDoc.id,
                            companyName: companyData.name,
                            departmentId: departmentDoc.id,
                            departmentName: departmentData.name,
                            topicId: topicDoc.id,
                            topicName: topicData.name,
                            isShared: shared?.isHost ?? false,
                            isChild: shared?.isChild ?? false,
                            hostId: shared?.hostId ?? "None",
                            isLoading: shared?.loading ?? false,
                            childDepartments: shared?.departments?.length > 0 ? shared.departments.join() : "None"
                        });

                    });

                    if ((d + 1) >= departmentDocs.length && (c + 1) >= companyDocs.length) {
                        console.log("allTopics = ", allTopics)
                        console.log("allTopics.length = ", allTopics.length)
                        res(allTopics);
                    }

                });

            });

        } catch (error) {
            rej(error);
        }

    });

    resetLearningCenterTopicLoadingState = (companyID: string) => new Promise<any>(async (res, rej) => {

        try {

            let numUpdatedTopics = 0;
            let updatedTopics: any[] = [];

            let departmentCollection = await firebase.firestore().collection(`Companies/${companyID}/Departments/`).get();
            let departmentDocs = departmentCollection.docs;

            for (let d = 0; d < (departmentDocs?.length ?? 0); d++) {
                const departmentDoc = departmentDocs[d];

                let topicCollection = await firebase.firestore().collection(`Companies/${companyID}/Departments/${departmentDoc.id}/TrainingMaterial`).get();
                let topicDocs = topicCollection.docs;

                for (let t = 0; t < (topicDocs?.length ?? 0); t++) {
                    const topicDoc = topicDocs[t];

                    let topicData = topicDoc.data();
                    let shared = topicData.shared;

                    if (shared?.loading === true) {
                        await firebase.firestore().collection(`Companies/${companyID}/Departments/${departmentDoc.id}/TrainingMaterial`).doc(topicDoc.id).update({
                            "shared.loading": false
                        });

                        numUpdatedTopics++;
                        updatedTopics.push({
                            departmentId: departmentDoc.id,
                            topicId: topicDoc.id
                        });
                    }

                }
            }

            console.log("Num topics updated = ", numUpdatedTopics);
            console.log("Updated topics = ", updatedTopics);

            res(numUpdatedTopics);

        } catch (error) {
            rej(error);
        }

    });

    getAllUserReports = () => new Promise<any>(async (res, rej) => {
        try {
            let resp = firebase.functions().httpsCallable('generateAllUsersReport');
            let response = await resp();
            res(response);
        } catch (e) {
            rej(e);
        }
    });

    getRegionIdentifiers = (companyID: string) => new Promise<any>(async (res, rej) => {
        try {
            let snapshot = await firebase.firestore().collection(`Companies/${companyID}/RegionIdentifiers`).get();
            let regionIdentifiers = snapshot.docs.map(doc => doc.data());
            res(regionIdentifiers);
        }
        catch (e) {
            //TODO: Handle error
            rej(e);
        }
    });

    saveRegionIdentifiers = (companyID: string, regionIdentifiers: any[]) => new Promise<any>(async (res, rej) => {
        try {
            //get the current region identifiers
            let snapshot = await firebase.firestore().collection(`Companies/${companyID}/RegionIdentifiers`).get();
            let currentRegionIdentifiers = snapshot.docs.map(doc => doc.data());
            console.log("currentRegionIdentifiers = ", currentRegionIdentifiers);
            //compare the current region identifiers to the new ones
            let newRegionIdentifiers = regionIdentifiers.filter(regionIdentifier => {
                return !currentRegionIdentifiers.some(currentRegionIdentifier => {
                    return currentRegionIdentifier.name === regionIdentifier.name;
                });
            });
            console.log("newRegionIdentifiers = ", newRegionIdentifiers);
            //add the new region identifiers
            let batch = firebase.firestore().batch();
            console.log("batch = ", batch);
            newRegionIdentifiers.forEach(regionIdentifier => {
                let ref = firebase.firestore().collection(`Companies/${companyID}/RegionIdentifiers`).doc();
                console.log("ref = ", ref);
                batch.set(ref, regionIdentifier);
                console.log("set = ", regionIdentifier);
            });
            await batch.commit();
            console.log("batch committed");
            res(newRegionIdentifiers);
        } catch (error) {
            console.log("error = ", error);
            rej(error);
        }
    });

    updateUserRegion = (companyID: string, userID: string, region: string) => new Promise<any>(async (res, rej) => {
        try {
            let snapshot = await firebase.firestore().collection(`Companies/${companyID}/Users`).doc(userID).update({
                'userInfo.region': region
            });
            res(snapshot);
        } catch (error) {
            rej(error);
        }
    });

    addOrEditUser = (values: any, actions: any, isEdit: boolean, disabledChanged: boolean, emailChanged: boolean, previousEmail:string, region?:string) => new Promise<any>(async (res, rej) => {
        try {
            if (isEdit) {
                console.log(values);
                console.log('Updating User');
                let userSnapshot = await firebase.firestore().collection(`Companies/${values.companyId}/Users`).doc(values.userId).update({
                    'userInfo.company': values.companyId,
                    'userInfo.department': values.departmentId,
                    'userInfo.email': values.email,
                    'userInfo.firstName': values.name,
                    'userInfo.gameRetryOverride': values.gameRetryOverride,
                    'userInfo.general': values.general,
                    'userInfo.idNumber': values.idNumber,
                    'userInfo.lastName': values.surname,
                    'userInfo.role': values.userRoleId,
                    'userInfo.region':region == null ? '' : region,
                    'userInfo.disabled': values.disabled
                })
                console.log('User Updated', userSnapshot);
                if (values.userRoleId > 1) {
                    console.log('Updating Managers');
                    let managerSnapshot = await firebase.firestore().collection('Managers').doc(values.userId).update({
                        'company': values.companyId,
                        'department': values.departmentId,
                        'email': values.email,
                        'firstName': values.name,
                        'gameRetryOverride': values.gameRetryOverride,
                        'general': values.general,
                        'idNumber': values.idNumber,
                        'lastName': values.surname,
                        'role': values.userRoleId,
                        'region':region == null ? '' : region,
                        'userInfo.disabled': values.disabled
                    })
                    console.log('Managers Updated', managerSnapshot);
                }
                console.log('Time to edit the Users Email And State Changed States');
                let editUser = firebase.functions().httpsCallable('editUser');
                let params: any = {
                    disabledStateChanged: disabledChanged,
                    emailChanged: emailChanged,
                    email: values.email,
                    disabled: values.disabled,
                    userId: values.userId,
                };
                console.log("updateUser", params);
                editUser(params)
                    .then(async () => {
                        console.log("User State Updated");
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
            else {
                firebase.auth().createUserWithEmailAndPassword(values.email, 'P@ssword!');
            }
            return res(true);
        } catch (error) {
            console.log(error);
            return rej(false);
        }
    })
}

export default DataProvider;
