import React, { useState, useEffect } from 'react';
import Panel from '../../components/Panel';
import { Grid } from '@material-ui/core';
import { Formik, Form, Field, setNestedObjectValues } from 'formik';
import { TextField } from 'formik-material-ui';
import FormFooter from '../../components/FormFooter';
import ImageUpload from '../../components/ImageUpload';
import DataProvider from '../../providers/DataProvider';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

const validation = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
});

export interface IEditAchievementProps {
}

const EditAchievement: React.FC<IEditAchievementProps> = ({ }) => {

    const [loading, setLoading] = useState<any>(true);
    const [initial, setInitial] = useState<any>();
    const [image, setImage] = useState<any>();
    const dataprovider = new DataProvider();
    const history = useHistory();
    const params = useParams();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();



    //@ts-ignore
    let company = location.state.company;
    //@ts-ignore
    let department = location.state.department;
    //@ts-ignore
    let id = params.id;


    useEffect(() => {

        dataprovider.getAchievement(company, department, id).then(data => {
            setInitial(data);
            setLoading(false);
        })
    }, []);

    return (
        <Panel isLoading={loading}>
            <Formik
                validationSchema={validation}
                initialValues={initial ? initial : {}}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);

                    let achievement = {
                        name: values.name,
                        description: values.description,
                        imgUrl: values.imgUrl,
                        imgPath: values.imgPath ? values.imgPath : ""
                    }

                    if (image !== undefined) {
                        let img = await dataprovider.addImage(image, "/achievements_images");
                        achievement.imgPath = img.imgPath;
                        achievement.imgUrl = img.imgUrl;
                    };

                    dataprovider.updateAchievement(company, department, id, achievement).then(() => {
                        setSubmitting(false);
                        enqueueSnackbar(`Achievement Updated`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    }).catch(err => {
                        enqueueSnackbar("An error occurred", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                        setSubmitting(false);
                    });



                }}>
                {({ submitForm, isSubmitting, setFieldValue, values }) => (

                    <Form style={{ width: "100%" }}>
                        <Grid container direction="column" spacing={3}>
                            <Grid item xs={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Achievement Name"
                                    name="name"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Description"
                                    name="description"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <ImageUpload
                                    src={values.imgUrl}
                                    header="Achievement icon"
                                    id="image_Image"
                                    onChange={(file: any) => {
                                        setImage(file);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormFooter actionName="Update" onAction={submitForm} onBack={history.goBack} submitting={isSubmitting} />
                            </Grid>
                        </Grid>
                    </Form>

                )}
            </Formik>
        </Panel>
    );
}

export default EditAchievement;