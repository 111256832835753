import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { Grid, AppBar, Toolbar, makeStyles, Tabs, Tab, Button } from '@material-ui/core';
import SuzukiReports from '../components/exports/suzukiReports';

const useStyles = makeStyles((theme) => ({
    bar: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    }
}));

export interface IByUser {
    userData: any[];
    dealerData: any[];
    groupedData: any[];
}
// const ByUser = ({ list }) => {
const ByUser: React.FC<IByUser> = ({ userData, dealerData, groupedData }) => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);


    const byUserColumns = [
        { title: 'Name', field: 'firstName' },
        { title: 'Surname', field: 'lastName' },
        { title: 'Email', field: 'email' },
        { title: 'General', field: 'general' },
        { title: 'Game Name', field: 'gameName' },
        { title: 'Game Date', field: 'gameDate' },
        { title: 'Accuracy', field: 'accuracy' },
        { title: 'Top Points', field: 'points' },
        {title:'Top Games Played', field:'topGamesPlayed'},
        { title: 'Region', field: 'region' },
    ]

    const byDealerColumns = [
        { title: 'Name', field: 'firstName' },
        { title: 'Surname', field: 'lastName' },
        { title: 'Email', field: 'email' },
        { title: 'General', field: 'general' },
        { title: 'Accuracy', field: 'accuracy' },
        { title: 'Top Points', field: 'points' },
        { title: 'Overall Dealer %', field: 'dealerPercentage' },
        { title: 'Region', field: 'region' },
    ]

    const byRegionColumns = [
        { title: 'Name', field: 'firstName' },
        { title: 'Surname', field: 'lastName' },
        { title: 'Email', field: 'email' },
        { title: 'General', field: 'general' },
        { title: 'Accuracy', field: 'accuracy' },
        { title: 'Top Points', field: 'points' },
        { title: 'Region', field: 'region' },
    ]

    const tableOptions = {
        paging: true,
        search: false,
        showTitle: false,
        actionsColumnIndex: -1,
        pageSizeOptions: [5, 20, 50, 100, 500],
    }

    function handleTabChange(event: any, newValue: any) {
        setSelectedTab(newValue);
    }

    return (
        <Grid container direction="column">
            <Grid item>
                <AppBar position="static" className={classes.bar}>
                    <Toolbar>
                        <Tabs value={selectedTab} onChange={handleTabChange}>
                            <Tab label="By User" />
                            <Tab label="By Dealer" />
                            <Tab label="Grouped" />
                        </Tabs>                   
                        <div style={{
                            flexGrow: 1,
                            display: 'flex',
                            justifyContent: 'flex-end'                            
                        }}>
                            <SuzukiReports byUser={userData} byDealer={dealerData} byRegion={groupedData} />
                        </div>
                    </Toolbar>
                </AppBar>
            </Grid>
            <Grid item>
                {selectedTab === 0 && <MaterialTable
                    columns={byUserColumns}
                    data={userData ? userData : []}
                    options={tableOptions}
                />}
                {selectedTab === 1 && <MaterialTable
                    columns={byDealerColumns}
                    data={dealerData ? dealerData : []}
                    options={tableOptions}
                />}
                {selectedTab === 2 && <MaterialTable
                    columns={byRegionColumns}
                    data={groupedData ? groupedData : []}
                    options={tableOptions}
                />}
            </Grid>
        </Grid>
    );
}

export default ByUser;