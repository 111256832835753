import * as React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import GameList from './GameList';
import AddGame from './AddGame';
import Categories from './Categories';
import AddCategory from './AddCategory';
import EditGame from './EditGame';
import QuestionList from './QuestionList';
import Questions from './Questions';
import EditCategory from './components/EditCategory';
import AddQuestions from './questions/AddQuestions';
import EditQuestion from './questions/EditQuestion';
import ImportQuestions from './ImportQuestions';
import ShareGame from './ShareGame';

export default function GameNavigation(props: any) {

    let { path } = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={`${path}/categories/questions/bulk`}>
                    <ImportQuestions />
                </Route>
                <Route path={`${path}/categories/questions/add`}>
                    <AddQuestions />
                </Route>
                <Route path={`${path}/categories/questions/edit`}>
                    <EditQuestion />
                </Route>
                <Route path={`${path}/categories/questions/list`}>
                    <Questions />
                </Route>
                <Route path={`${path}/categories/edit/:id`}>
                    <EditCategory />
                </Route>
                <Route path={`${path}/categories/add`}>
                    <AddCategory />
                </Route>
                <Route path={`${path}/categories/:id`}>
                    <Categories />
                </Route>
                <Route path={`${path}/edit/:id`}>
                    <EditGame />
                </Route>
                <Route path={`${path}/share`}>
                    <ShareGame />
                </Route>
                <Route path={`${path}/add`}>
                    <AddGame />
                </Route>
                <Route path={path}>
                    <GameList />
                </Route>
            </Switch>
        </div>
    );
}
