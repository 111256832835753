import React, { useContext } from 'react';
import Panel from '../../components/Panel';
import { AuthContext } from '../../providers/AuthProvider';
import ManagerAddCommunication from './components/ManagerAddCommunication';
import AdminAddCommunication from './components/AdminAddCommunication';
import CompManagerAddCommunication from './components/CompManagerAddCommunicaiton';
import { Grid } from '@material-ui/core';
import Roles from '../../models/enums/Roles';

export default function AddCommunication() {

    const { user } = useContext(AuthContext);

    const renderForm = (role: Roles | undefined) => {

        if (!role) {
            return <h1>Something went wrong</h1>
        }

        if (role === Roles.manager) {
            return <ManagerAddCommunication />
        }

        if (role === Roles.admin) {
            return <AdminAddCommunication />
        }

        if (role === Roles.comp_manager) {
            return <CompManagerAddCommunication />
        }
    };

    return (
        <Panel>
            <Grid container>
                <Grid item xs={12}>
                    {
                        renderForm(user?.role)
                    }
                </Grid>

            </Grid>
        </Panel>
    );
}
