import * as React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import DepartmentList from './DepartmentList';
import AddDepartment from './AddDepartment';
import EditDepartment from './EditDepartment';

export default function DepartmentNavigation(props: any) {

    let { path } = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={`${path}/edit/:id`}>
                    <EditDepartment />
                </Route>
                <Route path={`${path}/add`}>
                    <AddDepartment />
                </Route>
                <Route path={path}>
                    <DepartmentList />
                </Route>
            </Switch>
        </div>
    );
}
