import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom'
import DataProvider from '../../providers/DataProvider';
import AddIcon from '@material-ui/icons/Add';
import MainLayout from '../../components/MainLayout';

const CompanyList = (props: any) => {

    const dataProvider = new DataProvider();
    const [companies, setCompanies] = useState<any[]>([]);
    let history = useHistory();

    useEffect(() => {
        dataProvider.getCompaniesFD().then(data => {
            console.log(data);
            setCompanies(data);
        });
        //eslint-disable-next-line
    }, []);

    return (
        <MainLayout
            actions={
                [<Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={() => { history.push("companies/add") }}>Add Company</Button>]
            }
            content={
                <MaterialTable style={{ width: '100%' }}
                    columns={[
                        { title: "Name", field: "name" },
                        { title: "url", field: "url" }
                    ]}
                    data={companies}
                    title="Companies"
                    actions={[
                        {

                            icon: 'edit',
                            tooltip: 'Edit company',
                            onClick: (event, rowData) => {
                                history.push("companies/edit/" + rowData.id);
                            }
                        }
                    ]}
                    options={{
                        actionsColumnIndex: -1
                    }}
                />}
        />
    );
};

export default CompanyList;
