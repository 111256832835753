import React, { useEffect, useState, createContext } from "react";
import FirebaseProvider from './FirestoreProvider';
import Login from '../pages/authentication/LogIn';
import DataProvider from "./DataProvider";
import Roles from "../models/enums/Roles";
import LoggedUser from "../models/LoggedUser";
import firebase from 'firebase/compat/app';
import { useDispatch } from 'react-redux';
import AllActions from '../redux/actions/Index';
import { Grid, LinearProgress } from "@material-ui/core";
import GameOn from './redCard.png';

interface context {
    user: LoggedUser | undefined;
    currentUser: firebase.User | null;
}

interface Props {
    children: React.ReactNode
}

//@ts-ignore
export const AuthContext = createContext<context>();

export const AuthProvider: React.FC<Props> = ({ children }) => {

    const [currentUser, setCurrentUser] = useState<any>(null);
    const [userData, setUserData] = useState<LoggedUser | undefined>();
    const [pending, setPending] = useState(true);
    const dataProvider = new DataProvider();
    let dispatch = useDispatch();

    useEffect(() => {

        const managerSetup = async (user: firebase.User) => {

            try {
                console.log("MANAGER SETUP");
                const manager: LoggedUser = await dataProvider.getManager(user.uid);
                setUserData(manager);
                const company = await dataProvider.getCompany(manager.company);
                const department = await dataProvider.getDepartment(company.id, manager.department);
                dispatch(AllActions.searchState.setSearchState({ company: { id: company.id, name: company.name }, department: { id: department.id, name: department.name } }));
                console.log("DISPATCHED");
            } catch (error) {
                console.log(error);
            }
        }

        const comp_managerSetup = async (user: firebase.User) => {
            try {
                const comp_manager: LoggedUser = await dataProvider.getManager(user.uid);
                setUserData(comp_manager);
                const company = await dataProvider.getCompany(comp_manager.company);

                //dispatch to redux
                dispatch(AllActions.searchState.setSearchState({
                    company: { id: company.id, name: company.name },
                    department: { id: "", name: "" }
                }
                ));
            } catch (error) {
                console.log(error);
            }
        }

        FirebaseProvider.auth().onAuthStateChanged((user) => {
            setPending(true);
            setCurrentUser(user);

            user?.getIdTokenResult().then(token => {

                if (token.claims.manager) {

                    managerSetup(user).then(() => {
                        setPending(false)
                    });

                } else if (token.claims.admin) {
                    setUserData({
                        company: "",
                        department: "",
                        email: user.email || "",
                        role: Roles.admin
                    })
                    setPending(false);
                } else if (token.claims.comp_manager) {
                    comp_managerSetup(user).then(() => {
                        setPending(false)
                    })
                }
            }).catch(() => {
                setPending(false)
            });

            if (!user) {
                setPending(false);
            }
        });
        // eslint-disable-next-line
    }, []);

    if (pending) {
        return (
            <Grid container justify="center" alignItems="center" style={{ height: '80vh' }}>
                <Grid item xs={3} container >
                    <Grid item container justify="center">
                        <img src={GameOn} alt="" width="280" />
                    </Grid>
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    if (currentUser) {

        let context: context = {
            user: userData,
            currentUser: currentUser
        }

        return (
            <AuthContext.Provider value={context}>
                {children}
            </AuthContext.Provider>
        );
    }

    return (
        <Login />
    );
}