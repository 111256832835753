const setCurrentUser = (object: any) => {
    console.log("In current user action", object);
    return {
        type: "SET_USER",
        payload: object
    }
}

export default {
    setCurrentUser
}