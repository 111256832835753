import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Matrix, { IPosition } from '../../components/Grid';
import GroupList from '../imageMatch/List';
import { State } from '../AddQuestions';
import FormFooter from '../../../../components/FormFooter';
import { Selectable } from '../AddImageMap';
import DataProvider from '../../../../providers/DataProvider';
import { useSnackbar } from 'notistack';
import { useHistory } from "react-router-dom";


const url = 'https://firebasestorage.googleapis.com/v0/b/test-fddd5.appspot.com/o/testing%2FArtboard65.png?alt=media&token=6157be6c-7588-42ab-ba19-a0d4e159af3e';
const landscape = 'https://firebasestorage.googleapis.com/v0/b/test-fddd5.appspot.com/o/testing%2FFarm_fresh_02_2.png?alt=media&token=98363e85-4664-4714-9f7b-25aa0549aa36';

const MIN_WIDTH = 600;
const ROW_COUNT = 15;

const makeid = (length: number) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export interface IStep2Props {
    data: any;
    tableData:any;
    state: State;
    reset: any;
    back: any;
}

const EditStep2: React.FC<IStep2Props> = ({ data, tableData, state, reset, back }) => {

    const history = useHistory();
    const [gridX, setGridX] = useState<number>(1);
    const [gridY, setGridY] = useState<number>(1);
    const [height, setHeigth] = useState<number>(300);
    const [imageWidth, setImageWidth] = useState<number>(0);
    const [imageHeight, setImageHeight] = useState<number>(0);
    const [group, setGroup] = useState<Selectable[]>([
        {
            id: makeid(4),
            clickables: [
            ]
        }
    ]);
    const [selectGroup, setSelectedGroup] = useState<string>(group[0].id);

    const [submitting, setSubmitting] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const dataprovider = new DataProvider();

    const useStyles = makeStyles((theme) => ({
        container: {
            backgroundImage: `url(${data.imgUrl})`,
            backgroundSize: '600px',
            backgroundRepeat: 'no-repeat',
            minWidth: MIN_WIDTH,
            maxWidth: MIN_WIDTH,
            height: height
        },
    }));

    const addGroup = () => {
        console.log("Adding new group");
        let arr = [...group];
        arr.push({
            id: makeid(4),
            clickables: []
        });
        setGroup([...arr]);
    }

    const removeGroup = (index: number, isSelected: boolean) => {
        if (isSelected) {
            setSelectedGroup(group[0].id);
        }
        console.log("Remove");
        let arr = [...group];;
        arr.splice(index, 1);
        setGroup([...arr]);
    }

    const classes = useStyles();

    const handleSelect = (id: string) => {
        console.log("SELECTED: ", id);
        setSelectedGroup(id);
    }

    const submitFrom = () => {
        setSubmitting(true);
        console.log("State", state);
        console.log("data", data);

        let question = {
            learningSubTopicId: data.subTopic.id,
            learningTopicId: data.topic.id,
            mediaLink: data.mediaLink,
            questionText: data.questionText,
            questionType: data.questionType,
            whereToFind: data.whereToFind,
            id: tableData.id,

            gridWidth: gridX,
            gridHeight: gridY,
            image: {
                width: imageWidth,
                height: imageHeight,
                url: data.imgUrl,
                path: data.imgPath
            },
            selectables: group
        }

        //@ts-ignore
        dataprovider.updateQuestion(state.company.id, state.department.id, state.gameId, state.categoryId, question).then(() => {            
            history.push("/games/categories/questions/list",state);
            setGroup([]);
            setSubmitting(false);
            enqueueSnackbar(`question edited`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        }).catch(err => {
            enqueueSnackbar("An error occurred", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        })
    }

    useEffect(() => {
        console.log("DATA URL IN STEP 2", data);
        let xBlocks = 15; //amount of blocks in a row
        let image = new Image();
        image.addEventListener('load', (img) => {
            let height = image.height / (image.width / MIN_WIDTH);
            let yBlocks = height / (MIN_WIDTH / xBlocks);
            setGridX(Math.round(xBlocks));
            setGridY(Math.round(yBlocks));
            setImageWidth(image.width);
            setImageHeight(image.height);
            setHeigth(height);
        });
        image.src = data.imgUrl;
        console.log(image.src);

    }, []);
    console.log(state);
    console.log(data);
    return (
        <Grid container >
            <Grid item xs={4}>
                <GroupList groups={group} onSelect={handleSelect} addGroup={addGroup} removeGroup={(index: number, isSelected: boolean) => removeGroup(index, isSelected)} />
            </Grid>
            <Grid item xs={8}>
                <div className={classes.container}>
                    <Matrix
                        x={gridX}
                        y={gridY}
                        group={group}
                        selectedGroupID={selectGroup}
                        onGroupAdd={(point: IPosition, checked: boolean) => {
                            console.log("ADDING POINT");
                            console.log("POINT: ", point);
                            console.log("Checked", checked);
                            let currentGroupSelected = group.findIndex(x => x.id === selectGroup);
                            let arr = [...group];

                            if (!checked) {
                                /*
                                add cell
                                */
                                arr[currentGroupSelected].clickables.push(point);
                                setGroup([...arr]);
                            } else {
                                /*
                                qadd cell
                                */
                                let find = arr[currentGroupSelected].clickables.findIndex(item => item.x === point.x && item.y === point.y);
                                if (find > 0) {
                                    console.log("FIND index", find);
                                    arr[currentGroupSelected].clickables.splice(find, 1);
                                    setGroup([...arr]);
                                }
                            }
                        }} />
                </div>
            </Grid>
            <Grid item xs={12}>
                <FormFooter onAction={submitFrom} onBack={back} actionName="Edit" submitting={submitting} disableAction={submitting} disableBack={submitting} />
            </Grid>
        </Grid>
    );
}

export default EditStep2;