import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import {
    Button,
    Grid,
    TextField
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import DataProvider from "../../../providers/DataProvider";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export interface IRichEditorProps {
    state: any;
}

const RichEditor: React.FC<IRichEditorProps> = ({ state }) => {

    const dataProvider = new DataProvider();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [questionName, setQuestionName] = useState("");
    const [content, setContent] = useState("");
    const [blankedPositions, setBlankedPositions] = useState<any[]>([]);
    const [blankedItems, setBlankedItems] = useState([{ id: 1, word: "No Records" }]);
    const [validated, setValidated] = useState(false);
    const [question, setQuestion] = useState({});

    useEffect(() => {
        
        processText(content);

    }, [content]);

    function processText(incomingText: any) {

        /*
         *  Description:
         *      The words that need to be blanked are captured between <u> & </u> tags, but 
         *      there could be multiple words captured between the tags. We need the blanked
         *      words separated.
         *      The start- & endpoints of each blanked word needs to be captured to ensure its
         *      correct position in the statement.
         * 
         *  Modus operandi
         *      1)  Find the first opening <u> tag and note its position
         *      2)  Split all the words inside the <u> & </u> tags consideration spaces only
         *      3)  Store the start- & endpoints of each word together with the word itself 
         *          taking into account the <u> tag
         */
        
        // No need to process if there's no underlined words
        if (!incomingText.includes("<u>")) {
            
            // Reset the blanked words & positions, if any
            if (blankedItems.length > 0 || blankedPositions.length > 0) {
                setBlankedItems([]);
                setBlankedPositions([]);
            }

            return;
        }

        const statement = incomingText.toLowerCase();
        
        let tempWords = [];
        let tempPositions = [];
        let startingPos = 0;
        let openingTagPos = statement.indexOf("<u>", startingPos);

        while (openingTagPos !== -1) {

            const closingTagPos = statement.indexOf("</u>", openingTagPos);
            const numOpenTagsBefore = statement
                .substring(0, closingTagPos)
                .match(new RegExp(`<p>|<u>`, "g"))
                ?.length || 0;
            const numCloseTagsBefore = statement
                .substring(0, closingTagPos)
                .match(new RegExp(`</p>|</u>`, "g"))
                ?.length || 0;
            const underlinedWords = statement
                .substring(openingTagPos, closingTagPos)
                .replace(/,|\./g, " ")                          // Replace all punctuation with whitespace
                .replace(/<u>|<\/u>/g, "")
                .split(/(\s)/);                                 // Split by whitespace while retaining the whitespace entries

            startingPos = openingTagPos + 3;

            for (let i = 0; i < underlinedWords.length; i++) {
                const word = underlinedWords[i];

                if (word?.length <= 0) {
                    // Skip over empty entries
                    continue;
                }
                
                if (word?.trim()?.length > 0) {
                    // Account for all tags before it
                    let actualStartPos = startingPos - (3 * numOpenTagsBefore) - (4 * numCloseTagsBefore);
                    let endPos = actualStartPos + word.length - 1;
    
                    tempWords.push({ 
                        id: i, 
                        word: word
                    });
                    tempPositions.push({
                        word: word,
                        startPos: actualStartPos,
                        endPos: endPos
                    });
                }
                
                startingPos += word.length;
            }

            openingTagPos = statement.indexOf("<u>", startingPos);

        }

        setBlankedItems(tempWords);
        setBlankedPositions(tempPositions);

    }

    function untagStatement(statement: string) {

        return statement.replace(/<p>+|<\/p>+|<u>+|<\/u>+/g, "");

    }

    function blankStatement(cleanStatement: string) {

        const blankSpace = "______";

        let newStatement = "";
        let prevEndPos = 0;

        for (let i = 0; i < blankedPositions.length; i++) {
            const blank = blankedPositions[i];
            
            if (i === 0) {
                
                newStatement = cleanStatement.substring(0, blank.startPos) + blankSpace;

            } else if (i === blankedPositions.length - 1) {
                
                newStatement += cleanStatement.substring(prevEndPos + 1, blank.startPos) + blankSpace + cleanStatement.substring(blank.endPos + 1);

            } else {
                
                newStatement += cleanStatement.substring(prevEndPos + 1, blank.startPos) + blankSpace;

            }

            prevEndPos = blank.endPos;

        }

        return newStatement.trim();

    }

    function validate() {
        
        // Check if there is a question name and blanked words
        if (questionName == "") {
            enqueueSnackbar("Please Enter a Question Name", { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
            setValidated(false);
            return;
        }

        if (blankedPositions.length <= 0) {
            enqueueSnackbar("Please Enter a Blank Word", { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
            setValidated(false);
            return;
        }
        
        setValidated(true);

        // Create a new Game Object with an array of the blanked words
        const cleanStatement = untagStatement(content);

        let question = {
            statement: cleanStatement,
            blankedWords: blankedPositions,
            blankedStatement: blankStatement(cleanStatement),
            questionText: questionName,
            questionType: 5,
        };
        
        setQuestion(question);

    }

    function postGameQuestion() {
        
        dataProvider.addQuestions(state.company.id, state.department.id, state.gameId, state.categoryId, question).then(() => {
            enqueueSnackbar(`Added question`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        }).catch(err => {
            enqueueSnackbar(`An error occurred: ${err}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        })
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
        }}>
            {/* Text box for Question Name */}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        required
                        error={questionName === ""}
                        label="Question Name"
                        autoComplete="questionName"
                        placeholder={"Please enter a name for this question"}
                        onChange={(event) => setQuestionName(event.target.value)}
                    />
                </Grid>
            </Grid>

            {/* Rich-text editor & table for selecting & displaying blanked words */}
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <h4 style={{ color: "red" }}>Please insert and <strong style={{ textDecoration: "underline" }}>underline</strong> the text that you want to blank out below</h4>
                    <div style={{ border: "1px solid black", padding: '2px', minHeight: '400px' }}>
                        <ReactQuill
                            theme="snow"
                            value={content}
                            onChange={setContent}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={6}>
                    <MaterialTable style={{ width: '100%', overflow: 'auto', height: "100%" }}
                        columns={[
                            { title: "Blanked Word", field: "word" }
                        ]}
                        data={blankedItems}
                        options={{
                            search: false,
                            paging: false,
                            showTitle: false,
                        }}
                    />
                </Grid>
            </Grid>

            {/* Buttons */}
            <div style={{
                //flex end
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
            }}>
                <Button variant="contained" color="primary" style={{ marginTop: "20px" }} onClick={() => { history.goBack() }}>
                    Back
                </Button>
            </div>
            {
                blankedPositions.length > 0 && !validated &&
                <div style={{
                    //flex end
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}>
                    <Button variant="contained" color="primary" style={{ marginTop: "20px" }} onClick={validate}>
                        Validate
                    </Button>
                </div>
            }
            {
                blankedPositions.length > 0 && validated &&
                <div style={{
                    //flex end
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}>
                    <Button variant="contained" color="primary" style={{ marginTop: "20px" }} onClick={postGameQuestion}>
                        Submit
                    </Button>
                </div>
            }
        </div>
    );
}
export default RichEditor;


//Force