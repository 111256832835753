import React, { useState, useEffect, useContext } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Grid, TextField, Button } from '@material-ui/core';
import DataProvider from '../../../providers/DataProvider';
import { AuthContext } from '../../../providers/AuthProvider';
import Roles from '../../../models/enums/Roles';
import { useSelector } from 'react-redux';
import { positions } from '@material-ui/system';

export interface IFilterProps {
    submit: any;
    disabled?: boolean;
}

const Filter: React.FC<IFilterProps> = ({ submit, disabled = false }) => {

    const dataprovider = new DataProvider();
    const [companies, setCompanies] = useState<any[]>([]);
    const [departments, setDepartments] = useState<any[]>([]);
    const [selectedCompany, setCompany] = useState<any>({ id: "", name: "" });
    const [selectedDepartment, setDepartment] = useState<any>({ id: "", name: "" });
    const { user } = useContext(AuthContext);

    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;

    const onSubmit = (company: any, department: any) => {

        if (company.id !== "") {
            submit(company, department);
        }
    }

    const onCompanySelect = (id: string) => {
        dataprovider.getDepartments(id).then(data => {
            setDepartments(data.sort((a,b) => a.name > b.name ? 1 : -1));
            setDepartment({ id: "", name: "" });
        })
    }

    useEffect(() => {

        if (user?.role === Roles.manager) {
            onSubmit(searchState.company, searchState.department);
        }

        if (user?.role === Roles.comp_manager) {
            dataprovider.getDepartments(searchState.company.id).then(data => {
                setDepartments(data.sort((a,b) => a.name > b.name ? 1 : -1));
                setDepartment({ id: "", name: "" });
            });
        }

        if (searchState.company.id !== "") {

            setCompany(searchState.company);
            setDepartment(searchState.department || { id: "", name: "" });
            onSubmit(searchState.company, searchState.department);

        }

        if (user?.role !== Roles.comp_manager) {
            dataprovider.getCompanies().then(data => {
                setCompanies(data.sort((a, b) => a.name > b.name ? 1 : -1));
            });
        }
    }, []);

    if (user?.role === Roles.manager) {
        return <></>
    }

    return (    
            <div style={{
                display: "flex", 
                flexDirection: "row", 
                alignItems: "center",
                //dont render under the side bar, this needs to move right
                marginLeft: "100px",
                marginRight: "100px",
                width: "100%",
                position: "relative",
                justifyContent: "space-between",
            }}
                
            >
                <Autocomplete
                style={{
                    paddingLeft: "100px",
                    width: "100%",
                    position: "relative",
                }}
                    disabled={disabled || user?.role === Roles.comp_manager ? true : false}
                    id="combo-box-demo"
                    options={companies}
                    getOptionSelected={() => true}
                    getOptionLabel={(option) => option.name}
                    value={selectedCompany}
                    onChange={(event, data) => {
                        if (data) {
                            setCompany(data);
                            onCompanySelect(data.id);
                        } else {
                            setDepartments([]);
                            setDepartment({ id: "", name: "" });
                            setCompany({ id: "", name: "" });

                        }

                    }}
                    renderInput={(params) => <TextField {...params} label="Company" variant="outlined" />}
                />
                <Autocomplete
                    style={{
                        width: "100%",
                        position: "relative",
                    }}
                    disabled={disabled}
                    id="combo-box-demo"
                    options={departments}
                    value={selectedDepartment}
                    getOptionSelected={() => true}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, data) => {
                        if (data) {
                            setDepartment(data);
                        } else {
                            setDepartment({ id: "", name: "" });
                        }

                    }}
                    renderInput={(params) => <TextField {...params} label="Departments" variant="outlined" />}
                />
                <Button color="primary" variant="outlined" disabled={disabled} onClick={() => { onSubmit(selectedCompany, selectedDepartment) }}>
                    search
                </Button>
                </div>
    );
}

export default Filter;