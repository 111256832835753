import React, { useState, useEffect, Fragment } from 'react';
import Panel from '../../components/Panel';
import { Grid, Divider, TextField, Typography } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import readXlsxFile from 'read-excel-file';
import Tabs from './components/Tabs';
import MaterialTable from 'material-table';
import FormFooter from '../../components/FormFooter';
import { Autocomplete } from '@material-ui/lab';
import DataProvider from '../../providers/DataProvider';
import { useLocation, useHistory } from 'react-router';
import { State } from './questions/AddQuestions';
import { useSnackbar } from 'notistack';

export interface IImportQuestionsProps {
}

const options = [
    {
        id: 0,
        type: "Multiple choice"
    },
    {
        id: 1,
        type: "Fill in the blank"
    },
    {
        id: 2,
        type: "Match the terms"
    },
    {
        id: 3,
        type: "Word bucket"
    },
    // {
    //     id: 4,
    //     type: "Image Match"
    // }
    {
        id: 5,
        type: "Statement Blanking"
    },
    // {
    //     id: 6,
    //     type: "Crossword"
    // }
];

const multipleChoiceSchema = {
    'Question': {
        prop: "question",
        type: String,
        required: true
    },
    'answer 1': {
        prop: "answer1",
        type: String,
        required: true
    },
    'answer 2': {
        prop: "answer2",
        type: String,
        required: true
    },
    'answer 3': {
        prop: "answer3",
        type: String,
        required: true
    },
    'answer 4': {
        prop: "answer4",
        type: String,
        required: true
    },
    'Correct answer': {
        prop: "correctAnswer",
        type: String,
        required: true
    },
}

const fillInTheBlankSchema = {
    'Question': {
        prop: "question",
        type: String,
        required: true
    },
    'answer 1': {
        prop: "answer1",
        type: String,
        required: true
    },
    'answer 2': {
        prop: "answer2",
        type: String,
        required: true
    },
    'answer 3': {
        prop: "answer3",
        type: String,
        required: true
    },
    'answer 4': {
        prop: "answer4",
        type: String,
        required: true
    },
    'Correct answer': {
        prop: "correctAnswer",
        type: String,
        required: true
    },
}

const wordBucketSchema = {
    'Question': {
        prop: "question",
        type: String,
        required: true
    },
    'First Bucket name': {
        prop: "bucket1",
        type: String,
        required: true
    },
    'Second Bucket name': {
        prop: "bucket2",
        type: String,
        required: true
    },
    'First Bucket items': {
        prop: "bucket1Items",
        type: String,
        required: true
    },
    'Second Bucket items': {
        prop: "bucket2Items",
        type: String,
        required: true
    }
}

const matchTheTermsSchema = {
    'Question': {
        prop: "question",
        type: String,
        required: true
    },
    'Terms 1': {
        prop: "terms1",
        type: String,
        required: true,
        parse(value: string) {

            let arrSplit: any[] = value.split(',');
            if (arrSplit.length < 2 || arrSplit.length > 5) {
                throw new Error('There should be a minimum of 2 terms and a maximum of 5')
            }
            return value;
        }
    },
    'Terms 2': {
        prop: "terms2",
        type: String,
        required: true,
        parse(value: string) {

            let arrSplit: any[] = value.split(',');
            if (arrSplit.length < 2 || arrSplit.length > 5) {
                throw new Error('There should be a minimum of 2 terms and a maximum of 5')
            }
            return value;
        }
    }
}

const statementBlankingSchema = {
    'Question': {
        prop: "question",
        type: String,
        required: true
    },
    'Raw Statement': {
        prop: "rawStatement",
        type: String,
        required: true
    }
}

const ImportQuestions: React.FC<IImportQuestionsProps> = ({ }) => {

    const dataprovider = new DataProvider();
    const { enqueueSnackbar } = useSnackbar();

    const history = useHistory();
    const location = useLocation();
    const state = location.state as State;
    const defaultOption = { id: -1, type: '' };

    const [data, setData] = useState<any[]>([]);
    const [errors, setErrors] = useState<any[]>([]);
    const [file, setFile] = useState<any>();
    const [gameType, setGameType] = useState<any>(defaultOption);
    const [loading, setLoading] = useState(false);

    const filterData = (data: any[], errorData: any[]) => {

        if (!data && !errorData) {
            setData([]);
            setErrors([]);
            return;
        }

        let errors: any[] = [];
        let toAdd: any[] = [];

        for (let i = 0; i < data.length; i++) {
            const object = errorData.find(x => (x.row - 1) === i);

            // Error not found
            if (!object) {
                toAdd.push(data[i]);
                continue;
            }

            // Error found
            if (object) {
                errors.push({
                    question: data[i].question,
                    errors: errorData.filter(x => (x.row - 1) === i)
                });
            };
        }

        setErrors([...errors]);
        setData([...toAdd]);

    };

    const getSchema = (id: number) => {

        switch (id) {
            case 0: return multipleChoiceSchema;
            case 1: return fillInTheBlankSchema;
            case 2: return wordBucketSchema;
            case 3: return matchTheTermsSchema;
            case 5: return statementBlankingSchema;
            default: return null
        }

    }

    const buttonState = () => {

        const isData = data !== undefined;
        const isFile = file !== undefined;
        const isGameType = gameType.id >= 0;

        return !(isData && isFile && isGameType);

    }

    const mapQuestion = (id: number) => {
        switch (id) {
            case 0:
                return data.map(x => {
                    return {
                        learningSubTopicId: "",
                        learningTopicId: "",
                        mediaLink: "",
                        questionText: x.question,
                        questionType: 0,
                        answers: {
                            0: x.answer1,
                            1: x.answer2,
                            2: x.answer3,
                            3: x.answer4,
                        },
                        correctAnswerIndex: x.correctAnswer - 1,
                        whereToFind: ""
                    }
                });
            case 1:
                return data.map(x => {
                    return {
                        learningSubTopicId: "",
                        learningTopicId: "",
                        mediaLink: "",
                        questionText: x.question,
                        questionType: 1,
                        answers: {
                            0: x.answer1,
                            1: x.answer2,
                            2: x.answer3,
                            3: x.answer4,
                        },
                        correctAnswerIndex: x.correctAnswer - 1,
                        whereToFind: ""
                    }
                });
            case 2:
                let arr: any[] = [];
                console.log("Data to be mapped", data);

                data.forEach(item => {
                    let buckets: any = {
                        0: {
                            bucketIndex: 0,
                            name: item.bucket1
                        },
                        1: {
                            bucketIndex: 1,
                            name: item.bucket2
                        }
                    };

                    let bucket1Items: any[] = item.bucket1Items.split(',');
                    let bucket2Items: any[] = item.bucket2Items.split(',');

                    bucket1Items = bucket1Items.map(item => {
                        return {
                            matchIndex: 0,
                            name: item
                        }
                    });

                    bucket2Items = bucket2Items.map(item => {
                        return {
                            matchIndex: 1,
                            name: item
                        }
                    });

                    let question = {
                        learningSubTopicId: "",
                        learningTopicId: "",
                        mediaLink: "",
                        questionText: item.question,
                        questionType: 3,
                        whereToFind: "",
                        buckets: buckets,
                        items: bucket1Items.concat(bucket2Items)
                    }

                    arr.push(question);
                });

                console.log("Array", arr);

                return arr;
            case 3:
                let questionArr: any[] = [];

                console.log("Raw data", data);

                for (let i = 0; i < data.length; i++) {
                    const element = data[i];

                    let terms1: any[] = element.terms1.split(',');
                    let terms2: any[] = element.terms2.split(',');

                    let terms = {
                        0: {
                            columnIndex: 0,
                            items: []
                        },
                        1: {
                            columnIndex: 1,
                            items: []
                        }
                    }

                    if (terms1.length === terms2.length && terms1.length > 1 && terms1.length < 6) {
                        let items1: any[] = [];
                        let items2: any[] = [];

                        for (let i = 0; i < terms1.length; i++) {
                            let item = {
                                index: i,
                                name: terms1[i]
                            }

                            items1.push(item);
                        }

                        for (let i = 0; i < terms2.length; i++) {
                            let item = {
                                index: i,
                                name: terms2[i]
                            }

                            items2.push(item);
                        }

                        //@ts-ignore
                        terms[0].items = items1;
                        //@ts-ignore
                        terms[1].items = items2;

                        let question = {
                            learningSubTopicId: "",
                            learningTopicId: "",
                            mediaLink: "",
                            questionText: element.question,
                            questionType: 2,
                            whereToFind: "",
                            terms: terms
                        }


                        questionArr.push(question);
                    }
                }

                console.log(questionArr);
                return questionArr;
            case 5:

                /*
                    Developer's note (28-03-2023):
                        These functions are copied from the games/ExtraFunctionality/Editor.tsx file. They should actually
                        be extracted into a separate "services" utility file and reused from there to reduce redundant code
                        and improve maintainability 
                 */

                const getCleanStatement = (rawStatement: string) => {
                    
                    return rawStatement.replace(/<p>+|<\/p>+|<u>+|<\/u>+/g, "");

                };

                const getBlankedStatement = (cleanStatement: string, blankedPositions: any[]) => {
                                
                    const blankSpace = "______";

                    let newStatement = "";
                    let prevEndPos = 0;

                    for (let i = 0; i < blankedPositions.length; i++) {
                        const blank = blankedPositions[i];
                        
                        if (i === 0) {
                            
                            newStatement = cleanStatement.substring(0, blank.startPos) + blankSpace;

                        } else if (i === blankedPositions.length - 1) {
                            
                            newStatement += cleanStatement.substring(prevEndPos + 1, blank.startPos) + blankSpace + cleanStatement.substring(blank.endPos + 1);

                        } else {
                            
                            newStatement += cleanStatement.substring(prevEndPos + 1, blank.startPos) + blankSpace;

                        }

                        prevEndPos = blank.endPos;

                    }

                    return newStatement.trim();

                };

                const getBlankedPositions = (rawStatement: string) => {
                    
                    const statement = rawStatement.toLowerCase();
                    const openingTag = "<u>";
                    const closingTag = "<\/u>";
                    
                    let tempPositions = [];
                    let startingPos = 0;
                    let openingTagPos = statement.indexOf(openingTag, startingPos);

                    while (openingTagPos !== -1) {

                        const closingTagPos = statement.indexOf(closingTag, openingTagPos);
                        const numOpenTagsBefore = statement
                            .substring(0, closingTagPos)
                            .match(new RegExp(`<p>|${openingTag}`, "g"))
                            ?.length || 0;
                        const numCloseTagsBefore = statement
                            .substring(0, closingTagPos)
                            .match(new RegExp(`</p>|${closingTag}`, "g"))
                            ?.length || 0;
                        const underlinedWords = statement
                            .substring(openingTagPos, closingTagPos)
                            .replace(/,|\./g, " ")                          // Replace all punctuation with whitespace
                            .replace(new RegExp(`${openingTag}|${closingTag}`, "g"), "")
                            .split(/(\s)/);                                 // Split by whitespace while retaining the whitespace entries

                        startingPos = openingTagPos + 3;

                        for (let i = 0; i < underlinedWords.length; i++) {
                            const word = underlinedWords[i];

                            if (word?.length <= 0) {
                                // Skip over empty entries
                                continue;
                            }
                            
                            if (word?.trim()?.length > 0) {
                                // Account for all tags before it
                                let actualStartPos = startingPos - (3 * numOpenTagsBefore) - (4 * numCloseTagsBefore);
                                let endPos = actualStartPos + word.length - 1;
                
                                tempPositions.push({
                                    word: word,
                                    startPos: actualStartPos,
                                    endPos: endPos
                                });
                            }
                            
                            startingPos += word.length;
                        }

                        openingTagPos = statement.indexOf(openingTag, startingPos);

                    }

                    return tempPositions;

                };

                let questions: any = [];

                for (let i = 0; i < data.length; i++) {
                    const row = data[i];
                    const cleanStatement = getCleanStatement(row.rawStatement);
                    const blankedPositions = getBlankedPositions(row.rawStatement);

                    questions.push({
                        statement: cleanStatement,
                        blankedWords: blankedPositions,
                        blankedStatement: getBlankedStatement(cleanStatement, blankedPositions),
                        questionText: row.question,
                        questionType: 5,
                    });
                }
                
                return questions;

            default:
                return null;
        }
    }

    const submit = () => {
        
        try {

            setLoading(true);

            const questions = mapQuestion(gameType.id);
            
            //@ts-ignore
            const promise = dataprovider.addBulkQuestions(state.company.id, state.department.id, state.gameId, state.categoryId, questions);

            promise.then(data => {
                enqueueSnackbar(`Questions added`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                setLoading(false);
            });

            promise.catch(err => {
                enqueueSnackbar("Failed to add questions", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                setLoading(false);
            });

        } catch (error) {
            setLoading(false);
            enqueueSnackbar("Failed to add questions", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        }

    }

    return (
        <Panel>
            <Grid container spacing={3}>
                <Grid item container xs={5}>
                    <Grid item>
                        <Autocomplete
                            id="combo-box-demo"
                            getOptionSelected={() => true}
                            options={options}
                            getOptionLabel={(option) => option.type}
                            style={{ width: 300 }}
                            value={gameType}
                            renderInput={(params) => <TextField {...params} label="Question type" variant="outlined" />}
                            onChange={(event, data) => {

                                if (!data) {
                                    setGameType(defaultOption);
                                    setData([]);
                                    setErrors([]);
                                    return;
                                }

                                setGameType(data);
                                
                                const schema = getSchema(data.id);
                                
                                if (file !== undefined && data.id !== undefined) {
                                    readXlsxFile(file, { schema }).then(({ rows, errors }: any) => {
                                        filterData(rows, errors);
                                    });
                                }

                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DropzoneArea
                            acceptedFiles={[".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                            showAlerts={false}
                            filesLimit={1}
                            dropzoneText="Upload Excel (.xlsx extension only)"
                            onChange={(files: any) => {

                                setFile(files[0]);

                                if (gameType.id >= 0) {
                                    const schema = getSchema(gameType.id);
                                    readXlsxFile(files[0], { schema }).then(({ rows, errors }: any) => {
                                        filterData(rows, errors);
                                    });
                                }

                            }}
                            onDelete={() => {
                                setData([]);
                                setErrors([]);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={1} justifyContent="center">
                    <Grid item>
                        <Divider orientation="vertical" variant="fullWidth" />
                    </Grid>
                </Grid>
                <Grid item container xs={6}>
                    <Tabs items={
                        [
                            {
                                index: 0,
                                heading: "Questions",
                                content: 
                                    <MaterialTable 
                                        style={{ width: '100%', overflow: 'auto', height: "100%" }}
                                        columns={[
                                            { title: "Question", field: "question" }
                                        ]}
                                        data={data}
                                        options={{
                                            search: false,
                                            showTitle: false,
                                            toolbar: false,
                                            actionsColumnIndex: -1
                                        }}
                                    />
                            },
                            {
                                index: 1,
                                heading: "Errors",
                                content: 
                                    <MaterialTable 
                                        style={{ width: '100%', overflow: 'auto', height: "100%" }}
                                        columns={[
                                            { title: "Question", field: "question" }
                                        ]}
                                        data={errors}
                                        options={{
                                            search: false,
                                            showTitle: false,
                                            toolbar: false,
                                            actionsColumnIndex: -1
                                        }}
                                        detailPanel={
                                            rowData => (
                                                <Grid container direction="column">
                                                    {
                                                        rowData.errors.map((item: any) => {
                                                            return (
                                                                <Grid item container direction="column" style={{ marginBottom: 20, marginLeft: 30 }}>
                                                                    <Grid item><span style={{ fontWeight: "bold" }}>Column:</span> {item.column}</Grid>
                                                                    <Grid item><span style={{ fontWeight: "bold" }}>Error:</span> {item.error}</Grid>
                                                                </Grid>
                                                            );
                                                        })
                                                    }
                                                </Grid>
                                            )
                                        }
                                    />
                            }
                        ]
                    } />
                </Grid>
                <Grid item xs={12}>
                    <FormFooter disableAction={buttonState()} onAction={submit} submitting={loading} onBack={() => { history.goBack() }} />
                </Grid>
            </Grid>
        </Panel>
    );
}

export default ImportQuestions;