const initialState = {
    searchState: {
        company: { id: "", name: "" },
        department: { id: "", name: "" }
    }
}

const SearchState = (state = initialState, action: any) => {
    switch (action.type) {
        case "SET_SearchState":
            return {
                ...state,
                searchState: action.payload
            };
        default:
            return state;
    }
};

export default SearchState;