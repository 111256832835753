import React, { useState } from 'react';
import { Grid, Paper, Typography, Chip, Divider, makeStyles } from '@material-ui/core';
import { PercentageData } from '../../../models/Percentanges';

const useStyles = makeStyles((theme) => ({
    itemHeading: {
        fontWeight: 550,
        color: "#8f8f8f"
    },
    itemValue: {
        fontWeight: 600,
    }
}));

export interface IPercentageTileProps {
    position: string;
    data: PercentageData;

}

const PercentageTile: React.FC<IPercentageTileProps> = ({ position, data }) => {

    function round(val: number) {
        return Math.round(val);
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper>
                    <Grid container direction="column">
                        <Grid item container justify="center">
                            <Grid item style={{ padding: 20 }}>
                                <Typography variant="h5">{position}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item container style={{ marginTop: 20, marginBottom: 20 }} direction="column">
                            <Grid item>
                                <Item heading="Points" value={round(data.points)} />
                            </Grid>
                            <Grid item>
                                <Item heading="Games Played" value={data.gamesPlayed} />
                            </Grid>
                            <Grid item xs={12}>
                                <Item heading="Time Spend" value={data.timeSpent} unit="s" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

interface ItemProps {
    heading: string;
    value: any;
    unit?: any;
}

const Item: React.FC<ItemProps> = ({ heading, value, unit = null }) => {

    const classes = useStyles();

    const renderValue = () => {
        if (unit !== null) {
            return (
                <Grid item>
                    <Grid item >
                        <Typography className={classes.itemValue}>{value} {unit}</Typography>
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <Grid item>
                    <Typography className={classes.itemValue}>
                        {value}
                    </Typography>
                </Grid>
            );
        }
    }

    return (
        <Grid container justify="center" spacing={2}>
            <Grid item>
                <Typography className={classes.itemHeading}>
                    {heading}:
                </Typography>
            </Grid>
            {
                renderValue()
            }
        </Grid>
    )
}

export default PercentageTile;