import React, { useState, useEffect } from 'react';
import { State } from './AddQuestions';
import { Grid, TextField as MUITextField } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import FormFooter from '../../../components/FormFooter';
import ImageUpload from '../../../components/ImageUpload';
import { useHistory } from 'react-router-dom';
import DataProvider from '../../../providers/DataProvider';
import { useSnackbar } from 'notistack';

const validation = Yup.object().shape({
    question: Yup.string().required('Required'),
    answer_a: Yup.string().required('Required'),
    answer_b: Yup.string().required('Required'),
    answer_c: Yup.string().required('Required'),
    answer_d: Yup.string().required('Required'),
    correctAnswer: Yup.object().shape({
        id: Yup.string().required(),
        letter: Yup.string().required()
    }).required('Required')
});

let options = [
    { id: 0, letter: "A" },
    { id: 1, letter: "B" },
    { id: 2, letter: "C" },
    { id: 3, letter: "D" }
]

export interface IEditMultipleChoiceProps {
    data: any;
    state: State;
}

const EditMultipleChoice: React.FC<IEditMultipleChoiceProps> = ({ data, state }) => {

    const [topics, setTopics] = useState<any[]>([]);
    const [subTopics, setSubtopics] = useState<any[]>([]);
    const [imageChanged, setImageChanged] = useState(false);
    const history = useHistory();
    const dataprovider = new DataProvider();
    const { enqueueSnackbar } = useSnackbar();

    const onTopicSelect = (id: string) => {

        if (id) {
            //@ts-ignore
            dataprovider.getLearningCenterSubTopics(state.company.id, state.department.id, id).then(data => {
                setSubtopics(data);
            });
        }

    }

    useEffect(() => {

        //@ts-ignore
        dataprovider.getLearningCenterTopics(state.company.id, state.department.id).then(data => {
            setTopics(data);
        }).catch(err => {
            console.log(err);
        });

        if (data.learningTopicId) {
            //@ts-ignore
            dataprovider.getLearningCenterSubTopics(state.company.id, state.department.id, data.learningTopicId.id).then(data => {
                setSubtopics(data);
            });
        }

    }, []);

    return (
        <Grid container>
            <Grid item>
                <Formik
                    validationSchema={validation}
                    enableReinitialize={true}
                    initialValues={{ ...data }}
                    onSubmit={async (values: any, { setSubmitting }: any) => {

                        let addQuestion = {
                            id: data.id,
                            answers: {
                                0: values.answer_a,
                                1: values.answer_b,
                                2: values.answer_c,
                                3: values.answer_d,
                            },
                            correctAnswerIndex: values.correctAnswer.id,
                            imgUrl: values.imgUrl || null,
                            imgPath: values.imgPath || null, 
                            questionText: values.question,
                            mediaLink: values.mediaUri,
                            questionType: values.questionType,
                            learningTopicId: values.topic.id,
                            learningSubTopicId: values.subTopic.id,
                            whereToFind: values.whereToFind
                        };

                        if (imageChanged) {
                            try {
    
                                let image = await dataprovider.addImage(values.imgUrl, "games_images/");
    
                                addQuestion.imgUrl = image.imgUrl;
                                addQuestion.imgPath = image.imgPath;
    
                            } catch (error) {
                                enqueueSnackbar(`Failed to update the image: ${error}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                            }
                        }
                        
                        //@ts-ignore
                        dataprovider.updateQuestion(state.company.id, state.department.id, state.gameId, state.categoryId, addQuestion).then(data => {
                            setSubmitting(false);
                            enqueueSnackbar(`Question updated!`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                        }).catch(err => {
                            enqueueSnackbar("An error occurred", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                            setSubmitting(false);
                        });
                    }}
                >
                    {({ values, submitForm, isSubmitting, setFieldValue, touched, errors }) => (
                        <Grid item container lg={12}>
                            <Form style={{ width: '100%' }}>
                                <Grid container spacing={3} direction="row">

                                    <Grid item xs={12} md={8} container spacing={3} direction="column">
                                        <Grid item>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Question"
                                                name="question"
                                                variant="filled"
                                                fullWidth
                                                multiline
                                                rows={4}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Answer A"
                                                name="answer_a"
                                                variant="filled"
                                                fullWidth
                                                multiline
                                                rows={2}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Answer B"
                                                name="answer_b"
                                                variant="filled"
                                                fullWidth
                                                multiline
                                                rows={2}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Answer C"
                                                name="answer_c"
                                                variant="filled"
                                                fullWidth
                                                multiline
                                                rows={2}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Answer D"
                                                name="answer_d"
                                                variant="filled"
                                                fullWidth
                                                multiline
                                                rows={2}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Field
                                                name="correctAnswer"
                                                getOptionSelected={() => { return true }}
                                                component={Autocomplete}
                                                options={options}
                                                getOptionLabel={(option: any) => option.letter}
                                                value={values.correctAnswer}
                                                onChange={(event: any, data: any) => {
                                                    setFieldValue("correctAnswer", data);
                                                }}
                                                renderInput={(params: AutocompleteRenderInputParams) => (
                                                    <MUITextField
                                                        {...params}
                                                        error={touched['correctAnswer'] && !!errors['correctAnswer']}
                                                        helperText={
                                                            //@ts-ignore
                                                            touched['correctAnswer'] && errors['correctAnswer']?.title
                                                        }
                                                        label="Correct answer"
                                                        variant="filled"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <ImageUpload
                                            label="Question Image (optional)"
                                            id="image_questionImage"
                                            src={values.imgUrl}
                                            onChange={(file: any) => {
                                                setImageChanged(true);
                                                setFieldValue("imgUrl", file);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item container>
                                        <FormFooter onAction={submitForm} onBack={() => { history.goBack() }} submitting={isSubmitting} actionName="Save" backActionName="Cancel" />
                                    </Grid>

                                </Grid>
                            </Form>
                        </Grid>
                    )}
                </Formik >
            </Grid>
        </Grid >
    );
}

export default EditMultipleChoice;