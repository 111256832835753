import React, { useEffect, useState, Fragment } from 'react';
import { Button } from '@material-ui/core';
import MaterialTable from 'material-table';
import { useHistory, useParams } from 'react-router-dom'
import DataProvider from '../../providers/DataProvider';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddIcon from '@material-ui/icons/Add';
import { useSelector } from 'react-redux';
import MainLayout from '../../components/MainLayout';
import ConfirmDelete from './components/ConfirmCategoryDelete';

const Categories = (props: any) => {

    const params = useParams();
    const history = useHistory();
    const dataProvider = new DataProvider();
    const [catID, setCatID] = useState<any>();
    const [open, setOpen] = useState(false);
    const [categories, setCategories] = useState<any[]>([]);
    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;

    //@ts-ignore
    let company = searchState.company;
    //@ts-ignore
    let department = searchState.department;
    //@ts-ignore
    let gameId = params.id;

    const handleClose = () => {
        getData(company.id, department.id, gameId);
        setOpen(false);
    }

    const getData = (companyID: string, departmentID: string, gameID: string) => {
        if (companyID !== "" && departmentID !== "") {
            dataProvider.getCategoriesFD(company.id, department.id, gameId).then(data => {
                data.sort((gameA, gameB) => {
                    if (gameA.order !== undefined) {
                        return gameA.order < gameB.order ? -1 : gameA.order > gameB.order ? 1 : 0;
                    }
			        return gameA.name < gameB.name ? -1 : gameA.name > gameB.name ? 1 : 0;
		        });
                setCategories(data);
            }).catch(err => { console.log(err) });
        } else {
            history.goBack();
        }
    }


    useEffect(() => {

        getData(company.id, department.id, gameId);

        //eslint-disable-next-line
    }, []);

    return (
        <MainLayout
            actions={[
                <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={() => {
                    history.push("/games/categories/add", {
                        gameId: gameId,
                        company: company,
                        department: department
                    })
                }}>Add category</Button>
            ]}
            content={
                <Fragment>
                    <MaterialTable style={{ width: '100%' }}
                        title="Categories"
                        columns={[

                            { title: "Name", field: "name" },
                            { title: "Subtitle", field: "subtitle" },
                            { title: "Description", field: "description" },
                            { title: "Display Order", field: "order"},
                            { title: "Must watch video before proceeding", field: "mustWatchVideo"}
                        ]}
                        data={categories}
                        actions={[
                            {
                                icon: () => <AssignmentIcon />,
                                tooltip: 'Add Questions',
                                onClick: (event, rowData) => {
                                    let state = {
                                        company: company,
                                        department: department,
                                        gameId: gameId,
                                        categoryId: rowData.id,
                                        categoryName: rowData.name
                                    }

                                    history.push("questions/list", state);
                                }
                            },
                            {
                                icon: 'edit',
                                onClick: (event, rowData) => {
                                    console.log(rowData);
                                    history.push("/games/categories/edit/" + rowData.id, { company: company, department: department, gameID: gameId });
                                }
                            },
                            {
                                icon: 'delete',
                                onClick: (event, rowData) => {
                                    setCatID(rowData.id);
                                    setOpen(true);
                                }
                            }
                        ]}
                    />
                    <ConfirmDelete categoryID={catID} gameID={gameId} contentText="This action can not be reverted!" handleClose={handleClose} title="Are you sure you want to delete this item?" isOpen={open} />
                </Fragment>
            }
        />

    );
};

export default Categories;
