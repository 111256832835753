import React, { useState, useContext } from 'react';
import Panel from '../../../components/Panel';
import { Formik, Form, Field } from 'formik';
import DataProvider from '../../../providers/DataProvider';
import { Grid, Typography } from '@material-ui/core';
import FormFooter from '../../../components/FormFooter';
import { useHistory } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { DropzoneArea } from 'material-ui-dropzone';
import * as Yup from 'yup';
import { AuthContext } from '../../../providers/AuthProvider';
import { useSnackbar } from 'notistack';

const schema = Yup.object({
    name: Yup.string().required('Required'),
    subtitle: Yup.string().required('Required')
});

const AddTopic: React.FC<any> = () => {

    const { user } = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();

    const [image, setImage] = useState<any>();
    const dataProvider = new DataProvider();
    let history = useHistory();

    return (
        <Panel>
            <Formik
                validationSchema={schema}
                initialValues={{
                    name: "",
                    subtitle: "",
                    order: 0,
                    imageSelected: null
                }}
                onSubmit={async (values, { setSubmitting }) => {

                    if (!values?.imageSelected) {
                        enqueueSnackbar("No topic picture is uploaded", { variant: "warning", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                        setSubmitting(false);
                        return;
                    }
                    
                    let topic = {
                        name: values.name,
                        subtitle: values.subtitle,
                        order: values.order,
                        imgUrl: "",
                        imgPath: "",
                        disabled: true
                    }

                    try {
                        let imgLink = await dataProvider.addImage(image, "learningCenter_files/");

                        topic.imgUrl = imgLink.imgUrl;
                        topic.imgPath = imgLink.imgPath;

                    } catch (error) {
                        enqueueSnackbar(`Failed to save the image: ${error}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    }

                    dataProvider.AddLearningCenterTopics(user?.company, user?.department, topic)
                    .then(topicId => {
                        enqueueSnackbar(`Successfully saved the topic`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                        setSubmitting(false);
                        history.replace("categories/add", { topicId: topicId });
                    })
                    .catch((error) => {
                        enqueueSnackbar(`Failed to save the topic: ${error.message || error}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    });
                }}
            >
                {({ submitForm, isSubmitting, values, setFieldValue, errors }) => (
                    <Form>
                        <Grid container spacing={3} direction="column">
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Name"
                                    name="name"
                                    variant="filled"
                                    fullWidth
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Subtitle"
                                    name="subtitle"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <Field
                                    component={TextField}
                                    type="number"
                                    label="Display Order"
                                    name="order"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4} container spacing={1} direction="column">
                                <Grid item>
                                    <DropzoneArea 
                                        showAlerts={false} 
                                        filesLimit={1} 
                                        dropzoneText={"Upload an image"} 
                                        onChange={
                                            (files: any) => {
                                                setFieldValue("imageSelected", files[0] ? true : false);
                                                setImage(files[0]);
                                            }
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography color="error">
                                        {values.imageSelected === false ? errors.imageSelected : ""}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} xl={4}>
                                <FormFooter onAction={submitForm} onBack={() => { history.goBack() }} submitting={isSubmitting} />
                            </Grid>
                        </Grid>
                    </Form>

                )
                }
            </Formik >
        </Panel >
    );
}

export default AddTopic;
