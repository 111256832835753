import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { Grid, AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, makeStyles } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import ExcelByDepartment from './exports/ExcelByDepartment';

const useStyles = makeStyles((theme) => ({

    bar: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    }
}));

export interface IByDepartmentProps {
    list: any[] | undefined;
}

const ByDepartment: React.FC<IByDepartmentProps> = ({ list }) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const classes = useStyles();

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    function round(value: number) {
        return Math.round(value);
    }

    useEffect(() => {

        // Round the points to the nearest integer
        list?.forEach(item => item.averagePoints = round(item.averagePoints));

    }, []);

    return (
        <Grid container direction="column">
            <Grid item>
                <AppBar position="static" className={classes.bar}>
                    <Toolbar>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="h6">By Department</Typography>
                            </Grid>
                            <Grid item>
                                <ExcelByDepartment list={list ? list : []} />
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Grid>
            <Grid item>
                <MaterialTable style={{ width: '100%', overflow: 'auto', height: '400px' }}
                    columns={[
                        { title: "Name", field: "name" },
                        { title: "Number of Players", field: "userCount" },
                        { title: "Active players", field: "activePlayerCount" },
                        { title: "Avg Points", field: "averagePoints" },
                    ]}
                    data={list ? list : []}
                    options={{
                        paging: false,
                        search: false,
                        showTitle: false,
                        toolbar: false,
                        actionsColumnIndex: -1
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default ByDepartment;