import React, { useState, Fragment } from "react";
import MainLayout from "../../components/MainLayout";
import { Button, Grid, Typography, Chip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Filter from "../../components/Filter";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import DataProvider from "../../providers/DataProvider";
import CategoryIcon from "@material-ui/icons/Category";
import { useDispatch, useSelector } from "react-redux";
import AllActions from "../../redux/actions/Index";
import ConfirmDelete from "./components/ConfirmLearningTopicDelete";
import { useSnackbar } from 'notistack';

export default function LearningCenterList() {

    //@ts-ignore
    const searchState = useSelector(state => state.searchState)?.searchState;
    const dispatch = useDispatch();
    const dataProvider = new DataProvider();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

	const [topics, setTopics] = useState<any[]>([]);
	const [learningTopicID, setLearningTopicID] = useState("");
    const [loading, setLoading] = useState(false);
    const [dialogText, setDialogText] = useState("This action can not be reverted! Are you sure you want to procceed?");
    const [open, setOpen] = useState(false);

    function handleDialogClose(success: boolean, error: any = null) {

        setOpen(false);

        if (success === true) {
            enqueueSnackbar("Topic deleted successfully", { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        } else {
            enqueueSnackbar(`Failed to delete topic${error != null ? `. Error: ${error}` : "" }`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        }

        dataProvider.getLearningCenterTopics(searchState.company.id, searchState.department.id)
        .then(topics => {
            setTopics(topics);
        })
        .catch(error => {
            enqueueSnackbar("Failed to load remaining topics: " + error, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        });

    }

    function disableState(rowData: any) {
        return rowData?.shared?.isChild === true;
    }

	return (
		<MainLayout
			actions={[
				<Button
					startIcon={<AddIcon />}
					variant="contained"
					color="primary"
					disabled={searchState.company.id !== "" ? false : true}
					onClick={() => {
						history.push("learningCenter/share", { company: searchState.company, department: searchState.department });
					}}>
					<Typography variant="button">Share Topic</Typography>
				</Button>,
				<Button
					startIcon={<AddIcon />}
					variant="contained"
					color="primary"
					onClick={() => {
						history.push("learningCenter/add");
					}}>
					<Typography variant="button">Add Topic</Typography>
				</Button>
			]}
			filters={[
				<Filter
					loading={loading}
                    onSubmit={(company: any, department: any) => {
                        setLoading(true);
                        dataProvider.getLearningCenterTopics(company.id, department.id)
                        .then(topics => {
                            dispatch(AllActions.searchState.setSearchState({ company: company, department: department }));
							setTopics(topics);
							setLoading(false);
                        })
                        .catch(() => { 
                            setLoading(false)
                        });
                    }} />
			]}
			content={
				<Fragment>
					<MaterialTable
						title="Topics"
						data={topics}
                        isLoading={loading}
						style={{ width: "100%" }}
                        options={{ actionsColumnIndex: -1 }}
						columns={[
							{
                                title: "Name",
                                field: "name",
								render: (rowData) => (
									<Grid container spacing={1} direction="row" alignItems="center">
										<Grid item>
											<Typography>{rowData.name}</Typography>
										</Grid>
										<Grid item>
                                            {
                                                rowData.shared && (
                                                    rowData.shared.isHost && <Chip label="Shared Topic" color="secondary" /> ||
                                                    rowData.shared.isChild && <Chip label="Child Topic" style={{ backgroundColor: '#b3e6ff' }} />
                                                )
                                            }
										</Grid>
                                        <Grid item>
                                            {
                                                rowData.disabled &&
												<Chip label="Disabled" />
                                            }
                                        </Grid>
									</Grid>
								),
							},
							{ title: "Sub text", field: "subtitle" },
						]}
						actions={[
							{
								icon: () => <CategoryIcon />,
								tooltip: "View Subtopics",
								onClick: (event, rowData) => {
									history.push("learningCenter/categories/" + rowData.id);
								}
							},
							rowData => (
								{
									icon: "edit",
									tooltip: "Edit Topic",
									disabled: disableState(rowData),
									onClick: (event, rowData) => {
										history.push("/learningCenter/topic/edit/" + rowData.id, rowData);
									}
								}
							),
							rowData => (
								{
									icon: "delete",
									tooltip: "Delete Topic",
									disabled: disableState(rowData),
									onClick: (event, rowData) => {

										setLearningTopicID(rowData.id);
										
                                        if (rowData.shared && rowData.shared.isHost === true) {
                                            setDialogText("Deleting this topic will delete all of its shared topics as well. This can not be reverted! Are you sure you want to procceed?");
                                        }
    
										setOpen(true);

									}
								}
							)
						]}
					/>
					<ConfirmDelete
						title="Are you sure you want to delete this item?"
						contentText={dialogText}
						isOpen={open}
						handleClose={handleDialogClose}
						id={learningTopicID}
					/>
				</Fragment>
			}
		/>
	);
}
