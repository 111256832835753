import React, {  } from 'react';
import { Button,Grid, TextField, Typography, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSnackbar } from 'notistack';
import DataProvider from '../../../providers/DataProvider';
import { useHistory } from 'react-router-dom';
export interface ICrosswordProps {
    state: any;
}

const CrosswordPage: React.FC<ICrosswordProps> = ({state}) => {
let history = useHistory();
const { enqueueSnackbar } = useSnackbar();
const dataProvider = new DataProvider();
const [questionName, setQuestionName] = React.useState<string>("");
const [value, setValue] = React.useState<string>("");
const [clue, setClue] = React.useState<string>("");
const [data , setData] = React.useState<any>([]);

function addWord(){
    let temp = {
        value: value,
        clue: clue,
    };
    setData([...data, temp]);
    setValue("");
    setClue("");
}
function removeWord(index:any){
    let temp = [...data];
    temp.splice(index, 1);
    setData(temp);
}

function renderWordList(){
    return data.map((item:any, index:any) => {
        return (
            <div key={index} style={{padding:'10px'}}>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Clue:{item.clue}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{position: "relative"}}>                        
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <Typography>Value: {item.value}</Typography>
                            <Typography>Orientation: {item.orientation}</Typography>
                        </div>
                        <Button onClick={() => removeWord(index)} style={{position:'absolute', right:'10px', bottom:'10px'}}><DeleteIcon/></Button>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                
            </div>
        )
    })
}

function removeAll(){
    setData([]);
}

function saveCrossWord(){
    let temp = {
        questionText: questionName,
        questionType: 6,
        data:data
    };
    dataProvider.addQuestions(state.company.id, state.department.id, state.gameId, state.categoryId, temp).then(() => {
        enqueueSnackbar(`Added question`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        history.push(`/games/categories/${state.gameId}`);
    }).catch(err => {
        console.log("Something went wrong:", err);
        enqueueSnackbar("An error occurred", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
    })
}

    return (
    <Grid container spacing={4} style={{ paddingTop: "10px", alignItems: "center" }}>
        <Grid item xs={3} >
            <TextField value={questionName} label="Question" variant='outlined' onChange={(e) => setQuestionName(e.target.value)} style={{padding:"10px", width: "100%"}}/>
            <TextField value={value} label="Add Word" variant='outlined' onChange={(e) => setValue(e.target.value)} style={{padding:"10px", width: "100%"}}/>
            <TextField value={clue} label="Add Clue" variant='outlined' onChange={(e) => setClue(e.target.value)} style={{padding:"10px", width: "100%"}}/>
            <Button onClick={addWord} variant='outlined' style={{padding:"10px", width: "100%"}} disabled={value == "" || clue == "" ? true: false}>Add</Button>
        </Grid>
        <Grid item xs={9} style={{ paddingTop: "10px", alignItems: "center" }}>
            {data && renderWordList()}
            {data.length > 1 && <Button onClick={removeAll} variant='outlined' style={{padding:"10px", width: "100%"}}>Remove All</Button>}
            {data.length != 0 && <Button onClick={saveCrossWord} disabled={questionName != '' ? false : true} variant='outlined' style={{padding:"10px", width: "100%"}}>Save</Button>}
        </Grid>
            
    </Grid>  
    );
};

export default CrosswordPage;

//Force