import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Panel from '../../../components/Panel';
import { Formik, Form, Field } from 'formik';
import { Grid } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import FormFooter from '../../../components/FormFooter';
import DataProvider from '../../../providers/DataProvider';
import * as Yup from 'yup';

export interface IEditCategoryProps {
}

const validation = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(25, 'Too Long!')
        .required('Required'),
    subtitle: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    description: Yup.string().required('Required')
});

const EditCategory: React.FC<IEditCategoryProps> = ({ }) => {

    const [initial, setInitial] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);
    const [initialMedia, setInitialMedia] = useState<any[]>([]);
    const params = useParams();
    //@ts-ignore
    const id = params.id;
    const history = useHistory();
    const location = useLocation();
    let state = location.state;
    //@ts-ignore
    let company = location.state.company;
    //@ts-ignore
    let department = location.state.department;
    //@ts-ignore
    let gameID = location.state.gameID;

    const dataprovider = new DataProvider();

    useEffect(() => {

        dataprovider.getCategory(company.id, department.id, gameID, id).then(data => {
            if (data.passRate) {
                setInitial(data);
            } else {
                let appendField = { ...data, passRate: '0' };
                setInitial(appendField);
            }

            setInitialMedia(data.media ? data.media : []);
            setLoading(false);
        })
    }, [])

    return (
        <Panel isLoading={loading}>
            <Formik
                validationSchema={validation}
                initialValues={initial ? initial : {}}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);

                    dataprovider.updateCategory(company.id, department.id, gameID, id, values).then(() => {
                        setSubmitting(false);
                        history.goBack();
                    });
                }}
            >
                {({ submitForm, isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={3} direction="column">
                            <Grid item xs={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Name"
                                    name="name"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Description"
                                    name="description"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Subtitle"
                                    name="subtitle"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Display Order"
                                    name="order"
                                    variant="filled"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormFooter
                                    onAction={submitForm}
                                    submitting={isSubmitting}
                                    actionName="Update"
                                    backActionName="cancel"
                                    onBack={() => { history.goBack() }}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )
                }
            </Formik >
        </Panel >
    );
}

export default EditCategory;