import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@material-ui/core';
import MultipleChoice from './AddMultipleChoice';
import FillInTheBlank from './AddFillInTheBlank';
import ExcelExportButton, { ISheet } from '../../../components/ExcelExportButton';

export interface IExcelByQuestionsProps {
    list: any[];
}

const ExcelByQuestions: React.FC<IExcelByQuestionsProps> = ({ list }) => {

    const [exportSheets, setExportSheets] = useState<ISheet[]>([]);

    useEffect(() => {

        if (!list) return;

        const { multiChoice, fillInTheBlank, bucket, matchTerms } = mapList(list);

        let sheets = [];

        // Multiple Choice sheet
        sheets.push({
            sheetName: "Multiple Choice",
            sheetColumns: [
                { title: "Question", name: "Question" },
                { title: "answer 1", name: "answer1" },
                { title: "answer 2", name: "answer2" },
                { title: "answer 3", name: "answer3" },
                { title: "answer 4", name: "answer4" },
                { title: "Correct answer", name: "correctAnswer" },
            ],
            sheetData: multiChoice || []
        });

        // Fill in the blank sheet
        sheets.push({
            sheetName: "Fill in the blank",
            sheetColumns: [
                { title: "Question", name: "Question" },
                { title: "answer 1", name: "answer1" },
                { title: "answer 2", name: "answer2" },
                { title: "answer 3", name: "answer3" },
                { title: "answer 4", name: "answer4" },
                { title: "Correct answer", name: "correctAnswer" },
            ],
            sheetData: fillInTheBlank || []
        });

        // Word bucket sheet
        sheets.push({
            sheetName: "Word bucket",
            sheetColumns: [
                { title: "Question", name: "question" },
                { title: "First Bucket name", name: "bucket1" },
                { title: "Second Bucket name", name: "bucket2" },
                { title: "First Bucket items", name: "bucket1Items" },
                { title: "Second Bucket items", name: "bucket2Items" },
            ],
            sheetData: bucket || []
        });

        // Match the terms sheet
        sheets.push({
            sheetName: "Match the terms",
            sheetColumns: [
                { title: "Question", name: "question" },
                { title: "Terms 1", name: "terms1" },
                { title: "Terms 2", name: "terms2" },
            ],
            sheetData: bucket || []
        });

        setExportSheets(sheets);

    }, [list]);

    const mapList = (arr: any[]) => {

        let multiChoiceArr: any[] = [];
        let fillInTheBlankArr: any[] = [];
        let matchTheTermsArr: any[] = [];
        let wordBucketArr: any[] = [];

        for (let i = 0; i < arr.length; i++) {
            const element = arr[i];
            let question = mapQuestion(element);

            if (question !== null) {

                switch (element.questionType) {
                    case 0:
                        multiChoiceArr.push(question);
                        break;
                    case 1:
                        fillInTheBlankArr.push(question);
                        break;
                    case 2:
                        matchTheTermsArr.push(question);
                        break;
                    case 3:
                        wordBucketArr.push(question);
                        break;
                    default:
                        break;
                }
            }
        }

        return {
            multiChoice: [...multiChoiceArr], 
            fillInTheBlank: [...fillInTheBlankArr], 
            bucket: [...wordBucketArr], 
            matchTerms: [...matchTheTermsArr]
        };
    }

    const mapQuestion = (item: any) => {
        switch (item.questionType) {
            case 0:
                return {
                    Question: item.questionText,
                    answer1: item.answers[0],
                    answer2: item.answers[1],
                    answer3: item.answers[2],
                    answer4: item.answers[3],
                    correctAnswer: item.correctAnswerIndex + 1
                }
            case 1:
                return {
                    Question: item.questionText,
                    answer1: item.answers[0],
                    answer2: item.answers[1],
                    answer3: item.answers[2],
                    answer4: item.answers[3],
                    correctAnswer: item.correctAnswerIndex + 1
                }
            case 2:
                let matchTerms1 = item.terms[0].items;
                let matchTerms2 = item.terms[1].items;

                let terms1 = stringify(matchTerms1);
                let terms2 = stringify(matchTerms2);

                let object = {
                    question: item.questionText,
                    terms1: terms1,
                    terms2: terms2
                }

                return object;
            case 3:

                let items: any[] = item.items;

                let bucket1: string = stringify([...items].filter(x => x.matchIndex === 0));
                let bucket2: string = stringify([...items].filter(x => x.matchIndex === 1));

                return {
                    question: item.questionText,
                    bucket1: item.buckets[0].name,
                    bucket2: item.buckets[1].name,
                    bucket1Items: bucket1,
                    bucket2Items: bucket2,
                }
            default:
                return null;
                break;
        }
    }

    const stringify = (arr: any[]) => {

        let bucket = "";

        for (let i = 0; i < arr.length; i++) {
            const element = arr[i];
            if (bucket === "") {
                bucket = element.name;
            } else {
                bucket = bucket + "," + element.name;
            }
        }
        return bucket;
    }

    return (
        <ExcelExportButton 
            fileName='DataExport' 
            buttonTitle='Export Questions' 
            sheets={exportSheets}
        />
    );
}

export default ExcelByQuestions;