import React, { useState, useEffect } from 'react';
import { Grid, TextField as MUITextField, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import DataProvider from '../../../../providers/DataProvider';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { State } from '../AddQuestions';
import { DropzoneArea } from 'material-ui-dropzone';
import FormFooter from '../../../../components/FormFooter';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import ImageUpload from '../../../../components/ImageUpload';


const validation = Yup.object().shape({
    question: Yup.string().required('Required'),
    imageSelected: Yup.bool().oneOf([true], "Please select an image")
});

export interface IStep1Props {
    state: State;
    next: any;
    data: any;
}

const EditStep1: React.FC<IStep1Props> = ({ state, next, data }) => {

    const dataProvider = new DataProvider();
    const [topics, setTopics] = useState<any[]>([]);
    const [subTopics, setSubtopics] = useState<any[]>([]);
    let history = useHistory();

    const onTopicSelect = (id: string) => {
        if (id) {
            //@ts-ignore
            dataProvider.getLearningCenterSubTopics(state.company.id, state.department.id, id).then(data => {
                setSubtopics(data);
            });
        }
    }

    

    useEffect(() => {
        //@ts-ignore
        
        dataProvider.getLearningCenterTopics(state.company.id, state.department.id).then(data => {
            setTopics(data);
        }).catch(err => {
            console.log(err);
        });
        
    }, []);
    console.log(state);
    console.log(data);
    return (
        <Formik
            validationSchema={validation}
            initialValues={{
                questionType: 5,
                question: data.question.questionText,
                mediaLink: "",
                topic: { id: "", name: "" },
                subTopic: { id: "", title: "" },
                whereToFind: data.question.whereToFind,
                image: "",
                imageSelected: true
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {

                console.log(values);

                let object = {
                    questionType: 4,
                    questionText: values.question,
                    mediaLink: values.mediaLink,
                    topic: values.topic,
                    subTopic: values.subTopic,
                    whereToFind: values.whereToFind,
                    imgPath: data.question.image.path,
                    imgUrl: data.question.image.url
                }

                try {
                    next(object);
                } catch (error) {
                    alert(error)
                }
            }}
        >
            {({ values, submitForm, isSubmitting, setFieldValue, touched, errors }) => (
                <Form style={{ width: '100%' }} >
                    <Grid container justify="space-between" direction="column" style={{ minHeight: '50vh' }}>
                        <Grid item container xs={12} spacing={1}>
                            <Grid container item xs={7} spacing={3}>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Question"
                                        name="question"
                                        variant="filled"
                                        fullWidth
                                        multiline
                                        rows={2}
                                    />
                                </Grid>
                                <Grid item container xs={6} spacing={2}>
                                    <Grid item xs={12}>
                                        <Field
                                            name="topic"
                                            getOptionSelected={() => { return true }}
                                            component={Autocomplete}
                                            options={topics}
                                            getOptionLabel={(option: any) => option.name}
                                            value={values.topic}
                                            onChange={(event: any, data: any) => {
                                                if (data) {
                                                    setFieldValue("topic", data);
                                                    setFieldValue("subTopic", { id: '', title: '' });
                                                    onTopicSelect(data.id);
                                                } else {
                                                    setFieldValue("topic", { id: '', name: '' });
                                                    setFieldValue("subTopic", { id: '', title: '' });
                                                }

                                            }}
                                            renderInput={(params: AutocompleteRenderInputParams) => (
                                                <MUITextField
                                                    {...params}
                                                    error={touched['topic'] && !!errors['topic']}
                                                    helperText={
                                                        //@ts-ignore
                                                        touched['topic'] && errors['topic']?.title
                                                    }
                                                    label="Learning center Topic"
                                                    variant="filled"
                                                    fullWidth={true}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name="subTopic"
                                            getOptionSelected={() => { return true }}
                                            component={Autocomplete}
                                            options={subTopics}
                                            getOptionLabel={(option: any) => option.title}
                                            value={values.subTopic}
                                            onChange={(event: any, data: any) => {
                                                setFieldValue("subTopic", data);
                                            }}
                                            renderInput={(params: AutocompleteRenderInputParams) => (
                                                <MUITextField
                                                    {...params}
                                                    error={touched['subTopic'] && !!errors['subTopic']}
                                                    helperText={
                                                        //@ts-ignore
                                                        touched['subTopic'] && errors['subTopic']?.title
                                                    }
                                                    label="Learning center Subtopic"
                                                    variant="filled"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Where to find"
                                        name="whereToFind"
                                        variant="filled"
                                        fullWidth
                                        multiline
                                        rows={4}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Media uri"
                                        name="mediaLink"
                                        variant="filled"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={5}>
                                <Grid item xs={12}>
                                    {/* <DropzoneArea
                                        showAlerts={false}
                                        filesLimit={1}
                                        initialFiles={[data.question.image.url]}
                                        dropzoneText="Upload a image"
                                        onChange={
                                            (files: any) => {
                                                setFieldValue("image", files[0]);
                                                setFieldValue("imageSelected", files[0] ? true : false);
                                            }
                                        }
                                    /> */}
                                    <ImageUpload
                                        header="Question Image"
                                        id="image_questionImage"
                                        src={data.question.image.url}
                                        onChange={(file: any) => {
                                            setFieldValue("image", file);
                                            setFieldValue("imageSelected", file ? true : false);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="primary">{values.imageSelected ? "" : "Click image to upload new file"}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                            <FormFooter
                                onAction={submitForm}
                                submitting={isSubmitting}
                                actionName="Next"
                                disableAction={isSubmitting}
                                backActionName="cancel"
                                onBack={() => {
                                    history.goBack();
                                }} />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik >
    );
}

export default EditStep1;