import React, { useState, useEffect } from 'react';
import Panel from '../../components/Panel';
import { Formik, Form, Field } from 'formik';
import DataProvider from '../../providers/DataProvider';
import { Grid } from '@material-ui/core';
import FormFooter from '../../components/FormFooter';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import EditbleMediaSelector from '../../components/MediaSelectorEditable';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

const schema = Yup.object().shape({
    title: Yup.string().required('required'),
    subTitle: Yup.string().required('required'),
    imageSelected: Yup.bool().oneOf([true], "Please upload a image"),
    media: Yup.array().min(1)
});

export interface IEditSubTopicProps {
}

const EditSubTopic: React.FC<IEditSubTopicProps> = () => {


    const dataProvider = new DataProvider();
    let history = useHistory();
    let location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [mediaError, setMediaError] = useState(true);
    const [mediaArr, setMediaArr] = useState<any[]>();
    const [initialState, setInitialState] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [media, setMedia] = useState<any[]>([]);
    //@ts-ignore
    let topicId = location.state?.topicId;

    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;

    //@ts-ignore
    let company = searchState.company;
    //@ts-ignore
    let department = searchState.department;

    let params = useParams();
    //@ts-ignore
    let subTopicId = params.id;

    useEffect(() => {
        dataProvider.getLearningCenterSubTopic(company.id, department.id, topicId, subTopicId).then(data => {

            setInitialState({
                title: data.title,
                subTitle: data.subTitle,
                order: data.order
            });

            setLoading(false);
            setMedia(data.media);
        }).catch(err => {
            enqueueSnackbar(`Failed to load the sub topic: ${err.message || err}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
            history.goBack();
        })
        //eslint-disable-next-line
    }, []);

    return (
        <Panel>
            {
                loading ?
                    <></>
                    :
                    <Formik
                        validationSchema={schema}
                        enableReinitialize={true}
                        initialValues={initialState}
                        onSubmit={async (values, { setSubmitting }) => {

                            setSubmitting(true);

                            let updateSubTopic = {
                                ...values
                            }

                            dataProvider.updateLearningCenterSubTopic(company.id, department.id, topicId, subTopicId, updateSubTopic)
                            .then(() => {
                                enqueueSnackbar(`Successfully updated the sub topic`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                                setSubmitting(false);
                                history.goBack();
                            })
                            .catch((error) => {
                                enqueueSnackbar(`Failed to update the sub topic: ${error.message || error}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                            });

                        }}
                    >
                        {({ submitForm, isSubmitting, values, setFieldValue }) => (
                            <Form>
                                <Grid container spacing={3} direction="row">
                                    <Grid item container direction="column" xs={4} spacing={3}>
                                        <Grid item >
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Name"
                                                name="title"
                                                variant="filled"
                                                fullWidth
                                            />

                                        </Grid>
                                        <Grid item >
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Subtitle"
                                                name="subTitle"
                                                variant="filled"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item >
                                            <Field
                                                component={TextField}
                                                type="number"
                                                label="Display Order"
                                                name="order"
                                                variant="filled"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <EditbleMediaSelector
                                            dataPath = {`/Companies/${company.id}/Departments/${department.id}/TrainingMaterial/${topicId}/SubTopics`}
                                            error={mediaError}
                                            onMediaChange={(arr: any[]) => {
                                                setMediaError(arr.length > 0 ? false : true);
                                                setFieldValue("media", arr);
                                            }}
                                            initialState={media} />
                                    </Grid>
                                    <Grid item xl={12}>
                                        <FormFooter actionName="save" onAction={submitForm} onBack={() => { history.goBack() }} submitting={isSubmitting} />
                                    </Grid>

                                </Grid>
                            </Form>

                        )
                        }
                    </Formik >
            }

        </Panel>
    );
}

export default EditSubTopic;
