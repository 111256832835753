import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import ExcelExportButton, { ISheet } from '../../../../components/ExcelExportButton';

enum State {
    LOADING = 0,
    GENERATING,
    ERROR,
    EMPTY,
    SUCCESS,
    INITIAL
}

export interface IExcelAllProps {
    report: any;
    state: State
}

const ExcelAll: React.FC<IExcelAllProps> = ({ report, state }) => {

    const [exportSheets, setExportSheets] = useState<ISheet[]>([]);

    useEffect(() => {

        if (!report) return;

        let sheets = [];

        // By Department sheet
        sheets.push({
            sheetName: "By Department",
            sheetColumns: [
                { title: "Department Name", name: "name" },
                { title: "Number of Players", name: "userCount" },
                { title: "Active players", name: "activePlayerCount" },
                { title: "Average Points", name: "averagePoints" }
            ],
            sheetData: report?.byDepartment || []
        });

        // By Game sheet
        sheets.push({
            sheetName: "By Game",
            sheetColumns: [
                { title: "Name", name: "gameName" },
                { title: "Games Played", name: "gamesPlayed" },
                { title: "Answer Accuracy", name: "averageAccuracy" },
                { title: "Play Time", name: "timePlayed" },
            ],
            sheetData: report?.gameStats || []
        });

        // Top Accuracy sheet
        sheets.push({
            sheetName: "Top Accuracy",
            sheetColumns: [
                { title: "Name", name: "name" },
                { title: "Surname", name: "surname" },
                { title: "Email", name: "email" },
                { title: "General", name: "general" },
                { title: "Accuracy", name: "accuracy" }
            ],
            sheetData: report?.top10?.topAccuracy || []
        });

        // Top Points sheet
        sheets.push({
            sheetName: "Top Points",
            sheetColumns: [
                { title: "Name", name: "name" },
                { title: "Surname", name: "surname" },
                { title: "Email", name: "email" },
                { title: "General", name: "general" },
                { title: "Points", name: "points" },
            ],
            sheetData: report?.top10?.topPoints || []
        });

        // Top Games Played sheet
        sheets.push({
            sheetName: "Top Games Played",
            sheetColumns: [
                { title: "Name", name: "name" },
                { title: "Surname", name: "surname" },
                { title: "Email", name: "email" },
                { title: "General", name: "general" },
                { title: "Games Played", name: "gamesPlayed" },
            ],
            sheetData: report?.top10?.topGamesPlayed || []
        });

        // By Percentages sheet
        if (report.percentages) {
            sheets.push({
                sheetName: "By Percentages",
                sheetColumns: [
                    { title: "Percentage", name: "percentage" },
                    { title: "Points", name: "points" },
                    { title: "Games Played", name: "gamesPlayed" },
                    { title: "Time Played", name: "timeSpent" },
                ],
                sheetData: toPercentageArr(report.percentages) || []
            });
        }

        setExportSheets(sheets);

    }, [report]);

    const toPercentageArr = (object: any) => {
        let arr: any[] = [];

        arr[0] = {
            ...object.bottom25,
            percentage: "Bottom 25%"
        };

        arr[1] = {
            ...object.mid50,
            percentage: "Mid 50%"
        };

        arr[2] = {
            ...object.top25,
            percentage: "Top 25%"
        };

        return arr;

    }

    if (state.valueOf() !== 4) {
        return <></>
    }

    return (
        <ExcelExportButton 
            fileName='DataExport' 
            buttonTitle='Download' 
            sheets={exportSheets}
            buttonProps={{size: "small", variant: "outlined"}}
        >
            <Typography style={{ fontWeight: 600, fontSize: 14 }}>Export Page</Typography>
        </ExcelExportButton>
    );
}

export default ExcelAll;
