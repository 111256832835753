import React, { useState } from 'react';
import Tabs, { TabPanelObject } from './Tabs';
import { Paper } from '@material-ui/core';
import AddMultipleChoice from './AddMultipleChoice';
import AddMatchTheTerms from './AddMatchTheTerms';
import { useLocation } from 'react-router-dom';
import AddWordBucket from './AddWordBucket';
import FillInTheBlank from './AddFillInTheBlank';
import ImageMatch from './AddImageMap';
import RichEditor from "../ExtraFunctionality/Editor";
import Crossword from '../ExtraFunctionality/Crossword';


export interface State {
    categoryId: string;
    company: any;
    department: string;
    gameId: string;
    companyName?: string;
    departmentName?: string;
    categoryName?: string;
}

export interface IAddQuestionsProps {
}


const AddQuestions: React.FC<IAddQuestionsProps> = ({ }) => {

    const location = useLocation();
    const state = location.state as State;
    const items: TabPanelObject[] = [
        {
            heading: "Multiple Choice",
            content: <AddMultipleChoice state={state} />,
            index: 0
        },
        {
            heading: "Fill in the blank",
            content: <FillInTheBlank state={state} />,
            index: 1
        },
        {
            heading: "Match the terms",
            content: <AddMatchTheTerms state={state} />,
            index: 2
        },
        {
            heading: "Word bucket",
            content: <AddWordBucket state={state} />,
            index: 3
        },
        {
            heading: "Image match",
            content: <ImageMatch state={state} />,
            index: 4
        },
        {
            heading: "Statement Blanking",
            content: <RichEditor state={state}/>,
            index: 5
        },
        {
            heading: "Crossword",
            content: <Crossword state={state}/>,
            index: 6
        }
    ]

    return (
        <Paper>
            <Tabs items={items} />
        </Paper>
    );
}

export default AddQuestions;

//Force
