import * as React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AddCommunication from './AddCommunication';
import CommunicationsList from './CommunicationsList';
import EditCommunication from './components/EditCommunication';

export default function CommunicationsNavigator(props: any) {

    let { path } = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={`${path}/edit/:id`}>
                    <EditCommunication />
                </Route>
                <Route path={`${path}/add`}>
                    <AddCommunication />
                </Route>
                <Route path={path}>
                    <CommunicationsList />
                </Route>
            </Switch>
        </div>
    );
}
