import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import DataProvider from '../../../providers/DataProvider';
import SearchState from '../../../redux/reducers/SearchState';

export interface ISelectorProps {
    onSelect: any;
    companyInitial: any;
    departmentInitial: any;
    disableCompany: boolean;
}

export default function Selector(props: ISelectorProps) {

    const dataProvider = new DataProvider();

    const [companies, setCompanies] = useState<any[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<any>(props.companyInitial);

    const [departments, setDepartments] = useState<any[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<any>(props.departmentInitial);

    const [selectionCleared, setSelectionCleared] = useState(false);

    const onCompanySelect = (companyId: string) => {
        dataProvider.getDepartments(companyId).then(data => {
            setDepartments(data.sort((a, b) => a.name > b.name ? 1 : -1));
        });
    }

    const onSelect = () => {
        props.onSelect(selectedCompany, selectedDepartment);
    }

    useEffect(() => {
        dataProvider.getCompanies().then(data => {
            setCompanies(data.sort((a, b) => a.name > b.name ? 1 : -1));
        });

        if (props.companyInitial.id !== "") {
            dataProvider.getDepartments(props.companyInitial.id).then(data => {
                setDepartments(data.sort((a, b) => a.name > b.name ? 1 : -1));
            });
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {

        onSelect();
        //eslint-disable-next-line
    }, [selectedCompany, selectedDepartment, selectionCleared])


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Autocomplete
                    fullWidth
                    disabled={props.disableCompany}
                    getOptionSelected={() => true}
                    options={companies}
                    getOptionLabel={(option: any) => option.name}
                    value={selectedCompany}
                    onChange={(e, value) => {
                        if (value) {
                            setSelectedCompany(value);
                            onCompanySelect(value.id);
                            setSelectedDepartment({ id: '', name: '' });
                        } else {
                            setSelectedCompany({ id: '', name: '' });
                            setDepartments([]);
                            setSelectedDepartment({ id: '', name: '' });
                            setSelectionCleared(true);
                        }
                    }}
                    renderInput={params => (
                        <TextField {...params} name="company" label="Company" variant="filled" />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    fullWidth
                    getOptionSelected={() => true}
                    options={departments}
                    getOptionLabel={(option: any) => option.name}
                    value={selectedDepartment}
                    onChange={(e, value) => {
                        if (value) {
                            setSelectedDepartment(value);
                        } else {
                            setSelectedDepartment({ id: '', name: '' });
                            setSelectionCleared(true);
                        }
                    }}
                    renderInput={params => (
                        <TextField {...params} name="company" label="Department" variant="filled" />
                    )}
                />
            </Grid>

        </Grid>
    );
}
