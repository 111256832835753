import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Selectable } from '../questions/AddImageMap';

enum CellState {
    OPEN = 0,
    CHECKED,
    SELECTED_GROUP
}

const useStyles = makeStyles((theme) => ({

    table: {
        borderWidth: 1,
        borderColor: '#a3a3a3',
        borderStyle: 'solid'
    },
    selectedCell: {
        backgroundColor: 'grey',
        borderWidth: 1,
        borderColor: '#a3a3a3',
        borderStyle: 'solid',
        opacity: 0.8

    },
    selectedGroupCell: {
        backgroundColor: 'red',
        borderWidth: 1,
        borderColor: '#a3a3a3',
        borderStyle: 'solid',
        opacity: 0.8

    },
    unselectedCell: {
        borderWidth: 1,
        borderColor: '#a3a3a3',
        borderStyle: 'solid'
    },

}));

export interface IPosition {
    x: number;
    y: number;
}

export interface IGridProps {
    x: number;
    y: number;
    group: Selectable[];
    selectedGroupID: string;
    onGroupAdd: any
}

const Grid: React.FC<IGridProps> = ({ x, y, group, selectedGroupID, onGroupAdd }) => {

    const classes = useStyles();
    const rows = new Array(y).fill(0);
    //const [points, setPoints] = useState<IPosition[]>([]);

    const checkPoint = (a: IPosition, b: IPosition) => {
        return a.x === b.x && a.y === b.y;
    }

    const addPoint = (point: IPosition, selected: boolean) => {
        onGroupAdd(point, selected);
    }

    const getStyle = (state: CellState) => {
        switch (state) {
            case CellState.OPEN:
                return classes.unselectedCell
            case CellState.CHECKED:
                return classes.selectedCell
            case CellState.SELECTED_GROUP:
                return classes.selectedGroupCell
            default:
                return classes.unselectedCell
        }
    }

    const columns = (lenth: number, rowCount: number) => {

        const arr = new Array(lenth).fill(0);

        return (
            arr.map((item, index) => {

                let style = getStyle(CellState.OPEN);
                let checked = false;

                loop: {
                    for (let i = 0; i < group.length; i++) {
                        const singleGroup = group[i];
                        for (let i = 0; i < singleGroup.clickables.length; i++) {
                            const point = singleGroup.clickables[i];
                            if (point.x === (index) && point.y === (rowCount)) {
                                if (singleGroup.id === selectedGroupID) {
                                    style = getStyle(CellState.SELECTED_GROUP);
                                    checked = true;
                                    break loop;
                                } else {
                                    style = getStyle(CellState.CHECKED);
                                    checked = true;
                                    break loop;
                                }
                            }
                        }
                    }
                }

                return (
                    <td className={style} onClick={(event) => {
                        //@ts-ignore
                        let y = event.currentTarget.parentNode.rowIndex;
                        let x = event.currentTarget.cellIndex;

                        addPoint({ x: x, y: y }, checked);
                    }}></td>
                )
            })
        )
    }

    return (
        <table style={{ width: '100%', height: '100%' }} cellSpacing="0" cellPadding="0" className={classes.table} >
            {
                rows.map((rows, index) => {
                    return (
                        <tr>
                            {
                                columns(x, index)
                            }
                        </tr>
                    )
                })
            }
        </table>
    );
}

export default Grid;