import React, { useState, useEffect } from 'react';
import { Grid, TextField as MUITextField, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import DataProvider from '../../../../providers/DataProvider';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { State } from '../AddQuestions';
import { DropzoneArea } from 'material-ui-dropzone';
import FormFooter from '../../../../components/FormFooter';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

const validation = Yup.object().shape({
    question: Yup.string().required('Required'),
    imageSelected: Yup.bool().oneOf([true], "Please select an image")
});

export interface IStep1Props {
    state: State;
    next: any;
}

const Step1: React.FC<IStep1Props> = ({ state, next }) => {

    const dataProvider = new DataProvider();
    const [topics, setTopics] = useState<any[]>([]);
    const [subTopics, setSubtopics] = useState<any[]>([]);
    let history = useHistory();

    const onTopicSelect = (id: string) => {
        if (id) {
            //@ts-ignore
            dataProvider.getLearningCenterSubTopics(state.company.id, state.department.id, id).then(data => {
                setSubtopics(data);
            });
        }
    }

    useEffect(() => {
        //@ts-ignore
        dataProvider.getLearningCenterTopics(state.company.id, state.department.id).then(data => {
            setTopics(data);
        }).catch(err => {
            console.log(err);
        });
    }, []);
    console.log(state);
    return (
        <Formik
            validationSchema={validation}
            initialValues={{
                questionType: 5,
                question: "",
                mediaLink: "",
                topic: { id: "", name: "" },
                subTopic: { id: "", title: "" },
                whereToFind: "",
                image: "",
                imageSelected: false
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {

                console.log(values);

                let object = {
                    questionType: 4,
                    questionText: values.question,
                    mediaLink: values.mediaLink,
                    topic: values.topic,
                    subTopic: values.subTopic,
                    whereToFind: values.whereToFind,
                    imgPath: "",
                    imgUrl: ""
                }

                try {
                    const img = await dataProvider.addImage(values.image, "games_images/");
                    object.imgUrl = img.imgUrl;
                    object.imgPath = img.imgPath;

                    console.log("IMAGE URL IN STEP 1", img.imgUrl);
                    next(object);
                } catch (error) {
                    alert(error)
                }
            }}
        >
            {({ values, submitForm, isSubmitting, setFieldValue, touched, errors }) => (
                <Form style={{ width: '100%' }} >
                    <Grid container justify="space-between" direction="column" style={{ minHeight: '50vh' }}>
                        <Grid item container xs={12} spacing={1}>
                            <Grid container item xs={7} spacing={3}>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Question"
                                        name="question"
                                        variant="filled"
                                        fullWidth
                                        multiline
                                        rows={2}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={5}>
                                <Grid item xs={12}>
                                    <DropzoneArea
                                        showAlerts={false}
                                        filesLimit={1}
                                        dropzoneText="Upload a image"
                                        onChange={
                                            (files: any) => {
                                                setFieldValue("image", files[0]);
                                                setFieldValue("imageSelected", files[0] ? true : false);
                                            }
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="error">{values.imageSelected ? "" : "Please select a image"}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                            <FormFooter
                                onAction={submitForm}
                                submitting={isSubmitting}
                                actionName="Next"
                                disableAction={isSubmitting}
                                backActionName="cancel"
                                onBack={() => {
                                    history.goBack();
                                }} />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik >
    );
}

export default Step1;