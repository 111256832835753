import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Users from '../../pages/Users/UserNavigation';
import Games from '../../pages/games/GameNavigation';
import Calendar from '../../pages/scheduler/CalenderView';
import Achievements from '../../pages/achievements/AchievementNavigator';
import LearningCenter from '../../pages/learningCenter/LearningCenterNavigation';
import Communications from '../../pages/communications/CommunicationsNavigator';
import WrapperRoute from '../../components/RouteWrapper';
import Reports from '../../pages/reports/ReportsNavigator';

export default function ManagerRouter() {

    return (
        <div>
            <Switch>
                <WrapperRoute path="/users">
                    <Users />
                </WrapperRoute>
                <WrapperRoute path="/games">
                    <Games />
                </WrapperRoute>
                <WrapperRoute path="/gameScheduler">
                    <Calendar />
                </WrapperRoute>
                <WrapperRoute path="/achievements">
                    <Achievements />
                </WrapperRoute>
                <WrapperRoute path="/learningCenter">
                    <LearningCenter />
                </WrapperRoute>
                <WrapperRoute path="/communications">
                    <Communications />
                </WrapperRoute>
                <WrapperRoute path="/reports" useContainer={false}>
                    <Reports />
                </WrapperRoute>
            </Switch>
        </div>
    );
}
