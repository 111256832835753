import React, { useState, Fragment } from 'react';
import { Button, Chip, Grid, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom'
import DataProvider from '../../providers/DataProvider';
import CategoryIcon from '@material-ui/icons/Category';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Filter from '../../components/Filter';
import MainLayout from '../../components/MainLayout';
import { useDispatch } from 'react-redux';
import AllActions from '../../redux/actions/Index';
import Dialog from './components/ConfirmGameDelete';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { SignalCellularNull } from '@material-ui/icons';

const GameList = () => {

    //@ts-ignore
    const searchState = useSelector(state => state.searchState)?.searchState;
    const dispatch = useDispatch();
    const dataProvider = new DataProvider();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [games, setGames] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [gameId, setGameId] = useState("0");
    const [warningText, setWarningText] = useState("This action can not be reverted! Are you sure you want to procceed?");
    const [open, setOpen] = useState(false);

    function handleDialogClose(success: boolean, error: any = null) {

        setOpen(false);

        if (success === true) {
            enqueueSnackbar("Game deleted successfully", { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        } else {
            enqueueSnackbar(`Failed to delete game${error != null ? `. Error: ${error}` : "" }`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        }

        dataProvider.getGamesFD(searchState.company.id, searchState.department.id)
        .then(games => {
            setGames(games);
        })
        .catch(error => {
            enqueueSnackbar("Failed to load remaining games: " + error, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
        });

    }

    function disableState(rowData: any) {
        return rowData?.shared?.isChild === true;
    }

    return (
        <MainLayout
            actions={[
                <Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="primary"
                    disabled={searchState.company.id !== "" ? false : true}
                    onClick={() => {
                        history.push("games/share", { company: searchState.company, department: searchState.department })
                    }}>
                    <Typography variant="button">Share Game</Typography>
                </Button>,
                <Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        history.push("games/add")
                    }}>
                    <Typography variant="button">Add Game</Typography>
                </Button>
            ]}
            filters={[
                <Filter 
                    loading={loading} 
                    onSubmit={(company: any, department: any) => {
                        setLoading(true);
                        dataProvider.getGamesFD(company.id, department.id)
                        .then(games => {
                            dispatch(AllActions.searchState.setSearchState({ company: company, department: department }));
                            setGames(games);
                            setLoading(false);
                        })
                        .catch(() => { 
                            setLoading(false)
                        });
                    }} />
            ]}
            content={
                <Fragment>
                    <MaterialTable 
                        title="Games"
                        data={games}
                        isLoading={loading}
                        style={{ width: '100%' }}
                        options={{ actionsColumnIndex: -1 }}
                        columns={[
                            {
                                title: "Name",
                                field: "name",
                                render: rowData => (
                                    <Grid container spacing={1} direction="row" alignItems="center">
                                        <Grid item>
                                            <Typography>{rowData.name}</Typography>
                                        </Grid>
                                        <Grid item>
                                            {
                                                rowData.shared && (
                                                    rowData.shared.isHost && <Chip label="Shared Game" color="secondary" /> ||
                                                    rowData.shared.isChild && <Chip label="Child Game" style={{ backgroundColor: '#b3e6ff' }} />
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            },
                            { title: "Subtitle", field: "subtitle" }
                        ]}
                        actions={[
                            {
                                icon: () => <CategoryIcon />,
                                tooltip: 'View Categories',
                                onClick: (event, rowData) => {
                                    history.push("games/categories/" + rowData.id);
                                }
                            },
                            rowData => (
                                {
                                    icon: () => <EditIcon />,
                                    tooltip: 'Edit game',
                                    disabled: disableState(rowData),
                                    onClick: (event, rowData) => {
                                        history.push("/games/edit/" + rowData.id, { game: rowData });
                                    }
                                }
                            ),
                            rowData => (
                                {
                                    icon: 'delete',
                                    tooltip: 'Delete Game',
                                    disabled: disableState(rowData),
                                    onClick: (event, rowData) => {
                                        
                                        setGameId(rowData.id);

                                        if (rowData.shared && rowData.shared.isHost === true) {
                                            setWarningText("Deleting this game will delete all child games. This can not be reverted! Are you sure you want to procceed?");
                                        }
    
                                        setOpen(true);
                                        
                                    }
                                }
                            )
                        ]}
                    />
                    <Dialog title="Are you sure?" contentText={warningText} isOpen={open} handleClose={handleDialogClose} id={gameId} />
                </Fragment>
            }
        />
    );
};

export default GameList;
