import React, { useState, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import Top10Table from './Top10Table';
import ByDepartment from './ByDepartmentTable';
import GameStats from './GameStats';
import AverageBar from './AverageBar';
import ParticipationPanel from './ParticipationPanel';
import PercentageRow from './PercentageRow';
import { Percentages } from '../../../models/Percentanges';
import InactiveTable from './InactiveTable';
import ByUser from './ByUser';
import UserAnswers from './UserAnswers';

interface IStatsProps {
    report: any;
}

const Stats: React.FC<IStatsProps> = ({ report }) => {

    const [isLoading, setIsLoading] = useState(false);

    return (
        <Grid container spacing={3}>
            {
                !isLoading ?
                    <Fragment>
                        <Grid item xs={4}>
                            <AverageBar avgGames={report?.averages?.avgGamesPlayed} avgPoints={report?.averages?.avgPoints} avgWins={report?.averages?.avgWins} />
                        </Grid>
                        <Grid item xs={8}>
                            <Top10Table top10={report.top10} />
                        </Grid>
                        <Grid item xs={8}>
                            <InactiveTable users={report.inactivePlayers} />
                        </Grid>
                        <Grid item xs={4}>
                            <ParticipationPanel activeUsers={report?.participation?.activeUsers} registeredUsers={report?.participation?.registeredUsers} ratio={report?.participation?.ratio} />
                        </Grid>
                        {report?.percentages != null && <Grid item xs={12}>
                            <PercentageRow data={report?.percentages as Percentages}/>
                        </Grid>}
                        <Grid item xs={7}>
                            <ByDepartment list={report?.byDepartment} />
                        </Grid>
                        <Grid item xs={5}>
                            <GameStats gameStats={report?.gameStats} />
                        </Grid>
                        <Grid item xs={12}>
                            <ByUser userData={report?.userData} dealerData={report?.dealerData} groupedData={report?.groupedData} />
                        </Grid>
                        <Grid item xs={12}>
                            <UserAnswers data={report?.userAnswersData} />
                        </Grid>
                    </Fragment>
                    :
                    <>
                    </>
            }
        </Grid>
    );
}

export default Stats;
