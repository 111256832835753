import React, { useContext, useState } from 'react';
import Panel from '../../components/Panel';
import ManagerBulk from './components/ManagerBulkAdd';
import AdminBulk from './components/AdminBulkAdd';
import CompManagerBulk from './components/CompManagerAddBulk';
import { AuthContext } from '../../providers/AuthProvider';
import { Grid, Button } from '@material-ui/core';
import Roles from '../../models/enums/Roles';
import { green, orange, red } from '@material-ui/core/colors';

export default function AddBulk() {

    const { user } = useContext(AuthContext);
    const [usersInfo, setUsersInfo] = useState<any>({ current: 0, limit: 0 });
    const [usersInfoColor, setUsersInfoColor] = useState<any>({ color: green[700] });

    const renderForm = (role: Roles | undefined) => {

        if (!role) {
            return <h1>Something went wrong</h1>
        }

        if (role === Roles.manager) {
            return <ManagerBulk UsersInfo={usersInfo} onUsersInfoUpdated={(current: any, limit: any) => {
                setUsersInfo({current, limit});
                if (current > limit){
                    setUsersInfoColor({ color: red[700] });
                } else if (current > (limit - 5)){
                    setUsersInfoColor({ color: orange[700] });
                } else {
                    setUsersInfoColor({ color: green[700] });
                }
            }} />
        }

        if (role === Roles.admin) {
            return <AdminBulk UsersInfo={usersInfo} onUsersInfoUpdated={(current: any, limit: any) => {
                setUsersInfo({current, limit});
                if (current > limit){
                    setUsersInfoColor({ color: red[700] });
                } else if (current > (limit - 5)){
                    setUsersInfoColor({ color: orange[700] });
                } else {
                    setUsersInfoColor({ color: green[700] });
                }
            }} />
        }

        if (role === Roles.comp_manager) {
            return <CompManagerBulk UsersInfo={usersInfo} onUsersInfoUpdated={(current: any, limit: any) => {
                setUsersInfo({current, limit});
                if (current > limit){
                    setUsersInfoColor({ color: red[700] });
                } else if (current > (limit - 5)){
                    setUsersInfoColor({ color: orange[700] });
                } else {
                    setUsersInfoColor({ color: green[700] });
                }
            }} />
        }

    };

    return (
        <Panel>
            <Grid container spacing={3}>
                <Grid item container justify="space-between">
                    <div>Users added: <span style={usersInfoColor}>{usersInfo.current}/{usersInfo.limit}</span></div>
                    <Button href="/template.xlsx" variant="outlined" color="primary" style={{ marginRight: '21px' }}>Template</Button>
                </Grid>
                <Grid item container>
                    {
                        renderForm(user?.role)
                    }
                </Grid>
            </Grid>

        </Panel>
    );
}
