import React, { useState, useContext } from 'react';
import Panel from '../../../components/Panel';
import { Grid, Divider, Checkbox, FormControlLabel, TextField as MUITextFiled, Typography } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import FormFooter from '../../../components/FormFooter';
import DataProvider from '../../../providers/DataProvider';
import { useHistory } from 'react-router-dom';
import { DropzoneArea } from 'material-ui-dropzone';
import CriteriaSelector from '../../../components/CriteriaSelector';
import MonetarySelector from '../../../components/MonetarySelector';
import AchievementF from '../../../models/formModels/AchievementF';
import { AuthContext } from '../../../providers/AuthProvider';

const validation = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    criteria: Yup.array().min(1, "Should add atleast 1 criteria").required(),
    imageSelected: Yup.bool().oneOf([true], "Please select a image")
});

const initialValues: FormValues = {
    name: '',
    description: '',
    criteria: [],
    isTiered: false,
    endDate: '',
    tiers: [],
    imgUrl: '',
    imageSelected: false

}

interface FormValues {
    name: string,
    description: string,
    criteria: any[],
    isTiered: boolean,
    endDate: string | undefined,
    tiers: any[],
    imgUrl: string,
    imageSelected: boolean
}

const AddAchievement: React.FC<any> = () => {

    const { user } = useContext(AuthContext);

    const [isMonetary, setIsMonetary] = useState(false);
    const [isTiered, setIsTiered] = useState(false);
    const dataProvider = new DataProvider();
    const [endDate, setEndDate] = useState<Date>(new Date());
    let history = useHistory();

    const initialValues: FormValues = {
        name: '',
        description: '',
        criteria: [],
        isTiered: false,
        endDate: '',
        tiers: [],
        imgUrl: '',
        imageSelected: false

    }


    const formatDate = (date: string) => {
        let dateObject: Date = new Date(date);
        var day = dateObject.getDate();
        var month: number = dateObject.getMonth() + 1;
        var year = dateObject.getFullYear();

        var dateFormated = year + "-" + pad(month.toString(), 2, "0") + "-" + pad(day.toString(), 2, "0");
        return dateFormated;
    };

    const pad = (n: string, width: any, z: any) => {

        let difference = width - n.length;
        let padded = n;

        if (difference > 0) {

            for (let index = 0; index < difference; index++) {
                padded = "0" + padded;
            }
        }
        return padded;
    }

    const onSubmit = async (values: FormValues, setSubmitting: any) => {
        let achievement: AchievementF = {
            company: user?.company || "",
            department: user?.department || "",
            name: values.name,
            description: values.description,
            imgUrl: '',
            isMonetary: isMonetary,
            isTiered: values.isTiered,
            endDate: values.isTiered ? values.endDate : null,
            criteriaList: values.criteria,
            tierList: values.tiers
        }

        setSubmitting(true);
        try {
            let imageRef = await dataProvider.addImage(values.imgUrl, "achievements_images/");
            achievement.imgUrl = imageRef.imgUrl;
        } catch (error) {
            console.log("Upload error: ", error);
        }



        let addAchievement = dataProvider.addAchievement(achievement.company, achievement.department, achievement);

        addAchievement.then(res => {
            setSubmitting(false);
            history.goBack();
        })
    };

    const checkMonetary = (arr: any[]) => {

        let isMonetary = false;
        arr.forEach(item => {
            if (item.reward > 0)
                isMonetary = true;

        });

        return isMonetary;
    }

    return (
        <Panel helperText="Please fill in all the fields and upload an achievement image. Please make sure that atleast 1 criteria set is added and if 'is Tiered award' is selected that atleast 1 tier is added.">
            <Grid container spacing={3} direction="column" >

                <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={(values, { setSubmitting }) => {
                        //@ts-ignore
                        onSubmit(values, setSubmitting)
                    }}>
                    {({ submitForm, isSubmitting, setFieldValue, errors, touched, values }) => (
                        <Grid container item >
                            <Form style={{ width: "100%" }}>
                                <Grid container direction="column" spacing={3}>
                                    <Grid item container xs={6} spacing={3}>
                                        <Grid item xs={6}>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Achievement Name"
                                                name="name"
                                                variant="filled"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Sub title"
                                                name="description"
                                                variant="filled"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid item container xs={12} spacing={3} direction="row">
                                        <Grid item xs={8}>
                                            <CriteriaSelector onListChange={(list: any[]) => setFieldValue("criteria", list)} error={errors['criteria']?.toString()} />
                                        </Grid>

                                        <Grid item container xs={4} direction="column">
                                            <Grid item>
                                                <DropzoneArea showAlerts={false} filesLimit={1} dropzoneText="Please select a image" onChange={
                                                    (files: any) => {
                                                        //console.log(files[0] ? true : false);
                                                        setFieldValue("imageSelected", files[0] ? true : false);
                                                        setFieldValue("imgUrl", files[0]);
                                                    }
                                                } />
                                            </Grid>
                                            <Grid item>
                                                <Typography color="error">{values.imageSelected ? "" : "Please select a image"}</Typography>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<Checkbox onChange={(event) => {
                                                setFieldValue("isTiered", event.target.checked);
                                                setIsTiered(event.target.checked);
                                            }} />}
                                            label="Is Tiered award"
                                        />

                                    </Grid>

                                    {
                                        isTiered ?
                                            <Grid item container xs={10} spacing={3}>
                                                <Grid item>
                                                    <MUITextFiled
                                                        id="date"
                                                        label="End Date"
                                                        type="date"
                                                        defaultValue={formatDate(endDate.toString())}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={event => {
                                                            let endDate = event.target.value;
                                                            setFieldValue("endDate", endDate);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <MonetarySelector onTiersChange={(tiers: any[]) => {
                                                        setFieldValue("tiers", tiers);
                                                        var bool = checkMonetary(tiers);
                                                        setIsMonetary(bool);
                                                    }} />
                                                </Grid>
                                            </Grid>
                                            :
                                            <></>
                                    }
                                    <Grid item>
                                        <FormFooter onAction={submitForm} onBack={() => { history.goBack() }} submitting={isSubmitting} />
                                    </Grid>
                                </Grid>
                            </Form>

                        </Grid>
                    )}
                </Formik>
            </Grid>
        </Panel >
    );
}

export default AddAchievement;
