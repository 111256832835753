import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Divider, LinearProgress, makeStyles } from '@material-ui/core';
import Chart from 'react-apexcharts';

const useStyles = makeStyles((theme) => ({
    ratioContainer: {
        marginTop: 20
    },
    subText: {
        color: '#b8b8b8',
        fontSize: 20
    },
    percentage: {
        fontSize: 24
    }
}));

export interface IParticipationPanelProps {
    registeredUsers: number | undefined;
    activeUsers: number | undefined;
    ratio: number | undefined;
}

const ParticipationPanel: React.FC<IParticipationPanelProps> = ({ registeredUsers = 0, activeUsers = 0, ratio }) => {

    const [inActive, setInactive] = useState(0);
    const [labels, setLabels] = useState(['Active users', 'inactive users']);
    const [options, setOptions] = useState({
        legend: {
            show: false
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: function (value: any) {
                    return value.label;
                }
            }
        },
        labels: labels,
        toolTip: {
            enabled: false
        }
    });
    const [series, setSeries] = useState([70, 20]);

    const classes = useStyles();

    let ratioPercentage = ratio || 0;
    ratioPercentage = Math.floor(ratioPercentage * 100);

    const calcRadial = () => {

        let activeRadial = 180 * (activeUsers / registeredUsers);
        let inActiveRadial = 180 - activeRadial;

        setSeries([activeRadial, inActiveRadial]);
    }

    useEffect(() => {
        calcRadial();

        setInactive(registeredUsers - activeUsers);
    }, [registeredUsers, activeUsers]);

    return (


        <Paper style={{ padding: 10 }}>
            <Grid container direction="column" spacing={3}>
                <Grid item xs={4}>
                    <Chart
                        options={options}
                        series={series}
                        type="donut"
                        width="380"
                    />
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid container item xs={12}>
                    <Grid item container justify="space-between" xs={12}>
                        <Grid item xs={8}>
                            <Typography variant="h5">Inactive Players:</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="h5">{inActive}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container justify="space-between" xs={12}>
                        <Grid item xs={8}>
                            <Typography variant="h5">Active Players:</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="h5">{activeUsers}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container justify="space-between" xs={12}>
                        <Grid item xs={8}>
                            <Typography variant="h5">Registered Players:</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="h5">{registeredUsers}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.ratioContainer}>
                        <Grid item xs={12}>
                            <Typography variant="h4" className={classes.subText}>PARTICIPATION RATE</Typography>
                        </Grid>
                        <Grid item container justify="center">
                            <Grid item xs={2}>
                                <Typography variant="h1" className={classes.percentage}>{ratioPercentage}%</Typography>
                            </Grid>
                            <Grid item xs={10} style={{ marginTop: 15 }}>
                                <LinearProgress variant="determinate" value={ratioPercentage} />
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default ParticipationPanel;