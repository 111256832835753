import * as React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import PeopleIcon from '@material-ui/icons/People';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import ChatIcon from '@material-ui/icons/Chat';
import BarChartIcon from '@material-ui/icons/BarChart';

export interface IManangerMenuProps {
}

export default function ManangerMenu(props: IManangerMenuProps) {

    const history = useHistory();
    let path = useLocation();

    const matchRoute = (route: string) => {
        let match = path.pathname.search(route);
        if (match === 0)
            return true;
        return false;
    }

    return (
        <div >
            <ListItem button onClick={() => history.push("/users")} selected={matchRoute("/users")}>
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
            </ListItem>
            <ListItem button onClick={() => history.push("/games")} selected={matchRoute("/games")}>
                <ListItemIcon>
                    <SportsEsportsIcon />
                </ListItemIcon>
                <ListItemText primary="Games" />
            </ListItem>
            <ListItem button onClick={() => history.push("/gameScheduler")} selected={matchRoute("/gameScheduler")}>
                <ListItemIcon>
                    <DateRangeIcon />
                </ListItemIcon>
                <ListItemText primary="Game scheduler" />
            </ListItem>
            <ListItem button onClick={() => history.push("/achievements")} selected={matchRoute("/achievements")}>
                <ListItemIcon>
                    <EmojiEventsIcon />
                </ListItemIcon>
                <ListItemText primary="Achievements" />
            </ListItem><ListItem button onClick={() => history.push("/learningCenter")} selected={matchRoute("/learningCenter")}>
                <ListItemIcon>
                    <ImportContactsIcon />
                </ListItemIcon>
                <ListItemText primary="Learning Center" />
            </ListItem>
            <ListItem button onClick={() => history.push("/communications")} selected={matchRoute("/communications")}>
                <ListItemIcon>
                    <ChatIcon />
                </ListItemIcon>
                <ListItemText primary="Communications" />
            </ListItem>
            <ListItem button onClick={() => history.push("/reports")} selected={matchRoute("/reports")}>
                <ListItemIcon>
                    <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
            </ListItem>
        </div>
    );
}
