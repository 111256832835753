import React, { useContext } from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import Roles from '../../models/enums/Roles';
import AdminAddTopic from './components/AdminAddTopic';
import ManagerAddTopic from './components/ManagerAddTopic';
import CompManagerAddTopic from './components/CompManagerAddTopic';




const AddTopic = () => {

    const { user } = useContext(AuthContext);

    const renderForm = (role: Roles | undefined) => {

        if (!role) {
            return <h1>Something went wrong</h1>
        }

        if (role === Roles.manager) {
            return <ManagerAddTopic />
        }

        if (role === Roles.admin) {
            return <AdminAddTopic />
        }

        if (role === Roles.comp_manager) {
            return <CompManagerAddTopic />
        }
    };

    return (
        <>
            {
                renderForm(user?.role)
            }
        </>
    );
}

export default AddTopic;
