import React, { useState, useEffect } from 'react';
import Panel from '../../../components/Panel';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Grid } from '@material-ui/core';
import MediaSelector from '../../../components/MediaSelector';
import EditbleMediaSelector from '../../../components/MediaSelectorEditable';
import FormFooter from '../../../components/FormFooter';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import DataProvider from '../../../providers/DataProvider';

interface Communication {
    company: string;
    department: string;
    id: string;
    title: string;
    media: any[];
}

export interface IEditCommunicationProps {
}

const EditCommunication: React.FC<IEditCommunicationProps> = ({ }) => {

    let dataprovider = new DataProvider();

    const [mediaError, setMediaError] = useState(false);
    const [media, setMedia] = useState<any[]>();
    const [loading, setLoading] = useState(true);
    const [initialState, setInitialState] = useState<Communication>({
        company: "",
        department: "",
        id: "",
        media: [],
        title: "Test"
    });
    const placeholder: any = {};

    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    useEffect(() => {

        let state: any = location.state;
        //@ts-ignore
        let id: any = params.id;

        dataprovider.getCommunication(state.company, state.department, id).then(data => {
            let communication: Communication = data;
            setInitialState(communication);
            setMedia(communication.media);
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }, []);

    return (
        <Panel isLoading={loading}>
            <Formik
                enableReinitialize={true}
                initialValues={initialState}
                onSubmit={(values, { setSubmitting }) => {

                    let communication: any = {
                        ...values,
                        sendDate: new Date()
                    }

                    dataprovider.updateCommunication(values.id, communication).then(data => {
                        setSubmitting(false);
                        history.goBack();
                    }).catch(err => {
                        setSubmitting(false)
                        console.log(err);
                    });
                }}
            >
                {({ submitForm, isSubmitting, errors, touched, setFieldValue, values }) => (
                    <Form>
                        <Grid container direction="column" spacing={3}>
                            <Grid item xs={4}>
                                <Field
                                    variant="filled"
                                    component={TextField}
                                    name="title"
                                    type="text"
                                    label="Title"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item>
                                <EditbleMediaSelector
                                    dataPath = {`/Communication`}
                                    initialState={media}
                                    error={mediaError}
                                    heading="Message content"
                                    onMediaChange={(arr: any[]) => {
                                        setMediaError(arr.length > 0 ? false : true);
                                        setFieldValue("media", arr);
                                    }} />
                            </Grid>
                            <Grid item>
                                <FormFooter onBack={() => { history.goBack() }} actionName="Update" onAction={() => { submitForm() }} submitting={isSubmitting} />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Panel>
    );
}

export default EditCommunication;