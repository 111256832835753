import React, { useState, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LinearProgress, Grid } from '@material-ui/core';
import FirebaseProvider from '../../../providers/FirestoreProvider';
import { useSelector } from 'react-redux';
import LoadingButton from '../../../components/LoaderButton';

interface IDialogProps {
    isOpen: boolean;
    handleClose: any;
    title: string;
    contentText: string;
    user: any;
}

const AlertDialog: React.FC<IDialogProps> = ({ isOpen, handleClose, title, contentText, user }) => {

    const [loading, setLoading] = useState(false);

    function handleConfirm() {
        
        const addDuplicateUser = FirebaseProvider.functions().httpsCallable('addDuplicateUser');

        setLoading(true);
        
        addDuplicateUser(user).then((response) => {

            if (response?.data?.success !== true) {
                handleClose(false, response?.data?.error || "no additional information available");
            } else {
                handleClose(true);
            }

            setLoading(false);

        }).catch(err => {
            
            handleClose(false, err)
            setLoading(false);

        })

    }

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" align="center">
                        {contentText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Button onClick={handleClose} color="primary" disabled={loading}>
                                Cancel
                        </Button>
                        </Grid>
                        <Grid item>
                            <LoadingButton text="Continue" loading={loading} onClick={handleConfirm} color="secondary" />
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AlertDialog;
