import React, { useState } from 'react';
import Panel from '../../components/Panel';
import { Formik, Form, Field } from 'formik';
import DataProvider from '../../providers/DataProvider';
import { Grid } from '@material-ui/core';
import FormFooter from '../../components/FormFooter';
import { useHistory, useLocation } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { DropzoneArea } from 'material-ui-dropzone';
import EditbleMediaSelector from '../../components/MediaSelectorEditable';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

const schema = Yup.object().shape({
    title: Yup.string().required('required'),
    subtitle: Yup.string().required('required'),
    imageSelected: Yup.bool().oneOf([true], "Please upload a image"),
    media: Yup.array().min(1)
});

export interface IAddSubTopicProps {
}

const AddSubTopic: React.FC<IAddSubTopicProps> = () => {


    const dataProvider = new DataProvider();
    let history = useHistory();
    let location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [mediaError, setMediaError] = useState<boolean>(false);
    const [image, setImage] = useState<any>();

    //@ts-ignore
    let topicId = location.state?.topicId;

    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;

    //@ts-ignore
    let company = searchState.company;
    //@ts-ignore
    let department = searchState.department;

    return (
        <Panel>
            <Formik
                validationSchema={schema}
                initialValues={{
                    title: "",
                    subtitle: "",
                    order: 0,
                    imageSelected: "",
                    media: ""
                }}
                onSubmit={async (values, { setSubmitting }) => {

                    let subTopic = {
                        title: values.title,
                        subTitle: values.subtitle,
                        media: values.media,
                        order: values.order,
                        imgUrl: "",
                        imgPath: ""
                    }

                    try {
                        let imgLink = await dataProvider.addImage(image, "learningCenter_files/");
                        subTopic.imgUrl = imgLink.imgUrl;
                        subTopic.imgPath = imgLink.imgPath;
                    } catch (error) {
                        console.log(error);
                    }

                    dataProvider.addLearningCenterSubTopic(company.id, department.id, topicId, subTopic)
                    .then(() => {
                        dataProvider.toggleLearningCenterTopic(company.id, department.id, topicId, false);
                        enqueueSnackbar(`Successfully saved the sub topic`, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                        history.goBack()
                    })
                    .catch((error) => {
                        enqueueSnackbar(`Failed to save the sub topic: ${error.message || error}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } });
                    });

                    setSubmitting(false);
                }}
            >
                {({ submitForm, isSubmitting, values, setFieldValue, errors }) => (
                    <Form>
                        <Grid container spacing={3} direction="row">
                            <Grid item container direction="column" xs={4} spacing={3}>
                                <Grid item >
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Name"
                                        name="title"
                                        variant="filled"
                                        fullWidth
                                    />

                                </Grid>
                                <Grid item >
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Subtitle"
                                        name="subtitle"
                                        variant="filled"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item >
                                    <Field
                                        component={TextField}
                                        type="number"
                                        label="Display Order"
                                        name="order"
                                        variant="filled"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <EditbleMediaSelector
                                    dataPath = {`/Companies/${company.id}/Departments/${department.id}/TrainingMaterial/${topicId}/SubTopics`}
                                    error={mediaError}
                                    onMediaChange={(arr: any[]) => {
                                        setMediaError(arr.length > 0 ? false : true);
                                        setFieldValue("media", arr);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={12}>
                                <FormFooter onAction={submitForm} onBack={() => { history.goBack() }} submitting={isSubmitting} />
                            </Grid>

                        </Grid>
                    </Form>
                )}
            </Formik >
        </Panel>
    );
}

export default AddSubTopic;
