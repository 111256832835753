import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import ExcelExportButton, { ISheet } from '../../../../components/ExcelExportButton';

export interface IExportDefault {
    data: any[];
    columns: any[];
}

const ExportDefault: React.FC<IExportDefault> = ({ data, columns }) => {

    const [exportSheets, setExportSheets] = useState<ISheet[]>([]);

    useEffect(() => {

        if (!columns || !data) return;

        let sheets = [];

        sheets.push({
            sheetName: "User Answers",
            sheetColumns: columns.map(c => {return { title: c.title, name: c.field }}),
            sheetData: data || []
        });

        setExportSheets(sheets);

    }, [columns, data]);

    return (
        <ExcelExportButton 
            fileName='DataExport' 
            buttonTitle='Download' 
            sheets={exportSheets}
            buttonProps={{size: "small", variant: "outlined", style: { backgroundColor: 'white' }}}
        >
            <Typography style={{ fontWeight: 600, fontSize: 14 }}>Export</Typography>
        </ExcelExportButton>
    );
}

export default ExportDefault;