import React, { useState, ReactChildren, ReactElement } from 'react';
import { Route, RouteProps, RouteChildrenProps } from 'react-router-dom';
import { Container } from '@material-ui/core';

interface IRouteWrapperProps {
    useContainer?: boolean;
    children: ReactElement;
    path: string;
}

const RouteWrapper: React.FC<IRouteWrapperProps> = ({ useContainer = true, children, path }) => {
    return (
        <Route
            path={path}
            render={({ location }) =>
                (
                    <>
                        {
                            useContainer ?
                                <Container maxWidth="lg" style={{ paddingTop: 40, paddingBottom: 40 }}>
                                    {children}
                                </Container>
                                :
                                children
                        }
                    </>

                )
            }
        />
    );
}

export default RouteWrapper;