import * as React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import UserList from './UserList';
import AddBulk from './AddBulk';
import AddEditUser from './AddEditUser';

export interface IAppProps {
}

export default function UserNavigation(props: any) {

    const { path } = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={[ `${path}/add`, `${path}/edit/:id` ]}>
                    <AddEditUser />
                </Route>
                <Route path={`${path}/addBulk`} >
                    <AddBulk />
                </Route>
                <Route path={path}>
                    <UserList />
                </Route>
            </Switch>
        </div>
    );
}
