import React, { useState, useEffect, FC, useContext } from 'react';
import { Grid, TextField } from '@material-ui/core';
import DataProvider from '../providers/DataProvider';
import { Autocomplete } from '@material-ui/lab';
import LoadingBtn from '../components/LoaderButton';
import { useSelector } from 'react-redux';
import { AuthContext } from '../providers/AuthProvider';
import Roles from '../models/enums/Roles';

export interface IFilter {
    onSubmit: any;
    loading: boolean;
    onlyCompany?: boolean;
    allowCompanyOnlySearch?: boolean;
}

const Filter: FC<IFilter> = ({ onSubmit, loading, onlyCompany, allowCompanyOnlySearch }) => {

    const [companies, setCompanies] = useState<any[]>([]);
    const [departments, setDepartments] = useState<any[]>([]);
    const [company, setCompany] = useState<any>(null);
    const [department, setdepartment] = useState<any>(null);
    const dataProvider = new DataProvider();
    const { user } = useContext(AuthContext);

    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;

    const handleSubmit = () => {

        if (company && onlyCompany) {
            onSubmit(company);
        }

        if (company && (allowCompanyOnlySearch || department)) {
            onSubmit(company, department);
        }
    };

    const fetchDepartments = (companyId: string) => {
        if (companyId !== "")
            dataProvider.getDepartments(companyId).then(data => { setDepartments(data.sort((a,b) => a.name > b.name ? 1 : -1)) });
    }

    useEffect(() => {

        async function fetchData() {
            try {
                const companies = await dataProvider.getCompanies();
                setCompanies(companies.sort((a, b) => a.name > b.name ? 1 : -1));

            } catch (error) {
                console.log(error);
            }
        };

        switch (user?.role) {
            case Roles.comp_manager: {
                setCompany(searchState.company);

                if (onlyCompany) {
                    onSubmit(searchState.company, "");
                }

                if (!onlyCompany) {
                    fetchDepartments(searchState.company.id);
                }

                if (searchState.department?.id !== "") {
                    setdepartment(searchState.department);
                    onSubmit(searchState.company, searchState.department);
                }
            }
                break;
            case Roles.manager: {
                onSubmit(searchState.company, searchState.department);
            }
                break;
            case Roles.admin: {
                fetchData();

                if (searchState.company.id !== "") {
                    setCompany(searchState.company);
                    fetchDepartments(searchState.company.id);
                }

                if (searchState.department?.id !== "") {
                    setdepartment(searchState.department);
                    //fetchDepartments(searchState.company.id);
                    onSubmit(searchState.company, searchState.department);
                }
            }
                break;
            default:
                break;
        }



        // if (user?.role === Roles.manager) {
        //     onSubmit(searchState.company, searchState.department);
        // }

        // if (searchState.company.id !== "" && searchState.department.id !== "" && user?.role === Roles.admin) {
        //     setCompany(searchState.company);
        //     setdepartment(searchState.department);
        //     fetchDepartments(searchState.company.id);
        //     onSubmit(searchState.company, searchState.department);
        // }

        // if (searchState.company && !searchState.department) {
        //     setCompany(searchState.company);
        //     setdepartment(null);
        //     fetchDepartments(searchState.company.id);
        // }

        // if (user?.role === Roles.admin) {
        //     fetchData();
        // }

        // // if (user?.role === Roles.comp_manager) {
        // //     setCompany(searchState.company);
        // //     onSubmit(searchState.company, "");
        // // }
        // if (user?.role === Roles.comp_manager && onlyCompany) { 
        //     onSubmit(searchState.company, "");
        // }
        // //eslint-disable-next-line
    }, []);

    if (user?.role === Roles.manager) {
        return <></>
    }

    return (
        <Grid container spacing={3} justify="flex-start" alignItems="center">
            {
                user?.role === Roles.comp_manager ?
                    <></>
                    :
                    <Grid item xs={5}>
                        <Autocomplete
                            options={companies}
                            getOptionLabel={item => item.name ? item.name : ""}
                            getOptionSelected={() => true}
                            value={company}
                            fullWidth
                            renderInput={params => <TextField {...params} label="Company" variant="filled" fullWidth />}
                            onChange={(e, value) => {
                                if (value) {
                                    fetchDepartments(value.id);
                                    setCompany(value);
                                } else {
                                    setCompany(null);
                                }
                                setDepartments([]);
                                setdepartment(null);
                            }}
                        />
                    </Grid>
            }
            {
                onlyCompany ?
                    <></>
                    :
                    <Grid item xs={5}>
                        <Autocomplete
                            options={departments}
                            getOptionLabel={item => item.name}
                            getOptionSelected={() => true}
                            value={department}
                            fullWidth
                            renderInput={params => <TextField {...params} label="Department" variant="filled" fullWidth />}
                            onChange={(e, value) => {
                                if (value) {
                                    setdepartment(value);
                                } else {
                                    setdepartment(null);
                                }

                            }}
                        />
                    </Grid>
            }
            {
                user?.role === Roles.comp_manager && onlyCompany ?
                    <></>
                    :
                    <Grid item xs={2}>
                        <LoadingBtn variant="contained" color="primary" text="Search" onClick={handleSubmit} loading={loading} />
                    </Grid>
            }
        </Grid>
    );
}

export default Filter;
