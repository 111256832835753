import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Typography, InputAdornment } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import MaterialTable from 'material-table';

export interface IAppProps {
    onTiersChange: any;
}

const MonetarySelector: React.FC<IAppProps> = ({ onTiersChange }) => {

    const [tiers, setTiers] = useState<any[]>([]);


    const [budgetState, setBudget] = useState<number>();
    const [name, setName] = useState<any>();
    const [amount, setAmount] = useState<any>();
    const [reward, setReward] = useState<any>();
    const [position, setPosition] = useState<any>({ id: 0, name: "" });
    const [positionOptions, setPositionOptions] = useState<any[]>([
        { id: 0, name: "Top" }
    ]);

    const makeid = (length: number) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const clearFields = () => {
        setName("");
        setPosition({ id: 0, name: "" });
        setAmount(NaN);
        setReward(NaN);
    }

    const submit = () => {
        addStateArr({ name: name, position: position, amount: isNaN(amount) ? 0 : parseInt(amount), reward: isNaN(reward) ? 0 : reward });
        clearFields();
    }

    const addStateArr = (item: any) => {
        let id = makeid(4);
        item = { ...item, id };

        setTiers([...tiers, item]);
    }

    const removeStateArr = (id: any) => {
        let placehodler: any[] = tiers;

        placehodler = placehodler.filter((value, index, array) => { return value.id !== id });

        setTiers(placehodler);
    }

    const calculateBudget = () => {
        let budget: number = 0;

        tiers.forEach(tier => {

            //budget = budget + (tier.amount * tier.reward);
            budget += Number(tier.reward);

        });

        setBudget(budget);
    };

    useEffect(() => {

        calculateBudget();
        if (tiers.length !== 0) {
            setPositionOptions([
                { id: 1, name: "Next" },
                { id: 2, name: "Everyone Else" }
            ]);
        } else {
            setPositionOptions([
                { id: 0, name: "Top" }
            ]);
        }

        onTiersChange(tiers, budgetState);


        //eslint-disable-next-line
    }, [tiers]);


    return (
        <Grid container direction="column" spacing={3}>
            <Grid item container direction="row" spacing={3}>
                <Grid item xs={4}>
                    <TextField
                        value={name}
                        label="Award Name"
                        variant="filled"
                        fullWidth
                        onChange={(event) => {
                            setName(event.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        value={position}
                        options={positionOptions}
                        getOptionLabel={(item) => item.name}
                        onChange={(event, item) => {
                            setPosition(item);
                        }}
                        getOptionSelected={(option, value) => true}
                        renderInput={(params) => <TextField {...params} label="Placement" variant="filled" />}
                    />
                </Grid>
                {/* <Grid item xs={2}>
                    <TextField
                        disabled={position.id === 2 ? true : false}
                        value={amount}
                        type="number"
                        label="Positions"
                        variant="filled"
                        fullWidth
                        onChange={(event) => {
                            setAmount(event.target.value);
                        }}
                    />
                </Grid> */}
                <Grid item xs={2}>
                    <TextField
                        value={reward}
                        type="number"
                        label="Reward"
                        variant="filled"
                        fullWidth
                        onChange={(event) => {
                            setReward(event.target.value);
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Button variant="contained" color="primary" onClick={submit}>
                        Add
                    </Button>
                </Grid>

            </Grid>
            <Grid item>
                <MaterialTable style={{ width: '100%', overflow: 'auto', height: "200px" }}
                    columns={[
                        { title: "Award Name", field: "name" },
                        { title: "Placement", field: "position.name" },
                        //{ title: "Position", field: "amount" },
                        { title: "Reward", field: "reward" }
                    ]}
                    data={tiers}
                    options={{
                        search: false,
                        showTitle: false,
                        paging: false,
                        toolbar: false,
                        actionsColumnIndex: -1
                    }}
                    actions={[
                        {
                            icon: 'delete',
                            tooltip: 'Delete',
                            onClick: (event, rowData) => removeStateArr(rowData.id)
                        }
                    ]}

                />
            </Grid>
            <Grid item container justify="flex-end">
                <Typography variant="h6"> Budget: R{budgetState}</Typography>
            </Grid>
        </Grid >
    );
}

export default MonetarySelector;
