import React, { } from 'react';
import { Paper, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.primary.main
    }
}));

export interface ISelectStateProps {
}

export default function SelectState(props: ISelectStateProps) {

    //@ts-ignore
    let searchState = useSelector(state => state.searchState);
    searchState = searchState.searchState;

    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <ListItem >
                <ListItemIcon>
                    <BusinessIcon />
                </ListItemIcon>
                <ListItemText primary={searchState.company.name ? searchState.company.name : "  -  "} primaryTypographyProps={{ variant: "body1", style: { color: 'white' } }} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <LocationCityIcon />
                </ListItemIcon>
                <ListItemText primary={searchState.company.name ? searchState?.department?.name : "  -  "} primaryTypographyProps={{ variant: "body1", noWrap: true, style: { color: 'white' } }} />
            </ListItem>
        </Paper>
    );
}
