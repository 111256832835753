import React, {useEffect, useState} from 'react';
import { Button, Typography } from '@material-ui/core';
import ExcelExportButton, { ISheet } from '../../../../components/ExcelExportButton';

export interface ISuzukiReports {
    byUser: any[];
    byDealer: any[];
    byRegion: any[];
}

const SuzukiReports: React.FC<ISuzukiReports> = ({ byUser, byDealer, byRegion }) => {

    const [exportSheets, setExportSheets] = useState<ISheet[]>([]);

    useEffect(() => {

        if (!byUser || !byDealer || !byRegion) return;

        let sheets = [];

        // By User sheet
        sheets.push({
            sheetName: "By User",
            sheetColumns: [
                { title: "First Name", name: "firstName" },
                { title: "Surname", name: "lastName" },
                { title: "Email", name: "email" },
                { title: "General", name: "general" },
                { title: "Game Name", name: "gameName" },
                { title: "Game Date", name: "gameDate" },
                { title: "Accuracy", name: "accuracy" },
                { title: "Top Points", name: "points" },
                { title: "Region", name: "region" }
            ],
            sheetData: byUser || []
        });

        // By Dealer sheet
        sheets.push({
            sheetName: "By Dealer",
            sheetColumns: [
                { title: "First Name", name: "firstName" },
                { title: "Surname", name: "lastName" },
                { title: "Email", name: "email" },
                { title: "General", name: "general" },
                { title: "Accuracy", name: "accuracy" },
                { title: "Top Points", name: "points" },
                { title: "Overall Dealer %", name: "dealerPercentage" },
                { title: "Region", name: "region" },
            ],
            sheetData: byDealer || []
        });

        // Top Region sheet
        sheets.push({
            sheetName: "Top Region",
            sheetColumns: [
                { title: "First Name", name: "firstName" },
                { title: "Surname", name: "lastName" },
                { title: "Email", name: "email" },
                { title: "General", name: "general" },
                { title: "Accuracy", name: "accuracy" },
                { title: "Top Points", name: "points" },
                { title: "Region", name: "region" }
            ],
            sheetData: byRegion || []
        });

        setExportSheets(sheets);

    }, [byUser, byDealer, byRegion]);

    return (
        <ExcelExportButton 
            fileName='DataExport' 
            buttonTitle='Download' 
            sheets={exportSheets}
            buttonProps={{size: "small", variant: "outlined", style: { backgroundColor: 'white' }}}
        >
            <Typography style={{ fontWeight: 600, fontSize: 14 }}>Export Page</Typography>
        </ExcelExportButton>
    );
}

export default SuzukiReports;
