import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Autocomplete } from '@material-ui/lab';
import { Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { Field, Formik, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import DeleteIcon from '@material-ui/icons/Delete';


const useStyles = makeStyles((theme: Theme) => ({
    list: {
        borderRadius: 5,
        borderWidth: 1,
        borderColor: "#878787",
        borderStyle: "solid",
        width: '100%',
        overflow: 'auto',
        height: '170px'
    },
    button: {
        marginTop: 10
    }
}));

interface CriteriaObject {
    criteria: CriteriaTypes,
    amount: number,
    text: string,
    operator: string
}

interface CriteriaTypes {
    id: number;
    criteria: string;
}

interface FormValues {
    criteria: CriteriaTypes;
    operator: string;
    amount: number;
}

export interface ICriteriaSelector {
    onListChange: any;
    error?: string;
}

const CriteriaSelector: React.FC<ICriteriaSelector> = ({ onListChange, error }) => {

    const classes = useStyles();
    const [criteriaList, setCriteriaList] = useState<any[]>([]);
    const criteriaOptions: CriteriaTypes[] = [
        { id: 0, criteria: 'Games Played' },
        { id: 1, criteria: 'Total Correct Answers' },
        { id: 2, criteria: 'Correct Games' }
    ];
    const operatorOptions: string[] = [
        ">",
        "<",
        "="
    ];

    const initialValues: FormValues = {
        amount: 0,
        criteria: { id: 0, criteria: '' },
        operator: ''
    }

    const removeCriteria = (criteriaId: string) => {
        var placeholderArr: any[] = criteriaList;

        placeholderArr = placeholderArr.filter((value, index, array) => { return value.id !== criteriaId });
        setCriteriaList(placeholderArr);
    }

    const onSubmit = (values: FormValues, setSubmitting: any, resetForm: any) => {

        let id = criteriaList.length;

        var criteria: CriteriaObject = {
            amount: values.amount,
            operator: values.operator,
            text: values.criteria.criteria + " " + values.operator + " " + values.amount,
            criteria: values.criteria
        }

        setCriteriaList([...criteriaList, { ...criteria, id: id }]);
        resetForm();
        setSubmitting(false);
    };

    useEffect(() => {
        onListChange(criteriaList);
        //eslint-disable-next-line
    }, [criteriaList]);


    return (
        <Grid container direction="column" spacing={3}>
            <Grid item container>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting, resetForm }) => onSubmit(values, setSubmitting, resetForm)}
                >
                    {({ submitForm, isSubmitting, setFieldValue, values }) => (
                        <Form style={{ width: '100%' }}>
                            <Grid item container spacing={2} justify="space-between">
                                <Grid item xs={5}>
                                    <Autocomplete
                                        id="criteria"
                                        options={criteriaOptions}
                                        getOptionLabel={(option: CriteriaTypes) => option.criteria}
                                        value={values.criteria}
                                        renderInput={(params: any) => (
                                            <Field component={TextField} {...params} name="criteria" label="Criteria" variant="filled" />
                                        )}
                                        onChange={(event, value) => {
                                            setFieldValue("criteria", value);
                                        }}
                                        getOptionSelected={(option, value) => true}

                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        options={operatorOptions}
                                        getOptionLabel={(option: any) => option}
                                        value ={values.operator}
                                        renderInput={(params: any) => (
                                            <Field component={TextField} {...params} name="operator" label="Operator" variant="outlined" />
                                        )}
                                        onChange={(event, value) => {
                                            setFieldValue("operator", value);
                                        }}
                                        getOptionSelected={(option, value) => true}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Field
                                        component={TextField}
                                        type="number"
                                        label="Amount"
                                        name="amount"
                                        variant="filled"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Button variant="contained" color="primary" onClick={submitForm}>
                                        Add
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Grid>
            <Grid container item xs={12}>
                <List component="nav" className={classes.list}>
                    {
                        criteriaList.length === 0 ?
                            <ListItem>
                                <ListItemText secondary="There is currently no criteria addded" />
                            </ListItem>
                            :
                            criteriaList.map(object =>
                                <ListItem button>
                                    <ListItemText primary={object.text} />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="delete" onClick={() => {
                                            removeCriteria(object.id);
                                        }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                    }
                </List>
            </Grid>
            <Grid item>
                <Typography color="error">{error}</Typography>
            </Grid>
        </Grid >

    );
}

export default CriteriaSelector;
